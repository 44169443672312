import React, { useState } from 'react';
import { NotesContract } from '../../../store/notes/type';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { AccessRightType } from '../../../store/meetings/types';
import styles from './note-form-access-update.module.scss';
import { updateNotes } from '../../../store/notes/slice';
import { updateNotesShared } from '../../../store/sharing/slice';
import { SendNotifications } from '../../../store/notifications/slice';
import { NotificationsType } from '../../../store/notifications/type';
import NotesFormUpdateAccessSharedComponent from './notes.form-update-access-shared.component';
import { extractDomainWithExt } from '../../../shared/utils/domainext';
import { IconClose, IconContact, IconEdit, IconGlobe, IconLock, IconMeetingsPage } from '../../../shared/utils/icon';

interface Props {
	note: NotesContract;
	close: (note: NotesContract) => void;
	storedAccessMail?: string;
}

const NotesFormUpdateAccessComponent: React.FC<Props> = ({ storedAccessMail, note, close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const modalRef = React.useRef<HTMLDivElement>(null);
	const [animation, setAnimation] = useState<boolean>(false);

	const closeModal = (body: NotesContract) => {
		setAnimation(true);
		setTimeout(() => {
			close(body);
			setAnimation(false);
		}, 300);
	};

	const { user } = useAppSelector((state) => state.user);

	const domain = extractDomainWithExt(user ? user.email : storedAccessMail ? storedAccessMail : '');

	const [selectedAccessType, setSelectedAccessType] = React.useState<AccessRightType>(note.accessRightType);

	const handleCheckboxChange = (accessData: AccessRightType) => {
		const value = accessData;
		if (
			value === AccessRightType.ONLYME ||
			value === AccessRightType.EVERYONE ||
			value === AccessRightType.SHARED ||
			value === AccessRightType.INTERN
		) {
			setSelectedAccessType(value);
		}
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		if (AccessRightType) {
			event.preventDefault();

			if (note) {
				const body = {
					id: note.id,
					title: note.title,
					text: note.text,
					graphUserId: note.graphUserId,
					graphUserName: note.graphUserName,
					meetingAttendees: note.meetingAttendees,
					graphiCalUId: note.graphiCalUId,
					meetingTitle: note.meetingTitle,
					meetingStartDate: note.meetingStartDate,
					tags: note.tags,
					updateNote: new Date(),
					createdOn: note.createdOn,
					updateOn: note.updateOn,
					accessRightType: selectedAccessType,
					accessRightCompanies: domain ? [domain] : [],
					archived: note.archived,
					projectId: note.projectId,
				};

				if (user) {
					dispatch(updateNotes({ noteId: note.id, body })).then(() => {
						closeModal(body);

						if (selectedAccessType === AccessRightType.EVERYONE) {
							const attendees = note.meetingAttendees.filter(
								(attendee) => attendee.toLowerCase() !== user.email.toLowerCase(),
							);

							attendees?.forEach((attendee) => {
								const bodyNotification = {
									id: undefined,
									userFromName: user.displayName,
									userFromMail: user.email,
									userToName: attendee,
									userToMail: attendee,
									type: NotificationsType.NEW_NOTE,
									noteId: note.id,
									todoId: undefined,
									graphiCalUId: note.graphiCalUId,
									projectId: undefined,
									meetingStartDate: note.meetingStartDate,
									itemTitle: body.title,
									visible: true,
									meetingName: note.meetingTitle,
								};

								dispatch(SendNotifications(bodyNotification));
							});
						}
					});
				} else {
					dispatch(updateNotesShared({ noteId: note.id, body })).then(() => {
						close(body);

						if (storedAccessMail && selectedAccessType === AccessRightType.EVERYONE) {
							const attendees = note.meetingAttendees.filter(
								(attendee) => attendee.toLowerCase() !== storedAccessMail.toLowerCase(),
							);

							attendees?.forEach((attendee) => {
								const bodyNotification = {
									id: undefined,
									userFromName: storedAccessMail,
									userFromMail: storedAccessMail,
									userToName: attendee,
									userToMail: attendee,
									type: NotificationsType.NEW_NOTE,
									noteId: note.id,
									todoId: undefined,
									graphiCalUId: note.graphiCalUId,
									projectId: undefined,
									meetingStartDate: note.meetingStartDate,
									itemTitle: body.title,
									visible: true,
									meetingName: note.meetingTitle,
								};

								dispatch(SendNotifications(bodyNotification));
							});
						}
					});
				}
			}
		}
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					close(note);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [modalRef]);

	return (
		<>
			<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
				<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
					<div className={styles.titleWrapper}>
						<h3>
							<IconEdit />
							{t('Update access')}
						</h3>
						<div className={styles.closeButton}>
							<div
								className={styles.icon}
								onClick={() => {
									closeModal(note);
								}}>
								<IconClose />
							</div>
						</div>
					</div>
					<form onSubmit={handleSubmit}>
						<div className={styles.flexAccess}>
							<div
								className={
									selectedAccessType === AccessRightType.EVERYONE
										? styles.selectAccess
										: styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.EVERYONE)}>
								<IconGlobe />
								{t('Public')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.INTERN ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.INTERN)}>
								<IconContact />
								{t('Intern')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.SHARED ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.SHARED)}>
								<IconMeetingsPage />
								{t('Shared')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.ONLYME ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.ONLYME)}>
								<IconLock />
								{t('Private')}
							</div>
						</div>

						{selectedAccessType === AccessRightType.SHARED && (
							<>
								<NotesFormUpdateAccessSharedComponent note={note} />
							</>
						)}
						<br />
						<button type='submit'>{t('Confirm')}</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default NotesFormUpdateAccessComponent;
