/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './day-overview.component.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import {
	getDiffInMinutes,
	getDiffInMinutesStartOfDay,
	getHourFormat,
	getNowTimeLessOneDay,
	getRightTimeSlot,
	getRightTimeZoneForCreate,
	getStartOfDay,
	getStartOfDayDate,
} from '../../../shared/utils/date.util';
import { isSameDay } from 'date-fns';
import 'react-tooltip/dist/react-tooltip.css';
import { LayoutInformation, MeetingContract, MeetingEntry } from '../../../store/meetings/types';
import { RouteSearchParams, RouteUrls } from '../../../routes/routes-config';
import MeetingCreateModal from '../../meetings/modals/meeting-create.modal';
import TooltipMeetingCalendar from '../../../shared/components/tooltip/tooltip-meeting-calendar.component';

const cx = classNames.bind(styles);

interface MeetingsOverviewProps {
	date: Date;
	hours: string[];
	meetings: MeetingContract[] | undefined;
	selectedMeeting: MeetingContract | undefined;
	toggle: () => void;
	onDateSelect: (selectDate: Date) => void;
	agenda?: boolean;
	handleDateAgenda?: (date: Date) => void;
	meetingSlotHeight?: number;
	meetingSlotTop?: number;
	selectId?: string;
}

const DayOverviewComponent: React.FC<React.PropsWithChildren<MeetingsOverviewProps>> = ({
	meetings,
	selectedMeeting,
	hours,
	date,
	toggle,
	onDateSelect,
	agenda,
	handleDateAgenda,
	meetingSlotHeight,
	meetingSlotTop,
	selectId,
}) => {
	const [toggleCreateMeeting, setToggleCreateMeeting] = React.useState(false);
	const navigate = useNavigate();
	const handleToggleCreateMeeting = () => {
		return setToggleCreateMeeting(!toggleCreateMeeting);
	};

	// --------------

	const isOverlapping = (meetingA: MeetingEntry, meetingB: MeetingEntry): boolean => {
		const startA = new Date(meetingA.meeting.start).getTime();
		const endA = new Date(meetingA.meeting.end).getTime();
		const startB = new Date(meetingB.meeting.start).getTime();
		const endB = new Date(meetingB.meeting.end).getTime();

		return startA < endB && endA > startB;
	};

	const buildMeetingEntries = (meetingList: MeetingContract[] | undefined, dateM: Date): MeetingEntry[] => {
		return meetingList
			? meetingList
					.filter((meetingWAD) => !meetingWAD.isAllDay && isSameDay(new Date(meetingWAD.start), dateM))
					.map((meetingE: MeetingContract) => {
						return new MeetingEntry(
							meetingE,
							new LayoutInformation(
								getDiffInMinutesStartOfDay(meetingE.start, dateM),
								getDiffInMinutes(meetingE.start, meetingE.end),
								getDiffInMinutesStartOfDay(meetingE.start, dateM) +
									getDiffInMinutes(meetingE.start, meetingE.end),
								0,
								undefined,
							),
						);
					})
					.sort((a, b) => a.layoutInformation.top - b.layoutInformation.top)
			: [];
	};

	const meetingsEntries: MeetingEntry[] | undefined = buildMeetingEntries(meetings, date);

	if (meetingsEntries) {
		meetingsEntries.sort((a, b) => {
			const startTimeDiff = new Date(a.meeting.start).getTime() - new Date(b.meeting.start).getTime();

			return startTimeDiff !== 0
				? startTimeDiff
				: new Date(b.meeting.end).getTime() - new Date(a.meeting.end).getTime();
		});

		const slots: { [key: number]: MeetingEntry[] } = {};

		meetingsEntries.forEach((m) => {
			const start = Math.floor(new Date(m.meeting.start).getTime() / (30 * 60 * 1000));
			const end = Math.ceil(new Date(m.meeting.end).getTime() / (30 * 60 * 1000));

			for (let i = start; i < end; i++) {
				if (!slots[i]) {
					slots[i] = [];
				}
				slots[i].push(m);
			}
		});

		meetingsEntries.forEach((meeting, index) => {
			const start = Math.floor(new Date(meeting.meeting.start).getTime() / (30 * 60 * 1000));
			const end = Math.ceil(new Date(meeting.meeting.end).getTime() / (30 * 60 * 1000));

			let maxOverlapCount = 0;
			for (let i = start; i < end; i++) {
				if (slots[i].length > maxOverlapCount) {
					maxOverlapCount = slots[i].length;
				}
			}

			const totalWidth = maxOverlapCount > 0 ? 100 / maxOverlapCount : 100;

			let leftPosition = 0;

			for (let i = 0; i < index; i++) {
				const prevMeeting = meetingsEntries[i];
				if (isOverlapping(meeting, prevMeeting)) {
					leftPosition += prevMeeting.layoutInformation?.width || 0;
				}
			}

			if (leftPosition + totalWidth > 100) {
				leftPosition = 100 - totalWidth;
			}

			meeting.layoutInformation.width = totalWidth;
			meeting.layoutInformation.left = leftPosition;
		});
	}

	// --------------

	const startOfDay = getStartOfDayDate(date);

	const [createDate, setCreateDate] = React.useState(startOfDay);

	const handleRightTimeSlot = (hour: number, min: number) => {
		return setCreateDate(getRightTimeSlot(startOfDay, hour, min));
	};

	// Agenda Conflicts

	const filteredMeetings = selectId
		? meetingsEntries.filter((meeting) => {
				return meeting.meeting.iCalUId !== selectId;
		  })
		: meetingsEntries;

	// eslint-disable-next-line array-callback-return
	const isConflict = filteredMeetings.some((meeting) => {
		if (meetingSlotHeight && meetingSlotTop) {
			const slotBottom = meetingSlotTop + meetingSlotHeight;
			const meetingBottom = meeting.layoutInformation.top + meeting.layoutInformation.height;
			return !(slotBottom <= meeting.layoutInformation.top || meetingBottom <= meetingSlotTop);
		}
	});

	return (
		<>
			<div className={styles.meetings}>
				{hours.map((hour, index) => (
					<div key={index} className={styles.meeting}>
						{date >= getNowTimeLessOneDay() ? (
							<>
								<div
									onClick={() => {
										if (!agenda) {
											handleToggleCreateMeeting();
											handleRightTimeSlot(index, 0);
										} else {
											handleDateAgenda &&
												handleDateAgenda(getRightTimeSlot(startOfDay, index, 0));
										}
									}}
									className={styles.halfHour}
									id={index.toString() + 'a'}></div>
								<div
									onClick={() => {
										if (!agenda) {
											handleToggleCreateMeeting();
											handleRightTimeSlot(index, 30);
										} else {
											handleDateAgenda &&
												handleDateAgenda(getRightTimeSlot(startOfDay, index, 30));
										}
									}}
									className={styles.halfHour}
									id={index.toString() + 'b'}></div>
							</>
						) : (
							<>
								<div
									onClick={() => {
										if (!agenda) {
											handleToggleCreateMeeting();
											handleRightTimeSlot(index, 0);
										} else {
											handleDateAgenda &&
												handleDateAgenda(getRightTimeSlot(startOfDay, index, 0));
										}
									}}
									className={styles.halfHour}
									id={index.toString() + 'a'}></div>
								<div
									onClick={() => {
										if (!agenda) {
											handleToggleCreateMeeting();
											handleRightTimeSlot(index, 30);
										} else {
											handleDateAgenda &&
												handleDateAgenda(getRightTimeSlot(startOfDay, index, 30));
										}
									}}
									className={styles.halfHour}
									id={index.toString() + 'b'}></div>
							</>
						)}
					</div>
				))}

				{meetings ? (
					<>
						{meetingsEntries
							.filter(
								(meetingNotAllDay) =>
									getDiffInMinutes(meetingNotAllDay.meeting.start, meetingNotAllDay.meeting.end) <
									1400,
							)
							?.map((meeting: MeetingEntry, index) => {
								const height = getDiffInMinutes(meeting.meeting.start, meeting.meeting.end);

								return (
									<>
										<div
											key={index}
											style={{
												top: meeting.layoutInformation.top - 12,
												height: meeting.layoutInformation.height,
												width: `${meeting.layoutInformation.width}%`,
												left: `${meeting.layoutInformation.left}%`,
											}}
											className={cx(styles.meetingSlot, {
												shortMeeting: height < 30,
											})}
											onClick={() => {
												if (!agenda) {
													onDateSelect(new Date(meeting.meeting.start));
													toggle();
													const params: any = {};
													params[RouteSearchParams.Date] = getStartOfDay(
														meeting.meeting.start,
													);
													params[RouteSearchParams.MeetingId] = meeting.meeting.iCalUId;
													navigate(
														`${RouteUrls.Meetings}?${new URLSearchParams(
															params,
														).toString()}`,
													);
												}
											}}>
											<div
												style={{
													top: meeting.layoutInformation.top,
													height: meeting.layoutInformation.height,
												}}
												className={cx(styles.meetingStatus, {
													notResponded: meeting.meeting.responseStatus === 'notResponded',
													accepted: meeting.meeting.responseStatus === 'accepted',
													declined: meeting.meeting.responseStatus === 'declined',
													organizer: meeting.meeting.responseStatus === 'organizer',
													tentativelyAccepted:
														meeting.meeting.responseStatus === 'tentativelyAccepted',
												})}></div>

											<div
												className={cx(styles.meetingSubject, {
													selected:
														meeting.meeting.graphEventId === selectedMeeting?.graphEventId,
													lessthanOneHour: height < 60,
												})}>
												<div
													data-tooltip-id={meeting.meeting.iCalUId}
													data-tooltip-content={meeting.meeting.subject}>
													{meeting.meeting.subject}
												</div>

												{height > 30 ? (
													<>
														<div className={styles.startEnd}>
															{getHourFormat(meeting.meeting.start)} -{' '}
															{getHourFormat(meeting.meeting.end)}
														</div>
													</>
												) : (
													<></>
												)}
											</div>
										</div>
										<TooltipMeetingCalendar
											id={meeting.meeting.iCalUId}
											title={meeting.meeting.subject}
										/>
									</>
								);
							})}
					</>
				) : (
					<></>
				)}
				{meetingSlotHeight && meetingSlotTop && (
					<>
						<div
							className={isConflict ? styles.conflictSlot : styles.agendaSlot}
							style={{
								top: meetingSlotTop,
								height: meetingSlotHeight,
							}}></div>
					</>
				)}
			</div>

			{toggleCreateMeeting && (
				<MeetingCreateModal
					close={handleToggleCreateMeeting}
					date={getRightTimeZoneForCreate(createDate)}
					onDateSelect={onDateSelect}
				/>
			)}
		</>
	);
};

export default DayOverviewComponent;
