/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable new-cap */
/* eslint-disable unicorn/no-null */

import { ComponentType } from 'react';
import {
	addTodosNotes,
	createTodos,
	getCurrentUserTodosAssignee,
	getSelectMeetingTodos,
} from '../../../../store/todos/slice';
import { TodosStates } from '../../../../store/todos/type';
import {
	ExtensionTag,
	NodeExtension,
	NodeExtensionSpec,
	DOMCompatibleAttributes,
	CommandFunction,
} from '@remirror/core';
import { NodeViewComponentProps } from '@remirror/react';
import { TodoInsideEditor } from './todo-inside-editor';
import {
	addTodosSelectedMeetingShare,
	createTodosShared,
	getSelectMeetingTodosForShared,
} from '../../../../store/sharing/slice';

interface TodosNodeExtensionOptions {}

export class TodosNodeExtension extends NodeExtension<TodosNodeExtensionOptions> {
	constructor(options: TodosNodeExtensionOptions = {}) {
		super(options);
	}

	get name() {
		return 'todos' as const;
	}

	createTags() {
		return [ExtensionTag.Block];
	}

	ReactComponent: ComponentType<NodeViewComponentProps> = TodoInsideEditor;

	createNodeSpec(): NodeExtensionSpec {
		return {
			attrs: {
				todoTitle: { default: '' },
				todoId: { default: '' },
			},
			content: 'block*',
			toDOM: (node) => {
				const attrs: DOMCompatibleAttributes = {
					'data-todo-title': node.attrs.todoTitle,
					'data-todo-id': node.attrs.todoId,
				};
				return ['div', attrs, 0];
			},
			parseDOM: [
				{
					attrs: {
						todoTitle: { default: '' },
						todoId: { default: '' },
					},
					tag: 'div[data-todo-editor]',
					getAttrs: (dom) => {
						const node = dom as HTMLAnchorElement;

						const todoTitle = node.dataset.todoTitle;
						const todoId = node.dataset.todoId;

						return {
							todoTitle,
							todoId,
						};
					},
				},
			],
		};
	}

	createCommands() {
		return {
			createTodo:
				(todoId: string, todoTitle: string): CommandFunction =>
				({ state, dispatch }) => {
					const { from, to } = state.selection;

					// Create a new 'todo' node with the selected text as its 'todoTitle' attribute
					const todoNode = state.schema.nodes.todos.create({ todoTitle, todoId });

					if (dispatch) {
						dispatch(state.tr.replaceWith(from, to, todoNode));
					}

					return true;
				},
			checkTodo:
				(): CommandFunction =>
				({ state }): any => {
					// Get the 'todo' node type from the schema
					const todoType = state.schema.nodes.todos;

					// Check if the node before the cursor is a 'todo' node
					const pos = state.selection.$from.pos > 0 ? state.selection.$from.pos - 1 : 0;
					const node = state.doc.nodeAt(pos);
					const isTodoNode = node && node.type === todoType;

					if (isTodoNode) {
						return [node.attrs.todoId];
					}

					const { from, to } = state.selection;
					const todoNodeIds: string[] = [];
					state.doc.nodesBetween(from, to, (nodeElement) => {
						if (nodeElement.type === todoType) {
							todoNodeIds.push(nodeElement.attrs.todoId);
						}
					});

					if (todoNodeIds.length > 0) {
						return todoNodeIds;
					}
					return [];
				},
			deleteTodoInsideEditor:
				(todoId: string): CommandFunction =>
				({ state, dispatch }) => {
					console.log('delete todo inside editor function');
					const { tr, doc, schema } = state;
					let found = false;

					// Iterate through the document to find the todo node with the given todoId
					doc.descendants((node, pos) => {
						if (node.type === schema.nodes.todos && node.attrs.todoId === todoId) {
							tr.delete(pos, pos + node.nodeSize);
							found = true;
							return false; // Stop iteration
						}
						return true;
					});

					if (found && dispatch) {
						dispatch(tr);
					}

					return found;
				},
		};
	}

	async createTodoBackend(
		title: string,
		noteId: string,
		userId: string,
		dispatch: any,
		username: string,
		userMail: string,
		graphiCalUId?: string,
		meetingGraphId?: string,
		meetingName?: string,
		meetingStartDate?: string,
		meetingAttendees?: string[],
	) {
		const attendeeEmails = meetingAttendees ? meetingAttendees : [];

		const body = {
			id: undefined,
			title,
			text: undefined,
			state: TodosStates.TODO,
			dueDate: undefined,
			duration: undefined,
			graphUserId: userId,
			noteId,
			assigneeDisplayName: userMail,
			graphiCalUId: graphiCalUId ? graphiCalUId : undefined,
			meetingGraphId: meetingGraphId ? meetingGraphId : undefined,
			meetingName: meetingName ? meetingName : undefined,
			meetingStartDate: meetingStartDate ? meetingStartDate : undefined,
			createdOn: new Date(),
			tags: undefined,
			projectId: undefined,
			assigneeName: username,
			meetingAttendees: attendeeEmails,
		};
		return dispatch(createTodos(body))
			.then((res: any) => {
				const todo = res.payload as any;

				const newTodo: any = {
					id: todo.id,
					title: todo.title,
					text: '',
					state: TodosStates.TODO,
					dueDate: null,
					duration: 0,
					graphUserId: userId,
					noteId,
					assigneeDisplayName: todo.assigneeDisplayName,
					graphiCalUId: graphiCalUId ? graphiCalUId : null,
					meetingGraphId: meetingGraphId ? meetingGraphId : null,
					meetingName: meetingName ? meetingName : null,
					meetingStartDate: meetingStartDate ? meetingStartDate : null,
					createdOn: new Date().toISOString(),
					tags: [],
					projectId: null,
					assigneeName: todo.assigneeName,
					meetingAttendees: todo.meetingAttendees,
				};

				dispatch(addTodosNotes(newTodo));
				dispatch(getCurrentUserTodosAssignee());
				if (graphiCalUId) {
					dispatch(getSelectMeetingTodos(graphiCalUId));
				}

				return todo;
			})
			.catch((error: any) => {
				console.error('Error creating a new todo', error);
			});
	}

	async createTodoBackendInvite(
		title: string,
		noteId: string,
		userId: string,
		dispatch: any,
		username: string,
		userMail: string,
		shareId?: string,
		graphiCalUId?: string,
		meetingGraphId?: string,
		meetingName?: string,
		meetingStartDate?: string,
		meetingAttendees?: string[],
	) {
		const attendeeEmails = meetingAttendees ? meetingAttendees : [];

		const body = {
			id: undefined,
			title,
			text: undefined,
			state: TodosStates.TODO,
			dueDate: undefined,
			duration: undefined,
			graphUserId: userId,
			noteId,
			assigneeDisplayName: userMail,
			graphiCalUId: graphiCalUId ? graphiCalUId : undefined,
			meetingGraphId: meetingGraphId ? meetingGraphId : undefined,
			meetingName: meetingName ? meetingName : undefined,
			meetingStartDate: meetingStartDate ? meetingStartDate : undefined,
			createdOn: new Date(),
			tags: undefined,
			projectId: undefined,
			assigneeName: username,
			meetingAttendees: attendeeEmails,
		};
		return dispatch(createTodosShared({ bodyShared: body, sharedId: shareId ? shareId : '' }))
			.then((res: any) => {
				const todo = res.payload as any;

				const newTodo: any = {
					id: todo.id,
					title: todo.title,
					text: '',
					state: TodosStates.TODO,
					dueDate: null,
					duration: 0,
					graphUserId: userId,
					noteId,
					assigneeDisplayName: todo.assigneeDisplayName,
					graphiCalUId: graphiCalUId ? graphiCalUId : null,
					meetingGraphId: meetingGraphId ? meetingGraphId : null,
					meetingName: meetingName ? meetingName : null,
					meetingStartDate: meetingStartDate ? meetingStartDate : null,
					createdOn: new Date().toISOString(),
					tags: [],
					projectId: null,
					assigneeName: todo.assigneeName,
					meetingAttendees: todo.meetingAttendees,
				};

				dispatch(addTodosSelectedMeetingShare(newTodo));
				if (graphiCalUId) {
					dispatch(getSelectMeetingTodosForShared(graphiCalUId));
				}

				return todo;
			})
			.catch((error: any) => {
				console.error('Error creating a new todo', error);
			});
	}
}
