import React from 'react';
import styles from './date-selector.component.module.scss';
import { getDayCalendarFormat, setToMidnight } from '../../../../shared/utils/date.util';
import classNames from 'classnames/bind';
import { endOfDay, isSameDay, isSameMonth, isWithinInterval, startOfDay } from 'date-fns';
import { useAppSelector } from '../../../../store/hooks';

interface SelectDateProps {
	selectedDate: Date;
	onDateSelect: (selectDate: Date) => void;
	week: Date[];
}

const DateSelectorRowComponent: React.FC<React.PropsWithChildren<SelectDateProps>> = ({
	selectedDate,
	onDateSelect,
	week,
}) => {
	const cx = classNames.bind(styles);
	const { meetingsForWeek } = useAppSelector((state) => state.meetings);

	return (
		<div className={styles.row}>
			{week.map((day, index) => (
				<div
					className={cx(styles.day, {
						selected: isSameDay(day, selectedDate),
						past: setToMidnight(day) < setToMidnight(new Date()),
						differentMonth: !isSameMonth(day, selectedDate),
						hasMeetings: meetingsForWeek?.some(
							(meeting) =>
								isSameDay(day, new Date(meeting.start)) ||
								(isWithinInterval(day, {
									start: startOfDay(new Date(meeting.start)),
									end: endOfDay(new Date(meeting.end)),
								}) &&
									!isSameDay(day, new Date(meeting.end))),
						),
					})}
					onClick={() => onDateSelect(day)}
					key={index}>
					{getDayCalendarFormat(day)}
				</div>
			))}
		</div>
	);
};

export default DateSelectorRowComponent;
