/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TodosContract, TodosStates } from '../../../store/todos/type';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import styles from './todos.form.modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCurrentUserContacts } from '../../../store/user/slice';
import { IconClose, IconEdit, IconMeetings, IconSearch, IconSimplePlus, IconTodos } from '../../../shared/utils/icon';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { getDateFormatL, getDateFormatWithYear } from '../../../shared/utils/date.util';
import {
	addTags,
	addTodosAssignee,
	createTodos,
	deleteTagsFromTodo,
	deleteTodo,
	deleteTodosAssignee,
	updateTodoAssignee,
	updateTodoNotes,
	updateTodoSelectMeeting,
	updateTodoSelectMeetingPrev,
	updateTodos,
} from '../../../store/todos/slice';
import { MeetingContract, TagContract } from '../../../store/meetings/types';
import TagsFormModal from '../../meetings/modals/tags-form.modal';
import { addLastMeetings, getLastMeetings, getMeetingsForSelectOption } from '../../../store/meetings/slice';
import {
	updateTodoMeetingPrevShared,
	updateTodoMeetingShared,
	updateTodoNotesShared,
	updateTodosShared,
} from '../../../store/sharing/slice';
import { Avatar } from '../../../shared/utils/avatar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { RouteUrls } from '../../../routes/routes-config';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import SimpleEditor from '../../../shared/utils/simple-editor';
import TodoDateSelect from '../components/date-select';
import { ContactsGraphContract } from '../../../store/user/type';
import { SendNotificationsProject, updateTodoProject } from '../../../store/project/slice';
import { NotificationsProjectType, ProjectContract } from '../../../store/project/type';
import { NotificationsType } from '../../../store/notifications/type';
import { SendNotifications } from '../../../store/notifications/slice';

interface Props {
	todo: TodosContract;
	userId: string;
	handleToggle: () => void;
	handleClose: () => void;
}

const TodosFormComponent: React.FC<Props> = ({ todo, userId, handleToggle, handleClose }) => {
	const location = useLocation();
	const { contacts, user } = useAppSelector((state) => state.user);
	const { meetingsOption } = useAppSelector((state) => state.meetings);
	const { projects } = useAppSelector((state) => state.project);
	const [updatedTodo, setUpdatedTodo] = React.useState(todo);
	const [newTags, setNewTags] = React.useState<Array<{ tag: string; color: string }>>([]);
	const [newTagsC, setNewTagsC] = React.useState<Array<{ title: string; color: string }>>([]);
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const params = useParams();
	const shareId = params.shareID;

	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	// const handleClose = () => {
	// 	handleToggle();
	// };

	const [meetingTodo, setMeetingTodo] = React.useState<MeetingContract>();

	const close = () => {
		if (location.search.includes('?create')) {
			dispatch(deleteTodo(updatedTodo.id)).then(() => {
				dispatch(deleteTodosAssignee(updatedTodo.id));
			});
		}
		handleClose();
	};

	React.useEffect(() => {
		dispatch(getLastMeetings()).then((res) => {
			const m = res.payload as MeetingContract[];

			dispatch(addLastMeetings(m));
		});
		if (updatedTodo && updatedTodo.graphiCalUId !== null) {
			const body = {
				graphEventId: updatedTodo.meetingGraphId,
				iCalUId: updatedTodo.graphiCalUId,
				subject: updatedTodo.meetingName,
				start: updatedTodo.meetingStartDate,
			};

			setMeetingTodo(body as MeetingContract);
		}
	}, [updatedTodo]);

	// --------------

	const [timeType, setTimeType] = React.useState('minutes');

	type FormData = {
		name: string;
		assignee: string;
		assigneeName: string;
		description: string;
		dueDate: Date;
		meetingId: string;
		duration: number;
		timeType: string;
		meetingName: string;
		meetingStartDate: string;
		meeting: MeetingContract;
		state: TodosStates;
		project: ProjectContract;
	};

	const dataSchema = yup.object().shape({});

	const { register, handleSubmit, control, setValue, trigger } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	// -------------

	const onSubmit = (data: FormData) => {
		handleClose();
		if (updatedTodo && user) {
			if (location.search.includes('?create')) {
				const attendeeEmails = data.meeting
					? data.meeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
					: updatedTodo.meetingAttendees;
				const body = {
					id: undefined,
					title: data.name,
					text: data.description,
					state: data.state,
					dueDate: new Date(data.dueDate).toUTCString(),
					duration: timeType === 'minutes' ? data.duration : data.duration / 60,
					graphUserId: updatedTodo.graphUserId,
					noteId: undefined,
					assigneeDisplayName: data.assignee ? data.assignee : updatedTodo.assigneeDisplayName,
					graphiCalUId: data.meeting ? data.meeting.iCalUId : updatedTodo.graphiCalUId,
					meetingGraphId: data.meeting ? data.meeting.graphEventId : updatedTodo.meetingGraphId,
					meetingName: data.meeting ? data.meeting.subject : updatedTodo.meetingName,
					meetingStartDate: data.meeting ? data.meeting.start : updatedTodo.meetingStartDate,
					createdOn: new Date(),
					tags: undefined,
					assigneeName: data.assigneeName ? data.assigneeName : updatedTodo.assigneeName,
					archived: updatedTodo.archived,
					projectId: data.project ? data.project.id : undefined,
					meetingAttendees: attendeeEmails,
					columnUserId: null,
					columnProjectId: null,
				};

				dispatch(createTodos(body)).then((res) => {
					const todoN = res.payload as any;
					if (user) {
						const newTodo = {
							id: todoN.id,
							title: todoN.title,
							text: todoN.text,
							state: todoN.state,
							dueDate: todoN.dueDate,
							duration: todoN.duration,
							graphUserId: todoN.graphUserId,
							noteId: todoN.noteId,
							assigneeDisplayName: todoN.assigneeDisplayName,
							graphiCalUId: todoN.graphiCalUId,
							meetingGraphId: todoN.meetingGraphId,
							meetingName: todoN.meetingName,
							meetingStartDate: todoN.meetingStartDate,
							createdOn: new Date().toISOString(),
							tags: newTagsC,
							assigneeName: todoN.assigneeName,
							archived: todoN.archived,
							projectId: todoN.projectId,
							meetingAttendees: todoN.meetingAttendees,
							columnUserId: null,
							columnProjectId: null,
						};
						dispatch(addTodosAssignee(newTodo));

						if (todoN.assigneeDisplayName !== user.userName) {
							const bodyNotification: any = {
								id: undefined,
								userFromName: user.displayName,
								userFromMail: user.userName,
								userToName: todoN.assigneeName,
								userToMail: todoN.assigneeDisplayName,
								type: NotificationsType.NEW_TODO,
								noteId: undefined,
								todoId: todoN.id,
								graphiCalUId: undefined,
								meetingStartDate: undefined,
								itemTitle: todoN.title,
								visible: true,
								meetingName: undefined,
							};

							dispatch(SendNotifications(bodyNotification));
						}

						if (newTags.length !== 0) {
							newTags.forEach((tag) => {
								const bodyTag = {
									tag: tag.tag,
									color: tag.color,
									todoId: todoN.id,
								};

								dispatch(addTags(bodyTag));
							});
						}

						navigate(RouteUrls.Todos);
					}
				});
			} else {
				const attendeeEmails = data.meeting
					? data.meeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
					: updatedTodo.meetingAttendees;

				const body = {
					id: updatedTodo.id,
					title: data.name,
					text: data.description,
					state: data.state,
					dueDate: new Date(data.dueDate).toUTCString(),
					duration: timeType === 'minutes' ? data.duration : data.duration / 60,
					graphUserId: updatedTodo.graphUserId,
					noteId: updatedTodo.noteId,
					assigneeDisplayName: data.assignee,
					graphiCalUId: data.meeting ? data.meeting.iCalUId : updatedTodo.graphiCalUId,
					meetingGraphId: data.meeting ? data.meeting.graphEventId : updatedTodo.meetingGraphId,
					meetingName: data.meeting ? data.meeting.subject : updatedTodo.meetingName,
					meetingStartDate: data.meeting ? data.meeting.start : updatedTodo.meetingStartDate,
					createdOn: new Date(),
					tags: undefined,
					assigneeName: data.assigneeName,
					archived: updatedTodo.archived,
					projectId: data.project ? data.project.id : undefined,
					meetingAttendees: attendeeEmails,
				};

				dispatch(updateTodos({ body, todoId: updatedTodo.id })).then((res) => {
					if (res.meta.requestStatus === 'fulfilled') {
						const newTodo = res.payload as TodosContract;

						dispatch(updateTodoAssignee({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoSelectMeeting({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoSelectMeetingPrev({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoNotes({ todoId: newTodo.id, newTodo }));
						dispatch(updateTodoProject({ todoId: newTodo.id, newTodo }));

						if (data.project.id && newTodo.projectId !== todo.projectId) {
							const bodyNotification = {
								id: '',
								userFromName: user.name,
								userFromMail: user.email,
								type: NotificationsProjectType.NEW_TODO,
								noteId: '',
								todoId: updatedTodo.id,
								todoDueDate: new Date(data.dueDate).toUTCString(),
								itemTitle: data.name,
								graphiCalUId: '',
								meetingStartDate: '',
								meetingEndDate: '',
								meetingName: '',
								meetingAttendees: [],
								projectId: data.project.id,
								userSeenNotif: [],
								todoAssignee: data.assignee,
							};

							dispatch(SendNotificationsProject(bodyNotification));
						}

						// if (newTodo.assigneeDisplayName !== todo.assigneeDisplayName) {
						// 	const bodyNotification = {
						// 		id: '',
						// 		userFromName: user.name,
						// 		userFromMail: user.email,
						// 		type: NotificationsProjectType.NEW_TODO,
						// 		noteId: '',
						// 		todoId: updatedTodo.id,
						// 		todoDueDate: new Date(data.dueDate).toUTCString(),
						// 		itemTitle: data.name,
						// 		graphiCalUId: '',
						// 		meetingStartDate: '',
						// 		meetingEndDate: '',
						// 		meetingName: '',
						// 		meetingAttendees: [],
						// 		projectId: data.project.id,
						// 		userSeenNotif: [],
						// 		todoAssignee: data.assignee,
						// 	};

						// 	dispatch(SendNotificationsProject(bodyNotification));
						// }

						if (location.search.includes('?view')) {
							navigate(RouteUrls.Todos);
						}
					}
				});
			}
		} else if (storedAccessMail) {
			const body = {
				id: updatedTodo.id,
				title: data.name,
				text: data.description,
				state: data.state,
				dueDate: new Date(data.dueDate).toUTCString(),
				duration: timeType === 'minutes' ? data.duration : data.duration / 60,
				graphUserId: updatedTodo.graphUserId,
				noteId: updatedTodo.noteId,
				assigneeDisplayName: data.assignee,
				graphiCalUId: updatedTodo.graphiCalUId,
				meetingGraphId: updatedTodo.meetingGraphId,
				meetingName: updatedTodo.meetingName,
				meetingStartDate: updatedTodo.meetingStartDate,
				createdOn: updatedTodo.createdOn,
				tags: [],
				assigneeName: data.assigneeName,
				archived: updatedTodo.archived,
				projectId: updatedTodo.projectId,
				meetingAttendees: updatedTodo.meetingAttendees,
			};

			dispatch(updateTodosShared({ body, todoId: updatedTodo.id })).then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					const newTodo = res.payload as TodosContract;

					dispatch(updateTodoMeetingShared({ todoId: newTodo.id, newTodo }));
					dispatch(updateTodoMeetingPrevShared({ todoId: newTodo.id, newTodo }));
					dispatch(updateTodoNotesShared({ todoId: newTodo.id, newTodo }));
				}
			});
		}
	};

	let timeoutId: any;

	const [isShearching, setIsShearching] = React.useState('');
	const [noMatch, setNotMatch] = React.useState(false);
	const [contactNM, setContactNM] = React.useState<{ mail: string; name: string }>();

	const onChangeHandler = (filter: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setIsShearching(filter);

			if (user) {
				dispatch(getCurrentUserContacts(filter)).then((res) => {
					const response = res.payload as ContactsGraphContract[];
					setNotMatch(false);

					if (response?.length < 1) {
						setNotMatch(true);
						setContactNM({ mail: filter, name: filter });
					}
				});
			} else {
				setNotMatch(true);
				setContactNM({ mail: filter, name: filter });
			}
		}, 200);
	};

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	// -----

	const [tagsModalOpen, setTagsModalOpen] = React.useState(false);

	const deleteTag = (tag: TagContract) => {
		if (tag) {
			dispatch(deleteTagsFromTodo({ todoId: updatedTodo.id, tagsId: tag.id as string })).then(() => {
				setUpdatedTodo((prevTodo) => {
					const updatedTags = prevTodo.tags.filter((td) => td.id !== tag.id);
					return { ...prevTodo, tags: updatedTags };
				});
			});

			const updatesTags = newTags.filter((tagToDelete) => tagToDelete.tag !== tag.title);
			setNewTags(updatesTags);
			const updatesTagsC = newTagsC.filter((tagToDelete) => tagToDelete.title !== tag.title);
			setNewTagsC(updatesTagsC);
		}
	};

	const closeTagsModal = (tag: string, color: string) => {
		if (updatedTodo) {
			const body = {
				tag,
				color,
				todoId: updatedTodo.id,
			};

			dispatch(addTags(body)).then((res) => {
				setUpdatedTodo((prevTodo) => {
					const updatedTags = [...prevTodo.tags, res.payload as TagContract];
					return { ...prevTodo, tags: updatedTags };
				});
				setTagsModalOpen(false);
			});

			const newTagTB = { tag, color };
			const newTagTBC = { title: tag, color };
			const updatedTagsToBeCreated = [...newTags, newTagTB];
			const updatedTagsToBeCreatedC = [...newTagsC, newTagTBC];
			setNewTags(updatedTagsToBeCreated);
			setNewTagsC(updatedTagsToBeCreatedC);
		}
	};

	const openTagsModal = () => {
		return setTagsModalOpen(!tagsModalOpen);
	};
	// ----

	const onChangeHandlerMeeting = (filter: string) => {
		const clearFilter = filter.replace(/\(\d{2}\/\d{2}\/\d{4}\)|[éè\\/]/g, '');

		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getMeetingsForSelectOption(clearFilter));
		}, 200);
	};

	// ---

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [attendeeMenu, setAttendeeMenu] = React.useState(false);

	const handleMenu = () => {
		setAttendeeMenu(!attendeeMenu);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	// ___

	const handleTextChange = (content: string) => {
		setValue('description', content);
		trigger('description');
	};

	const [datePicker, setDatePicker] = React.useState(false);

	const handleDatePicker = () => {
		setDatePicker(!datePicker);
	};

	const [date, setDate] = React.useState(todo.dueDate ? new Date(todo.dueDate) : undefined);

	const handleDateSelect = (dateSelectPicker: Date) => {
		setDate(dateSelectPicker);
		setValue('dueDate', dateSelectPicker);
		trigger('dueDate');
		handleDatePicker();
	};

	return (
		<>
			<div>
				{userId === updatedTodo.graphUserId ? (
					<h3>
						<span>
							<IconTodos />
						</span>

						{location.search.includes('?create') ? (
							<> {t('Create your todo')}</>
						) : (
							<>{t('Update your todo')}</>
						)}
					</h3>
				) : user?.userName === updatedTodo.assigneeDisplayName ? (
					<h3>
						<span>
							<IconTodos />
						</span>

						{location.search.includes('?create') ? (
							<> {t('Create your todo')}</>
						) : (
							<>{t('Update your todo')}</>
						)}
					</h3>
				) : userId === updatedTodo.assigneeDisplayName ? (
					<h3>
						<span>
							<IconTodos />
						</span>

						{location.search.includes('?create') ? (
							<> {t('Create your todo')}</>
						) : (
							<>{t('Update your todo')}</>
						)}
					</h3>
				) : (
					<h3>
						<span>
							<IconTodos />
						</span>
						{t('Todos')}
					</h3>
				)}

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.inputWrapper}>
						<label>{t('Title')}</label>
						<input defaultValue={updatedTodo.title} {...register('name')} />
					</div>
					<div>
						<div className={styles.flex}>
							<div className={styles.attendeeLayout} onClick={() => handleMenu()} />
							<div className={styles.attendeeWrapper}>
								<Avatar
									name={updatedTodo.assigneeName}
									mail={updatedTodo.assigneeDisplayName}
									index={8}
								/>
								<p className={styles.nameAt}>{updatedTodo.assigneeName}</p>
							</div>

							{!location.search.includes('?create') && <IconEdit />}

							{!location.search.includes('?create') && attendeeMenu && (
								<div className={styles.modalAttendee} ref={modalRef}>
									<div className={styles.searchInput}>
										<span>
											<IconSearch />
										</span>
										<input type='text' onChange={(e) => onChangeHandler(e.target.value)} />
									</div>

									<div className={styles.containerList}>
										{user &&
											user?.userCompany
												.filter((userC) =>
													userC.userName.toLowerCase().includes(isShearching.toLowerCase()),
												)
												.map((userC, index) => (
													<div
														className={styles.list}
														key={index}
														onClick={() => {
															setUpdatedTodo({
																...updatedTodo,
																assigneeDisplayName: userC.userName,
																assigneeName: userC.name,
															});
															setValue('assignee', userC.userName);
															setValue('assigneeName', userC.name);
															handleMenu();
															setIsShearching('');
														}}>
														<div className={styles.name}>
															<Avatar
																name={userC.name}
																mail={userC.userName}
																index={index}
															/>
															<span>{userC.name}</span>
														</div>
													</div>
												))}
										{updatedTodo.graphiCalUId &&
											updatedTodo.meetingAttendees &&
											updatedTodo.meetingAttendees?.length > 0 &&
											updatedTodo.meetingAttendees
												.filter((userC) =>
													userC.toLowerCase().includes(isShearching.toLowerCase()),
												)
												.map((userC, index) => (
													<div
														className={styles.list}
														key={index}
														onClick={() => {
															setUpdatedTodo({
																...updatedTodo,
																assigneeDisplayName: userC,
																assigneeName: userC,
															});
															setValue('assignee', userC);
															setValue('assigneeName', userC);
															handleMenu();
															setIsShearching('');
														}}>
														<div className={styles.name}>
															<Avatar name={userC} mail={userC} index={index} />
															<span>{userC}</span>
														</div>
													</div>
												))}
										{user &&
											isShearching &&
											contacts &&
											contacts
												.filter(
													(contact) =>
														contact.emailAddresses &&
														contact.emailAddresses.length > 0 &&
														contact.emailAddresses[0].address !== '' &&
														contact.displayName !== '',
												)
												.map((contact, index) => (
													<div
														className={styles.list}
														key={index}
														onClick={() => {
															setUpdatedTodo({
																...updatedTodo,
																assigneeDisplayName: contact.emailAddresses[0].address,
																assigneeName: contact.displayName,
															});
															setValue('assignee', contact.emailAddresses[0].address);
															setValue('assigneeName', contact.displayName);
															handleMenu();
															setIsShearching('');
														}}>
														<div className={styles.name}>
															<Avatar
																name={contact.displayName}
																mail={contact.emailAddresses[0].address}
																index={index}
															/>
															<span>{contact.displayName}</span>
														</div>
													</div>
												))}
										{noMatch && contactNM && isEmail(contactNM.mail) === true && (
											<div
												className={styles.list}
												onClick={() => {
													setUpdatedTodo({
														...updatedTodo,
														assigneeDisplayName: contactNM.mail,
														assigneeName: contactNM.name,
													});
													setValue('assignee', contactNM.mail);
													setValue('assigneeName', contactNM.name);
													handleMenu();
													setIsShearching('');
												}}>
												<div className={styles.name}>
													<Avatar name={contactNM.name} mail={contactNM.mail} index={1} />
													<span>{contactNM.name}</span>
												</div>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
					<input
						style={{ visibility: 'hidden', display: 'none' }}
						type='text'
						defaultValue={updatedTodo ? updatedTodo.dueDate : undefined}
						{...register('dueDate')}
					/>
					<div className={styles.dueDate}>
						<div className={styles.timeslot}>
							<div className={styles.startDate}>
								<div className={styles.startDateButton} onClick={() => handleDatePicker()}>
									<div>
										<span className={styles.titleLabel}>{t('Due date')}</span>

										<div className={styles.datePickerTime}>
											<span>
												{date !== null && date !== undefined
													? getDateFormatWithYear(date.toString())
													: t('No due date')}
											</span>
										</div>
									</div>

									<div className={styles.icon}>
										<IconMeetings />
									</div>
								</div>

								{datePicker && (
									<TodoDateSelect
										date={date ? date : new Date()}
										handleDatePicker={handleDateSelect}
										close={handleDatePicker}
									/>
								)}
							</div>
						</div>
					</div>
					<div className={styles.inputWrapperTime}>
						<label htmlFor='duration'>{t('Duration')}</label>
						<div className={styles.inputFlex}>
							<input
								type='number'
								defaultValue={updatedTodo.duration !== null ? updatedTodo.duration : 0}
								{...register('duration')}
							/>

							<select
								{...register('timeType')}
								onChange={(e) => {
									setTimeType(e.target.value);
								}}>
								<option value='minutes'>min</option>
								<option value='hours'>h</option>
							</select>
						</div>
					</div>

					<div className={styles.inputWrapper}>
						<label htmlFor='state'>{t('State')}</label>
						<select {...register('state')} defaultValue={todo.state}>
							<option value={TodosStates.TODO}>{t('To do')}</option>
							<option value={TodosStates.DOING}>{t('Doing')}</option>
							<option value={TodosStates.BLOCKED}>{t('Blocked')}</option>
							<option value={TodosStates.DONE}>{t('Done')}</option>
						</select>
					</div>
					<div>
						<div className={styles.containerTags}>
							<div
								className={styles.buttonTags}
								onClick={() => {
									if (user) {
										openTagsModal();
									}
								}}>
								<div className={styles.tagsWrapper}>
									<p>{t('Tags')}</p>
									<div className={styles.tags}>
										{updatedTodo?.tags.map((tag) => (
											<div
												key={tag.id}
												className={styles.tag}
												style={{
													border: `1px solid ${tag.color}`,
													backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
												}}>
												<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>
												{user?.graphUserId === updatedTodo.graphUserId && (
													<div
														style={{ color: tag.color, fontWeight: '600' }}
														className={styles.iconDelete}
														onClick={() => {
															deleteTag(tag);
														}}>
														<IconClose />
													</div>
												)}
											</div>
										))}
									</div>
								</div>
								{user && (
									<div className={styles.icon}>
										<IconSimplePlus />
									</div>
								)}
							</div>

							{tagsModalOpen && (
								<TagsFormModal
									close={closeTagsModal}
									handleSimpleClose={openTagsModal}
									todo={updatedTodo}
									type='todos'
								/>
							)}
						</div>
					</div>
					<div className={styles.description}>
						<input
							style={{ display: 'none' }}
							type='text'
							defaultValue={todo.text}
							{...register('description')}
						/>
						<SimpleEditor content={todo.text} onChangeHandlerText={handleTextChange} />
					</div>
					<div className={styles.dueDateAu}>
						{/* <span>{t('Meeting')}</span> */}
						<>
							{meetingsOption && meetingTodo && user && (
								<Controller
									name='meeting'
									control={control}
									defaultValue={meetingTodo}
									render={({ field }) => (
										<Stack spacing={2} sx={{ width: '100%' }}>
											<Autocomplete
												id='meeting'
												disableClearable
												{...field}
												onInputChange={(event, newValue) => {
													onChangeHandlerMeeting(newValue);
												}}
												onChange={(event, newValue) => {
													field.onChange(newValue);
												}}
												options={meetingsOption.map((meeting: MeetingContract) => meeting)}
												getOptionLabel={(meeting: MeetingContract) =>
													`${meeting.subject} (${getDateFormatL(meeting.start)})`
												}
												renderInput={(param) => (
													<TextField
														placeholder={t<string>('Meeting')}
														sx={{
															'& .MuiInputBase-root': {
																padding: 0,
																margin: 0,
															},
															'& .MuiOutlinedInput-root': {
																border: 'none',
																paddingLeft: '14px',
																paddingBlock: '12px',
																fontSize: '14px',
																width: '100%',
															},
															'& .MuiOutlinedInput-notchedOutline': {
																border: 'none',
															},
															'& .MuiSvgIcon-fontSizeMedium': {
																padding: 0,
															},
															'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
																{
																	padding: 0,
																	margin: 0,
																},
															'borderRadius': '6px',
															'& .MuiSvgIcon-root': {
																'color': 'black',
																'transform': 'scale(1.3)',
																'&:hover': {
																	backgroundColor: 'white',
																},
															},
															'width': '100%',
															'border': 'transparent',
															'& input::placeholder': {
																opacity: 1,
																fontStyle: 'italic',
																color: '#707070',
															},
														}}
														{...param}
													/>
												)}
											/>
										</Stack>
									)}
								/>
							)}
							{user && meetingsOption && !meetingTodo && (
								<Controller
									name='meeting'
									control={control}
									defaultValue={meetingTodo}
									render={({ field }) => (
										<Stack spacing={2}>
											<Autocomplete
												id='meeting'
												disableClearable
												{...field}
												onInputChange={(event, newValue) => {
													onChangeHandlerMeeting(newValue);
												}}
												onChange={(event, newValue) => {
													field.onChange(newValue);
												}}
												options={meetingsOption.map((meeting: MeetingContract) => meeting)}
												getOptionLabel={(meeting: MeetingContract) =>
													`${meeting.subject} (${getDateFormatL(meeting.start)})`
												}
												renderInput={(param) => (
													<TextField
														placeholder={t<string>('Meeting')}
														sx={{
															'& .MuiInputBase-root': {
																padding: 0,
																margin: 0,
															},
															'& .MuiOutlinedInput-root': {
																border: 'none',
																paddingLeft: '14px',
																paddingBlock: '12px',
																fontSize: '14px',
																width: '100%',
															},
															'& .MuiOutlinedInput-notchedOutline': {
																border: 'none',
															},
															'& .MuiSvgIcon-fontSizeMedium': {
																padding: 0,
															},
															'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
																{
																	padding: 0,
																	margin: 0,
																},
															'borderRadius': '6px',
															'& .MuiSvgIcon-root': {
																'color': 'black',
																'transform': 'scale(1.3)',
																'&:hover': {
																	backgroundColor: 'white',
																},
															},
															'width': '100%',
															'border': 'transparent',
															'& input::placeholder': {
																opacity: 1,
																fontStyle: 'italic',
																color: '#707070',
															},
														}}
														{...param}
													/>
												)}
											/>
										</Stack>
									)}
								/>
							)}
							{!user && updatedTodo.graphiCalUId !== null ? (
								<input
									type='text'
									readOnly
									value={`${updatedTodo.meetingName} (${getDateFormatL(
										updatedTodo.meetingStartDate,
									)})`}
								/>
							) : !user && updatedTodo.graphiCalUId === null ? (
								<input type='text' readOnly />
							) : (
								<></>
							)}
						</>
					</div>
					<div className={styles.dueDateAu}>
						{/* <span>{t('Project')}</span> */}
						{user && projects && (
							<Controller
								name='project'
								control={control}
								defaultValue={projects.find((project) => project.id === updatedTodo.projectId)}
								render={({ field }) => (
									<Stack spacing={2}>
										<Autocomplete
											id='project'
											disableClearable
											{...field}
											onInputChange={(event, newValue) => {
												onChangeHandlerMeeting(newValue);
											}}
											onChange={(event, newValue) => {
												field.onChange(newValue);
											}}
											options={projects.map((project) => project)}
											getOptionLabel={(project: ProjectContract) =>
												`${project.projectName} (${getDateFormatL(project.createdOn)})`
											}
											renderInput={(param) => (
												<TextField
													placeholder={t<string>('Project')}
													sx={{
														'& .MuiInputBase-root': {
															padding: 0,
															margin: 0,
														},
														'& .MuiOutlinedInput-root': {
															border: 'none',
															paddingLeft: '14px',
															paddingBlock: '12px',
															fontSize: '14px',
															width: '100%',
														},
														'& .MuiOutlinedInput-notchedOutline': {
															border: 'none',
														},
														'& .MuiSvgIcon-fontSizeMedium': {
															padding: 0,
														},
														'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
															{
																padding: 0,
																margin: 0,
															},
														'borderRadius': '6px',
														'& .MuiSvgIcon-root': {
															'color': 'black',
															'transform': 'scale(1.3)',
															'&:hover': {
																backgroundColor: 'white',
															},
														},
														'width': '100%',
														'border': 'transparent',
														'& input::placeholder': {
															opacity: 1,
															fontStyle: 'italic',
															color: '#707070',
														},
													}}
													{...param}
												/>
											)}
										/>
									</Stack>
								)}
							/>
						)}
					</div>

					{userId === updatedTodo.graphUserId ? (
						<div className={styles.btnContainer}>
							<button className={styles.cancel} onClick={() => close()} type='button'>
								{t('Cancel')}
							</button>
							<button type='submit'>
								{location.search.includes('?create') ? <> {t('Create')}</> : <>{t('Save')}</>}
							</button>
						</div>
					) : userId === updatedTodo.assigneeDisplayName ? (
						<div className={styles.btnContainer}>
							<button className={styles.cancel} onClick={() => close()} type='button'>
								{t('Cancel')}
							</button>
							<button type='submit'>
								{location.search.includes('?create') ? <> {t('Create')}</> : <>{t('Save')}</>}
							</button>
						</div>
					) : user?.userName === updatedTodo.assigneeDisplayName ? (
						<div className={styles.btnContainer}>
							<button className={styles.cancel} onClick={() => close()} type='button'>
								{t('Cancel')}
							</button>
							<button type='submit'>
								{location.search.includes('?create') ? <> {t('Create')}</> : <>{t('Save')}</>}
							</button>
						</div>
					) : (
						<></>
					)}
				</form>
			</div>
		</>
	);
};

export default TodosFormComponent;
