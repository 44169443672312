import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import styles from './meeting-details-form.module.scss';
import { MeetingsProjectContract, ProjectContract } from '../../../../../../../store/project/type';
import { getDateFormatL } from '../../../../../../../shared/utils/date.util';
import { linkMeetingProject } from '../../../../../../../store/project/slice';
import { MeetingContract } from '../../../../../../../store/meetings/types';
import { updateSelectedMeeting, updateSelectedMeetingForMeetings } from '../../../../../../../store/meetings/slice';
import { IconClose, IconLink } from '../../../../../../../shared/utils/icon';
import { toast } from '../../../../../../../shared/components/modals/toast/toast-manager';

export interface MeetingDetailsHeaderProps {
	handleClose: () => void;
}

const MeetingDetailsProjectConnect = ({ handleClose }: MeetingDetailsHeaderProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { projects } = useAppSelector((state) => state.project);
	const modalRef = React.useRef<HTMLDivElement>(null);
	const [animation, setAnimation] = useState<boolean>(false);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleClose();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleClose]);

	type FormData = {
		project: ProjectContract;
	};

	const dataSchema = yup.object().shape({
		project: yup.object(),
	});

	const { handleSubmit, control } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const onSubmit = (data: FormData) => {
		if (data.project && user && selectedMeeting) {
			const newMeeting = selectedMeeting as MeetingContract;
			const attendeeEmails = newMeeting
				? newMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
				: [];

			const bodyMeetingProject: MeetingsProjectContract = {
				id: '',
				projectId: data.project.id,
				graphiCalUId: newMeeting.iCalUId,
				meetingName: newMeeting.subject,
				meetingStartDate: newMeeting.start,
				meetingEndDate: newMeeting.end,
				meetingAttendees: attendeeEmails,
				tags: [],
			};
			dispatch(linkMeetingProject({ body: bodyMeetingProject, projectId: data.project.id })).then((res) => {
				const val = {
					subject: selectedMeeting.subject,
					start: selectedMeeting.start,
					end: selectedMeeting.end,
					location: selectedMeeting.location,
					attendees: selectedMeeting.attendees,
					group: selectedMeeting.group,
					isOnlineMeeting: selectedMeeting.isOnlineMeeting,
					onlineMeetingProvider: 'teamsForBusiness',
					bodyContent: selectedMeeting.bodyContent,
					isAllDay: selectedMeeting.isAllDay,
					projectId: data.project
						? data.project.id
						: selectedMeeting.projectId
						? selectedMeeting.projectId
						: '',
					IcalUid: selectedMeeting.iCalUId,
				};

				if (res.meta.requestStatus === 'fulfilled') {
					dispatch(updateSelectedMeeting(val));
					dispatch(updateSelectedMeetingForMeetings(val));
					toast.show({
						id: user?.graphUserId,
						title: t('Project connect success') as string,
						duration: 10000,
						type: 'success',
					});
				}

				closeModal();
			});
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.titleWrapper}>
					<h3>
						<IconLink />
						{t('Connected project')}
					</h3>
					<div className={styles.closeButton}>
						<div
							className={styles.icon}
							onClick={() => {
								closeModal();
							}}>
							<IconClose />
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.dueDateAu}>
						{user && projects && selectedMeeting && (
							<Controller
								name='project'
								control={control}
								defaultValue={projects.find((project) => project.id === selectedMeeting.projectId)}
								render={({ field }) => (
									<Stack spacing={2} sx={{ width: '100%' }}>
										<Autocomplete
											id='project'
											disableClearable
											{...field}
											onChange={(event, newValue) => {
												field.onChange(newValue);
											}}
											options={projects.map((project) => project)}
											getOptionLabel={(project: ProjectContract) =>
												`${project.projectName} (${getDateFormatL(project.createdOn)})`
											}
											renderInput={(param) => (
												<TextField
													placeholder={t<string>('Project')}
													sx={{
														'& .MuiInputBase-root': {
															padding: 0,
															margin: 0,
														},
														'& .MuiOutlinedInput-root': {
															border: 'none',
															paddingLeft: '14px',
															paddingBlock: '12px',
															fontSize: '14px',
														},
														'& .MuiOutlinedInput-notchedOutline': {
															border: 'none',
														},
														'& .MuiSvgIcon-fontSizeMedium': {
															padding: 0,
														},
														'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
															{
																padding: 0,
																margin: 0,
															},
														'borderRadius': '6px',
														'& .MuiSvgIcon-root': {
															'height': '100%',
															'padding': 0,
															'margin': 0,
															'color': 'black',
															'transform': 'scale(1.3)',
															'&:hover': {
																backgroundColor: 'white',
															},
														},
														'border': 'transparent',
														'& input::placeholder': {
															opacity: 1,
															fontStyle: 'italic',
															color: '#707070',
														},
													}}
													{...param}
												/>
											)}
										/>
									</Stack>
								)}
							/>
						)}
					</div>
					<div className={styles.btnContainer}>
						<button className={styles.addBtn}>{t('Save')}</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default MeetingDetailsProjectConnect;
