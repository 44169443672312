import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import ErrorUtil from '../../shared/utils/error.util';
import { createRequestStatus, RequestStatus, RequestStatusType, UpdateUserRole } from '../shared/types';
import SettingsAPI from '../../api/settings-api';
import { UserContract } from '../user/type';
import { CompanyContract, CompanyUpdate, RequestContract } from './type';
import SettingsFivedaysAPI from '../../api/settings-fivedays-api';
import { BillingPlan } from '../billing/type';

interface SettingsState {
	company?: CompanyContract;
	companyRequestStatus: RequestStatus;

	companies?: CompanyContract[];
	companiesRequestStatus: RequestStatus;

	companyRequests?: RequestContract[];
	companyRequestsRequestStatus: RequestStatus;
}

const initialState: SettingsState = {
	company: undefined,
	companyRequestStatus: createRequestStatus(RequestStatusType.New),

	companies: undefined,
	companiesRequestStatus: createRequestStatus(RequestStatusType.New),

	companyRequests: undefined,
	companyRequestsRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getCompanyForUser = createAsyncThunk('settings/getCompany', async (): Promise<CompanyContract> => {
	return SettingsAPI.getCompanyUser();
});

export const updateUser = createAsyncThunk(
	'settings/users/update',
	async (body: UpdateUserRole): Promise<UserContract> => {
		return SettingsAPI.updateUser(body);
	},
);

export const revokeUser = createAsyncThunk(
	'settings/users/revoke',
	async (userToRevokeId: string): Promise<undefined> => {
		return SettingsAPI.deleteUser(userToRevokeId);
	},
);

export const updateCompany = createAsyncThunk('settings/company/update', async (body: FormData): Promise<FormData> => {
	return SettingsAPI.updateCompany(body);
});

export const updateCompanyAllowOpt = createAsyncThunk(
	'settings/company/update/users/allow',
	async (): Promise<CompanyContract> => {
		return SettingsAPI.updateUserAllowOption();
	},
);

export const deleteCompanyLogo = createAsyncThunk('settings/company/logo/delete', async (): Promise<undefined> => {
	return SettingsAPI.deleteCompanyLogo();
});

export const downloadCompanyLogo = createAsyncThunk('settings/company/logo/download', async (): Promise<string> => {
	const file: ArrayBuffer | Blob = await SettingsAPI.downloadCompanyLogo();
	const blob = file instanceof Blob ? file : new Blob([file]);

	const reader = new FileReader();
	return new Promise<string>((resolve, reject) => {
		reader.onloadend = () => {
			const result = reader.result;
			if (typeof result === 'string') {
				resolve(result);
			} else {
				reject(new Error('Failed to convert file to string'));
			}
		};
		reader.readAsDataURL(blob);
	});
});

export const acceptOrRevoqueARequestForACompany = createAsyncThunk(
	'settings/company/request/accept',
	async (body: RequestContract): Promise<RequestContract> => {
		return SettingsAPI.acceptOrRevoqueARequest(body);
	},
);

export const getAllRequestForACompany = createAsyncThunk(
	'settings/company/requests',
	async (): Promise<RequestContract[]> => {
		return SettingsAPI.getCompanyRequests();
	},
);

export const getAllCompanies = createAsyncThunk('settings/fivedays/companies', async (): Promise<CompanyContract[]> => {
	return SettingsFivedaysAPI.getAllCompanies();
});

export const deleteCompany = createAsyncThunk(
	'settings/fivedays/company/delete',
	async (companyId: string): Promise<undefined> => {
		return SettingsFivedaysAPI.deleteCompany(companyId);
	},
);

const slice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		updateUserAllowOption: (state, action: PayloadAction<boolean>) => {
			const newOption = action.payload;
			if (state.company) {
				state.company.allowNewUser = newOption;
			}
		},

		updateCompanies: (state, action: PayloadAction<CompanyContract>) => {
			const company = action.payload;

			if (state.companies) {
				const companyToUpdate = state.companies.find((comp) => comp.id === company.id);

				if (companyToUpdate) {
					Object.assign(companyToUpdate, company);
				}
			}
		},

		deleteCompanyState: (state, action: PayloadAction<CompanyContract>) => {
			const company = action.payload;
			if (state.companies) {
				state.companies = state.companies.filter((c) => c.id !== company.id);
			}
		},

		updateBilling: (state, action: PayloadAction<{ companyId: string; newPlan: BillingPlan; dueDate: string }>) => {
			const { companyId, newPlan, dueDate } = action.payload;

			if (state.companies) {
				const companyToUpdate = state.companies.find((company) => company.id === companyId);
				if (companyToUpdate) {
					companyToUpdate.billingPlan.plan = newPlan;
					companyToUpdate.billingPlan.endDateTrial = dueDate;
					Object.assign(companyToUpdate, newPlan, dueDate);
				}
			}
		},
	},
	extraReducers(builder): void {
		builder.addCase(getCompanyForUser.pending, (state) => {
			state.companyRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getCompanyForUser.fulfilled, (state, action) => {
			state.companyRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.company = action.payload;
		});
		builder.addCase(getCompanyForUser.rejected, (state, action) => {
			state.companyRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getAllRequestForACompany.pending, (state) => {
			state.companyRequestsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllRequestForACompany.fulfilled, (state, action) => {
			state.companyRequestsRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.companyRequests = action.payload;
		});
		builder.addCase(getAllRequestForACompany.rejected, (state, action) => {
			state.companyRequestsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});

		builder.addCase(getAllCompanies.pending, (state) => {
			state.companiesRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getAllCompanies.fulfilled, (state, action) => {
			state.companiesRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.companies = action.payload;
		});
		builder.addCase(getAllCompanies.rejected, (state, action) => {
			state.companiesRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { reducer, actions } = slice;
export const { updateUserAllowOption, updateCompanies, updateBilling, deleteCompanyState } = actions;
export default reducer;
