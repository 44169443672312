import React, { KeyboardEvent, useRef } from 'react';
import styles from './meetings-details-notes.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { AccessRightType } from '../../../../../../store/meetings/types';
import MeetingsDetailsNotesText from './meetings-details-notes-text';
import {
	IconContact,
	IconFilter,
	IconGlobe,
	IconLock,
	IconMeetingsPage,
	IconNotes,
	IconSimplePlus,
} from '../../../../../../shared/utils/icon';
import NotesFilterModal from './meeting-details-notes-filter';
import { useParams } from 'react-router-dom';
import { createNotes, getAllNoteForAMeeting, postPeopleShareNote } from '../../../../../../store/notes/slice';
import { getDateFormatL } from '../../../../../../shared/utils/date.util';
import {
	createNotesShared,
	getAllNoteForAMeetingShared,
	postPeopleShareNoteShared,
} from '../../../../../../store/sharing/slice';
import { getCommentsForANoteForMeetings } from '../../../../../../store/comments/slice';
import { extractDomainWithExt } from '../../../../../../shared/utils/domainext';
import { NotesContract } from '../../../../../../store/notes/type';
import { NotificationsType } from '../../../../../../store/notifications/type';
import { SendNotifications } from '../../../../../../store/notifications/slice';

interface Props {
	storedAccessMail?: string;
}

const MeetingsDetailsNotesContent: React.FC<Props> = ({ storedAccessMail }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const params = useParams();
	const shareId = params.shareID;
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const { selectedMeetingNotes, prevMeetingsNote } = useAppSelector((state) => state.notes);
	const { shareNotes } = useAppSelector((state) => state.sharing);
	const inputRef = useRef<HTMLInputElement | null>(null);

	// filters //

	const [showFilters, setShowFilters] = React.useState(false);

	const handleShowFilterModal = () => {
		setShowFilters(!showFilters);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		if (user) {
			const selectedMeetingNoteIds = selectedMeetingNotes
				? selectedMeetingNotes.map((note) => note.graphiCalUId)
				: [];
			const prevMeetingsNoteIds = prevMeetingsNote ? prevMeetingsNote.map((note) => note.graphiCalUId) : [];
			const allNoteIds = [...selectedMeetingNoteIds, ...prevMeetingsNoteIds];
			dispatch(getCommentsForANoteForMeetings(allNoteIds));
		} else {
			const shareNotesIds = shareNotes ? shareNotes.map((note) => note.graphiCalUId) : [];
			const prevMeetingsNoteIds = prevMeetingsNote ? prevMeetingsNote.map((note) => note.graphiCalUId) : [];
			const allNoteIds = [...shareNotesIds, ...prevMeetingsNoteIds];
			dispatch(getCommentsForANoteForMeetings(allNoteIds));
		}
	}, [selectedMeeting]);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowFilterModal]);

	React.useEffect(() => {
		inputRef.current?.focus();
	}, []);

	// -----

	const [showPersonalNote, setShowPersonalNote] = React.useState<boolean>(true);

	const handleShowPersoNote = (show: boolean) => {
		setShowPersonalNote(show);
	};

	const [showSharedNote, setShowSharedNote] = React.useState<boolean>(true);

	const handleShowSharedNote = (show: boolean) => {
		setShowSharedNote(show);
	};

	const [showSelectMeetingNote, setShowSelectedMeetingNote] = React.useState<boolean>(true);

	const handleShowSelectMeetingNote = (show: boolean) => {
		setShowSelectedMeetingNote(show);
	};

	const [showSeriesMeetingNote, setShowSeriesMeetingNote] = React.useState<boolean>(true);

	const handleShowSeriesMeetingNote = (show: boolean) => {
		setShowSeriesMeetingNote(show);
	};

	const [showArchived, setShowArchived] = React.useState<boolean>(false);

	const handleShowArchived = (show: boolean) => {
		setShowArchived(show);
	};

	// ----------------

	const [editingIndex, setEditingIndex] = React.useState('');

	const handleIsEditing = (index: string) => {
		setEditingIndex(index);
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		if (!menu) {
			handleIsEditing('');
		}
		setMenu(!menu);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	// ____________

	const [title, setTitle] = React.useState('');
	const [access, setAccess] = React.useState(AccessRightType.EVERYONE);

	// -------

	const domain = extractDomainWithExt(user ? user.email : storedAccessMail ? storedAccessMail : '');

	const createNote = async () => {
		if (user) {
			const attendeeEmails = selectedMeeting
				? selectedMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
				: [];
			const body = {
				id: '',
				title: title
					? title
					: `${t('New draft note')} ${selectedMeeting?.subject} (${getDateFormatL(new Date())})`,
				text: '',
				graphUserId: user ? user.graphUserId : '',
				graphUserName: user ? user.displayName : '',
				meetingAttendees: attendeeEmails.length > 0 ? attendeeEmails : [user.email],
				graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
				meetingTitle: selectedMeeting ? selectedMeeting.subject : '',
				meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
				tags: [],
				updateNote: new Date(),
				createdOn: new Date(),
				updateOn: new Date(),
				accessRightType: access,
				accessRightCompanies: domain ? [domain] : [],
				archived: false,
				projectId: '',
			};

			dispatch(createNotes(body)).then((res) => {
				const createdNote = res.payload as NotesContract;

				const createNotificationBody = (attendee: string) => {
					return {
						id: undefined,
						userFromName: user.displayName,
						userFromMail: user.userName,
						userToName: attendee,
						userToMail: attendee,
						type: NotificationsType.NEW_MEETING_NOTE,
						noteId: createdNote.id,
						todoId: undefined,
						graphiCalUId: createdNote.graphiCalUId,
						projectId: undefined,
						meetingStartDate: createdNote.meetingStartDate,
						itemTitle: createdNote.title,
						visible: true,
						meetingName: createdNote.meetingTitle,
					};
				};

				switch (access) {
					case AccessRightType.EVERYONE: {
						createdNote.meetingAttendees.forEach((attendee) => {
							if (attendee !== user.userName) {
								const bodyNotification: any = createNotificationBody(attendee);
								dispatch(SendNotifications(bodyNotification));
							}
						});

						break;
					}
					case AccessRightType.SHARED: {
						createdNote.meetingAttendees.forEach((attendee) => {
							if (attendee !== user.userName) {
								const bodyNotification: any = createNotificationBody(attendee);
								dispatch(SendNotifications(bodyNotification));
							}
						});

						selectedMeeting?.attendees.forEach((att) => {
							if (att.emailAddress.address !== user.userName) {
								const bodyAtt = {
									id: '1',
									username: att.emailAddress.name,
									mail: att.emailAddress.address,
								};

								dispatch(postPeopleShareNote({ body: bodyAtt, noteId: createdNote.id }));
							}
						});

						break;
					}
					case AccessRightType.INTERN: {
						const internUserSharingMeeting = user.userCompany.filter((userCompany) =>
							createdNote.meetingAttendees.includes(userCompany.userName),
						);
						internUserSharingMeeting.forEach((attendee) => {
							if (attendee.userName !== user.userName) {
								const bodyNotification: any = createNotificationBody(attendee.userName);
								dispatch(SendNotifications(bodyNotification));
							}
						});

						break;
					}
					// No default
				}

				selectedMeeting && dispatch(getAllNoteForAMeeting(selectedMeeting?.iCalUId));
				setTitle('');
				setAccess(AccessRightType.EVERYONE);
				handleMenu();
			});
		} else if (shareId && selectedMeeting && storedAccessMail) {
			const attendeeEmails = selectedMeeting
				? selectedMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
				: [];
			const body = {
				id: '',
				title: title
					? title
					: `${t('New draft note')} ${selectedMeeting.subject} (${getDateFormatL(new Date())})`,
				text: '',
				graphUserId: storedAccessMail,
				graphUserName: storedAccessMail,
				meetingAttendees: attendeeEmails,
				graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
				meetingTitle: selectedMeeting ? selectedMeeting.subject : '',
				meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
				tags: [],
				updateNote: new Date(),
				createdOn: new Date(),
				updateOn: new Date(),
				accessRightType: access,
				accessRightCompanies: domain ? [domain] : [],
				archived: false,
				projectId: '',
			};

			dispatch(createNotesShared({ body, shareId })).then((res) => {
				const newData = res.payload as NotesContract;

				if (access === AccessRightType.SHARED) {
					selectedMeeting?.attendees.forEach((att) => {
						if (att.emailAddress.address !== storedAccessMail) {
							const bodyAtt = {
								id: '1',
								username: att.emailAddress.name,
								mail: att.emailAddress.address,
							};

							dispatch(postPeopleShareNoteShared({ body: bodyAtt, noteId: newData.id }));
						}
					});
				}

				selectedMeeting &&
					dispatch(
						getAllNoteForAMeetingShared({ iCalUId: selectedMeeting?.iCalUId, userId: storedAccessMail }),
					);

				setTitle('');
				setAccess(AccessRightType.EVERYONE);
				handleMenu();
			});
		}
	};

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'Enter') {
			createNote();
		}
	};

	const trueCount = [
		showPersonalNote,
		showSharedNote,
		showArchived,
		showSelectMeetingNote,
		showSeriesMeetingNote,
	].filter(Boolean).length;

	return (
		<>
			<div className={styles.filters}>
				<div>
					<button
						className={styles.cancel}
						style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
						onClick={() => {
							handleMenu();
						}}>
						{' '}
						<IconSimplePlus /> {t('Create a new note')}
					</button>
				</div>
				<div
					className={showFilters ? styles.activeButton : styles.buttonFilter}
					onClick={() => handleShowFilterModal()}>
					<IconFilter />
					{t('Filters')}
					<div>{trueCount !== 0 ? trueCount : ''}</div>
				</div>
				{showFilters && (
					<div ref={modalRefFilter}>
						<NotesFilterModal
							showPersonalNote={showPersonalNote}
							handleShowPersoNote={handleShowPersoNote}
							showSharedNote={showSharedNote}
							handleShowSharedNote={handleShowSharedNote}
							showSelectMeetingNote={showSelectMeetingNote}
							handleShowSelectMeetingNote={handleShowSelectMeetingNote}
							showSeriesMeetingNote={showSeriesMeetingNote}
							handleShowSeriesMeetingNote={handleShowSeriesMeetingNote}
							showArchived={showArchived}
							handleShowArchived={handleShowArchived}
						/>
					</div>
				)}

				{menu && (
					<div ref={modalRef}>
						<div className={styles.form} onKeyDown={handleKeyDown}>
							<p>
								<div className={styles.icon}>
									<IconNotes width='23' height='23' />
								</div>
								{t('Create a new note')}
							</p>

							<input
								ref={inputRef}
								type='text'
								placeholder={t('Write your title here') as string}
								defaultValue={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<div className={styles.flexAccess}>
								<div
									className={
										access === AccessRightType.EVERYONE ? styles.selectAccess : styles.access
									}
									onClick={() => setAccess(AccessRightType.EVERYONE)}>
									<IconGlobe />
									{t('Public')}
								</div>
								<div
									className={access === AccessRightType.SHARED ? styles.selectAccess : styles.access}
									onClick={() => setAccess(AccessRightType.SHARED)}>
									<IconMeetingsPage />
									{t('Shared')}
								</div>
								<div
									className={access === AccessRightType.INTERN ? styles.selectAccess : styles.access}
									onClick={() => setAccess(AccessRightType.INTERN)}>
									<IconContact />
									{t('Intern')}
								</div>
								<div
									className={access === AccessRightType.ONLYME ? styles.selectAccess : styles.access}
									onClick={() => setAccess(AccessRightType.ONLYME)}>
									<IconLock />
									{t('Private')}
								</div>
							</div>
							<button className={styles.formButton} onClick={() => createNote()}>
								<IconSimplePlus />
								{t('Create')}
							</button>
						</div>
					</div>
				)}
			</div>
			{user ? (
				<>
					{showSelectMeetingNote && selectedMeetingNotes && selectedMeetingNotes.length > 0 && (
						<MeetingsDetailsNotesText
							notes={selectedMeetingNotes.filter((note) => {
								if (!note || (!showArchived && note?.archived)) {
									return false;
								}

								if (
									(showPersonalNote && note?.graphUserId === user?.graphUserId) ||
									(showSharedNote && note?.graphUserId !== user?.graphUserId)
								) {
									return true;
								}

								return false;
							})}
							editingIndex={editingIndex}
							handleIsEditing={handleIsEditing}
						/>
					)}

					{prevMeetingsNote !== undefined && showSeriesMeetingNote && prevMeetingsNote.length > 0 && (
						<>
							<MeetingsDetailsNotesText
								notes={prevMeetingsNote
									.filter(
										(note) =>
											selectedMeetingNotes &&
											!selectedMeetingNotes.some((selectedNote) => selectedNote.id === note.id),
									)
									.filter((note) => {
										if (!note || (!showArchived && note?.archived)) {
											return false;
										}

										if (
											(showPersonalNote && note?.graphUserId === user?.graphUserId) ||
											(showSharedNote && note?.graphUserId !== user?.graphUserId)
										) {
											return true;
										}

										return false;
									})}
								editingIndex={editingIndex}
								handleIsEditing={handleIsEditing}
							/>
						</>
					)}
				</>
			) : (
				<>
					{showSelectMeetingNote && shareNotes && shareNotes.length > 0 && (
						<MeetingsDetailsNotesText
							notes={shareNotes.filter((note) => {
								if (!note || (!showArchived && note?.archived)) {
									return false;
								}

								if (
									(showPersonalNote && note?.graphUserId === storedAccessMail) ||
									(showSharedNote && note?.graphUserId !== storedAccessMail)
								) {
									return true;
								}

								return false;
							})}
							storedAccessMail={storedAccessMail}
							editingIndex={editingIndex}
							handleIsEditing={handleIsEditing}
						/>
					)}

					{prevMeetingsNote !== undefined && showSeriesMeetingNote && prevMeetingsNote.length > 0 && (
						<>
							<MeetingsDetailsNotesText
								notes={prevMeetingsNote.filter((note) => {
									if (!note || (!showArchived && note?.archived)) {
										return false;
									}

									if (
										(showPersonalNote && note?.graphUserId === storedAccessMail) ||
										(showSharedNote && note?.graphUserId !== storedAccessMail)
									) {
										return true;
									}

									return false;
								})}
								storedAccessMail={storedAccessMail}
								editingIndex={editingIndex}
								handleIsEditing={handleIsEditing}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};

export default MeetingsDetailsNotesContent;
