import React, { useEffect } from 'react';
import styles from '../todos.module.scss';
import { useTranslation } from 'react-i18next';
import { IconDownChev } from '../../../shared/utils/icon';

interface Props {
	type: boolean;
	handleType: () => void;
	title: string;
	number: number;
}

const TodoListHeader: React.FC<Props> = ({ type, handleType, title, number }) => {
	const { t } = useTranslation();

	useEffect(() => {
		if (number === 0) {
			handleType();
		}
	}, []);

	return (
		<div className={styles.cat}>
			<span
				style={{ transform: type ? 'rotate(0deg)' : 'rotate(-90deg)', transition: '0.1s', fontSize: '12px' }}
				onClick={() => {
					handleType();
				}}>
				<IconDownChev />
			</span>
			<div
				className={styles.titleCat}
				onClick={() => {
					handleType();
				}}>
				<span>{t(title)}</span>
				<span className={styles.number}> {number}</span>
			</div>
		</div>
	);
};

export default TodoListHeader;
