import React from 'react';
import styles from './project-notes.module.scss';
import ProjectNotesContent from './project-notes-content';
import { useLocation, useNavigate } from 'react-router-dom';

const ProjectNotes = () => {
	const location = useLocation();
	const navigate = useNavigate();

	React.useEffect(() => {
		if (location.search.includes('?id')) {
			const noteId = location.search.split('/')[1];

			const noteElement = document.querySelector(`#noteIdProject${noteId}`);

			if (noteElement) {
				if (noteElement) {
					noteElement.scrollIntoView({
						behavior: 'smooth',
					});
					navigate('/project');
				}
			} else {
				console.error('Container or Note Element not found.');
			}
		}
	}, [location]);

	return (
		<div className={styles.over}>
			<>
				<div className={styles.overviewList} id='meetingsNotesProject'>
					<ProjectNotesContent />
				</div>
			</>
		</div>
	);
};

export default ProjectNotes;
