import React from 'react';
import styles from './register-form.component.module.scss';
import { useTranslation } from 'react-i18next';
import { countries } from '../../../shared/utils/countries';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { extractDomain } from '../../../shared/utils/domainext';
import { useNavigate } from 'react-router-dom';
import { RouteUrls } from '../../../routes/routes-config';
import { BillingPlan } from '../../../store/billing/type';
import { getDayPastOneMonth } from '../../../shared/utils/date.util';
import { transformLanguage } from '../../../shared/utils/language';
import { registerAdminUser } from '../../../store/register/slice';
import { toast } from '../../../shared/components/modals/toast/toast-manager';
import { sendInvitationUsers } from '../../../store/invitations/slice';
import { UserRole } from '../../../store/user/type';
import { getCurrentUser } from '../../../store/user/slice';
import { useMsal } from '@azure/msal-react';
import CustomSelect from './custom-select.component';
import { companySizeRate, FiveDaysUsages } from '../../../shared/utils/register-options';
import { jobTitles } from '../../../shared/utils/jobtitle';

const RegisterFormComponent = () => {
	const { instance } = useMsal();
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [userDomain, setUserDomain] = React.useState<string>('');
	const [emailInvite, setEmailInvite] = React.useState<string>('');
	const [fiveDaysUsages, setFiveDaysUsages] = React.useState<string[]>(['Sélectionnez']);
	const [companySize, setCompanySize] = React.useState<string[]>(['1-5']);
	const [currentPosition, setCurrentPosition] = React.useState<number>(0);

	const browserLang = window.navigator.language;

	React.useEffect(() => {
		if (user) {
			const domain = extractDomain(user.email);
			setUserDomain(domain);
		}
	}, [user, setUserDomain]);

	const handleCompanySize = (el: string) => {
		const value = [el!];
		setCompanySize(value);
	};

	const handleFiveDaysUsages = (el: string) => {
		if (fiveDaysUsages[0] === 'Sélectionnez') {
			const newValues = [el!];
			setFiveDaysUsages(newValues);
		} else {
			if (fiveDaysUsages.includes(el)) {
				const newArray = [...fiveDaysUsages.filter((element) => el !== element)];

				if (newArray.length === 0) {
					const defaultArray = ['Sélectionnez'];
					return setFiveDaysUsages(defaultArray);
				}

				return setFiveDaysUsages(newArray);
			}

			const newValues = [...fiveDaysUsages, el!];
			setFiveDaysUsages(newValues);
		}
	};

	const handleInvitePeopleMenu = (e: HTMLInputElement) => {
		const symbolRegex = /^@/;
		const charRegex = /^[a-zA-Z0-9]/;
		const position = e.value.length;

		if (currentPosition < position || currentPosition === position) {
			if (symbolRegex.test(e.value[position - 1]) && charRegex.test(e.value[position - 2]) && position !== 1) {
				const value = e.value + user?.email.split('@')[1] + ', ';
				return setEmailInvite(value);
			}

			setEmailInvite(e.value);
			setCurrentPosition(position);
		}

		if (currentPosition > position) {
			setEmailInvite(e.value);
			setCurrentPosition(position);
		}
	};

	const handlePreviousStep = () => {
		instance.logoutRedirect({ postLogoutRedirectUri: '/' });
	};

	type FormData = {
		name: string;
		country: string;
		users: string;
		jobTitle: string;
	};

	const dataSchema = yup.object().shape({
		name: yup.string().required(),
		country: yup.string().required(),
		users: yup.string(),
		jobTitle: yup.string(),
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { isValid },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const onSubmit = (data: FormData) => {
		const body = {
			graphId: user?.graphUserId,
			mail: user?.email,
			name: user?.name,
			dailyMail: false,
			updateMail: false,
			billingPlan: BillingPlan.TRIAL,
			companyName: data.name.toLowerCase(),
			companyScale: companySize[0],
			usage: fiveDaysUsages[0] !== 'Sélectionnez' ? fiveDaysUsages : [],
			companyCountry: data.country,
			WDYHA: '',
			language: transformLanguage(browserLang),
			endDateTrial: getDayPastOneMonth(new Date()).toString(),
			jobTitle: data.jobTitle,
		};

		dispatch(registerAdminUser(body)).then(() => {
			if (data.users !== '') {
				const emails = data.users.split(/[,\s]+/).filter((email) => email.trim() !== '');

				const filteredInputs = emails.map((email) => ({
					mail: email,
					role: UserRole.USER,
				}));

				const isValidEmails = filteredInputs.every((input) => {
					const emailParts = extractDomain(input.mail);
					return emailParts.toLowerCase() === userDomain.toLowerCase();
				});

				if (!isValidEmails) {
					toast.show({
						id: user?.graphUserId,
						title: t('Please only invite users from your own domain') as string,
						duration: 10000,
						type: 'failed',
					});
					return;
				}

				const users = {
					users: filteredInputs,
				};

				user &&
					dispatch(sendInvitationUsers(users)).then((result) => {
						if (result.meta.requestStatus === 'fulfilled') {
							dispatch(getCurrentUser()).then(() => {
								navigate(RouteUrls.Meetings);
							});
						} else {
							toast.show({
								id: user?.graphUserId,
								title: t('Something went wrong with the invitations, please try again') as string,
								duration: 10000,
								type: 'failed',
							});
						}
					});
			} else {
				dispatch(getCurrentUser()).then(() => {
					navigate(RouteUrls.Meetings);
				});
			}
		});
	};

	return (
		<div className={styles.container}>
			<div className={`${styles.form} ${styles.fadeRight}`}>
				<h1>{t('Stay connected.')}</h1>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.inputWrapper}>
						<label>{t('Company name')}</label>
						<input
							spellCheck='false'
							{...register('name')}
							placeholder={t('Enter your company name') as string}
						/>
					</div>

					<CustomSelect options={companySizeRate} handleValue={handleCompanySize} value={companySize}>
						Number of people
					</CustomSelect>

					<CustomSelect
						options={FiveDaysUsages}
						handleValue={handleFiveDaysUsages}
						value={fiveDaysUsages}
						checkbox={true}>
						Usages FiveDays
					</CustomSelect>

					<div className={`${styles.country} ${styles.inputWrapper}`}>
						<label>{t('Role with the Organisation')}</label>
						<Controller
							name='jobTitle'
							control={control}
							render={({ field }) => (
								<Stack spacing={2}>
									<Autocomplete
										id='JobTitle'
										disableClearable
										freeSolo
										{...field}
										filterOptions={(options, state) =>
											state.inputValue.length > 0
												? options.filter((option) =>
														option
															.toLowerCase()
															.startsWith(state.inputValue.toLowerCase().trim()),
												  )
												: options
										}
										slotProps={{
											paper: {
												sx: {
													'& .MuiAutocomplete-listbox': {
														'padding': 0,
														'backgroundColor': 'white',
														'width': 'calc(100% + 20px)',
														'left': '-10px',
														'position': 'absolute',
														'top': '12px',
														'borderRadius': '6px',
														'maxHeight': '300px',
														'boxShadow': '1px 1px 10px rgba(0, 0, 0, 0.2)',

														'&::-webkit-scrollbar': {
															width: '6px',
														},
														'&::-webkit-scrollbar-thumb': {
															background: '#888',
															borderRadius: '10px',
															marginBlock: '10px',
														},
														'& .MuiAutocomplete-option': {
															'fontSize': '14px',
															'borderRadius': '10px',
															'margin': '5px',
															'paddingInline': '8px',
															'paddingBlock': '12px',

															'&[aria-selected="true"]': {
																backgroundColor: 'rgba(5, 173, 86, 0.10)',
															},

															'&[data-focus="true"]': {
																backgroundColor: 'rgba(5, 173, 86, 0.10)',
															},

															'&:hover': {
																backgroundColor: 'rgba(128, 128, 128, 0.05)',
															},
														},
													},
												},
											},
										}}
										onChange={(event, newValue) => {
											if (newValue !== null && newValue !== undefined) {
												field.onChange(newValue);
											}
										}}
										onInputChange={(value) => {
											field.onChange(value);
										}}
										options={jobTitles.map((job) => t(job) as string)}
										renderInput={(param) => (
											<TextField
												placeholder={t('Enter your role') as string}
												sx={{
													'& .MuiOutlinedInput-root': {
														border: 'none',
														padding: 0,
														margin: 0,
													},
													'& .MuiOutlinedInput-notchedOutline': {
														border: 'none',
													},
													'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
														{
															paddingTop: 0,
															paddingBottom: 0,
															padding: 0,
															margin: 0,
															border: 'none',
														},
													'backgroundColor': '#fbfaf9',
													'& input': {
														margin: 0,
														padding: 0,
														fontSize: '14px',
														lineHeight: 1,
														zIndex: 5,
													},
													'border': 'transparent',
													'background-color': 'transparent',
													'paddingTop': '20px',
												}}
												{...param}
											/>
										)}
									/>
								</Stack>
							)}
						/>
					</div>

					<div className={`${styles.country} ${styles.inputWrapper}`}>
						<label>{t('Country')}</label>
						<Controller
							name='country'
							control={control}
							render={({ field }) => (
								<Stack spacing={2}>
									<Autocomplete
										id='country'
										disableClearable
										freeSolo
										{...field}
										slotProps={{
											paper: {
												sx: {
													'& .MuiAutocomplete-listbox': {
														'padding': 0,
														'backgroundColor': 'white',
														'width': 'calc(100% + 20px)',
														'left': '-10px',
														'position': 'absolute',
														'top': '12px',
														'borderRadius': '6px',
														'maxHeight': '300px',
														'boxShadow': '1px 1px 10px rgba(0, 0, 0, 0.2)',

														'&::-webkit-scrollbar': {
															width: '6px',
														},
														'&::-webkit-scrollbar-thumb': {
															background: '#888',
															borderRadius: '10px',
															marginBlock: '10px',
														},
														'& .MuiAutocomplete-option': {
															'fontSize': '14px',
															'borderRadius': '10px',
															'margin': '5px',
															'paddingInline': '8px',
															'paddingBlock': '12px',

															'&[aria-selected="true"]': {
																backgroundColor: 'rgba(5, 173, 86, 0.10)',
															},

															'&[data-focus="true"]': {
																backgroundColor: 'rgba(5, 173, 86, 0.10)',
															},

															'&:hover': {
																backgroundColor: 'rgba(128, 128, 128, 0.05)',
															},
														},
													},
												},
											},
										}}
										onChange={(event, newValue) => {
											field.onChange(newValue);
										}}
										options={countries.map((country) => t(country) as string)}
										renderInput={(param) => (
											<TextField
												placeholder={t('Enter your country') as string}
												sx={{
													'& .MuiOutlinedInput-root': {
														border: 'none',
														padding: 0,
														margin: 0,
													},
													'& .MuiOutlinedInput-notchedOutline': {
														border: 'none',
													},
													'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
														{
															paddingTop: 0,
															paddingBottom: 0,
															padding: 0,
															margin: 0,
															border: 'none',
														},
													'backgroundColor': '#fbfaf9',
													'& input': {
														margin: 0,
														padding: 0,
														fontSize: '14px',
														lineHeight: 1,
														zIndex: 5,
													},
													'border': 'transparent',
													'background-color': 'transparent',
													'paddingTop': '20px',
												}}
												{...param}
											/>
										)}
									/>
								</Stack>
							)}
						/>
					</div>

					<div className={styles.invitePeoplesWrapper}>
						<div className={styles.inputWrapper}>
							<label>{t('Invite people')}</label>
							<input
								placeholder={'exemple@' + user?.email.split('@')[1]}
								value={emailInvite}
								type='text'
								spellCheck='false'
								{...register('users')}
								onChange={(e) => handleInvitePeopleMenu(e.target)}></input>
						</div>
						{/* {emailInvite !== '' && (
							<div className={styles.invitePeoplesModal}>
								<p>{emailInvite}</p>
							</div>
							)} */}
					</div>

					<p className={styles.bottomTextForm}>
						{t('You only can invite people with @')}
						{user?.email.split('@')[1]}
					</p>
					<p className={styles.bottomTextForm}>{t('Add email addresse separated by a comma (,).')}</p>
					<button type='submit' disabled={!isValid}>
						{t('Next')}
					</button>
					<p onClick={handlePreviousStep} className={styles.stepBack}>
						{t('Previous step')}
					</p>
				</form>
			</div>
		</div>
	);
};

export default RegisterFormComponent;
