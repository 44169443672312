import React from 'react';
import { App } from './app/app';
import store from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import i18n from './i18n';
import './index.scss';

import { createRoot } from 'react-dom/client';

import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './app/config/msal-auth-config.';

const container = document.querySelector('#root');
const root = createRoot(container!);

root.render(
	<>
		<Provider store={store}>
			<MsalProvider instance={msalInstance}>
				<App i18n={i18n} />
			</MsalProvider>
		</Provider>
	</>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
