import React, { useState, useEffect } from 'react';
import styles from './meetings-details-header-buttons.module.scss';
import { useTranslation } from 'react-i18next';
import { MeetingDetailsProps } from '../../../../../shared/types/component-interface';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import MeetingDetailsUpdateModal from '../../../modals/meeting-update.modal';
import {
	getDuration,
	getDurationDiff,
	getMeetingTime,
	getNowTime,
	isSameOrAfter,
} from '../../../../../shared/utils/date.util';
import { IconDelete, IconEdit, IconSend } from '../../../../../shared/utils/icon';
import MeetingCollaborationComponent from './collaboration/meeting-collaboration.component';
import { RequestStatusType } from '../../../../../store/shared/types';
import { toast } from '../../../../../shared/components/modals/toast/toast-manager';
import { deleteMeeting, deleteMeetingSuccess, setSelectedMeeting } from '../../../../../store/meetings/slice';
import { RouteUrls } from '../../../../../routes/routes-config';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmBox from '../../../../../shared/utils/delete-box';
import googleImg from '../../../../../../images/icones/google-meets.svg';

// ---ICON

const IconTeams = 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_48x1.svg';

// ----

const MeetingDetailsHeaderButtons = ({ meeting }: MeetingDetailsProps) => {
	const [timeLeft, setTimeLeft] = useState(getDuration());
	const { user } = useAppSelector((state) => state.user);
	const meetingTime = getMeetingTime(meeting.start);

	const { t } = useTranslation();

	useEffect(() => {
		const currentTime = getNowTime();
		const duration = getDurationDiff(meetingTime, currentTime);
		if (duration.asMilliseconds() >= 0) {
			setTimeLeft(duration);
		} else {
			setTimeLeft(getDuration());
		}
	}, [meeting]);

	// MODAL //

	const [toggleUpdateMeeting, setToggleUpdateMeeting] = useState(false);

	const handleToggleUpdateMeeting = () => {
		setToggleUpdateMeeting(!toggleUpdateMeeting);
	};

	const [openCollab, setOpenCollab] = React.useState(false);

	const handleCollab = () => {
		setOpenCollab(!openCollab);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleCollab();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleCollab]);

	// DELETE

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);

	const { deleteMeetingsRequestStatus } = useAppSelector((state) => state.meetings);
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const deleteMeetingState = (val: string) => {
		dispatch(deleteMeetingSuccess(val));
	};

	const deleteMeetingOption = () => {
		dispatch(deleteMeeting(meeting.graphEventId)).then(() => {
			deleteMeetingState(meeting.graphEventId);
			navigate(RouteUrls.Meetings);
			dispatch(setSelectedMeeting(undefined));

			toast.show({
				id: user?.graphUserId,
				title: t('Meeting delete success') as string,
				duration: 10000,
				type: 'success',
			});

			if (deleteMeetingsRequestStatus.type === RequestStatusType.Failed) {
				toast.show({
					id: user?.graphUserId,
					title: t('Meeting delete failed') as string,
					duration: 10000,
					type: 'failed',
				});
			}
		});
	};

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	return (
		<div className={styles.meetingTopButton}>
			<div className={styles.options}>
				<button className={styles.sendButton} onClick={() => handleCollab()}>
					{' '}
					<IconSend /> {t('Invite')}
				</button>
				{meeting.isOnlineMeeting === true ? (
					<button className={isSameOrAfter(meetingTime) ? styles.buttonTeams : styles.pastTeams}>
						<img src={IconTeams} alt='teams icon' />
						<a href={meeting.onlineMeeting} target='_blank' rel='noreferrer'>
							{t('Join')}
						</a>
					</button>
				) : (
					<>
						{meeting.googleMeetUrl ? (
							<button className={isSameOrAfter(meetingTime) ? styles.buttonTeams : styles.pastTeams}>
								<img src={googleImg} alt='teams icon' />
								<a href={meeting.googleMeetUrl} target='_blank' rel='noreferrer'>
									{t('Join')}
								</a>
							</button>
						) : (
							<></>
						)}
					</>
				)}
				{user && (
					<>
						{meeting.isOrganizer === true && (
							<div className={styles.icon} onClick={() => handleConfirmBox()}>
								<IconDelete />
							</div>
						)}

						{openConfirmBox && (
							<DeleteConfirmBox
								handleDelete={deleteMeetingOption}
								handleConfirmBox={handleConfirmBox}
								message='Are you sure you want to delete this meeting?'
							/>
						)}
					</>
				)}
				{user ? (
					<>
						{meeting.isOrganizer === true && (
							<div className={styles.icon} onClick={() => handleToggleUpdateMeeting()}>
								<IconEdit />
							</div>
						)}

						{toggleUpdateMeeting && (
							<MeetingDetailsUpdateModal
								toggle={toggleUpdateMeeting}
								handleToggle={handleToggleUpdateMeeting}
								meeting={meeting}
							/>
						)}
					</>
				) : (
					<></>
				)}
			</div>
			{openCollab && (
				<div ref={modalRef}>
					<MeetingCollaborationComponent />
				</div>
			)}
		</div>
	);
};

export default MeetingDetailsHeaderButtons;
