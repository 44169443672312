import type { ChangeEvent, HTMLProps, KeyboardEvent } from 'react';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createMarkPositioner, LinkExtension, ShortcutHandlerProps } from 'remirror/extensions';
import {
	CommandButton,
	FloatingToolbar,
	FloatingWrapper,
	useActive,
	useAttrs,
	useChainedCommands,
	useCurrentSelection,
	useExtensionEvent,
	useUpdateReason,
} from '@remirror/react';
import { useTranslation } from 'react-i18next';
import styles from '../note-editor.module.scss';
import { NotesContract } from '../../../../store/notes/type';
import { MeetingContract } from '../../../../store/meetings/types';
import Menu from './notes-editor-menu-float.component';

function useLinkShortcut() {
	const [linkShortcut, setLinkShortcut] = useState<ShortcutHandlerProps | undefined>();
	const [isEditing, setIsEditing] = useState(false);

	useExtensionEvent(
		LinkExtension,
		'onShortcut',
		useCallback(
			(props) => {
				if (!isEditing) {
					setIsEditing(true);
				}

				return setLinkShortcut(props);
			},
			[isEditing],
		),
	);

	return { linkShortcut, isEditing, setIsEditing };
}

function useFloatingLinkState() {
	const chain = useChainedCommands();
	const { isEditing, linkShortcut, setIsEditing } = useLinkShortcut();
	const { to, empty } = useCurrentSelection();

	const url = (useAttrs().link()?.href as string) ?? '';
	const [href, setHref] = useState<string>(url);

	// A positioner which only shows for links.
	const linkPositioner = useMemo(() => createMarkPositioner({ type: 'link' }), []);

	const onRemove = useCallback(() => chain.removeLink().focus().run(), [chain]);

	const updateReason = useUpdateReason();

	useLayoutEffect(() => {
		if (!isEditing) {
			return;
		}

		if (updateReason.doc || updateReason.selection) {
			setIsEditing(false);
		}
	}, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);

	useEffect(() => {
		setHref(url);
	}, [url]);

	const submitHref = useCallback(() => {
		setIsEditing(false);
		const range = linkShortcut ?? undefined;

		if (href === '') {
			chain.removeLink();
		} else {
			chain.updateLink({ href, auto: false }, range);
		}

		chain.focus(range?.to ?? to).run();
	}, [setIsEditing, linkShortcut, chain, href, to]);

	const cancelHref = useCallback(() => {
		setIsEditing(false);
	}, [setIsEditing]);

	const clickEdit = useCallback(() => {
		if (empty) {
			chain.selectLink();
		}

		setIsEditing(true);
	}, [chain, empty, setIsEditing]);

	return useMemo(
		() => ({
			href,
			setHref,
			linkShortcut,
			linkPositioner,
			isEditing,
			clickEdit,
			onRemove,
			submitHref,
			cancelHref,
		}),
		[href, linkShortcut, linkPositioner, isEditing, clickEdit, onRemove, submitHref, cancelHref],
	);
}

const DelayAutoFocusInput = ({ autoFocus, ...rest }: HTMLProps<HTMLInputElement>) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!autoFocus) {
			return;
		}

		const frame = window.requestAnimationFrame(() => {
			inputRef.current?.focus();
		});

		return () => {
			window.cancelAnimationFrame(frame);
		};
	}, [autoFocus]);

	return <input ref={inputRef} {...rest} />;
};

interface Props {
	userId: string;
	noteId: string;
	userName: string;
	userMail: string;
	note: NotesContract;
	meeting?: MeetingContract;
}

export const FloatingLinkToolbar: React.FC<Props> = ({ userId, noteId, userName, userMail, note, meeting }) => {
	const { t } = useTranslation();
	const { isEditing, linkPositioner, clickEdit, onRemove, submitHref, href, setHref, cancelHref } =
		useFloatingLinkState();
	const active = useActive();
	const activeLink = active.link();
	const { empty } = useCurrentSelection();

	const handleClickEdit = useCallback(() => {
		clickEdit();
	}, [clickEdit]);

	const floatingBarButtons = activeLink ? (
		<>
			<div className={styles.menu}>
				<CommandButton commandName='updateLink' onSelect={handleClickEdit} icon='pencilLine' enabled />
				<CommandButton commandName='removeLink' onSelect={onRemove} icon='linkUnlink' enabled />
				{/* <Menu
					userId={userId}
					noteId={noteId}
					userName={userName}
					userMail={userMail}
					note={note}
					meeting={meeting}
				/> */}
			</div>
		</>
	) : (
		<div className={styles.menu}>
			<CommandButton commandName='updateLink' onSelect={handleClickEdit} icon='link' enabled />
			{/* <Menu
				userId={userId}
				noteId={noteId}
				userName={userName}
				userMail={userMail}
				note={note}
				meeting={meeting}
			/> */}
		</div>
	);

	return (
		<>
			{!isEditing && <FloatingToolbar placement='right'>{floatingBarButtons}</FloatingToolbar>}
			{!isEditing && empty && <FloatingToolbar positioner={linkPositioner}>{floatingBarButtons}</FloatingToolbar>}

			<FloatingWrapper positioner='always' placement='right' enabled={isEditing}>
				<DelayAutoFocusInput
					style={{ zIndex: 99, marginTop: '30px' }}
					autoFocus
					placeholder={t('Enter a link') as string}
					onChange={(event: ChangeEvent<HTMLInputElement>) => setHref(event.target.value)}
					value={href}
					onKeyPress={(event: KeyboardEvent<HTMLInputElement>) => {
						const { code } = event;

						if (code === 'Enter') {
							submitHref();
						}

						if (code === 'Escape') {
							cancelHref();
						}
					}}
				/>
			</FloatingWrapper>
		</>
	);
};
