import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './files-share.module.scss';
import { AccessRightType, FileContract, MeetingContract } from '../../../../../../store/meetings/types';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
	addPeopleShareFile,
	deletePeopleFile,
	deletePeopleShareFile,
	postPeopleShareFile,
} from '../../../../../../store/project/slice';
import { People } from '../../../../../../store/notes/type';
import {
	addPeopleShareFileShared,
	deletePeopleFileShared,
	deletePeopleShareFileShare,
	getFilesForAMeetingShared,
	postPeopleShareFileShare,
	updateMeetingFileShared,
} from '../../../../../../store/sharing/slice';
import { updateFileAccessRightSuccess, updateMeetingFile } from '../../../../../../store/meetings/slice';
import {
	IconClose,
	IconContact,
	IconEdit,
	IconGlobe,
	IconLock,
	IconMeetingsPage,
	IconSimplePlus,
} from '../../../../../../shared/utils/icon';
import { Avatar } from '../../../../../../shared/utils/avatar';
import { extractDomainWithExt } from '../../../../../../shared/utils/domainext';

interface props {
	file: FileContract;
	userId: string;
	selectedMeeting: MeetingContract;
	mail: string;
	close: (index: number) => void;
}

const MeetingFilesShareFormModal: React.FC<React.PropsWithChildren<props>> = ({
	file,
	userId,
	selectedMeeting,
	mail,
	close,
}) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const modalRef = React.useRef<HTMLDivElement>(null);
	const [animation, setAnimation] = useState<boolean>(false);

	const closeModal = (index: number) => {
		setAnimation(true);
		setTimeout(() => {
			close(index);
			setAnimation(false);
		}, 300);
	};

	const { user } = useAppSelector((state) => state.user);
	const { filesPeople } = useAppSelector((state) => state.project);
	const { filesPeopleShare } = useAppSelector((state) => state.sharing);

	const domain = extractDomainWithExt(mail);

	const [selectedAccessType, setSelectedAccessType] = React.useState<AccessRightType>(file.accessRightType);
	const [companiesAccess, setcompaniesAccess] = React.useState<string[]>([domain ? domain : '']);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		if (AccessRightType) {
			event.preventDefault();
			const body = { accessRightType: selectedAccessType, accessRightCompanies: companiesAccess };
			if (file) {
				if (user) {
					dispatch(
						updateMeetingFile({
							body,
							selectedMeetingID: selectedMeeting.graphEventId,
							fileId: file?.id,
						}),
					).then(() => {
						close(-1);
						dispatch(
							updateFileAccessRightSuccess({
								fileId: file?.id,
								accessRightTypeSelect: selectedAccessType,
								accessRightCompanies: companiesAccess,
							}),
						);
					});
				} else {
					dispatch(
						updateMeetingFileShared({
							body,
							selectedMeetingID: selectedMeeting.iCalUId,
							fileId: file?.id,
							userId,
						}),
					).then(() => {
						close(-1);
						dispatch(getFilesForAMeetingShared({ iCalUId: selectedMeeting.iCalUId, userId: mail }));
					});
				}
			}
		}
	};

	const handleCheckboxChange = (accessData: AccessRightType) => {
		const value = accessData;
		if (
			value === AccessRightType.ONLYME ||
			value === AccessRightType.EVERYONE ||
			value === AccessRightType.SHARED ||
			value === AccessRightType.INTERN
		) {
			setSelectedAccessType(value);
		}
	};

	// --------

	const [openMenu, setOpenMenu] = React.useState(false);
	const handleOpenMenu = () => {
		setOpenMenu(!openMenu);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleOpenMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleOpenMenu]);

	// --

	const [selectedAddresses, setSelectedAddresses] = React.useState<People[]>([]);

	const handleCheckboxChangePeople = (attendee: People) => {
		setSelectedAddresses([...selectedAddresses, attendee]);

		const body = {
			id: '1',
			username: attendee.username,
			mail: attendee.mail,
		};

		if (file) {
			if (user) {
				dispatch(postPeopleShareFile({ body, fileId: file.id })).then((res) => {
					dispatch(addPeopleShareFile(res.payload as People));
				});
			} else {
				dispatch(postPeopleShareFileShare({ body, fileId: file.id })).then((res) => {
					dispatch(addPeopleShareFileShared(res.payload as People));
				});
			}
		}
	};

	const handleDeletePeopleShare = (mailPeople: string) => {
		if (file) {
			if (user) {
				dispatch(deletePeopleShareFile({ fileId: file.id, mail: mailPeople })).then(() => {
					dispatch(deletePeopleFile(mailPeople));
				});
			} else {
				dispatch(deletePeopleShareFileShare({ fileId: file.id, mail: mailPeople })).then(() => {
					dispatch(deletePeopleFileShared(mailPeople));
				});
			}
		}
	};

	return (
		<>
			<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
				<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
					<div className={styles.titleWrapper}>
						<h3>
							<IconEdit />
							{t('Update access')}
						</h3>
						<div className={styles.closeButton}>
							<div
								className={styles.icon}
								onClick={() => {
									closeModal(-1);
								}}>
								<IconClose />
							</div>
						</div>
					</div>
					<form onSubmit={handleSubmit}>
						<div className={styles.flexAccess}>
							<div
								className={
									selectedAccessType === AccessRightType.EVERYONE
										? styles.selectAccess
										: styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.EVERYONE)}>
								<IconGlobe />
								{t('Public')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.INTERN ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.INTERN)}>
								<IconContact />
								{t('Intern')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.SHARED ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.SHARED)}>
								<IconMeetingsPage />
								{t('Shared')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.ONLYME ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.ONLYME)}>
								<IconLock />
								{t('Private')}
							</div>
						</div>

						<br />
						{selectedAccessType === AccessRightType.SHARED && (
							<>
								<div className={styles.invited}>
									{user &&
										filesPeople?.map((people, index) => (
											<div key={index} className={styles.avatar}>
												<div className={styles.avatarWrapper}>
													<Avatar name={people.username} mail={people.mail} index={index} />
													<p>{people.username}</p>
												</div>
												<div
													className={styles.icon}
													onClick={() => handleDeletePeopleShare(people.mail)}>
													<IconClose />
												</div>
											</div>
										))}
									{!user &&
										filesPeopleShare?.map((people, index) => (
											<div key={index} className={styles.avatar}>
												<div className={styles.avatarWrapper}>
													<Avatar name={people.username} mail={people.mail} index={index} />
													<p>{people.username}</p>
												</div>
												<div
													className={styles.icon}
													onClick={() => handleDeletePeopleShare(people.mail)}>
													<IconClose />
												</div>
											</div>
										))}
								</div>
								{selectedMeeting && selectedMeeting.attendees.length > 0 && (
									<div className={styles.addAttendee}>
										<div className={styles.addAttendeeContainer} onClick={() => handleOpenMenu()}>
											{t('Invite people')}

											<div className={styles.iconPlus}>
												<IconSimplePlus />
											</div>
										</div>
										{openMenu && (
											<div className={styles.modalPeople} ref={modalRef}>
												<div className={styles.peopleList}>
													{selectedMeeting &&
														selectedMeeting.attendees.map((contact, index) => (
															<div
																className={styles.row}
																key={contact.emailAddress.address}
																onClick={() =>
																	handleCheckboxChangePeople({
																		id: '',
																		username: contact.emailAddress.name,
																		mail: contact.emailAddress.address,
																	})
																}>
																<div className={styles.avatar}>
																	<Avatar
																		name={contact.emailAddress.name}
																		mail={contact.emailAddress.address}
																		index={index}
																	/>
																	<span>{contact.emailAddress.name}</span>
																</div>
																<div>
																	<IconSimplePlus />
																</div>
															</div>
														))}
												</div>
											</div>
										)}
									</div>
								)}
							</>
						)}
						<br />

						<button className={styles.button} type='submit'>
							{t('Confirm')}
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default MeetingFilesShareFormModal;
