/* eslint-disable quotes */
import React, { ReactElement } from 'react';
import styles from './copilot.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import CopilotTranscriptModal from './modal/copilot-transcript.modal';
import {
	IconAnalysis,
	IconAnalysisDone,
	IconAnalytics,
	IconDownArrow,
	IconNotRecording,
	IconRecording,
	IconSend,
	IconTJoining,
	IconWaiting,
} from '../../../../../../shared/utils/icon';
import {
	createDataMeetingBot,
	deleteDataMeetingBot,
	generateMistralSummary,
	generateMistralTodos,
	getDataMeetingBot,
	getDataMeetingBotTranscript,
	setTodosMistral,
	updateSelectMeetingDataBot,
} from '../../../../../../store/recall/slice';
import { BotStatus, DataMeetingBotContract, MistralCall, MistralTodos } from '../../../../../../store/recall/type';
import { toast } from '../../../../../../shared/components/modals/toast/toast-manager';
import CopilotSummarytModal from './modal/copilot-summary.modal';
import { TodosContract, TodosStates } from '../../../../../../store/todos/type';
import CopilotTodosModal from './modal/copilot-todos-modal';
import Loader from '../../../../../../shared/components/loader/loader.component';
import { RequestStatusType } from '../../../../../../store/shared/types';
import { getQuestionTemplate, getTextTemplate, getListTemplate } from '../../../../../../shared/types/template-mistral';

interface Status {
	icon: ReactElement | undefined;
	text: string | undefined;
}

const CopilotComponent = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { dataMeetingBot, dataMeetingBotTranscript, dataMeetingBotRequestStatusTranscript } = useAppSelector(
		(state) => state.recall,
	);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const [openTranscript, setOpenTranscript] = React.useState(false);
	const [openSummary, setOpenSummary] = React.useState(false);
	const [openTodos, setOpenTodos] = React.useState(false);
	const [todos, setTodos] = React.useState<TodosContract[]>([]);
	const [isBot, setIsBot] = React.useState<boolean>(dataMeetingBot ? true : false);
	const [status, setStatus] = React.useState<Status>();
	const dataStatus: string | undefined = 'in_call_not_recording';
	const [questionMistral, setQuestionMistral] = React.useState('');
	const [templateSum, setTemplateSum] = React.useState<string>('');
	const [dropdown, setDropdown] = React.useState(false);

	const handleDropDown = () => {
		setDropdown(!dropdown);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					if (dropdown) handleDropDown();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleDropDown]);

	React.useEffect(() => {
		if (selectedMeeting) {
			dispatch(getDataMeetingBot(selectedMeeting.iCalUId)).then((res) => {
				const data = res.payload;
				if (data === false) {
					setIsBot(false);
				} else {
					setIsBot(true);
				}
			});
		}
	}, [selectedMeeting]);

	// React.useEffect(() => {
	// 	const intervalId = setInterval(() => {
	// 		if (selectedMeeting) {
	// 			dispatch(getDataMeetingBot(selectedMeeting.iCalUId)).then((res) => {
	// 				const data = res.payload;
	// 				if (data === false) {
	// 					setIsBot(false);
	// 				} else {
	// 					setIsBot(true);
	// 				}
	// 			});
	// 		}
	// 	}, 20000);
	// 	return () => clearInterval(intervalId);
	// }, []);

	React.useEffect(() => {
		if (
			selectedMeeting &&
			(!dataMeetingBotTranscript || dataMeetingBotTranscript.graphiCalUId !== selectedMeeting.iCalUId) &&
			dataMeetingBot &&
			(dataMeetingBot.status === BotStatus.analysis_done || dataMeetingBot.status === BotStatus.media_expired)
		) {
			dispatch(getDataMeetingBotTranscript(dataMeetingBot.botId));
		}
	}, [dataMeetingBot, selectedMeeting, dataMeetingBotTranscript]);

	React.useEffect(() => {
		switch (dataMeetingBot?.status) {
			case BotStatus.ready:
				return setStatus({
					icon: <IconTJoining />,
					text: 'Ready',
				});
			case BotStatus.joining_call:
				setStatus({
					icon: <IconTJoining />,
					text: 'Joining the call',
				});
				break;
			case BotStatus.in_waiting_room:
				return setStatus({
					icon: <IconWaiting />,
					text: 'In waiting room',
				});
			case BotStatus.in_call_recording:
				return setStatus({
					icon: <IconRecording />,
					text: 'Recording',
				});
			case BotStatus.in_call_not_recording:
				return setStatus({
					icon: <IconNotRecording />,
					text: 'Not recording',
				});
			case BotStatus.call_ended:
				return setStatus({
					icon: <IconAnalysis />,
					text: 'Call ended',
				});
			case BotStatus.done:
				return setStatus({
					icon: <IconAnalysis />,
					text: 'Analysis in progress',
				});
			case BotStatus.analysis_failed:
				return setStatus({
					icon: <IconNotRecording />,
					text: 'Analysis failed',
				});
			case BotStatus.analysis_done:
				return setStatus({
					icon: <IconAnalysisDone />,
					text: 'Analysis done',
				});

			case BotStatus.media_expired:
				return setStatus({
					icon: <IconNotRecording />,
					text: 'Media expired',
				});
		}
	}, [dataMeetingBot?.status]);

	const handleIsBot = (newData: Boolean) => {
		if (user) {
			if (selectedMeeting && !dataMeetingBot && newData === true) {
				if (selectedMeeting.isOnlineMeeting) {
					const bodyDMB: DataMeetingBotContract = {
						id: '',
						graphiCalUId: selectedMeeting.iCalUId,
						onlineMeetingUrl: selectedMeeting.onlineMeeting,
						meetingStartDate: selectedMeeting.start,
						meetingEndDate: selectedMeeting.end,
						botId: '',
						userGraphId: '',
						userMail: '',
						meetingName: selectedMeeting.subject,
						createdOn: new Date(),
						updatedOn: new Date(),
					};

					dispatch(createDataMeetingBot(bodyDMB)).then((res) => {
						const newDataBot = res.payload as DataMeetingBotContract;
						dispatch(updateSelectMeetingDataBot(newDataBot));
						setIsBot(true);
					});
				} else if (selectedMeeting.googleMeetUrl) {
					const bodyDMB: DataMeetingBotContract = {
						id: '',
						graphiCalUId: selectedMeeting.iCalUId,
						onlineMeetingUrl: selectedMeeting.googleMeetUrl,
						meetingStartDate: selectedMeeting.start,
						meetingEndDate: selectedMeeting.end,
						botId: '',
						userGraphId: '',
						userMail: '',
						meetingName: selectedMeeting.subject,
						createdOn: new Date(),
						updatedOn: new Date(),
					};

					dispatch(createDataMeetingBot(bodyDMB)).then((res) => {
						const newDataBot = res.payload as DataMeetingBotContract;
						dispatch(updateSelectMeetingDataBot(newDataBot));
						// dispatch(updateUserCreditCopilot(user.creditCopilot - 1));
						// setIsBot(true);

						dispatch(getDataMeetingBot(selectedMeeting.iCalUId)).then((resD) => {
							const data = resD.payload;

							if (data === false) {
								setIsBot(false);
							} else {
								setIsBot(true);
							}
						});
					});
				} else {
					toast.show({
						id: user?.graphUserId,
						title: t('Failed to start the bot IA') as string,
						duration: 10000,
						type: 'failed',
					});

					setIsBot(false);
				}
			} else if (dataMeetingBot) {
				dispatch(deleteDataMeetingBot(dataMeetingBot.graphiCalUId)).then((res) => {
					const data = res.payload;

					if (data !== false) {
						dispatch(updateSelectMeetingDataBot(undefined));
						setIsBot(false);
					} else {
						setIsBot(true);
						toast.show({
							id: user?.graphUserId,
							title: t('Only scheduled bots which have not yet joined a call can be deleted') as string,
							duration: 10000,
							type: 'failed',
						});
					}
				});
			}
		}
	};

	const handleOpenTranscript = () => {
		setOpenTranscript(!openTranscript);
	};

	const handleOpenSummary = () => {
		setOpenSummary(!openSummary);
	};

	const getTranscript = () => {
		if (dataMeetingBot) {
			handleOpenTranscript();
		} else {
			toast.show({
				id: user?.graphUserId,
				title: t('Failed to get the transcript') as string,
				duration: 10000,
				type: 'failed',
			});
		}
	};

	const handleGenerateSummaryMistral = (question: string) => {
		if (dataMeetingBot && dataMeetingBotTranscript) {
			const body: MistralCall = {
				botId: dataMeetingBotTranscript.botId,
				question,
				transcript: dataMeetingBotTranscript.transcript,
			};
			handleDropDown();
			dispatch(generateMistralSummary(body)).then(() => {});
			setTemplateSum('');
			handleOpenSummary();
		} else {
			toast.show({
				id: user?.graphUserId,
				title: t('Failed to get the summary') as string,
				duration: 10000,
				type: 'failed',
			});
		}
	};

	const handleOpenTodos = () => {
		setOpenTodos(!openTodos);
	};

	const handleGenerateTodosMistral = async () => {
		if (dataMeetingBot && dataMeetingBotTranscript) {
			dispatch(setTodosMistral(undefined));
			setTodos([]);
			handleOpenTodos();

			const body: MistralCall = {
				botId: dataMeetingBotTranscript.botId,
				question: '',
				transcript: dataMeetingBotTranscript.transcript,
			};
			await dispatch(generateMistralTodos(body)).then((res) => {
				const newTodos = res.payload as MistralTodos[];
				const todosArray: TodosContract[] = [];
				for (const element of newTodos) {
					const attendeeEmails = selectedMeeting
						? selectedMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
						: [];

					const rightAttendee = selectedMeeting
						? selectedMeeting.attendees.find(
								(att) =>
									att.emailAddress.name.toLocaleLowerCase() === element.assignee.toLocaleLowerCase(),
						  )
						: {
								type: 'attendee',
								emailAddress: {
									name: user?.name,
									address: user?.userName,
								},
						  };

					const bodyT: any = {
						id: newTodos.indexOf(element),
						title: element.todo,
						text: '',
						state: TodosStates.TODO,
						dueDate: undefined,
						duration: undefined,
						graphUserId: user?.graphUserId,
						noteId: undefined,
						assigneeDisplayName: rightAttendee ? rightAttendee.emailAddress.address : user?.userName,
						graphiCalUId: selectedMeeting?.iCalUId,
						meetingGraphId: selectedMeeting?.graphEventId,
						meetingName: selectedMeeting?.subject,
						meetingStartDate: selectedMeeting?.start,
						createdOn: new Date().toISOString(),
						tags: [],
						projectId: '',
						assigneeName: rightAttendee ? rightAttendee.emailAddress.name : user?.name,
						meetingAttendees: attendeeEmails,
					};

					todosArray.push(bodyT);
				}
				setTodos(todosArray);
				dispatch(setTodosMistral(todosArray));
			});
		} else {
			toast.show({
				id: user?.graphUserId,
				title: t('Failed to get todos') as string,
				duration: 10000,
				type: 'failed',
			});
		}
	};

	return (
		<div className={styles.copilotContainer}>
			<div>
				<div className={styles.copilotPreInfo}>
					<div>
						<IconAnalytics />
					</div>
					<div>
						<p>
							{t(
								'Copilot knows about the context of this meeting. It has access to previous meeting notes, tasks and decisions.',
							)}
						</p>
					</div>
				</div>
				<div className={styles.flexButton}>
					{dataMeetingBot?.status === undefined && (
						<div className={styles.isOnline}>
							<div className={styles.copilotWrapper}>
								<div>
									<IconAnalytics />
								</div>
								<div>
									<div className={styles.teams}>
										{isBot
											? t('Copilot is programmed for this meeting.')
											: t('Copilot is not programmed for this meeting.')}
									</div>
									<div className={styles.teamsBtn}>
										{isBot
											? t('Click on the button to disable Copilot.')
											: t('Click on the button to activate Copilot.')}
									</div>
								</div>
							</div>
							<label className={styles.switch}>
								<input
									type='checkbox'
									onChange={() => handleIsBot(!isBot)}
									checked={isBot}
									disabled={user && user.creditCopilot > 0 ? false : true}
								/>
								<span className={styles.slider}></span>
							</label>
						</div>
					)}
					{dataMeetingBot?.status && (
						<>
							{dataMeetingBot.status !== BotStatus.done &&
							dataMeetingBot.status !== BotStatus.recording_done &&
							dataMeetingBot.status !== BotStatus.analysis_done &&
							dataMeetingBot.status !== BotStatus.media_expired &&
							dataMeetingBot.status !== BotStatus.analysis_failed ? (
								<div className={styles.isOnline}>
									<div className={styles.copilotWrapper}>
										<div>
											<IconAnalytics />
										</div>
										<div>
											<div className={styles.teams}>
												{t('The meeting is in progress. Copilot is activated.')}
											</div>
											{/* <div className={styles.teamsBtn}>
											{t('Click on the button to disable Copilot.')}
										</div> */}
											<div className={styles.botStatus}>
												{status?.icon}
												<p>{t(status?.text ?? '')}</p>
											</div>
										</div>
									</div>
									<label className={styles.switch}>
										<input type='checkbox' onChange={() => handleIsBot(!isBot)} checked={isBot} />
										<span className={styles.slider}></span>
									</label>
								</div>
							) : (
								<div className={styles.isOnline}>
									<div className={styles.copilotWrapper}>
										<div>
											<IconAnalytics />
										</div>
										<div>
											<div className={styles.teams}>{t('The meeting is over.')}</div>

											{dataMeetingBot?.status === BotStatus.done ? (
												<div style={{ marginTop: '20px' }} className={styles.botStatus}>
													{status?.icon}
													<p>{t(status?.text ?? '')}</p>
												</div>
											) : (
												<>
													{dataMeetingBot.status === BotStatus.media_expired && (
														<div style={{ marginTop: '20px' }} className={styles.botStatus}>
															{status?.icon}
															<p>{t(status?.text ?? '')}</p>
														</div>
													)}
												</>
											)}
											{dataMeetingBotRequestStatusTranscript.type ===
												RequestStatusType.InProgress && (
												<div>
													<Loader />
												</div>
											)}
											{dataMeetingBotTranscript && (
												<>
													<div className={styles.options}>
														<h4 onClick={() => getTranscript()}>
															{t('Meeting transcript')}
														</h4>
													</div>
													<div className={styles.options}>
														<h4
															onClick={() => {
																if (templateSum !== '') {
																	handleGenerateSummaryMistral(templateSum);
																} else {
																	toast.show({
																		id: user?.graphUserId,
																		title: t('Please, select a template') as string,
																		duration: 10000,
																		type: 'failed',
																	});
																}
															}}>
															{t('Generate a note')}
														</h4>
														<div className={styles.selectCustom} ref={modalRef}>
															<button type='button' onClick={() => handleDropDown()}>
																<p>{t('Select a template')}</p>
																<IconDownArrow />
															</button>
															{dropdown && user && (
																<div className={styles.dropdown}>
																	<p
																		onClick={() => {
																			setTemplateSum(
																				getListTemplate(
																					user.preferences.language,
																				),
																			);
																			handleGenerateSummaryMistral(
																				getListTemplate(
																					user.preferences.language,
																				),
																			);
																		}}>
																		{t('List')}
																	</p>
																	<p
																		onClick={() => {
																			setTemplateSum(
																				getTextTemplate(
																					user.preferences.language,
																				),
																			);
																			handleGenerateSummaryMistral(
																				getTextTemplate(
																					user.preferences.language,
																				),
																			);
																		}}>
																		{t('Text')}
																	</p>
																</div>
															)}
														</div>
													</div>
													<div className={styles.options}>
														<h4 onClick={() => handleGenerateTodosMistral()}>
															{t('Generate todos')}
														</h4>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</div>
				{/* <div className={styles.creditContainer}>
					<div className={styles.creditInfo}>
						<div>
							<IconAnalytics />
						</div>
						<div>
							<p>{t("You don't have much credit left")}</p>
							<p className={styles.creditSubTitle}>
								{t('Add credits to continue enjoying Copilot features.')}
							</p>
							<p className={styles.creditLeft}>
								{user?.creditCopilot} {t('credits left')}
							</p>
						</div>
					</div>
					<button className={styles.creditBtn}>{t('Buy credits')}</button>
				</div> */}
			</div>

			<div>
				{dataMeetingBot?.status === BotStatus.analysis_done && user && dataMeetingBotTranscript && (
					<>
						<div className={styles.promptInput}>
							<IconAnalytics />
							<input
								type='text'
								placeholder={
									t('Based on the context, ask for information about this meeting') as string
								}
								onChange={(e) =>
									setQuestionMistral(getQuestionTemplate(user.preferences.language, e.target.value))
								}
							/>
							<div className={styles.send} onClick={() => handleGenerateSummaryMistral(questionMistral)}>
								<IconSend />
							</div>
						</div>
					</>
				)}

				{dataMeetingBot?.status === BotStatus.media_expired && user && dataMeetingBotTranscript && (
					<>
						<div className={styles.promptInput}>
							<IconAnalytics />
							<input
								type='text'
								placeholder={
									t('Based on the context, ask for information about this meeting') as string
								}
								onChange={(e) =>
									setQuestionMistral(getQuestionTemplate(user.preferences.language, e.target.value))
								}
							/>
							<div className={styles.send} onClick={() => handleGenerateSummaryMistral(questionMistral)}>
								<IconSend />
							</div>
						</div>
					</>
				)}
			</div>

			{openTranscript && <CopilotTranscriptModal handleClose={handleOpenTranscript} />}

			{openSummary && <CopilotSummarytModal handleClose={handleOpenSummary} />}

			{openTodos && <CopilotTodosModal handleClose={handleOpenTodos} />}
		</div>
	);
};

export default CopilotComponent;
