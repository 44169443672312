import { ContactsGraphContract } from '../../store/user/type';

export const MAX_CONTACTS = 8;

export const saveContactsToLocalStorage = (contacts: ContactsGraphContract[]) => {
	const existingContactsJSON = localStorage.getItem('lastContacts');
	const existingContacts: ContactsGraphContract[] = existingContactsJSON ? JSON.parse(existingContactsJSON) : [];

	const combinedContacts: ContactsGraphContract[] = [...existingContacts, ...contacts].slice(-MAX_CONTACTS);

	const uniqueContacts = combinedContacts.reduce(
		(unique: ContactsGraphContract[], contact: ContactsGraphContract) => {
			const isDuplicate = unique.some((c) => c.emailAddresses[0].address === contact.emailAddresses[0].address);
			if (!isDuplicate) {
				unique.push(contact);
			}
			return unique;
		},
		[],
	);

	localStorage.setItem('lastContacts', JSON.stringify(uniqueContacts));
};

export const getContactsFromLocalStorage = (): ContactsGraphContract[] => {
	const contactsJSON = localStorage.getItem('lastContacts');
	if (contactsJSON) {
		return JSON.parse(contactsJSON);
	}
	return [];
};
