/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import styles from './meeting-update-create.modal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';
import { MeetingContract, TagContract } from '../../../store/meetings/types';
import {
	getDayPastSevenDays,
	getInputDateTimeFormat,
	getStartOfWeekToDate,
	getMoment,
	getMinutesPastDayAgenda,
	getStartOfDay,
	getHour,
	getMin,
	getDateFormatL,
	getHourFormat,
	getRightTimeSlot,
	getDateFormatWithYear,
	getStartOfDayDate,
} from '../../../shared/utils/date.util';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	IconClose,
	IconDownArrow,
	IconMeetingCreateModal,
	IconMeetings,
	IconSimplePlus,
} from '../../../shared/utils/icon';
import { getCurrentUserContacts } from '../../../store/user/slice';
import { Avatar } from '../../../shared/utils/avatar';
import { ContactsGraphContract } from '../../../store/user/type';
import { saveContactsToLocalStorage } from '../../../shared/utils/contacts';
import MeetingFormAttendeesListComponent from './meeting-form-attendees-list.component';
import {
	addLastMeetings,
	getLastMeetings,
	getMeetingsForAWeek,
	getMeetingsForSelectOption,
	updateMeetingTags,
	updateSelectedMeetingTags,
} from '../../../store/meetings/slice';
import MeetingFormDatePickerModal from './meeting-form-date-picker.modal';
import NotesFormMeetingsModalComponent from '../../notes/components/notes.form-meetings.modal.component';
import MeetingFormHoursPickerModal from './meeting-form-hours-picker';
import { MeetingForSeriesContract } from '../../../store/series/type';
import { meetingsForSeries } from '../../../store/series/slice';
import TagsFormModal from './tags-form.modal';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import SimpleEditor from '../../../shared/utils/simple-editor';
import { ProjectContract } from '../../../store/project/type';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { PersonaSize } from '@fluentui/react';

type FormData = {
	eventSubject: string;
	eventLocation: string;
	eventStart: Date;
	eventEnd: Date;
	isOnline: boolean;
	bodyContent: string;
	attendees: Array<{
		type: string;
		emailAddress: {
			address: string;
		};
	}>;
	serial: boolean;
	meeting: MeetingContract;
	project: ProjectContract;
	isBot: boolean;
};

interface Props {
	meeting?: MeetingContract;
	onSubmit: (data: FormData) => void;
	handleClose: () => void;
	isAllDay: boolean;
	handleAllDay: (allDay: boolean) => void;
	date?: string;
	handleTimeTime: (time: string) => void;
	handleNewAttendees: (
		newAtt: Array<{
			type: string;
			emailAddress: {
				name: string;
				address: string;
			};
		}>,
	) => void;
	type: string;
	handleNewTags: (tags: Array<{ tag: string; color: string }>) => void;
	projectId?: string;
	isBot?: boolean;
}

const MeetingFormComponent: React.FC<Props> = ({
	meeting,
	onSubmit,
	handleClose,
	isAllDay,
	handleAllDay,
	date,
	handleTimeTime,
	handleNewAttendees,
	type,
	handleNewTags,
	projectId,
	isBot,
}) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user, contacts } = useAppSelector((state) => state.user);
	const { selectedMeetingInformation } = useAppSelector((state) => state.meetings);
	const { dataMeetingBot } = useAppSelector((state) => state.recall);
	const { projects } = useAppSelector((state) => state.project);
	const [animation, setAnimation] = useState<boolean>(false);
	const inputRef = useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		if (inputRef) {
			const inputChild = inputRef.current?.firstChild as HTMLInputElement;
			setTimeout(() => {
				inputChild.focus();
			}, 300);
		}
	}, []);

	const dataSchema = yup.object().shape({
		eventSubject: yup.string().required(),
		eventLocation: yup.string(),
		eventStart: yup.date().required(),
		eventEnd: yup.date().required(),
		attendees: yup
			.array()
			.min(1, 'At least one attendee is required')
			.of(
				yup.object().shape({
					type: yup.string().required(),
					emailAddress: yup.object().shape({
						address: yup.string().email().required(),
					}),
				}),
			),
		serial: yup.boolean(),
		meeting: yup.object(),
		project: yup.object(),
		isBot: yup.boolean(),
	});

	const { register, handleSubmit, control, setValue, trigger } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	// --- TAGS

	const [tagsModalOpen, setTagsModalOpen] = React.useState(false);

	const [tagsMeeting, setTagsMeeting] = React.useState<TagContract[]>();

	const [newTags, setNewTags] = React.useState<Array<{ tag: string; color: string }>>([]);

	React.useEffect(() => {
		if (meeting) setTagsMeeting(meeting.tags);
	}, [meeting]);

	const deleteTag = (tag: TagContract) => {
		if (meeting) {
			const tags = meeting && meeting.tags.filter((tagsD) => tagsD !== tag);
			const updatedTags = tagsMeeting?.filter((tagsD) => tagsD !== tag);
			setTagsMeeting(updatedTags);

			dispatch(
				updateMeetingTags({
					tags,
					selectedMeetingID: meeting.iCalUId,
				}),
			).then((response) => {
				dispatch(updateSelectedMeetingTags(response.payload as TagContract[]));
			});
		} else {
			if (newTags) {
				const updatedTags = tagsMeeting?.filter((tagsD) => tagsD !== tag);
				setTagsMeeting(updatedTags);
				const updatesTags = newTags.filter((tagToDelete) => tagToDelete.tag !== tag.title);
				setNewTags(updatesTags);
				handleNewTags(updatesTags);
			}
		}
	};

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	const closeTagsModal = (tag: string, color: string) => {
		if (tagsMeeting) {
			if (meeting) {
				const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [meeting.iCalUId], [], [], []);
				const updatedTags = [...tagsMeeting, newTag];
				setTagsMeeting(updatedTags);
				setTagsModalOpen(false);
				const tags = [
					...meeting.tags,
					new TagContract(undefined, tag, color, user!.graphUserId, [meeting.iCalUId], [], [], []),
				];

				dispatch(
					updateMeetingTags({
						tags,
						selectedMeetingID: meeting.iCalUId,
					}),
				).then((response) => {
					dispatch(updateSelectedMeetingTags(response.payload as TagContract[]));
				});
			} else {
				const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [], [], [], []);
				const updatedTags = [...tagsMeeting, newTag];
				setTagsMeeting(updatedTags);
				setTagsModalOpen(false);

				const newTagTB = { tag, color };
				const updatedTagsToBeCreated = [...newTags, newTagTB];
				setNewTags(updatedTagsToBeCreated);
				handleNewTags(updatedTagsToBeCreated);
			}
		} else {
			const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [], [], [], []);
			const updatedTags = [newTag];
			setTagsMeeting(updatedTags);
			setTagsModalOpen(false);

			const newTagTB = { tag, color };
			const updatedTagsToBeCreated = [...newTags, newTagTB];
			setNewTags(updatedTagsToBeCreated);
			handleNewTags(updatedTagsToBeCreated);
		}
	};

	const openTagsModal = () => {
		return setTagsModalOpen(!tagsModalOpen);
	};

	// ------ ATTENDEES

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [attendeeMenu, setAttendeeMenu] = React.useState(false);
	const [selectedAttendees, setSelectedAttendees] = React.useState<ContactsGraphContract[]>([]);

	const handleMenu = () => {
		setAttendeeMenu(!attendeeMenu);
		setSelectedAttendees([]);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	const [attendees, setAttendees] = React.useState<
		Array<{
			type: string;
			emailAddress: {
				name: string;
				address: string;
			};
		}>
	>(
		meeting
			? meeting.attendees
			: [
					{
						type: 'required',
						emailAddress: {
							name: user?.displayName ? user.displayName : '',
							address: user?.email ? user.email : '',
						},
					},
			  ],
	);

	const handleRemoveAttendee = (index: number) => {
		const newAttendees = [...attendees];
		newAttendees.splice(index, 1);
		setValue('attendees', newAttendees);
		trigger('attendees');
		setAttendees(newAttendees);
		handleNewAttendees(newAttendees);
	};

	const handleSelectAttendee = (selectedContact: ContactsGraphContract) => {
		const newAttendees = [...attendees];

		const newAttendee = {
			type: 'required',
			emailAddress: {
				name: selectedContact.displayName,
				address: selectedContact.emailAddresses[0].address,
			},
		};

		newAttendees.push(newAttendee);

		saveContactsToLocalStorage(selectedAttendees);
		setAttendees(newAttendees);
		handleNewAttendees(newAttendees);
		setSelectedAttendees([]);
		setAttendeeMenu(!attendeeMenu);
	};

	// -------------

	let timeoutId: any;

	const [noMatch, setNotMatch] = React.useState(false);
	const [contactNM, setContactNM] = React.useState<ContactsGraphContract>();
	const [isShearching, setIsShearching] = React.useState(false);

	const onChangeHandler = (filter: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getCurrentUserContacts(filter)).then((res) => {
				if (filter === '') {
					setIsShearching(false);
				} else {
					setIsShearching(true);
				}

				const response = res.payload as ContactsGraphContract[];
				const newContactPR = {
					displayName: filter,
					emailAddresses: [{ address: filter, name: filter }],
					imAddresses: ['filter'],
				};
				setNotMatch(false);

				if (response?.length < 1) {
					setNotMatch(true);
					setContactNM(newContactPR);
				}
			});
		}, 200);
	};

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const IconTeams = 'https://static2.sharepointonline.com/files/fabric/assets/brand-icons/product/svg/teams_48x1.svg';

	// --- agenda --- //

	const [datePicker, setDatePicker] = React.useState(false);

	const handleDatePicker = () => {
		setDatePicker(!datePicker);
	};

	const [datePickerEnd, setDatePickerEnd] = React.useState(false);

	const handleDatePickerEnd = () => {
		setDatePickerEnd(!datePickerEnd);
	};

	const [dateSelect, setDateSelect] = React.useState(
		meeting ? new Date(meeting.start) : date ? new Date(date) : new Date(),
	);

	const [dateSelectEnd, setDateSelectEnd] = React.useState(
		meeting ? new Date(meeting.end) : date ? new Date(getRightTimeSlot(new Date(date), 0, 30)) : new Date(),
	);

	// const { meetingsForWeek, selectedMeetingForWeek } = useAppSelector((state) => state.meetings);

	// const start = getStartOfWeek(dateSelect);
	// const weekDays = Array.from({ length: 7 }, (v, i) => start.clone().add(i, 'days').toDate());

	// const handleNextWeek = () => {
	// 	const newDate = getDayPastOneWeek(dateSelect);

	// 	setDateSelect(newDate);
	// };

	// const handlePastWeek = () => {
	// 	const newDate = getDayLessOneWeek(dateSelect);

	// 	setDateSelect(newDate);
	// };

	const startDate = getStartOfWeekToDate(dateSelect);
	const endDate = getDayPastSevenDays(startDate);
	const dateRange = { startDate, endDate };

	React.useEffect(() => {
		dispatch(getMeetingsForAWeek(dateRange));
		setValue('eventStart', dateSelect);
		setValue('eventEnd', dateSelectEnd);
	}, [dispatch, dateSelect]);

	const [hours, setOpenHours] = React.useState(false);

	const handleHours = () => {
		const toggleHour = !hours;
		setOpenHours(toggleHour);
	};

	const [hoursEnd, setOpenHoursEnd] = React.useState(false);

	const handleHoursEnd = () => {
		setOpenHoursEnd(!hoursEnd);
	};

	const handleDateSelect = (dateSelectPicker: Date) => {
		setDateSelect(dateSelectPicker);
		setDateSelectEnd(
			getRightTimeSlot(getStartOfDayDate(dateSelectPicker), getHour(dateSelectEnd), getMin(dateSelectEnd)),
		);
		setValue('eventStart', dateSelectPicker);
		setValue(
			'eventEnd',
			getRightTimeSlot(getStartOfDayDate(dateSelectPicker), getHour(dateSelectEnd), getMin(dateSelectEnd)),
		);
		handleDatePicker();
	};

	const handleDateSelectEnd = (dateSelectPicker: Date) => {
		setDateSelectEnd(dateSelectPicker);
		setValue('eventEnd', dateSelectPicker);
		handleDatePickerEnd();
	};

	const handleHourSelect = (dateSelectPicker: Date) => {
		const hour = getHour(dateSelect);
		const newHour = getHour(dateSelectPicker);
		const hourDifference = newHour - hour;
		const newEndDate = getRightTimeSlot(dateSelectEnd, hourDifference, 0);
		setDateSelect(dateSelectPicker);
		setDateSelectEnd(newEndDate);
		setValue('eventStart', dateSelectPicker);
		setValue('eventEnd', newEndDate);
		handleHours();
	};

	const handleHourSelectEnd = (dateSelectPicker: Date) => {
		setDateSelectEnd(dateSelectPicker);
		setValue('eventEnd', dateSelectPicker);
		handleHoursEnd();
	};

	// const handleDateSelectAgenda = (dateSelectPicker: Date) => {
	// 	setDateSelect(dateSelectPicker);
	// 	setValue('eventStart', dateSelectPicker);
	// };

	// -----

	const [minutesPast, setMinutesPast] = React.useState(0);

	React.useEffect(() => {
		const interval = setInterval(() => {
			const actualTime = getMinutesPastDayAgenda(dateSelect);
			setMinutesPast(actualTime);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	const scrollToRef = React.useRef<HTMLDivElement>(null);
	const select = getMoment(dateSelect);

	React.useEffect(() => {
		const interval = setInterval(() => {
			const midnight = getStartOfDay(dateSelect);
			const actualTime = select.diff(midnight, 'minutes');
			setMinutesPast(actualTime);
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	React.useEffect(() => {
		if (scrollToRef.current) {
			const scrollPosition = Math.floor((minutesPast / 60) * 60 - 50);
			scrollToRef.current.scrollTop = scrollPosition;
		}
	}, [minutesPast]);

	React.useEffect(() => {
		dispatch(getLastMeetings()).then((res) => {
			const m = res.payload as MeetingContract[];

			dispatch(addLastMeetings(m));
		});
	}, [dispatch]);

	// ___ connected meeting

	const [isSearching, setIsSearching] = React.useState(false);

	const [openMeetingMenu, setOpenMeetingMenu] = React.useState(false);

	const handleMeetingMenu = () => {
		setIsSearching(false);
		setOpenMeetingMenu(!openMeetingMenu);
	};

	const onChangeHandlerMeeting = (filter: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getMeetingsForSelectOption(filter)).then(() => {
				setIsSearching(true);
			});
		}, 200);
	};

	const [meetingConnect, setMeetingConnect] = React.useState<MeetingContract>();

	const [series, setSeries] = React.useState<MeetingForSeriesContract[]>();

	React.useEffect(() => {
		if (meeting) {
			dispatch(meetingsForSeries(meeting.iCalUId)).then((res) => {
				const data = res.payload as MeetingForSeriesContract[];
				const sortedData =
					data !== undefined
						? [...data].sort(
								(a, b) =>
									new Date(a.meetingStartDate).getTime() - new Date(b.meetingStartDate).getTime(),
						  )
						: [];
				setSeries(sortedData);
			});
		}
	}, [meeting]);

	const handleChangeMeeting = (selectedValue: MeetingContract) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setValue('serial', true);
			setValue('meeting', selectedValue);
			setMeetingConnect(selectedValue);
			handleMeetingMenu();
		}, 1000);
	};

	const handleTextChange = (content: string) => {
		setValue('bodyContent', content);
		trigger('bodyContent');
	};

	return (
		<>
			<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
				<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
					<div>
						<div className={styles.titleWrapper}>
							<h3>
								<IconMeetingCreateModal />
								{t('Meeting details')}
							</h3>
							<div className={styles.closeButton}>
								<div
									className={styles.icon}
									onClick={() => {
										closeModal();
										setSelectedAttendees([]);
									}}>
									<IconClose />
								</div>
							</div>
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div ref={inputRef}>
								<input
									className={styles.nameMeeting}
									defaultValue={meeting ? meeting.subject : ''}
									{...register('eventSubject')}
									placeholder={t('Meeting name') as string}
								/>
							</div>
							<div>
								<input
									defaultValue={meeting ? meeting.location : ''}
									{...register('eventLocation')}
									placeholder={t('Meeting location') as string}
								/>
							</div>
							<div className={styles.attendees}>
								{attendees.map((attendee, index) => (
									<>
										<div className={styles.flex} key={index}>
											<div className={styles.name}>
												<Avatar
													size={PersonaSize.size32}
													name={attendee.emailAddress.name}
													mail={attendee.emailAddress.address}
													index={index}
												/>
												<span>{attendee.emailAddress.name}</span>
											</div>
											<span className={styles.close} onClick={() => handleRemoveAttendee(index)}>
												<IconClose />
											</span>
										</div>
									</>
								))}
								<div className={styles.addAttendee} onClick={() => handleMenu()}>
									{/* {t('Invite people')} */}
									<input
										placeholder={t('Invite people') as string}
										type='text'
										onChange={(e) => onChangeHandler(e.target.value)}
									/>
									<span className={styles.iconColor}>
										<IconSimplePlus />
									</span>
								</div>
								{attendeeMenu && (
									<div className={styles.modalAttendee} ref={modalRef}>
										{/* <div className={styles.searchInput}>
											<span>
												<IconSearch />
											</span>
											<input
												placeholder='Rechercher'
												type='text'
												onChange={(e) => onChangeHandler(e.target.value)}
											/>
										</div> */}
										<div className={styles.containerList}>
											{selectedAttendees &&
												selectedAttendees.map((attendee, indexSA) => (
													<>
														<MeetingFormAttendeesListComponent
															attendee={attendee}
															index={indexSA}
															handleSelectAttendee={handleSelectAttendee}
															selectedAttendees={selectedAttendees}
														/>
													</>
												))}
											{!isShearching &&
												user?.userCompany
													.filter(
														(contact) =>
															!selectedAttendees.some(
																(attendee) =>
																	attendee.emailAddresses[0].address ===
																	contact.userName,
															),
													)
													.filter(
														(contact) =>
															!attendees.some(
																(attendee) =>
																	attendee.emailAddress.address === contact.userName,
															),
													)
													.map((userC, index) => (
														<>
															<div
																key={index}
																className={styles.list}
																onClick={() =>
																	handleSelectAttendee({
																		displayName: userC.name,
																		emailAddresses: [
																			{
																				address: userC.userName,
																				name: userC.name,
																			},
																		],
																		imAddresses: [userC.userName],
																	})
																}>
																<div className={styles.name}>
																	<Avatar
																		size={PersonaSize.size32}
																		name={userC.name}
																		mail={userC.userName}
																		index={index}
																	/>
																	<span>{userC.name}</span>
																</div>
																<input
																	onChange={() =>
																		handleSelectAttendee({
																			displayName: userC.name,
																			emailAddresses: [
																				{
																					address: userC.userName,
																					name: userC.name,
																				},
																			],
																			imAddresses: [userC.userName],
																		})
																	}
																	checked={selectedAttendees.includes({
																		displayName: userC.name,
																		emailAddresses: [
																			{
																				address: userC.userName,
																				name: userC.name,
																			},
																		],
																		imAddresses: [userC.userName],
																	})}
																	type='checkbox'
																/>
																<IconSimplePlus />
															</div>
														</>
													))}
											{isShearching &&
												contacts &&
												contacts
													.filter(
														(contact) =>
															contact.emailAddresses &&
															contact.emailAddresses.length > 0 &&
															contact.emailAddresses[0].address !== '' &&
															contact.displayName !== '',
													)
													.filter(
														(contact) =>
															!selectedAttendees.some(
																(attendee) =>
																	attendee.emailAddresses[0].address ===
																	contact.emailAddresses[0].address,
															),
													)
													.filter(
														(contact) =>
															!attendees.some(
																(attendee) =>
																	attendee.emailAddress.address ===
																	contact.emailAddresses[0].address,
															),
													)
													.map((contact, indexCA) => (
														<>
															<MeetingFormAttendeesListComponent
																attendee={contact}
																index={indexCA}
																handleSelectAttendee={handleSelectAttendee}
																selectedAttendees={selectedAttendees}
															/>
														</>
													))}
											{noMatch &&
												contactNM &&
												isEmail(contactNM.displayName) === true &&
												!selectedAttendees.some(
													(attendee) => attendee.displayName === contactNM.displayName,
												) && (
													<>
														<MeetingFormAttendeesListComponent
															attendee={contactNM}
															index={12}
															handleSelectAttendee={handleSelectAttendee}
															selectedAttendees={selectedAttendees}
														/>
													</>
												)}
										</div>
									</div>
								)}
							</div>

							<div>
								<div className={styles.linkMeeting} onClick={() => handleMeetingMenu()}>
									{!meetingConnect && !series ? (
										<>{t('Connected meeting')}</>
									) : !meetingConnect && series ? (
										<>
											{t('Connected meeting')}
											{meeting &&
												series &&
												series
													.filter(
														(meetingS) =>
															new Date(meetingS.meetingStartDate) <
																new Date(meeting?.start) &&
															meetingS.iCalUId !== meeting.iCalUId,
													)
													.slice(-1)
													.map((meetingS, index) => (
														<div key={index}>
															{meetingS.meetingName} (
															{getDateFormatL(meetingS.meetingStartDate)})
														</div>
													))}
										</>
									) : meetingConnect ? (
										<>
											{' '}
											<span>{t('Connected meeting')}</span>
											{meetingConnect.subject} ({getDateFormatL(meetingConnect.start)})
										</>
									) : (
										<></>
									)}

									<span className={styles.iconColor}>
										<IconDownArrow />
									</span>
								</div>
								{openMeetingMenu && (
									<NotesFormMeetingsModalComponent
										isSearching={isSearching}
										onChangeHandler={onChangeHandlerMeeting}
										handleChangeMeeting={handleChangeMeeting}
										close={handleMeetingMenu}
									/>
								)}
							</div>

							<div className={styles.dueDateAu}>
								{/* <span>{t('Project')}</span> */}
								{user && projects && (
									<Controller
										name='project'
										control={control}
										defaultValue={projects.find((project) =>
											meeting
												? project.id === meeting.projectId
												: projectId
												? project.id === projectId
												: project.id === '',
										)}
										render={({ field }) => (
											<Stack spacing={2} sx={{ width: '100%' }}>
												<Autocomplete
													id='project'
													disableClearable
													{...field}
													onChange={(event, newValue) => {
														field.onChange(newValue);
													}}
													options={projects.map((project) => project)}
													getOptionLabel={(project: ProjectContract) =>
														`${project.projectName} (${getDateFormatL(project.createdOn)})`
													}
													renderInput={(param) => (
														<TextField
															placeholder={t<string>('Project')}
															sx={{
																'& .MuiInputBase-root': {
																	padding: 0,
																	margin: 0,
																},
																'& .MuiOutlinedInput-root': {
																	border: 'none',
																	paddingLeft: '14px',
																	paddingBlock: '12px',
																	fontSize: '14px',
																},
																'& .MuiOutlinedInput-notchedOutline': {
																	border: 'none',
																},
																'& .MuiSvgIcon-fontSizeMedium': {
																	padding: 0,
																},
																'& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input':
																	{
																		padding: 0,
																		margin: 0,
																	},
																'borderRadius': '6px',
																'& .MuiSvgIcon-root': {
																	'height': '100%',
																	'padding': 0,
																	'margin': 0,
																	'color': 'black',
																	'transform': 'scale(1.3)',
																	'&:hover': {
																		backgroundColor: 'white',
																	},
																},
																'border': 'transparent',
																'& input::placeholder': {
																	opacity: 1,
																	fontStyle: 'italic',
																	color: '#707070',
																},
															}}
															{...param}
														/>
													)}
												/>
											</Stack>
										)}
									/>
								)}
							</div>

							<div>
								{meeting && meeting.isOnlineMeeting === false ? (
									<>
										<div className={styles.isOnline}>
											<span className={styles.teams}>
												<img src={IconTeams} alt='icon teams' />
												{t('Create a Microsoft Teams meeting')}
											</span>
											<label className={styles.switch}>
												<input type='checkbox' {...register('isOnline')} />
												<span className={styles.slider}></span>
											</label>
										</div>
									</>
								) : !meeting ? (
									<>
										<div className={styles.isOnline}>
											<span className={styles.teams}>
												<img src={IconTeams} alt='icon teams' />
												{t('Create a Microsoft Teams meeting')}
											</span>
											<label className={styles.switch}>
												<input type='checkbox' {...register('isOnline')} />
												<span className={styles.slider}></span>
											</label>
										</div>
									</>
								) : (
									<></>
								)}
							</div>
							<div className={styles.timeslotContainer}>
								<div className={styles.timeslot}>
									<div className={styles.startDate}>
										<div className={styles.startDateButton} onClick={() => handleDatePicker()}>
											<div>
												<span>{t('Start dateTime')}</span>
												<br />

												<input
													style={{ display: 'none' }}
													type='datetime-local'
													defaultValue={
														meeting
															? getInputDateTimeFormat(meeting.start)
															: date
															? date
															: ''
													}
													{...register('eventStart')}
												/>

												<div className={styles.datePickerTime}>
													<span>{getDateFormatWithYear(dateSelect.toString())}</span>
												</div>
											</div>

											<div className={styles.icon}>
												<IconMeetings />
											</div>
										</div>

										{datePicker && dateSelect && (
											<>
												{meeting ? (
													<MeetingFormDatePickerModal
														date={new Date(dateSelect)}
														handleDatePicker={handleDateSelect}
														close={handleDatePicker}
														meeting={meeting}
														type='start'
													/>
												) : (
													<MeetingFormDatePickerModal
														date={new Date(dateSelect)}
														handleDatePicker={handleDateSelect}
														close={handleDatePicker}
														type='start'
													/>
												)}
											</>
										)}
									</div>

									<div className={styles.startDate}>
										<div className={styles.startDateButton} onClick={() => handleHours()}>
											<span style={{ fontWeight: 500 }}>{getHourFormat(dateSelect)}</span>
											<div className={styles.icon}>
												<span className={styles.iconColor}>
													<IconDownArrow />
												</span>
											</div>
										</div>

										{hours && (
											<>
												{meeting ? (
													<MeetingFormHoursPickerModal
														date={new Date(dateSelect)}
														handleDatePicker={handleHourSelect}
														close={handleHours}
														meeting={meeting}
														type='start'
													/>
												) : (
													<MeetingFormHoursPickerModal
														date={new Date(dateSelect)}
														handleDatePicker={handleHourSelect}
														close={handleHours}
														type='start'
													/>
												)}
											</>
										)}
									</div>
								</div>

								<div className={styles.timeslot}>
									<div className={styles.startDate}>
										<div className={styles.startDateButton} onClick={() => handleDatePickerEnd()}>
											<div>
												<span>{t('End dateTime')}</span>
												<br />

												<input
													style={{ display: 'none' }}
													type='datetime-local'
													defaultValue={
														meeting
															? getInputDateTimeFormat(meeting.start)
															: date
															? date
															: ''
													}
													{...register('eventEnd')}
												/>

												<div className={styles.datePickerTime}>
													<span>{getDateFormatWithYear(dateSelectEnd.toString())}</span>
												</div>
											</div>

											<div className={styles.icon}>
												<IconMeetings />
											</div>
										</div>

										{datePickerEnd && dateSelectEnd && (
											<>
												{meeting ? (
													<MeetingFormDatePickerModal
														date={new Date(dateSelectEnd)}
														handleDatePicker={handleDateSelectEnd}
														close={handleDatePickerEnd}
														meeting={meeting}
														type='end'
													/>
												) : (
													<MeetingFormDatePickerModal
														date={new Date(dateSelectEnd)}
														handleDatePicker={handleDateSelectEnd}
														close={handleDatePickerEnd}
														type='end'
													/>
												)}
											</>
										)}
									</div>

									<div className={styles.startDate}>
										<div className={styles.startDateButton} onClick={() => handleHoursEnd()}>
											<span style={{ fontWeight: 500 }}>{getHourFormat(dateSelectEnd)}</span>
											<div className={styles.icon}>
												<span className={styles.iconColor}>
													<IconDownArrow />
												</span>
											</div>
										</div>

										{hoursEnd && (
											<>
												{meeting ? (
													<MeetingFormHoursPickerModal
														date={new Date(dateSelectEnd)}
														handleDatePicker={handleHourSelectEnd}
														close={handleHoursEnd}
														meeting={meeting}
														type='end'
													/>
												) : (
													<MeetingFormHoursPickerModal
														date={new Date(dateSelectEnd)}
														handleDatePicker={handleHourSelectEnd}
														close={handleHoursEnd}
														type='end'
													/>
												)}
											</>
										)}
									</div>
								</div>
							</div>

							{/* <div className={styles.agenda}>
								<div className={styles.dayHeader}>
									<div className={styles.daySlot} onClick={() => handlePastWeek()}>
										<IconLeft />
									</div>
									{weekDays.map((day, indexWD) => {
										return (
											<div
												onClick={() => setDateSelect(day)}
												className={styles.daySlot}
												key={indexWD}>
												<span
													style={{
														color: isSameDay(day, dateSelect) ? '#00ad56' : '#201f1e',
													}}>
													{getDayofTheWeek(day).slice(0, 3)}
													<br />
													{getDayCalendarFormat(day)}
												</span>
											</div>
										);
									})}
									<div
										className={styles.daySlot}
										onClick={() => {
											handleNextWeek();
										}}>
										<IconRight />
									</div>
								</div>
								<div className={styles.agendaMeetings} ref={scrollToRef}>
									<div className={styles.week}>
										<HoursHeaderComponent hours={hours}></HoursHeaderComponent>

										<div className={styles.day}>
											<DayOverviewComponent
												date={dateSelect}
												meetings={meetingsForWeek?.filter((meetingW) =>
													isBetween(meetingW.start, dateSelect),
												)}
												selectedMeeting={selectedMeetingForWeek}
												hours={hours}
												toggle={() => undefined}
												onDateSelect={() => undefined}
												agenda={true}
												handleDateAgenda={handleDateSelectAgenda}
												meetingSlotHeight={
													timeType === 'minutes' ? time : timeType === 'hours' ? time * 60 : 0
												}
												meetingSlotTop={getDiffInMinutesStartOfDay(
													dateSelect,
													getStartOfDayDate(dateSelect),
												)}
												selectId={meeting?.iCalUId}></DayOverviewComponent>
										</div>
									</div>
								</div>
							</div> */}

							<div>
								<div className={styles.containerTags}>
									<div className={styles.containerTagsButton} onClick={() => openTagsModal()}>
										<div className={styles.tagsWrapper}>
											<p>{t('Tags')}</p>
											<div className={styles.tags}>
												{tagsMeeting?.map((tag) => (
													<div
														style={{
															backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
															border: `1px solid ${tag.color}`,
														}}
														key={tag.title}
														className={styles.tag}>
														<div
															style={{
																fontWeight: '600',
																color: tag.color,
															}}>
															{tag.title}
														</div>

														<div
															style={{ fontWeight: '600', color: tag.color }}
															className={styles.iconDelete}
															onClick={() => {
																deleteTag(tag);
															}}>
															<IconClose />
														</div>
													</div>
												))}
											</div>
										</div>

										<div className={styles.icon}>
											<span className={styles.iconColor}>
												<IconSimplePlus />
											</span>
										</div>
									</div>

									{tagsModalOpen && (
										<TagsFormModal
											close={closeTagsModal}
											handleSimpleClose={openTagsModal}
											type={meeting ? 'meetings' : 'newMeeting'}
										/>
									)}
								</div>
							</div>

							<div className={styles.textEditor}>
								<p>{t('Description')}</p>
								<input
									style={{ display: 'none' }}
									type='text'
									defaultValue={
										meeting && selectedMeetingInformation
											? selectedMeetingInformation?.textMeeting
											: ''
									}
									{...register('bodyContent')}
								/>
								<SimpleEditor
									content={
										meeting && selectedMeetingInformation
											? selectedMeetingInformation?.textMeeting
											: ''
									}
									onChangeHandlerText={handleTextChange}
								/>
							</div>
							<div className={styles.btnContainer}>
								<button
									className={styles.cancel}
									onClick={() => {
										closeModal();
										setSelectedAttendees([]);
									}}
									type='button'>
									{t('Cancel')}
								</button>
								<button className={styles.addBtn} type='submit' onClick={closeModal}>
									{type === 'create' ? (
										<>
											<IconSimplePlus />
											{t('Create')}
										</>
									) : (
										<>{t('Save')}</>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default MeetingFormComponent;
