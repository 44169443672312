/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styles from './project-create.module.scss';
import {
	IconClose,
	IconContact,
	IconLock,
	IconMeetingsPage,
	IconProjectModal,
	IconSimplePlus,
} from '../../../shared/utils/icon';
import { AccessRightType, TagContract } from '../../../store/meetings/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCurrentUserContacts } from '../../../store/user/slice';
import { ContactsGraphContract } from '../../../store/user/type';
import { Avatar } from '../../../shared/utils/avatar';
import { hexToRgb } from '../../../shared/utils/hextorgb';
import TagsFormModal from '../../meetings/modals/tags-form.modal';
import SimpleEditor from '../../../shared/utils/simple-editor';
import { ProjectContract } from '../../../store/project/type';
import {
	addTagsProject,
	addproject,
	createProjects,
	getPeopleShareProject,
	getProjects,
	postPeopleShareProject,
	setSelectedProject,
} from '../../../store/project/slice';
import { People } from '../../../store/notes/type';
import { emoticonsUT8 } from '../../../shared/utils/ut8-emoticon';
import { NotificationsType } from '../../../store/notifications/type';
import { SendNotifications } from '../../../store/notifications/slice';

interface Props {
	close: () => void;
}

const ProjectCreateModal: React.FC<Props> = ({ close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user, contacts } = useAppSelector((state) => state.user);

	// STATE

	const [title, setTitle] = React.useState('');
	const [access, setAccess] = React.useState(AccessRightType.INTERN);
	const [selectedAddresses, setSelectedAddresses] = React.useState<People[]>([]);
	const [openMenu, setOpenMenu] = React.useState(false);
	const [emoticon, setEmoticon] = React.useState('');
	const [menuEmoticon, setMenuEmoticon] = React.useState(false);
	const [tagsModalOpen, setTagsModalOpen] = React.useState(false);
	const [tags, setTags] = React.useState<TagContract[]>();
	const [newTags, setNewTags] = React.useState<Array<{ tag: string; color: string }>>([]);
	const [text, setText] = React.useState('');
	const [animation, setAnimation] = useState<boolean>(false);

	// ----

	const handleMenu = () => {
		setOpenMenu(!openMenu);
	};

	const handleMenuEmoticon = () => {
		setMenuEmoticon(!menuEmoticon);
	};

	const handleCheckboxChange = (attendee: People) => {
		setSelectedAddresses([...selectedAddresses, attendee]);
	};

	const handleDeletePeople = (attendee: People) => {
		setSelectedAddresses(selectedAddresses.filter((address) => address !== attendee));
	};

	const handleTextChange = (content: string) => {
		setText(content);
	};
	// --- PEOPLE

	let timeoutId: any;

	const [noMatch, setNotMatch] = React.useState(false);
	const [contactNM, setContactNM] = React.useState<People>();
	const [isShearching, setIsShearching] = React.useState('');

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const onChangeHandler = (filter: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setIsShearching(filter);

			dispatch(getCurrentUserContacts(filter)).then((res) => {
				const response = res.payload as ContactsGraphContract[];
				const newContactPR = {
					id: '',
					username: filter,
					mail: filter,
				};
				setNotMatch(false);

				if (response?.length < 1) {
					setNotMatch(true);
					setContactNM(newContactPR);
				}
			});
		}, 200);
	};

	// TAG

	const deleteTag = (tag: TagContract) => {
		if (newTags) {
			const updatedTags = tags?.filter((tagsD) => tagsD !== tag);
			setTags(updatedTags);
			const updatesTags = newTags.filter((tagToDelete) => tagToDelete.tag !== tag.title);
			setNewTags(updatesTags);
		}
	};

	const closeTagsModal = (tag: string, color: string) => {
		if (tags) {
			const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [], [], [], []);
			const updatedTags = [...tags, newTag];
			setTags(updatedTags);
			setTagsModalOpen(false);

			const newTagTB = { tag, color };
			const updatedTagsToBeCreated = [...newTags, newTagTB];
			setNewTags(updatedTagsToBeCreated);
		} else {
			const newTag = new TagContract(undefined, tag, color, user!.graphUserId, [], [], [], []);
			const updatedTags = [newTag];
			setTags(updatedTags);
			setTagsModalOpen(false);

			const newTagTB = { tag, color };
			const updatedTagsToBeCreated = [...newTags, newTagTB];
			setNewTags(updatedTagsToBeCreated);
		}
	};

	const handleClose = () => {
		setAnimation(true);

		setTimeout(() => {
			close();
			setAnimation(false);
		}, 300);
	};

	const openTagsModal = () => {
		return setTagsModalOpen(!tagsModalOpen);
	};

	// --- CREATE

	const createNewProject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		if (user) {
			const body: ProjectContract = {
				id: '',
				projectName: title,
				accessRightType: access,
				graphUserId: user.graphUserId,
				text,
				tags: [],
				emoticon,
				archived: false,
				todoCol: 'To do',
				doingCol: 'Doing',
				blockedCol: 'Blocked',
				doneCol: 'Done',
				createdOn: new Date(),
			};

			dispatch(createProjects(body))
				.then((res) => {
					const newProject = res.payload as ProjectContract;

					const createBodyNotification = (attendeeName: string, attendeeEmail: string) => {
						return {
							id: undefined,
							userFromName: user.displayName,
							userFromMail: user.email,
							userToName: attendeeName,
							userToMail: attendeeEmail,
							type: NotificationsType.NEW_PROJECT,
							noteId: undefined,
							todoId: undefined,
							graphiCalUId: undefined,
							projectId: newProject.id,
							meetingStartDate: undefined,
							itemTitle: newProject.projectName,
							visible: true,
							meetingName: undefined,
						};
					};

					if (access === AccessRightType.INTERN) {
						user.userCompany.forEach((userCompany) => {
							if (user.email !== userCompany.userName) {
								const projectBodyNotification = createBodyNotification(
									userCompany.name,
									userCompany.userName,
								);
								dispatch(SendNotifications(projectBodyNotification));
							}
						});
					}

					if (newTags) {
						newTags.forEach((tag) => {
							const bodyTag = {
								tag: tag.tag,
								color: tag.color,
								projectId: newProject.id,
							};

							dispatch(addTagsProject(bodyTag));
						});
					}

					if (access === AccessRightType.SHARED && selectedAddresses.length !== 0) {
						selectedAddresses.forEach((attendee) => {
							const bodyContact = {
								id: '1',
								username: attendee.username,
								mail: attendee.mail,
							};

							dispatch(postPeopleShareProject({ body: bodyContact, projectId: newProject.id }));
						});
					}

					const newBody: ProjectContract = {
						id: newProject.id,
						projectName: newProject.projectName,
						accessRightType: newProject.accessRightType,
						graphUserId: newProject.graphUserId,
						text: newProject.text,
						tags: tags ? tags : [],
						emoticon: newProject.emoticon,
						archived: newProject.archived,
						todoCol: 'To do',
						doingCol: 'Doing',
						blockedCol: 'Blocked',
						doneCol: 'Done',
						createdOn: newProject.createdOn,
					};

					dispatch(addproject(newBody));
					getPeopleShareProject(newProject.id);
					dispatch(setSelectedProject(newBody));
				})

				.then(() => {
					dispatch(getProjects()).then();
					handleClose();
				});
		}
	};

	// --

	const modalRef = React.useRef<HTMLDivElement>(null);
	const modalRefEmoticon = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefEmoticon.current && !modalRefEmoticon.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenuEmoticon();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenuEmoticon]);

	return (
		<div className={`${styles.modal} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.container} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.close} onClick={handleClose}>
					<IconClose />
				</div>
				<h3>
					<IconProjectModal />

					{t('Create a new project')}
				</h3>
				<form>
					<div className={styles.flexAccess}>
						<div
							className={access === AccessRightType.INTERN ? styles.selectAccess : styles.access}
							onClick={() => setAccess(AccessRightType.INTERN)}>
							<IconContact />
							{t('Intern')}
						</div>
						<div
							className={access === AccessRightType.SHARED ? styles.selectAccess : styles.access}
							onClick={() => setAccess(AccessRightType.SHARED)}>
							<IconMeetingsPage />
							{t('Shared')}
						</div>
						<div
							className={access === AccessRightType.ONLYME ? styles.selectAccess : styles.access}
							onClick={() => setAccess(AccessRightType.ONLYME)}>
							<IconLock />
							{t('Private')}
						</div>
					</div>

					<div className={styles.iconZone}>
						{emoticon === '' ? (
							<div className={styles.addEmoticone} onClick={() => handleMenuEmoticon()}>
								<div className={styles.iconPlus}>
									<IconSimplePlus />
								</div>

								{emoticon === '' && <>{t('Select an emoticon')}</>}
							</div>
						) : (
							<div className={styles.iconSelect} onClick={() => handleMenuEmoticon()}>
								<span dangerouslySetInnerHTML={{ __html: emoticon }}></span>
							</div>
						)}

						{menuEmoticon && (
							<div className={styles.modalPeople} ref={modalRefEmoticon}>
								<div className={styles.emoticonBlock}>
									{emoticonsUT8.map((emoticonUT, index) => (
										<div
											key={index}
											className={styles.emoticon}
											onClick={() => {
												setEmoticon(emoticonUT.code);
												handleMenuEmoticon();
											}}>
											{emoticonUT.smiley}
										</div>
									))}
								</div>
							</div>
						)}
					</div>

					<div>
						<input
							type='text'
							placeholder={t('Write your title here') as string}
							defaultValue={title}
							onChange={(e) => setTitle(e.target.value)}
						/>
					</div>

					{access === AccessRightType.SHARED && (
						<>
							{selectedAddresses.length > 0 && (
								<div className={styles.invited}>
									{selectedAddresses.length !== 0 &&
										selectedAddresses.map((contact, index) => (
											<div key={index} className={styles.avatar}>
												<div className={styles.avatarWrapper}>
													<Avatar name={contact.username} mail={contact.mail} index={index} />
													<p>{contact.username}</p>
												</div>
												<div
													className={styles.icon}
													onClick={() => handleDeletePeople(contact)}>
													<IconClose />
												</div>
											</div>
										))}
								</div>
							)}
							<div className={styles.attendees}>
								<div className={styles.addAttendee} onClick={() => handleMenu()}>
									{/* {t('Invite people')} */}
									<input
										placeholder={t('Invite people') as string}
										type='text'
										onChange={(e) => onChangeHandler(e.target.value)}
									/>
									<span className={styles.iconColor}>
										<IconSimplePlus />
									</span>
								</div>

								{isShearching && (
									<div className={styles.modalPeople} ref={modalRef}>
										<div className={styles.peopleList}>
											{/* {user && (
												<div className={styles.searchInput}>
													<span>
														<IconSearch />
													</span>
													<input
														type='text'
														onChange={(e) => onChangeHandler(e.target.value)}
													/>
												</div>
											)} */}
											{user?.userCompany
												.filter((userC) => userC.userName !== user.email)
												.filter((userC) =>
													userC.userName.toLowerCase().includes(isShearching.toLowerCase()),
												)
												.filter(
													(userC) =>
														!selectedAddresses.some(
															(address) => address.mail === userC.userName,
														),
												)
												.map((userC, index) => (
													<div
														className={styles.row}
														key={userC.userName}
														onClick={() =>
															handleCheckboxChange({
																id: '',
																username: userC.name,
																mail: userC.userName,
															})
														}>
														<div className={styles.avatar}>
															<Avatar
																name={userC.name}
																mail={userC.userName}
																index={index}
															/>
															<span>{userC.name}</span>
														</div>
														<div>
															<IconSimplePlus />
														</div>
													</div>
												))}
											{contacts &&
												contacts
													.filter(
														(contact) =>
															contact.emailAddresses &&
															contact.emailAddresses.length > 0 &&
															contact.emailAddresses[0].address !== '' &&
															contact.displayName !== '',
													)
													.filter(
														(contact) =>
															!selectedAddresses.some(
																(address) =>
																	address.mail === contact.emailAddresses[0].address,
															),
													)
													.map((contact, index) => (
														<div
															className={styles.row}
															key={contact.emailAddresses[0].address}
															onClick={() =>
																handleCheckboxChange({
																	id: '',
																	username: contact.displayName,
																	mail: contact.emailAddresses[0].address,
																})
															}>
															<div className={styles.avatar}>
																<Avatar
																	name={contact.displayName}
																	mail={contact.emailAddresses[0].address}
																	index={index}
																/>
																<span>{contact.displayName}</span>
															</div>
															<div>
																<IconSimplePlus />
															</div>
														</div>
													))}
											{noMatch && contactNM && isEmail(contactNM.mail) === true && (
												<>
													<div
														className={styles.row}
														key={contactNM.mail}
														onClick={() => handleCheckboxChange(contactNM)}>
														<div className={styles.avatar}>
															<Avatar
																name={contactNM.username}
																mail={contactNM.mail}
																index={2}
															/>
															<span>{contactNM.username}</span>
														</div>
														<div>
															<IconSimplePlus />
														</div>
													</div>
												</>
											)}
										</div>
									</div>
								)}
							</div>
						</>
					)}

					<div className={styles.containerTags}>
						<div className={styles.buttonTags} onClick={() => openTagsModal()}>
							<div className={styles.tagsWrapper}>
								<p>{t('Tags')}</p>
								<div className={styles.tags}>
									{tags?.map((tag) => (
										<div
											key={tag.title}
											className={styles.tag}
											style={{
												backgroundColor: `rgba(${hexToRgb(tag.color)}, 0.1)`,
												border: `1px solid ${tag.color}`,
											}}>
											<div style={{ color: tag.color, fontWeight: '600' }}>{tag.title}</div>

											<div
												style={{ color: tag.color, fontWeight: '600' }}
												className={styles.iconDelete}
												onClick={() => deleteTag(tag)}>
												<IconClose />
											</div>
										</div>
									))}
								</div>
							</div>
							<div className={styles.icon}>
								<IconSimplePlus />
							</div>
						</div>

						{tagsModalOpen && (
							<TagsFormModal
								close={closeTagsModal}
								handleSimpleClose={openTagsModal}
								type={'newProject'}
							/>
						)}
					</div>
					<div className={styles.textEditor}>
						<SimpleEditor content={text} onChangeHandlerText={handleTextChange} />
					</div>
					<div className={styles.buttons}>
						<button type='button' className={styles.cancel} onClick={handleClose}>
							{t('Cancel')}
						</button>
						<button
							className={styles.addBtn}
							disabled={title === '' ? true : false}
							onClick={(e) => createNewProject(e)}>
							<IconSimplePlus />
							{t('Create')}
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ProjectCreateModal;
