import { Organizer, GroupAttendeesByOrg, Attendees } from '../../shared/types/meetings-graph-interface';

export class MeetingContract {
	constructor(
		public graphEventId: string,
		public iCalUId: string,
		public createdDateTime: string,
		public subject: string,
		public start: string,
		public end: string,
		public attendees: Attendees[],
		public group: GroupAttendeesByOrg,
		public organizer: Organizer,
		public location: string,
		public isOnlineMeeting: boolean,
		public onlineMeeting: string,
		public bodyContent: string,
		public responseStatus: string,
		public isAllDay: boolean,
		public isOrganizer: boolean,
		public type: string,
		public tags: TagContract[],
		public files: FileContract[],
		public description: string,
		public seriesMasterId: string,
		public reccurence: Recurrence,
		public projectId?: string,
		public googleMeetUrl?: string,
	) {}
}

export class MeetingPrevContract {
	constructor(public iCalUId: string, public subject: string, public start: string) {}
}

export class LayoutInformation {
	constructor(
		public top: number,
		public height: number,
		public bottom: number,
		public left: number,
		public width?: number,
	) {}
}

export class MeetingEntry {
	constructor(public meeting: MeetingContract, public layoutInformation: LayoutInformation) {}
}

export class TagContract {
	constructor(
		public id: string | undefined,
		public title: string,
		public color: string,
		public userId: string,
		public graphiCalUIds: string[],
		public notesId: string[],
		public todoId: string[],
		public projectId: string[],
	) {}
}

export class FileContract {
	constructor(
		public id: string,
		public name: string,
		public location: string,
		public type: string,
		public accessRightType: AccessRightType,
		public accessRightCompanies: string[],
		public graphUserId: string,
		public graphiCalUId: string,
		public projectId: string,
	) {}
}

export enum AccessRightType {
	ONLYME = 'Only Me',
	EVERYONE = 'Everyone',
	COMPANIES = 'Companies',
	SHARED = 'Shared',
	INTERN = 'Intern',
}

export interface Recurrence {
	pattern: any;
	range: any;
}

export class InformationsContract {
	constructor(public id: string, public iCalUId: string, public textMeeting: string) {}
}
