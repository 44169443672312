import React from 'react';
import styles from './login-sign-in.component.module.scss';
import { loginRequest } from '../../../config/msal-auth-config.';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useMsal } from '@azure/msal-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch } from '../../../store/hooks';
import { NavLink, useParams } from 'react-router-dom';
import { RouteUrls } from '../../../routes/routes-config';
import { IconCheck } from '../../../shared/utils/icon';
import { extractDomain } from '../../../shared/utils/domainext';
import {
	checkExistingCompany,
	checkRequestStatus,
	checkUserFromFivedaysRegister,
	getInvitationDataPreRegister,
	sendRequest,
	subscribeForUpdate,
} from '../../../store/register/slice';
import { CompanyContract, RequestContract } from '../../../store/settings/type';
import LoginButton from './login-button.component';
import TermsAndConditions from './terms-and-conditions.component';
import AccessRequestMessage from './access-request-message.component';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { BillingPlan } from '../../../store/billing/type';
import { InvitationDataContract, InvitationInvalidReason, InvitationStatus } from '../../../store/invitations/type';

function LoginSignInComponent() {
	const { instance } = useMsal();
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const [canConnect, setCanConnect] = React.useState(false);
	const [canRegister, setCanRegister] = React.useState(false);
	const [existingCompany, setExistingCompany] = React.useState(false);
	const [company, setCompany] = React.useState<CompanyContract>();
	const [hasAcceptTheRights, setHasAcceptTheRights] = React.useState(false);
	const [noMicrosoft, setNoMicrosoft] = React.useState(false);
	const [mailCheck, setMailCheck] = React.useState('');
	const [send, isSend] = React.useState(false);
	const [requestSend, setRequestSend] = React.useState(false);
	const [request, setRequest] = React.useState<RequestContract>();

	const params = useParams();

	const currentPath = window.location.pathname;
	const SignUpPagePath = RouteUrls.SignUp;
	const isOnSignUpPage = currentPath === SignUpPagePath;

	const handleNoMicrosoft = () => {
		setNoMicrosoft(!noMicrosoft);
	};

	const handleAcceptTheRights = (check: boolean) => {
		setHasAcceptTheRights(check);
	};

	const handleLoginRedirect = () => {
		instance.loginRedirect(loginRequest);
	};

	const [inviteData, setInviteData] = React.useState<InvitationDataContract>();

	const storedInvitationId = params.inviteID;
	// if (params && params.inviteID) {
	// 	localStorage.setItem('invite', params.inviteID ? params.inviteID : 'no');
	// }

	React.useEffect(() => {
		if (params && params.inviteID) {
			dispatch(getInvitationDataPreRegister(params.inviteID)).then((res) => {
				const dataInvitation = res.payload as InvitationDataContract;
				setInviteData(dataInvitation);
				localStorage.setItem('invite', params.inviteID ? params.inviteID : 'no');
			});
		}
	}, [params]);

	// ---

	type FormData = {
		email: string;
	};

	const dataSchema = yup.object().shape({
		email: yup
			.string()
			.email()
			.required()
			.test('is-professional', 'Email must be from a professional domain', (value) => {
				if (value) {
					const domain = value.split('@')[1];
					return (
						!domain.includes('gmail.') &&
						!domain.includes('hotmail.') &&
						!domain.includes('outlook.') &&
						!domain.includes('yahoo.')
					);
				}
				return true;
			}),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const [isWritting, setIsWritting] = React.useState(false);

	const checkUser = (data: FormData) => {
		setIsWritting(true);
		const regex = /^\S+@\S+\.\S+$/;
		if (regex.test(data.email)) {
			setTimeout(() => {
				const mail = data.email;
				setMailCheck(mail);
				dispatch(checkUserFromFivedaysRegister(mail)).then((res) => {
					const isUser = res.payload as boolean;

					if (isUser === true) {
						setCanConnect(isUser);
						setCanRegister(false);
						setExistingCompany(false);
						setCompany(undefined);
						setNoMicrosoft(false);
						setIsWritting(false);
					} else {
						const domain = extractDomain(mail);
						dispatch(checkExistingCompany(domain)).then((resCom) => {
							const result = resCom.payload;

							if (result === false) {
								setCanConnect(isUser);
								setCanRegister(true);
								setExistingCompany(false);
								setCompany(undefined);
								setNoMicrosoft(false);
								setIsWritting(false);
							} else {
								setCanConnect(isUser);
								setCanRegister(false);
								setExistingCompany(true);
								setCompany(result);
								setNoMicrosoft(false);
								setIsWritting(false);
								dispatch(checkRequestStatus(mail)).then((resultRequest) => {
									const body = resultRequest.payload;

									if (body !== false) {
										setRequest(body as RequestContract);
									}
								});
							}
						});
					}
				});
			}, 600);
		}
	};

	const checkUserInvite = (data: FormData) => {
		setIsWritting(true);
		const regex = /^\S+@\S+\.\S+$/;
		if (regex.test(data.email)) {
			setTimeout(() => {
				const mail = data.email;
				setMailCheck(mail);
				setCanRegister(true);
				setIsWritting(false);
			}, 600);
		}
	};

	const subscribe = () => {
		dispatch(subscribeForUpdate(mailCheck)).then(() => {
			isSend(true);
		});
	};

	const sendRequestForCompany = () => {
		if (company && mailCheck) {
			const body = {
				id: '',
				mail: mailCheck,
				companyId: company.id,
				companyNam: company.name,
				requestAccepte: false,
			};

			dispatch(sendRequest(body)).then(() => {
				setRequestSend(true);
			});
		}
	};

	return (
		<div className={styles.container}>
			{storedInvitationId && inviteData && (
				<div className={styles.form}>
					<div>
						<h2>{t('Register to 5DAYS')}</h2>
					</div>
					{inviteData.invitation?.status === InvitationStatus.PENDING && (
						<>
							<form onChange={handleSubmit(checkUserInvite)} onSubmit={handleSubmit(checkUserInvite)}>
								<span
									style={{
										display:
											(canRegister || canConnect || existingCompany) && !requestSend
												? 'inline-block'
												: 'none',
										color: requestSend ? 'black' : '',
									}}
									className={styles.checkIcon}>
									<IconCheck />
								</span>
								<span
									className={styles.spinner}
									style={{ display: isWritting ? 'inline-block' : 'none' }}>
									<Spinner size={SpinnerSize.xSmall} />
								</span>
								<input
									style={{
										borderColor: errors.email
											? 'red'
											: canConnect || canRegister || (existingCompany && !requestSend)
											? 'green'
											: '',
									}}
									type='email'
									spellCheck='false'
									placeholder={t('Professional email address') as string}
									onChangeCapture={() => {
										setIsWritting(true);
									}}
									{...register('email')}
								/>
								{errors.email && (
									<p className={styles.error}>{t('You should user a professional email address')}</p>
								)}
							</form>
							{canRegister && (
								<div className={styles.fadeRightBottomRegister}>
									<TermsAndConditions
										handleAcceptTheRights={handleAcceptTheRights}
										hasAcceptTheRights={hasAcceptTheRights}
									/>
									<LoginButton
										hasAcceptTheRights={hasAcceptTheRights}
										handleClick={handleLoginRedirect}>
										Connect with Microsoft
									</LoginButton>
									<button onClick={() => handleNoMicrosoft()} className={styles.cancel}>
										{t('I dont have Microsoft account')}
									</button>
								</div>
							)}
						</>
					)}
					{inviteData.invitation && inviteData.invitation.status === InvitationStatus.COMPLETE && (
						<div className={styles.fadeRightBottomRegister}>{t('Invitation already completed')}</div>
					)}
					{inviteData && inviteData.success === false && (
						<div className={styles.fadeRightBottomRegister}>
							{inviteData?.invalidReason === InvitationInvalidReason.EXPIRED ? (
								<span>{t('Your invitation expired, please contact your admin to get a new one.')}</span>
							) : inviteData?.invalidReason === InvitationInvalidReason.NOT_FOUND ? (
								<span>{t('Your invitation expired, please contact your admin to get a new one.')}</span>
							) : inviteData?.invalidReason === InvitationInvalidReason.ALREADY_COMPLETE ? (
								<span>{t('Your invitation has already been completed.')}</span>
							) : (
								<span>{t('Something went wrong, please contact your admin.')}</span>
							)}
						</div>
					)}
				</div>
			)}
			{!storedInvitationId && (
				<div className={styles.form}>
					{!request || request.requestAccepte === true ? (
						<>
							{!requestSend ? (
								<>
									<div>
										<h1>{t('Welcome')}.</h1>
										{isOnSignUpPage ? (
											<h2>{t('Register to 5DAYS')}</h2>
										) : (
											<h2>{t('Connect to 5DAYS')}</h2>
										)}
									</div>

									<form onChange={handleSubmit(checkUser)} onSubmit={handleSubmit(checkUser)}>
										<span
											style={{
												display:
													(canRegister || canConnect || existingCompany) && !requestSend
														? 'inline-block'
														: 'none',
												color: requestSend ? 'black' : '',
											}}
											className={styles.checkIcon}>
											<IconCheck />
										</span>
										<span
											className={styles.spinner}
											style={{ display: isWritting ? 'inline-block' : 'none' }}>
											<Spinner size={SpinnerSize.xSmall} />
										</span>
										<input
											style={{
												borderColor: errors.email
													? 'red'
													: canConnect || canRegister || (existingCompany && !requestSend)
													? 'green'
													: '',
											}}
											type='email'
											spellCheck='false'
											placeholder={t('Professional email address') as string}
											onChangeCapture={() => {
												setIsWritting(true);
											}}
											{...register('email')}
										/>
										{errors.email && (
											<p className={styles.error}>
												{t('You should user a professional email address')}
											</p>
										)}
									</form>
								</>
							) : (
								<AccessRequestMessage company={company}>
									Congrats, an email has been sent to your organisations.
								</AccessRequestMessage>
							)}
						</>
					) : (
						<AccessRequestMessage company={company}>
							A request has already been sent to your organisation.
						</AccessRequestMessage>
					)}

					{!requestSend && (
						<div>
							{canConnect && (
								<div className={styles.fadeRightLogin}>
									<LoginButton handleClick={handleLoginRedirect}>Connect with Microsoft</LoginButton>
								</div>
							)}

							{canRegister && isOnSignUpPage && !noMicrosoft && (
								<div className={styles.fadeRightBottomRegister}>
									<TermsAndConditions
										handleAcceptTheRights={handleAcceptTheRights}
										hasAcceptTheRights={hasAcceptTheRights}
									/>
									<LoginButton
										hasAcceptTheRights={hasAcceptTheRights}
										handleClick={handleLoginRedirect}>
										Connect with Microsoft
									</LoginButton>
									<button onClick={() => handleNoMicrosoft()} className={styles.cancel}>
										{t('I dont have Microsoft account')}
									</button>
								</div>
							)}

							{canRegister && !isOnSignUpPage && !noMicrosoft && (
								<div className={styles.fadeRightBottom}>
									<p className={styles.notifier}>{t('To get started, please register')}</p>
									<NavLink to={RouteUrls.SignUp}>
										<button className={styles.button}>{t('Sign up')}</button>
									</NavLink>
								</div>
							)}

							{existingCompany && (
								<div>
									{!request && !company?.allowNewUser && (
										<div className={styles.fadeRightBottom}>
											<p className={styles.notifier}>
												{t('Hey, it looks like')} {''}
												<span className={styles.companyName}>{company?.name}</span> {''}
												{t('has already an account')} 🎉
											</p>
											<div>
												<p style={{ marginTop: '0' }} className={styles.notifier}>
													{t('Request an access.')}
												</p>
												<LoginButton handleClick={sendRequestForCompany} showIcons={false}>
													Request an access
												</LoginButton>
											</div>
										</div>
									)}

									{request && request.requestAccepte && (
										<div className={styles.fadeRightBottom}>
											<p style={{ marginTop: '10px' }}>
												<span className={styles.companyName}>{company?.name}</span> {''}
												{t('has accepted your request')} 🎉
											</p>
											<div>
												<p>{t('Connect with your Microsoft account')}</p>
												<TermsAndConditions
													handleAcceptTheRights={handleAcceptTheRights}
													hasAcceptTheRights={hasAcceptTheRights}
												/>
												<LoginButton
													hasAcceptTheRights={hasAcceptTheRights}
													handleClick={handleLoginRedirect}>
													Connect with Microsoft
												</LoginButton>
											</div>
										</div>
									)}

									{company?.allowNewUser && (
										<>
											{company.billingPlan.plan === BillingPlan.PERUSER ? (
												<>
													{company.billingPlan &&
													company &&
													company.billingPlan.usersForPlan - company.user.length > 0 ? (
														<div className={styles.fadeRightAccess}>
															<p className={styles.connectWithWindows}>
																{t('Connect with your Microsoft account')}
															</p>
															<div>
																<TermsAndConditions
																	handleAcceptTheRights={handleAcceptTheRights}
																	hasAcceptTheRights={hasAcceptTheRights}
																/>
																<LoginButton
																	hasAcceptTheRights={hasAcceptTheRights}
																	handleClick={handleLoginRedirect}>
																	Connect with Microsoft
																</LoginButton>
															</div>
														</div>
													) : (
														<div className={styles.fadeRightBottom}>
															<p className={styles.notifier}>
																{t('Hey, it looks like')} {''}
																<span className={styles.companyName}>
																	{company?.name}
																</span>{' '}
																{''}
																{t('has already an account')} 🎉
															</p>
															<div>
																<p
																	style={{ marginTop: '0' }}
																	className={styles.notifier}>
																	{t('Request an access.')}
																</p>
																<LoginButton
																	handleClick={sendRequestForCompany}
																	showIcons={false}>
																	Request an access
																</LoginButton>
															</div>
														</div>
													)}
												</>
											) : (
												<div className={styles.fadeRightAccess}>
													<p className={styles.connectWithWindows}>
														{t('Connect with your Microsoft account')}
													</p>
													<div>
														<TermsAndConditions
															handleAcceptTheRights={handleAcceptTheRights}
															hasAcceptTheRights={hasAcceptTheRights}
														/>
														<LoginButton
															hasAcceptTheRights={hasAcceptTheRights}
															handleClick={handleLoginRedirect}>
															Connect with Microsoft
														</LoginButton>
													</div>
												</div>
											)}
										</>
									)}
								</div>
							)}

							{noMicrosoft && (
								<div className={`${styles.notifier} ${styles.fadeRight}`}>
									<p>{t('Today 5DAYS is only running on Microsoft account.')}</p>
									<p>{t('Get notified soon 5DAYS is available on other platform.')}</p>

									{!send ? (
										<button className={styles.button} onClick={() => subscribe()}>
											<div className={styles.loginButton}>
												<span>{t('Subscribe to get notified')}</span>
											</div>
										</button>
									) : (
										<div style={{ marginTop: '50px' }}>
											<p className={styles.fadeRight}>
												{t(
													'You will get notified as soon as 5DAYS is available on other platform.',
												)}
											</p>
										</div>
									)}
								</div>
							)}

							{!request && !noMicrosoft && (
								<p className={styles.signUp}>
									{isOnSignUpPage ? (
										<>
											{t('Already have an account?')}{' '}
											<NavLink to={RouteUrls.Login}>
												<span>{t('Sign in')}</span>
											</NavLink>
										</>
									) : (
										<>
											{t('Dont have an account yet?')}{' '}
											<NavLink to={RouteUrls.SignUp}>
												<span>{t('Sign up')}</span>
											</NavLink>
										</>
									)}
								</p>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default LoginSignInComponent;
