import React from 'react';
import styles from './todos-filter.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
	showTodo: boolean;
	showDone: boolean;
	showArchived: boolean;
	showDoing: boolean;
	showBlock: boolean;
	handleShowTodo: (show: boolean) => void;
	handleShowDone: (show: boolean) => void;
	handleShowArchived: (show: boolean) => void;
	handleShowDoing: (show: boolean) => void;
	handleShowBlock: (show: boolean) => void;
}

const TodosFilterModal: React.FC<Props> = ({
	showTodo,
	showDone,
	showArchived,
	showDoing,
	showBlock,
	handleShowTodo,
	handleShowDone,
	handleShowArchived,
	handleShowDoing,
	handleShowBlock,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={styles.container}>
				<div className={styles.list}>
					<h2>{t('Select filters')}</h2>

					<div className={styles.row} onClick={() => handleShowTodo(showTodo === true ? false : true)}>
						<p>{t('To do')}</p>
						<input
							type='checkbox'
							checked={showTodo === true ? true : false}
							onChange={(e) => handleShowTodo(e.target.checked)}
						/>
					</div>

					<div className={styles.row} onClick={() => handleShowDoing(showDoing === true ? false : true)}>
						<p>{t('Doing')}</p>
						<input
							type='checkbox'
							checked={showDoing === true ? true : false}
							onChange={(e) => handleShowDoing(e.target.checked)}
						/>
					</div>

					<div className={styles.row} onClick={() => handleShowBlock(showBlock === true ? false : true)}>
						<p>{t('Blocked')}</p>
						<input
							type='checkbox'
							checked={showBlock === true ? true : false}
							onChange={(e) => handleShowBlock(e.target.checked)}
						/>
					</div>

					<div className={styles.row} onClick={() => handleShowDone(showDone === true ? false : true)}>
						<p>{t('Done')}</p>
						<input
							type='checkbox'
							checked={showDone === true ? true : false}
							onChange={(e) => handleShowDone(e.target.checked)}
						/>
					</div>

					<div
						className={styles.row}
						onClick={() => handleShowArchived(showArchived === true ? false : true)}>
						<p>{t('Archived')}</p>
						<input
							type='checkbox'
							checked={showArchived === true ? true : false}
							onChange={(e) => handleShowArchived(e.target.checked)}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default TodosFilterModal;
