import React from 'react';
import styles from './login-button.component.module.scss';
import { IconRight } from '../../../shared/utils/icon';
import LogoMicrosoft from '../../../../../src/images/logo/microsoft 1.png';
import { useTranslation } from 'react-i18next';

interface Props {
	hasAcceptTheRights?: boolean;
	handleClick: () => void;
	showIcons?: boolean;
	children: string;
}

const LoginButton: React.FC<Props> = ({ hasAcceptTheRights = true, handleClick, showIcons = true, children }) => {
	const { t } = useTranslation();

	return (
		<button
			className={styles.button}
			onClick={handleClick}
			disabled={hasAcceptTheRights ? false : true}
			style={{ backgroundColor: hasAcceptTheRights ? '' : 'rgba(128, 128, 128, 0.3)' }}>
			<div className={styles.loginButton}>
				<img
					style={{ display: showIcons ? 'inline-block' : 'none' }}
					src={LogoMicrosoft}
					alt='Logo Office 365'
				/>
				<span>{t(children)}</span>
				<span style={{ display: showIcons ? 'inline-block' : 'none' }}>
					<IconRight />
				</span>
			</div>
		</button>
	);
};

export default LoginButton;
