import React, { useEffect } from 'react';
import styles from './meeting-details-header-attendees.module.scss';
import { Attendees, Domain } from '../../../../../shared/types/meetings-graph-interface';
import { MeetingDetailsProps } from '../../../../../shared/types/component-interface';
import { Avatar } from '../../../../../shared/utils/avatar';
import { extractDomainWithExt } from '../../../../../shared/utils/domainext';
import 'react-tooltip/dist/react-tooltip.css';
import { IconMore, IconSimplePlus } from '../../../../../shared/utils/icon';
import AttendeesModal from './attendees-modal';
import { PersonaSize } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getCompanyForUser } from '../../../../../store/settings/slice';
import TooltipAttendees from '../../../../../shared/components/tooltip/tooltip-attendees.component';

// ----

const MeetingDetailsAttendees = ({ meeting }: MeetingDetailsProps) => {
	const modalRef = React.useRef<HTMLDivElement>(null);
	const { user, usersJobList } = useAppSelector((state) => state.user);
	const { company } = useAppSelector((state) => state.settings);
	const dispatch = useAppDispatch();
	const domains = Object.keys(meeting.group);
	const results = domains.map((domain) => ({
		domain,
		emailAddresses: meeting.group[domain].map((item: Attendees) => ({
			name: item.emailAddress.name,
			address: item.emailAddress.address,
		})),
	}));

	useEffect(() => {
		dispatch(getCompanyForUser());
	}, []);

	const [modal, setModal] = React.useState(false);

	const handleModal = () => {
		setModal(!modal);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleModal]);

	return (
		<>
			<div className={styles.attendees}>
				{results.slice(0, 3).map((result: Domain, index) => {
					const url = `https://www.google.com/s2/favicons?domain=${extractDomainWithExt(
						result.emailAddresses[0].address,
					)}&sz=${256}`;
					return (
						<div key={index}>
							<div className={styles.groupAttendees} style={{ marginLeft: index > 0 ? '40px' : '0' }}>
								<div className={styles.domain}>
									<img src={url} alt='favico' />
								</div>
								{result.emailAddresses
									.slice(0, 2)
									.map((attendee: { name: string; address: string }, indexAt) => {
										const userMeeting = usersJobList?.find(
											(userC) => attendee.address === userC.userName,
										);

										return (
											<div
												style={{
													position: 'absolute',
													top: '50%',
													transform: 'translateY(-50%)',
													left: indexAt === 0 ? '27px' : `${(indexAt + 1) * 25}px`,
													zIndex: `${10 - (indexAt + 1) * 1}`,
												}}
												className={styles.attendeeWrapper}
												key={indexAt}
												data-tooltip-place='bottom'
												data-tooltip-id={attendee.address}>
												<Avatar
													size={PersonaSize.size40}
													name={attendee.name}
													mail={attendee.address}
													index={index + indexAt}
												/>
												{!modal && (
													<TooltipAttendees
														address={attendee.address}
														name={attendee.name}
														jobTitle={userMeeting?.jobTitle}
														url={url}
														indexAt={indexAt}
													/>
												)}
											</div>
										);
									})}
								{result.emailAddresses.length > 3 && (
									<div className={styles.icon} onClick={() => handleModal()}>
										<IconSimplePlus />
									</div>
								)}
							</div>
						</div>
					);
				})}
				{results.length > 2 && (
					<div className={styles.icon} onClick={() => handleModal()}>
						<IconMore />
					</div>
				)}
			</div>
			{modal && (
				<div ref={modalRef}>
					<AttendeesModal meeting={meeting} />
				</div>
			)}
		</>
	);
};

export default MeetingDetailsAttendees;
