import React from 'react';
import styles from './note-people.module.scss';
import 'react-tooltip/dist/react-tooltip.css';
import { useAppSelector } from '../../../store/hooks';
import { extractDomainWithExt, groupByDomain, sortByGroup } from '../../../shared/utils/domainext';
import { NotesContract, People } from '../../../store/notes/type';
import { Domain } from '../../../shared/types/meetings-graph-interface';
import { IconMore } from '../../../shared/utils/icon';
import { Avatar } from '../../../shared/utils/avatar';
import AttendeesModal from '../modal/note-people.modal';
import { AccessRightType } from '../../../store/meetings/types';
import NotePeopleCompanyModal from '../modal/note-people-company.modal';
import { PersonaSize } from '@fluentui/react';
import TooltipAttendees from '../../../shared/components/tooltip/tooltip-attendees.component';

interface Props {
	note: NotesContract;
}

const HeaderPeople = ({ note }: Props) => {
	const { sharedPeopleNote } = useAppSelector((state) => state.notes);
	const { user, usersJobList } = useAppSelector((state) => state.user);
	const modalRef = React.useRef<HTMLDivElement>(null);
	const modalRefCompany = React.useRef<HTMLDivElement>(null);

	const [modal, setModal] = React.useState(false);

	const handleModal = () => {
		setModal(!modal);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleModal]);

	const sortedPeople = sortByGroup(sharedPeopleNote ? sharedPeopleNote : []);
	const groupedPeople = groupByDomain(sortedPeople);
	const domains = Object.keys(groupedPeople);
	const results = domains.map((domain) => ({
		domain,
		emailAddresses: groupedPeople[domain].map((item: People) => ({
			name: item.username,
			address: item.mail,
		})),
	}));

	const urlCreator = `https://www.google.com/s2/favicons?domain=${extractDomainWithExt(
		note.userMail ? note.userMail : note.graphUserId,
	)}&sz=${256}`;

	// ----

	const [modalCompany, setModalCompany] = React.useState(false);

	const handleModalCompany = () => {
		setModalCompany(!modalCompany);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefCompany.current && !modalRefCompany.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleModalCompany();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleModalCompany]);

	return (
		<>
			<div className={styles.attendees}>
				{note.accessRightType === AccessRightType.SHARED && (
					<>
						{results.slice(0, 2).map((result: Domain, index) => {
							const url = `https://www.google.com/s2/favicons?domain=${extractDomainWithExt(
								result.emailAddresses[0].address,
							)}&sz=${256}`;
							return (
								<div key={index}>
									<div
										className={styles.groupAttendees}
										style={{ marginLeft: index > 0 ? '40px' : '0' }}>
										<div className={styles.domain}>
											<img src={url} alt='favico' />
										</div>
										{result.emailAddresses
											.slice(0, 3)
											.map((attendee: { name: string; address: string }, indexAt) => {
												const userForFivedays = usersJobList?.find(
													(userJ) =>
														userJ.userName.toLowerCase() === attendee.address.toLowerCase(),
												);
												return (
													<>
														<div
															style={{
																position: 'absolute',
																top: '50%',
																transform: 'translateY(-50%)',
																left:
																	indexAt === 0 ? '27px' : `${(indexAt + 1) * 25}px`,
																zIndex: `${10 - (indexAt + 1) * 1}`,
															}}
															className={styles.attendeeWrapper}
															key={indexAt}
															data-tooltip-id={attendee.address}
															data-tooltip-place='bottom'>
															<Avatar
																size={PersonaSize.size40}
																name={attendee.name}
																mail={attendee.address}
																index={index + indexAt}
															/>
															{!modal && !modalCompany && (
																<TooltipAttendees
																	address={attendee.address}
																	name={
																		userForFivedays
																			? userForFivedays.name
																			: attendee.name
																	}
																	jobTitle={userForFivedays?.jobTitle}
																	url={url}
																	indexAt={indexAt}
																/>
															)}
														</div>
													</>
												);
											})}
									</div>
								</div>
							);
						})}
						{results.length > 2 && (
							<div className={styles.icon} onClick={() => handleModal()}>
								<IconMore />
							</div>
						)}
					</>
				)}

				{note.accessRightType === AccessRightType.ONLYME && (
					<>
						<div>
							<div className={styles.groupAttendees}>
								<div className={styles.domain}>
									<img src={urlCreator} alt='favico' />
								</div>
								<div
									data-tooltip-id={note.userMail ? note.userMail : note.graphUserId}
									data-tooltip-place='bottom'
									className={styles.attendeeWrapper}
									style={{
										position: 'absolute',
										top: '50%',
										transform: 'translateY(-50%)',
										left: '27px',
										zIndex: '10',
									}}>
									<Avatar
										size={PersonaSize.size40}
										name={note.graphUserName}
										mail={note.userMail ? note.userMail : note.graphUserId}
										index={1}
									/>
									{!modal && !modalCompany && (
										<TooltipAttendees
											address={note.userMail ? note.userMail : note.graphUserId}
											name={note.graphUserName}
											jobTitle={user?.jobTitle}
											url={urlCreator}
											indexAt={1}
										/>
									)}
								</div>
							</div>
						</div>
					</>
				)}

				{note.accessRightType === AccessRightType.INTERN && (
					<>
						<div>
							<div className={styles.groupAttendees}>
								<div className={styles.domain}>
									<img src={urlCreator} alt='favico' />
								</div>

								{note && note.creatorCompany ? (
									<>
										{note.creatorCompany.slice(0, 3).map((person, index) => {
											return (
												<>
													<div
														style={{
															position: 'absolute',
															top: '50%',
															transform: 'translateY(-50%)',
															left: index === 0 ? '27px' : `${(index + 1) * 25}px`,
															zIndex: `${10 - (index + 1) * 1}`,
														}}
														key={index}
														data-tooltip-id={person.graphUserId}
														data-tooltip-place='bottom'
														className={styles.attendeeWrapper}>
														<Avatar
															name={person.name}
															mail={person.graphUserId}
															index={index}
															size={PersonaSize.size40}
														/>
														{!modal && !modalCompany && (
															<TooltipAttendees
																address={person.graphUserId}
																name={person.name}
																jobTitle={person.jobTitle}
																url={urlCreator}
																indexAt={1}
															/>
														)}
													</div>
												</>
											);
										})}
									</>
								) : (
									<div>
										<Avatar
											size={PersonaSize.size40}
											name={note.graphUserName}
											mail={note.userMail ? note.userMail : note.graphUserId}
											index={1}
										/>
									</div>
								)}
								{note.creatorCompany && note.creatorCompany.length > 3 && (
									<div className={styles.icon} onClick={() => handleModalCompany()}>
										<IconMore />
									</div>
								)}
							</div>
						</div>
					</>
				)}

				{note.accessRightType === AccessRightType.EVERYONE && (
					<>
						<div>
							<div className={styles.groupAttendees}>
								<div className={styles.domain}>
									<img src={urlCreator} alt='favico' />
								</div>

								<div
									style={{
										position: 'absolute',
										top: '50%',
										transform: 'translateY(-50%)',
										left: '27px',
										zIndex: '10',
									}}
									data-tooltip-place='bottom'
									data-tooltip-id={note.userMail ? note.userMail : note.graphUserId}
									className={styles.attendeeWrapper}>
									<Avatar
										name={note.graphUserName}
										mail={note.userMail ? note.userMail : note.graphUserId}
										index={1}
										size={PersonaSize.size40}
									/>
									{!modal && !modalCompany && (
										<TooltipAttendees
											address={note.userMail ? note.userMail : note.graphUserId}
											name={note.graphUserName}
											jobTitle={user?.jobTitle}
											url={urlCreator}
											indexAt={1}
										/>
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</div>
			{modal && (
				<div ref={modalRef}>
					<AttendeesModal />
				</div>
			)}
			{modalCompany && note.creatorCompany && (
				<div ref={modalRefCompany}>
					<NotePeopleCompanyModal people={note.creatorCompany} />
				</div>
			)}
		</>
	);
};

export default HeaderPeople;
