import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './todo-form-col.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IconClose, IconTodos } from '../../../shared/utils/icon';
import { TodosStates } from '../../../store/todos/type';
import { addProjectColumn, addUserColumn, createCustomColumn } from '../../../store/column/slice';
import { ColumnCustomContract } from '../../../store/column/type';

export interface Props {
	handleClose: () => void;
	projectId?: string;
}

const TodoCreateCol = ({ handleClose, projectId }: Props) => {
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const dispatch = useAppDispatch();
	const [animation, setAnimation] = useState<boolean>(false);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	type FormData = {
		title: string;
		state: TodosStates;
	};

	const dataSchema = yup.object().shape({
		title: yup.string().required(),
	});

	const { handleSubmit, register } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const onSubmit = (data: FormData) => {
		if (user) {
			const body = {
				id: '',
				userId: user.graphUserId,
				projectId: projectId ? projectId : '',
				title: data.title,
				todoState: data.state,
			};

			dispatch(createCustomColumn(body)).then((res) => {
				if (projectId) {
					dispatch(addProjectColumn(res.payload as ColumnCustomContract));
				} else {
					dispatch(addUserColumn(res.payload as ColumnCustomContract));
				}
				closeModal();
			});
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.titleWrapper}>
					<h3>
						<IconTodos />
						{t('Create a new section')}
					</h3>
					<div className={styles.closeButton}>
						<div
							className={styles.icon}
							onClick={() => {
								closeModal();
							}}>
							<IconClose />
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={styles.inputWrapper}>
						<label>{t('Title')}</label>
						<input defaultValue={''} {...register('title')} />
					</div>
					<br />
					<div className={styles.inputWrapper}>
						<label>{t('State')}</label>
						<select id='state' defaultValue={TodosStates.TODO} {...register('state')}>
							<option value={TodosStates.TODO}>{t('To do')}</option>
							<option value={TodosStates.DOING}>{t('Doing')}</option>
							<option value={TodosStates.BLOCKED}>{t('Blocked')}</option>
							<option value={TodosStates.DONE}>{t('Done')}</option>
						</select>
					</div>
					<div className={styles.btnContainer}>
						<button className={styles.addBtn}>{t('Save')}</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default TodoCreateCol;
