import React, { useEffect, useRef } from 'react';
import { NodeViewComponentProps, useCommands, useRemirrorContext } from '@remirror/react';
import { IconClose, IconEdit } from '../../../../shared/utils/icon';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Avatar } from '../../../../shared/utils/avatar';
import { TodosContract, TodosStates } from '../../../../store/todos/type';
import {
	completeTodos,
	deleteTodo,
	deleteTodosNotes,
	getCurrentUserTodos,
	getNotesTodos,
	updateTodoStateNote,
	updateTodoTitleNote,
	updateTodos,
} from '../../../../store/todos/slice';
import { NotificationsType } from '../../../../store/notifications/type';
import { SendNotifications } from '../../../../store/notifications/slice';
import { useParams } from 'react-router-dom';
import {
	deleteTodoShared,
	deleteTodosShareNote,
	getNotesTodosShared,
	updateTodoSharedStateNote,
	updateTodoTitleNoteShared,
	updateTodosShared,
} from '../../../../store/sharing/slice';
import TodosFormModal from '../../../todos/modals/todos.form.modal';
import { TodosNodeExtension } from './todos-extension.component';

export function TodoInsideEditor({ node, forwardRef }: NodeViewComponentProps) {
	const { todoTitle, todoId, noteId } = node?.attrs;
	const { notesTodos } = useAppSelector((state) => state.todos);
	const { shareTodosNote } = useAppSelector((state) => state.sharing);
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);

	React.useEffect(() => {
		if (user) {
			dispatch(getNotesTodos(noteId));
		} else {
			dispatch(getNotesTodosShared(noteId));
		}
	}, []);

	const todo = user
		? notesTodos?.find((todoTF) => todoTF.id === todoId)
		: shareTodosNote?.find((todoTF) => todoTF.id === todoId);

	const params = useParams();
	const shareId = params.shareID;

	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	const { createTodo, deleteTodoInsideEditor } = useCommands();
	const { commands } = useRemirrorContext();

	const onChangeComplete = (checked: boolean, todoC: TodosContract) => {
		const body = {
			state: checked === true ? TodosStates.DONE : TodosStates.TODO,
			index: todoC.orderAssignee,
		};

		const bodyUpdate = {
			todoId: todoC.id,
			stateTodo: checked === true ? TodosStates.DONE : TodosStates.TODO,
		};

		if (user) {
			dispatch(completeTodos({ body, todoId: todoC.id })).then(() => {
				if (todo?.noteId) {
					dispatch(updateTodoStateNote(bodyUpdate));
				}

				if (checked === true && user && user.graphUserId !== todoC.graphUserId) {
					const bodyNotification = {
						id: undefined,
						userFromName: user.displayName,
						userFromMail: user.email,
						userToName: todoC.graphUserId,
						userToMail: todoC.graphUserId,
						type: NotificationsType.TODO_COMPLETE,
						noteId: undefined,
						todoId: todoC.id,
						graphiCalUId: undefined,
						projectId: undefined,
						meetingStartDate: undefined,
						itemTitle: todoC.title,
						visible: true,
						meetingName: undefined,
					};

					dispatch(SendNotifications(bodyNotification));
				}
			});
		} else if (storedAccessMail) {
			const bodyComplete = {
				id: todoC.id,
				title: todoC.title,
				text: todoC.text,
				state: TodosStates.DONE,
				dueDate: todoC.dueDate,
				duration: todoC.duration,
				graphUserId: todoC.graphUserId,
				noteId: todoC.noteId,
				assigneeDisplayName: todoC.assigneeDisplayName,
				graphiCalUId: todoC.graphiCalUId,
				meetingGraphId: todoC.meetingGraphId,
				meetingName: todoC.meetingName,
				meetingStartDate: todoC.meetingStartDate,
				createdOn: todoC.createdOn,
				tags: [],
				assigneeName: todoC.assigneeName,
				archived: todoC.archived,
				projectId: todoC.projectId,
			};

			dispatch(updateTodosShared({ body: bodyComplete, todoId: todoC.id })).then(() => {
				dispatch(updateTodoSharedStateNote(bodyComplete));

				if (checked === true && storedAccessMail && storedAccessMail !== todoC.graphUserId) {
					const bodyNotification = {
						id: undefined,
						userFromName: storedAccessMail,
						userFromMail: storedAccessMail,
						userToName: todoC.graphUserId,
						userToMail: todoC.graphUserId,
						type: NotificationsType.TODO_COMPLETE,
						noteId: undefined,
						todoId: todoC.id,
						graphiCalUId: undefined,
						meetingStartDate: undefined,
						projectId: undefined,
						itemTitle: todoC.title,
						visible: true,
						meetingName: undefined,
					};

					dispatch(SendNotifications(bodyNotification));
				}
			});
		}
	};

	const handleDeleteTodo = () => {
		if (todo) {
			if (user) {
				deleteTodoInsideEditor(todo?.id);
				dispatch(deleteTodosNotes(todo.id));
				dispatch(deleteTodo(todo?.id)).then(() => {
					dispatch(getCurrentUserTodos()).then(() => {
						if (todo.noteId) {
							dispatch(deleteTodosNotes(todo.id));
						}
					});
				});
			} else {
				deleteTodoInsideEditor(todo?.id);
				dispatch(deleteTodosShareNote(todo.id));
				dispatch(deleteTodoShared(todo.id)).then(() => {
					dispatch(deleteTodosShareNote(todo.id));
				});
			}
		}
	};

	let timeoutId: any;
	let isHandlerTriggered = false;

	const onChangeHandlerTodo = (data: TodosContract, newTitle: string) => {
		clearTimeout(timeoutId);
		isHandlerTriggered = true;
		timeoutId = setTimeout(function () {
			if (user) {
				const body = {
					id: data.id,
					title: newTitle,
					text: data.text,
					state: data.state,
					dueDate: data.dueDate,
					duration: data.duration,
					graphUserId: data.graphUserId,
					noteId: data.noteId,
					assigneeDisplayName: data.assigneeDisplayName,
					graphiCalUId: data.graphiCalUId,
					meetingGraphId: data.meetingGraphId,
					meetingName: data.meetingName,
					meetingStartDate: data.meetingStartDate,
					createdOn: new Date(),
					tags: data.tags,
					projectId: data.projectId,
				};
				const bodyUpdate = {
					todoId: data.id,
					newTitle,
				};

				dispatch(updateTodos({ body, todoId: data.id })).then(() => {
					dispatch(updateTodoTitleNote(bodyUpdate));
				});
			} else if (storedAccessMail) {
				const bodyComplete = {
					id: data.id,
					title: newTitle,
					text: data.text,
					state: data.state,
					dueDate: data.dueDate,
					duration: data.duration,
					graphUserId: data.graphUserId,
					noteId: data.noteId,
					assigneeDisplayName: data.assigneeDisplayName,
					graphiCalUId: data.graphiCalUId,
					meetingGraphId: data.meetingGraphId,
					meetingName: data.meetingName,
					meetingStartDate: data.meetingStartDate,
					createdOn: new Date(),
					tags: undefined,
					projectId: data.projectId,
				};

				const bodyUpdate = {
					todoId: data.id,
					newTitle,
				};

				dispatch(updateTodosShared({ body: bodyComplete, todoId: data.id })).then(() => {
					dispatch(updateTodoTitleNoteShared(bodyUpdate));
				});
			}
		}, 1000);
	};

	const [openUpdate, setOpenUpdate] = React.useState(false);

	const toggleUpdate = () => {
		setOpenUpdate(!openUpdate);
	};

	const todosExtension = new TodosNodeExtension();

	const [localTitle, setLocalTitle] = React.useState(todo?.title);

	const updateTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

	useEffect(() => {
		if (updateTimeout.current) {
			clearTimeout(updateTimeout.current);
		}

		updateTimeout.current = setTimeout(() => {
			if (todo?.title !== localTitle) {
				commands.syncYjs();

				setLocalTitle(todo?.title);
			}
		}, 100);

		return () => {
			if (updateTimeout.current) {
				clearTimeout(updateTimeout.current);
			}
		};
	}, [todo]);

	const handleToggleTodosExtension = async (event: { key: string }) => {
		if (event.key === 'Enter' && todo) {
			if (user) {
				const todoToBeCreated = await todosExtension.createTodoBackend(
					'new todo',
					noteId,
					todo.graphUserId,
					dispatch,
					todo.assigneeName,
					todo.assigneeDisplayName,
					todo.graphiCalUId,
					todo.graphiCalUId,
					todo.meetingName,
					todo.meetingStartDate,
					todo.meetingAttendees,
				);

				createTodo(todoToBeCreated.id, todoToBeCreated.title, todoToBeCreated.noteId);
			} else {
				const todoToBeCreated = await todosExtension.createTodoBackendInvite(
					'new todo',
					noteId,
					todo.graphUserId,
					dispatch,
					todo.assigneeName,
					todo.assigneeDisplayName,
					shareId,
					todo.graphiCalUId,
					todo.graphiCalUId,
					todo.meetingName,
					todo.meetingStartDate,
					todo.meetingAttendees,
				);

				createTodo(todoToBeCreated.id, todoToBeCreated.title, todoToBeCreated.noteId);
			}
		}
	};

	return (
		<>
			{todo && (
				<div
					style={{
						display: 'Flex',
						alignItems: 'center',
						gap: '15px',
						height: '20px',
						margin: '0px',
						maxWidth: '400px',
						padding: '0px',
					}}
					contentEditable={false}>
					<input
						style={{ height: '14px', width: '14px', margin: '0' }}
						type='checkbox'
						contentEditable='false'
						onClick={(e) => e.stopPropagation()}
						onChange={(e) => {
							onChangeComplete(e.target.checked, todo);
							e.stopPropagation();
						}}
						checked={todo.state === TodosStates.DONE ? true : false}
					/>

					<input
						style={{
							border: 'none',
							height: '20px',
							padding: '0',
							margin: '0',
							background: 'none',
						}}
						ref={forwardRef}
						type='text'
						value={todo.title}
						onChange={(e) => {
							// onChangeHandlerTodo(todo, e.target.value);
							// setLocalTitle(e.target.value);
							// e.stopPropagation();
						}}
						disabled
						onKeyDown={handleToggleTodosExtension}
					/>

					<Avatar name={todo.assigneeName} mail={todo.assigneeDisplayName} index={1} />

					<div style={{ height: '20px', cursor: 'pointer' }} onClick={() => toggleUpdate()}>
						<IconEdit />
					</div>
					<div style={{ height: '20px' }} onClick={() => handleDeleteTodo()}>
						<IconClose />
					</div>
				</div>
			)}
			{openUpdate && todo && <TodosFormModal toggle={toggleUpdate} handleToggle={toggleUpdate} todo={todo} />}
		</>
	);
}
