/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './meetings-details-notes.module.scss';
import { useTranslation } from 'react-i18next';
import { NotesContract } from '../../../../../../store/notes/type';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
	IconArchive,
	IconContact,
	IconDelete,
	IconEdit,
	IconGlobe,
	IconLock,
	IconMeetingsPage,
} from '../../../../../../shared/utils/icon';
import { getDateFormatWithYear } from '../../../../../../shared/utils/date.util';
import {
	deleteNotesFromAMeeting,
	deleteOneNote,
	getAllNoteForAMeeting,
	updateNotes,
} from '../../../../../../store/notes/slice';
import DeleteConfirmBox from '../../../../../../shared/utils/delete-box';
import MeetingsDetailsNotesComments from './meetings-details-comment';
import MeetingNotesSimpleEditor from './meeting-simple-editor';
import { AccessRightType } from '../../../../../../store/meetings/types';
import {
	deleteOneNoteShared,
	getAllNoteForAMeetingShared,
	updateNotesShared,
} from '../../../../../../store/sharing/slice';
import MeetingsDetailsNotesEditing from './meetings-details-notes-editing';
import useWebSocketConnections from '../../../../../notes/utils/websocket';
import NotesFormUpdateAccessComponent from '../../../../../notes/components/notes.form-update-access.component';

interface Props {
	storedAccessMail?: string;
	notes?: NotesContract[];
	editingIndex: string;
	handleIsEditing: (index: string) => void;
}

const MeetingsDetailsNotesText: React.FC<Props> = ({ storedAccessMail, notes, editingIndex, handleIsEditing }) => {
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);

	// const [editingIndex, setEditingIndex] = React.useState(-1);

	// const handleIsEditing = (index: number) => {
	// 	setEditingIndex(index);
	// };

	// DEELETE //

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [noteToDelete, setNoteToDelete] = React.useState<NotesContract | undefined>(undefined);
	const dispatch = useAppDispatch();

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleNoteClick = (NoteTD: NotesContract) => {
		setNoteToDelete(NoteTD);
		handleConfirmBox();
	};

	const handleDeleteNote = () => {
		if (noteToDelete)
			if (user) {
				dispatch(deleteOneNote(noteToDelete.id)).then(() => {
					dispatch(deleteNotesFromAMeeting(noteToDelete.id));
					setOpenConfirmBox(!openConfirmBox);
				});
			} else {
				dispatch(deleteOneNoteShared(noteToDelete.id)).then(() => {
					dispatch(deleteNotesFromAMeeting(noteToDelete.id));
					setOpenConfirmBox(!openConfirmBox);
				});
			}
	};

	// --- arch

	const [openConfirmBoxArchived, setOpenConfirmBoxArchived] = React.useState(false);

	const handleArchiveNote = (noteA: NotesContract) => {
		if (noteA) {
			const body = {
				id: noteA.id,
				title: noteA.title,
				text: noteA.text,
				graphUserId: noteA.graphUserId,
				graphUserName: noteA.graphUserName,
				meetingAttendees: noteA.meetingAttendees,
				graphiCalUId: noteA.graphiCalUId,
				meetingTitle: noteA.meetingTitle,
				meetingStartDate: noteA.meetingStartDate,
				tags: noteA.tags,
				updateNote: new Date(),
				createdOn: noteA.createdOn,
				updateOn: noteA.updateOn,
				accessRightType: noteA.accessRightType,
				accessRightCompanies: noteA.accessRightCompanies,
				archived: noteA.archived === false ? true : false,
				projectId: noteA.projectId,
			};

			if (user) {
				dispatch(updateNotes({ noteId: noteA.id, body })).then(() => {
					dispatch(getAllNoteForAMeeting(noteA.graphiCalUId));
					setOpenConfirmBoxArchived(!openConfirmBoxArchived);
				});
			} else {
				dispatch(updateNotesShared({ noteId: noteA.id, body })).then(() => {
					setOpenConfirmBoxArchived(!openConfirmBoxArchived);
					if (selectedMeeting && storedAccessMail)
						dispatch(
							getAllNoteForAMeetingShared({ iCalUId: selectedMeeting.iCalUId, userId: storedAccessMail }),
						);
				});
			}
		}
	};

	// --- ACCESS

	const [openAccessMenu, setOpenAccessMenu] = React.useState(false);
	const [noteToUpdate, setNoteToUpdate] = React.useState<NotesContract | undefined>(undefined);

	const handleAccessMenu = (note: NotesContract) => {
		setOpenAccessMenu(!openAccessMenu);
		setNoteToUpdate(note);
	};

	const handleAccessMenuUpdate = (noteAccess: NotesContract) => {
		setOpenAccessMenu(!openAccessMenu);
		if (noteToUpdate) {
			dispatch(getAllNoteForAMeeting(noteToUpdate.graphiCalUId));
		}
	};

	// --- modal

	const { getConnection } = useWebSocketConnections();

	return (
		<div>
			{notes &&
				notes?.length > 0 &&
				notes.map((note, index) => {
					const connection: any = getConnection(note?.id);
					const { doc, provider } = connection;

					return (
						<>
							{' '}
							<div key={note.id} className={styles.fullNote} id={`noteid${note?.id}`}>
								<div className={styles.button}>
									<p className={styles.date}>
										{getDateFormatWithYear(note?.updateOn.toString())}
										<div className={styles.accessFLex}>
											{note?.accessRightType === AccessRightType.EVERYONE ? (
												<div className={styles.accessButton}>
													<IconGlobe />
													<span>{t('Public note')}</span>
												</div>
											) : note.accessRightType === AccessRightType.ONLYME ? (
												<div className={styles.accessButton}>
													<IconLock />
													<span>{t('Private note')}</span>
												</div>
											) : note.accessRightType === AccessRightType.SHARED ? (
												<div className={styles.accessButton}>
													<IconMeetingsPage />
													<span>{t('Shared note')}</span>
												</div>
											) : (
												<div className={styles.accessButton}>
													<IconContact />
													<span>{t('Internal note')}</span>
												</div>
											)}
											{user?.graphUserId === note?.graphUserId && (
												<div className={styles.icon} onClick={() => handleAccessMenu(note)}>
													<IconEdit />
												</div>
											)}
										</div>
									</p>

									{user && user.graphUserId === note?.graphUserId && (
										<div className={styles.flexButton}>
											<div className={styles.icon} onClick={() => handleArchiveNote(note)}>
												<IconArchive />
											</div>
											<div className={styles.icon} onClick={() => handleNoteClick(note)}>
												<IconDelete />
											</div>
										</div>
									)}

									{storedAccessMail && storedAccessMail === note?.graphUserId && (
										<div className={styles.flexButton}>
											<div className={styles.icon} onClick={() => handleArchiveNote(note)}>
												<IconArchive />
											</div>
											<div className={styles.icon} onClick={() => handleNoteClick(note)}>
												<IconDelete />
											</div>
										</div>
									)}
								</div>

								<div
									className={editingIndex === note.id ? styles.selectNote : styles.note}
									onClick={() => handleIsEditing(note.id)}
									key={index}>
									{editingIndex === note.id ? (
										<div key={index}>
											<MeetingsDetailsNotesEditing
												storedAccessMail={storedAccessMail ? storedAccessMail : ''}
												note={note}
												doc={doc}
												provider={provider}
												handleIsEditing={handleIsEditing}
											/>
										</div>
									) : (
										<MeetingNotesSimpleEditor
											content={note?.text}
											title={note?.title}
											note={note}
										/>
									)}
								</div>
								<MeetingsDetailsNotesComments storedAccessMail={storedAccessMail} note={note} />
								{openAccessMenu && noteToUpdate && noteToUpdate.id === note.id && (
									<NotesFormUpdateAccessComponent
										note={noteToUpdate}
										close={handleAccessMenuUpdate}
									/>
								)}
							</div>
						</>
					);
				})}
			{openConfirmBox && noteToDelete && (
				<DeleteConfirmBox
					handleDelete={handleDeleteNote}
					handleConfirmBox={handleConfirmBox}
					message='Are you sure you want to delete this note?'
				/>
			)}
		</div>
	);
};

export default MeetingsDetailsNotesText;
