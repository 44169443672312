import React from 'react';
import styles from './login-illustration.component.module.scss';
import { useTranslation } from 'react-i18next';
import logo from '../../../../images/logo/Logo.png';

function LoginIllustrationComponent() {
	const { t } = useTranslation();
	return (
		<div className={styles.containerIllustration}>
			<div>
				<p className={styles.topParagraph}>
					{t('Organize meetings, notes, projects.')}
					<strong>{t('All together.')}</strong>
				</p>
			</div>
			<div className={styles.bottom}>
				<img src={logo} alt='logo' />
				<div className={styles.policy}>
					<p>{t('© 2024 5DAYS. All rights reserved.')}</p>
					<a href='https://fr.getfivedays.com/privacy-and-terms' target='_blank' rel='noreferrer'>
						{t('Policy Privacy & terms of service.')}
					</a>
				</div>
			</div>
		</div>
	);
}

export default LoginIllustrationComponent;
