import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './utils.module.scss';

interface Props {
	handleDelete: () => void;
	handleConfirmBox: () => void;
	message: string;
	toggle?: () => void;
	toggleIndex?: (index: number) => void;
	index?: number;
}

const DeleteConfirmBox: React.FC<Props> = ({ handleDelete, handleConfirmBox, message, toggle, toggleIndex, index }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.confirmBox}>
			<div className={styles.modal}>
				<p>{t(message)}</p>
				<div className={styles.buttonContainer}>
					<button onClick={() => handleDelete()}>{t('Delete')}</button>
					<button
						className={styles.cancelButton}
						onClick={() => {
							handleConfirmBox();
							toggle && toggle();
							toggleIndex && index && toggleIndex(index);
						}}>
						{t('Cancel')}
					</button>
				</div>
			</div>
		</div>
	);
};

export default DeleteConfirmBox;
