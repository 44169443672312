import React from 'react';
import { ContactsGraphContract } from '../../../store/user/type';
import { Avatar } from '../../../shared/utils/avatar';
import styles from './meeting-update-create.modal.module.scss';
// import { Tooltip } from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css';
import { IconSimplePlus } from '../../../shared/utils/icon';
import { PersonaSize } from '@fluentui/react';

interface Props {
	attendee: ContactsGraphContract;
	index: number;
	handleSelectAttendee: (attendee: ContactsGraphContract) => void;
	selectedAttendees: ContactsGraphContract[];
}

const MeetingFormAttendeesListComponent: React.FC<Props> = ({
	attendee,
	index,
	handleSelectAttendee,
	selectedAttendees,
}) => {
	return (
		<>
			<div
				style={{ backgroundColor: selectedAttendees.includes(attendee) ? 'rgba(0, 128, 0, 0.03)' : '' }}
				key={index}
				className={styles.list}
				onClick={() => handleSelectAttendee(attendee)}>
				<div className={styles.name}>
					<Avatar
						size={PersonaSize.size32}
						name={attendee.displayName}
						mail={attendee.emailAddresses[0].address}
						index={index}
					/>
					<span>{attendee.displayName}</span>
				</div>
				<input
					onChange={() => handleSelectAttendee(attendee)}
					checked={selectedAttendees.includes(attendee)}
					type='checkbox'
				/>
				<IconSimplePlus />
			</div>
		</>
	);
};

export default MeetingFormAttendeesListComponent;
