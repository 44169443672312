import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { extractDomainWithExt, groupByDomain, sortByGroup } from '../../../../shared/utils/domainext';
import { People } from '../../../../store/notes/type';
import styles from './project-details-people.module.scss';
import { Domain } from '../../../../shared/types/meetings-graph-interface';
import { Avatar } from '../../../../shared/utils/avatar';
import { IconMore } from '../../../../shared/utils/icon';
import 'react-tooltip/dist/react-tooltip.css';
import AttendeesModal from '../../modal/attendees-modal';
import { AccessRightType } from '../../../../store/meetings/types';
import ProjectPeopleCompanyModal from '../../modal/attendees-company-modal';
import { PersonaSize } from '@fluentui/react';
import TooltipAttendees from '../../../../shared/components/tooltip/tooltip-attendees.component';

const ProjectHeaderPeople = () => {
	const { projectsSharePeople, selectProject } = useAppSelector((state) => state.project);
	const { user, usersJobList } = useAppSelector((state) => state.user);
	const { company } = useAppSelector((state) => state.settings);

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [modal, setModal] = React.useState(false);

	const handleModal = () => {
		setModal(!modal);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleModal]);

	const rightPeople =
		selectProject?.accessRightType !== AccessRightType.SHARED
			? projectsSharePeople?.filter((people) => people.id === selectProject?.graphUserId)
			: projectsSharePeople;

	const sortedPeople = sortByGroup(rightPeople ? rightPeople : []);
	const groupedPeople = groupByDomain(sortedPeople);
	const domains = Object.keys(groupedPeople);
	const results = domains.map((domain) => ({
		domain,
		emailAddresses: groupedPeople[domain].map((item: People) => ({
			name: item.username,
			address: item.mail,
		})),
	}));

	const urlUser = `https://www.google.com/s2/favicons?domain=${extractDomainWithExt(
		user ? user.userName : '',
	)}&sz=${256}`;

	const modalRefCompany = React.useRef<HTMLDivElement>(null);

	const [modalCompany, setModalCompany] = React.useState(false);

	const handleModalCompany = () => {
		setModalCompany(!modalCompany);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefCompany.current && !modalRefCompany.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleModalCompany();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleModalCompany]);

	return (
		<>
			<div className={styles.attendees}>
				{selectProject?.accessRightType !== AccessRightType.INTERN && (
					<>
						{results.slice(0, 2).map((result: Domain, index) => {
							const url = `https://www.google.com/s2/favicons?domain=${extractDomainWithExt(
								result.emailAddresses[0].address,
							)}&sz=${256}`;

							return (
								<div key={index}>
									<div
										className={styles.groupAttendees}
										style={{ marginLeft: index > 0 ? '40px' : '0' }}>
										<div className={styles.domain}>
											<img src={url} alt='favico' />
										</div>
										{result.emailAddresses.map(
											(attendee: { name: string; address: string }, indexAt) => {
												const userForFivedays = usersJobList?.find(
													(userJ) =>
														userJ.userName.toLowerCase() === attendee.address.toLowerCase(),
												);
												return (
													<div
														key={indexAt}
														style={{
															position: 'absolute',
															top: '50%',
															transform: 'translateY(-50%)',
															left: indexAt === 0 ? '27px' : `${(indexAt + 1) * 25}px`,
															zIndex: `${0 - (indexAt + 1) * 1}`,
														}}
														className={styles.attendeeWrapper}
														data-tooltip-place='bottom'
														data-tooltip-id={attendee.address}>
														<Avatar
															name={
																userForFivedays ? userForFivedays.name : attendee.name
															}
															mail={attendee.address}
															index={index + indexAt}
															size={PersonaSize.size40}
														/>
														{!modal && !modalCompany && (
															<TooltipAttendees
																address={attendee.address}
																name={
																	userForFivedays
																		? userForFivedays.name
																		: attendee.name
																}
																jobTitle={userForFivedays?.jobTitle}
																url={url}
																indexAt={indexAt}
															/>
														)}
													</div>
												);
											},
										)}
									</div>
								</div>
							);
						})}
						{results.length > 2 && (
							<div className={styles.icon} onClick={() => handleModal()}>
								<IconMore />
							</div>
						)}
					</>
				)}
				{user && selectProject?.accessRightType === AccessRightType.INTERN && (
					<div>
						<div className={styles.groupAttendees}>
							<div className={styles.domain}>
								<img src={urlUser} alt='favico' />
							</div>
							{user.userCompany.slice(0, 3).map((attendee, index) => {
								const companyUser = company?.user?.find(
									(userC) => attendee.userName === userC.username,
								);
								return (
									<div
										style={{
											position: 'absolute',
											top: '50%',
											transform: 'translateY(-50%)',
											left: index === 0 ? '27px' : `${(index + 1) * 25}px`,
											zIndex: `${20 - (index + 1) * 1}`,
										}}
										className={styles.attendeeWrapper}
										key={index}
										data-tooltip-id={attendee.userName}
										data-tooltip-place='bottom'>
										<Avatar
											size={PersonaSize.size40}
											name={attendee.name}
											mail={attendee.userName}
											index={index}
										/>
										{!modal && (
											<TooltipAttendees
												address={attendee.userName}
												name={attendee.name}
												jobTitle={companyUser?.jobTitle}
												url={urlUser}
												indexAt={index}
											/>
										)}
									</div>
								);
							})}
							{user.userCompany.length > 3 && (
								<div className={styles.icon} onClick={() => handleModalCompany()}>
									<IconMore />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
			{modal && (
				<div ref={modalRef}>
					<AttendeesModal />
				</div>
			)}
			{modalCompany && user && user.userCompany && (
				<div ref={modalRefCompany}>
					<ProjectPeopleCompanyModal people={user.userCompany} />
				</div>
			)}
		</>
	);
};

export default ProjectHeaderPeople;
