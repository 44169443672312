import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './app.module.scss';
import { BrowserRouter } from 'react-router-dom';
import { i18n } from 'i18next';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Main } from './main';
import RoutesConfig from './routes/routes-config';
import { TourProvider } from '@reactour/tour';
import { steps } from './shared/utils/step';

export const App: FunctionComponent<OwnProps> = () => {
	return (
		<>
			<BrowserRouter>
				<UnauthenticatedTemplate>
					<RoutesConfig />
				</UnauthenticatedTemplate>
				<AuthenticatedTemplate>
					<div className={styles.app}>
						<TourProvider steps={steps}>
							<Main></Main>
						</TourProvider>
					</div>
				</AuthenticatedTemplate>
			</BrowserRouter>
		</>
	);
};

interface OwnProps {
	i18n: i18n;
}
