import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { randomColor } from './random-color';
import styles from './utils.module.scss';
import { getAvatarForUserByEmail } from '../../store/avatars/slice';
import { AvatarData } from '../../store/avatars/type';
import { checkUserFromFivedaysShared } from '../../store/sharing/slice';

interface AvatarProps {
	name: string;
	mail: string;
	index: number;
	showBorder?: boolean;
	size?: PersonaSize;
}

// ---------------------------------

export const Avatar: React.FC<AvatarProps> = ({ name, mail, index, showBorder = true, size = PersonaSize.size24 }) => {
	const dispatch = useAppDispatch();
	const [avatar, setAvatar] = React.useState<string | undefined>(undefined);
	const [color, setColor] = React.useState('#FF6633');
	const [isLoading, setIsLoading] = React.useState(false);
	const [isFromFivedays, setIsFromFivedays] = React.useState(false);
	const { user } = useAppSelector((state) => state.user);
	const { usersAvatars } = useAppSelector((state) => state.avatars);

	const storedAccessMailIndex = localStorage.getItem(`storedMailIndex/${mail}`);

	if (!storedAccessMailIndex) {
		localStorage.setItem(`storedMailIndex/${mail}`, index.toString());
	}

	React.useEffect(() => {
		if (storedAccessMailIndex) {
			setColor(randomColor(+storedAccessMailIndex));
		} else {
			if (index) setColor(randomColor(index));
		}

		if (mail && name) {
			const existingAvatar = usersAvatars[mail];

			if(existingAvatar) {
				setAvatar(existingAvatar.avatar);
				setIsFromFivedays(existingAvatar.isFiveDays);
				setIsLoading(false);
			}
			else {
				if (user) {
					dispatch(getAvatarForUserByEmail(mail)).then((response) => {
						const avatarData = response.payload as AvatarData;
						if (response.payload !== undefined) {
							setAvatar(avatarData.avatar);
						} else {
							setAvatar(undefined);
						}
	
						setIsFromFivedays(avatarData.isFiveDays ? avatarData.isFiveDays : false);
	
						setIsLoading(false);
					});
				} else {
					setAvatar(undefined);
					setIsLoading(false);
					dispatch(checkUserFromFivedaysShared(mail)).then((res) => {
						const isUser = res.payload as boolean;
						setIsFromFivedays(isUser);
					});
				}
			}
		} else {
			setAvatar(undefined);
			setIsLoading(false);
		}
	}, []);

	return (
		<div style={{ display: 'flex', alignItems: 'center' }} key={index}>
			{isLoading ? (
				<></>
			) : (
				<>
					<div
						key={mail}
						className={isFromFivedays ? styles.fivedays : styles.nonFivedays}
						style={{ border: showBorder ? '' : 'none' }}>
						<Persona
							text={name}
							secondaryText={mail}
							size={size}
							initialsColor={color}
							hidePersonaDetails={true}
							imageUrl={avatar}
						/>
					</div>
				</>
			)}
		</div>
	);
};
