import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import styles from './header-notification.module.scss';
import {
	IconClose,
	IconGroupS,
	IconMeetingsSidebar,
	IconMention,
	IconNotes,
	IconNotifications,
	IconProject,
	IconTodos,
} from '../../../utils/icon';
import { NotificationsContract, NotificationsType } from '../../../../store/notifications/type';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Logo from '../../../../../images/logo/Logo_Fivedays.png';
import { getNotifications, hideNotificationReducer, hideNotifications } from '../../../../store/notifications/slice';
import { RouteSearchParams, RouteUrls } from '../../../../routes/routes-config';
import { setSelectedNote } from '../../../../store/notes/slice';
import { getNotificationsProject } from '../../../../store/project/slice';
import {
	getDateFormat,
	getDateString,
	getDayLessOneMonth,
	getDayPastOneDay,
	getDayPastSevenDays,
	getHourFormat,
	getStartOfDay,
	getStartOfDayDate,
	getStartOfMonth,
	getStartOfTheDayDate,
	getTimeDuration,
	isBetween,
} from '../../../utils/date.util';
import { Avatar } from '../../../utils/avatar';
import { PersonaSize } from '@fluentui/react/lib/Persona';
import { isSameDay, isToday, isYesterday } from 'date-fns';
import moment from 'moment';

interface Props {
	close: () => void;
}

const HeaderNotificationsModal: React.FC<Props> = ({ close }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const date = new Date();
	const dispatch = useAppDispatch();
	const { notifications } = useAppSelector((state) => state.notifications);
	const [searchParams, setSearchParams] = useSearchParams();
	const [animation, setAnimation] = useState<boolean>(false);

	const handleHideNotification = (id: string) => {
		dispatch(hideNotifications(id)).then(() => {
			dispatch(hideNotificationReducer(id));
		});
	};

	useEffect(() => {
		dispatch(getNotifications());
	}, []);

	const handleClose = () => {
		setAnimation(true);

		setTimeout(() => {
			close();
			setAnimation(false);
		}, 300);
	};

	const formatDate = (createdOn: Date): string => {
		const notificationDate = new Date(createdOn);
		const notificationMoment = moment(createdOn);
		const currentDate = moment(date);

		if (isToday(notificationDate)) {
			const diffInMinutes = currentDate.diff(notificationMoment, 'minutes');
			if (diffInMinutes >= 60) {
				const diffInHours = currentDate.diff(notificationMoment, 'hours', true);
				return `Il y a ${diffInHours.toFixed(0)} heures`;
			}

			return `Il y a ${diffInMinutes} minutes`;
		}
		return `${getDateFormat(new Date(createdOn))} ${getHourFormat(new Date(createdOn))}`;
	};

	const displayNotification = (notification: NotificationsContract, index: number) => {
		return (
			<div className={styles.notif} key={index}>
				<div className={styles.logo}>
					<Avatar
						size={PersonaSize.size40}
						showBorder={false}
						name={notification.userFromName}
						mail={notification.userFromMail}
						index={index}
					/>
				</div>
				{notification.type === NotificationsType.NEW_TODO && (
					<div
						onClick={() => {
							navigate(`/todos/?view/${notification.todoId}`);
							handleClose();
						}}>
						<div className={styles.notifIconRegular}>
							<IconTodos />
						</div>
						<p>
							{t('You have a new todo ')}
							{` "${notification.itemTitle}" `}
							{t(' created by ')}
							<span className={styles.notifUserFrom}>{notification.userFromName}</span>
						</p>
						<p className={styles.notifDate}>{formatDate(notification.createdOn)}</p>
					</div>
				)}

				{notification.type === NotificationsType.NEW_MEETING_NOTE && (
					<div
						onClick={() => {
							navigate(`/notes?noteId=${notification.noteId}`);
							handleClose();
						}}>
						<div className={styles.notifIcon}>
							<IconNotes />
						</div>
						<p>
							{t('A new note has been shared by ')}
							<span className={styles.notifUserFrom}>{notification.userFromName}</span>
							{t(' on the meeting ')}
							<span className={styles.notifName}>{notification.meetingName}</span>
						</p>
						<p className={styles.notifDate}>{formatDate(notification.createdOn)}</p>
					</div>
				)}

				{notification.type === NotificationsType.NEW_PROJECT_NOTE && (
					<div
						onClick={() => {
							navigate(`/notes?noteId=${notification.noteId}`);
							handleClose();
						}}>
						<div className={styles.notifIcon}>
							<IconNotes />
						</div>
						<p>
							{t('A new note has been shared by ')}
							<span className={styles.notifUserFrom}>{notification.userFromName}</span>
							{t(' on the project ')}
							<span className={styles.notifName}>{notification.itemTitle}</span>
						</p>
						<p className={styles.notifDate}>{formatDate(notification.createdOn)}</p>
					</div>
				)}

				{notification.type === NotificationsType.TODO_COMPLETE && (
					<div>
						<div className={styles.notifIconRegular}>
							<IconTodos />
						</div>
						<p>
							<span className={styles.notifUserFrom}>{notification.userFromName}</span>
							{t(' just finish the todo ')}
							<span className={styles.notifName}>{` "${notification.itemTitle}" `}</span>
							{t(' you assigned to him/her ')}
						</p>
						<p className={styles.notifDate}>{formatDate(notification.createdOn)}</p>
					</div>
				)}
				{notification.type === NotificationsType.MENTION && (
					<div
						onClick={() => {
							navigate(`/notes?noteId=${notification.noteId}`);
							handleClose();
						}}>
						<div className={styles.notifIconRegular}>
							<IconMention />
						</div>
						<p>
							<span className={styles.notifUserFrom}>{notification.userFromName}</span>
							{t(' vous a identifié dans la note  ')}
							<span className={styles.notifName}>{notification.itemTitle}</span>
						</p>
						<p className={styles.notifDate}>{formatDate(notification.createdOn)}</p>
					</div>
				)}
				{notification.type === NotificationsType.NEW_COMMENT && (
					<div
						onClick={() => {
							navigate(`/notes?noteId=${notification.noteId}`);
							handleClose();
						}}>
						<div className={styles.notifIconRegular}>
							<IconMention />
						</div>
						<p>
							<span className={styles.notifUserFrom}>{notification.userFromName}</span>
							{t(' vous a identifié dans un commentaire sous la note ')}
							<span className={styles.notifName}>{notification.itemTitle}</span>
						</p>
						<p className={styles.notifDate}>{formatDate(notification.createdOn)}</p>
					</div>
				)}
				{notification.type === NotificationsType.NEW_MEETING && (
					<div
						onClick={() => {
							const params: any = {};
							params[RouteSearchParams.Date] = getStartOfDay(notification.meetingStartDate);
							params[RouteSearchParams.MeetingId] = notification.graphiCalUId;
							navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
							handleClose();
						}}>
						<div className={styles.notifIcon}>
							<IconGroupS />
						</div>
						<p>
							<span className={styles.notifUserFrom}>{notification.userFromName}</span>
							{t(' vous a ajouté dans une nouvelle réunion ')}
							<span className={styles.notifName}>{notification.itemTitle}</span>
						</p>
						<p className={styles.notifDate}>{formatDate(notification.createdOn)}</p>
					</div>
				)}
				{notification.type === NotificationsType.NEW_PROJECT && (
					<div
						onClick={() => {
							navigate(`/project?projectId=${notification.projectId}`);
							handleClose();
						}}>
						<div className={styles.notifIcon}>
							<IconProject />
						</div>
						<p>
							<span className={styles.notifUserFrom}>{notification.userFromName}</span>
							{t(' vous a ajouté dans un nouveau projet ')}
							<span className={styles.notifName}>{notification.itemTitle}</span>
						</p>
						<p className={styles.notifDate}>{formatDate(notification.createdOn)}</p>
					</div>
				)}
				<div className={styles.closeBtn} onClick={() => handleHideNotification(notification.id)}>
					<IconClose />
				</div>
			</div>
		);
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.titleWrapper}>
					<h3>
						<IconNotifications />
						{t('Notifications')}
					</h3>
					<div className={styles.closeButton}>
						<div
							className={styles.icon}
							onClick={() => {
								handleClose();
							}}>
							<IconClose />
						</div>
					</div>
				</div>
				<div>
					{notifications &&
						[...notifications]
							.filter((notification) => isToday(new Date(notification.createdOn)))
							.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())
							.map((notification, index) => (
								<>
									{index === 0 && <h1 className={styles.notifTitle}>{t('Today')}</h1>}
									{displayNotification(notification, index)}
								</>
							))}
				</div>
				<div>
					{notifications &&
						[...notifications]
							.filter((notification) => isYesterday(new Date(notification.createdOn)))
							.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())
							.map((notification, index) => (
								<>
									{index === 0 && <h1 className={styles.notifTitle}>{t('Yesterday')}</h1>}
									{displayNotification(notification, index)}
								</>
							))}
				</div>
				<div>
					{notifications &&
						[...notifications]
							.filter(
								(notification) =>
									getStartOfDayDate(new Date(notification.createdOn)) <
										moment().subtract(2, 'days').toDate() &&
									getStartOfDayDate(new Date(notification.createdOn)) > getDayLessOneMonth(date),
							)
							.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())
							.map((notification, index) => (
								<>
									{index === 0 && <h1 className={styles.notifTitle}>{t('Last 30 days')}</h1>}
									{displayNotification(notification, index)}
								</>
							))}
				</div>
				<div>
					{notifications &&
						[...notifications]
							.filter(
								(notification) =>
									getStartOfDayDate(new Date(notification.createdOn)) < getDayLessOneMonth(date),
							)
							.sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime())
							.map((notification, index) => (
								<>
									{index === 0 && <h1 className={styles.notifTitle}>{t('Older')}</h1>}
									{displayNotification(notification, index)}
								</>
							))}
				</div>
			</div>
		</div>
	);
};

export default HeaderNotificationsModal;
