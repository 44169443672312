import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import meetingsReducer from './meetings/slice';
import layoutReducer from './layout/slice';
import avatarsReducer from './avatars/slice';
import userReducer from './user/slice';
import settingsReducer from './settings/slice';
import registerReducer from './register/slice';
import invitationsReducer from './invitations/slice';
import sharingReducer from './sharing/slice';
import notesReducer from './notes/slice';
import todosReducer from './todos/slice';
import agendaReducer from './agenda/slice';
import seriesReducer from './series/slice';
import notificationsReducer from './notifications/slice';
import commentReducer from './comments/slice';
import projectReducer from './project/slice';
import columnReducer from './column/slice';
import billingReducer from './billing/slice';
import recallReducer from './recall/slice';

const store = configureStore({
	reducer: {
		meetings: meetingsReducer,
		layout: layoutReducer,
		avatars: avatarsReducer,
		user: userReducer,
		settings: settingsReducer,
		register: registerReducer,
		invitations: invitationsReducer,
		sharing: sharingReducer,
		notes: notesReducer,
		todos: todosReducer,
		agenda: agendaReducer,
		series: seriesReducer,
		notifications: notificationsReducer,
		comments: commentReducer,
		project: projectReducer,
		column: columnReducer,
		billing: billingReducer,
		recall: recallReducer,
	},
	devTools: true,
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export default store;
