import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/home/home.page';
import MeetingsPage from '../pages/meetings/meetings.page';
import RegisterPage from '../pages/register/register.page';
import AccountPage from '../pages/account/account.page';
import SettingsPage from '../pages/settings/settings.page';
import { useAppSelector } from '../store/hooks';
import { UserRole, UserRoleFivedays } from '../store/user/type';
import NotesPage from '../pages/notes/notes.page';
import TodosPage from '../pages/todos/todos.page';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import LoginPage from '../pages/login/login.page';
import PublicMeetingPageForm from '../pages/publicMeeting/public-meeting-form.page';
import PublicMeetingPage from '../pages/publicMeeting/public-meeting.page';
import CalendarPage from '../pages/calendar/calendar.page';
import ProjectPage from '../pages/project/project.page';
import SettingsFivedaysPage from '../pages/settingsFivedays/settings-fivedays.page';
import { loginRequest } from '../config/msal-auth-config.';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { AuthenticationService } from '../services/authentication.service';

export enum RouteUrls {
	Home = '/',
	Login = '/login',
	SignUp = '/signUp',
	Register = '/register',
	RegisterInvite = '/invite/:inviteID',
	Invite = '/invite',
	Settings = '/settings',
	Meetings = '/meetings',
	Calendars = '/calendar',
	Account = '/account',
	Notes = '/notes',
	NotesEdit = '/notes/edit/:noteID',
	Todos = '/todos',
	Project = '/project',
	SettingFivedays = '/fivedays',

	NotFound = '/404',

	PublicMeetingForm = '/share/:shareID',
	PublicMeeting = '/meeting/share/:shareID',
	Default = '/*',
}

export enum RouteSearchParams {
	Date = 'date',
	MeetingId = 'meetingId',
	Filter = 'filter',
	NoteId = 'noteId',
	ProjectId = 'projectId',
	inviteId = 'inviteId',
	Login = 'login',
	Signin = 'signin',
}

const RoutesConfig: FunctionComponent = () => {
	const { user } = useAppSelector((state) => state.user);

	return (
		<>
			<UnauthenticatedTemplate>
				<Routes>
					<Route path={RouteUrls.Default} element={<LoginPage />} />
					<Route path={RouteUrls.Login} element={<LoginPage />} />
					<Route path={RouteUrls.SignUp} element={<LoginPage />} />
					<Route path={RouteUrls.RegisterInvite} element={<LoginPage />}></Route>
					<Route path={RouteUrls.PublicMeetingForm} element={<PublicMeetingPageForm />} />
					<Route path={RouteUrls.PublicMeeting} element={<PublicMeetingPage />} />
				</Routes>
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>
				<Routes>
					<Route path={RouteUrls.Register} element={<RegisterPage />}></Route>
					<Route path={RouteUrls.Home} element={<HomePage />}></Route>
					<Route path={RouteUrls.Account} element={<AccountPage />}></Route>
					<Route path={RouteUrls.Meetings} element={<MeetingsPage />}></Route>
					<Route path={RouteUrls.Calendars} element={<CalendarPage />}></Route>
					<Route path={RouteUrls.Notes} element={<NotesPage />}></Route>
					<Route path={RouteUrls.Todos} element={<TodosPage />}></Route>
					<Route path={RouteUrls.Project} element={<ProjectPage />}></Route>

					{user?.role === UserRole.ADMIN && (
						<Route path={RouteUrls.Settings} element={<SettingsPage />}></Route>
					)}

					{user?.roleFivedays === UserRoleFivedays.SUPERADMIN && (
						<Route path={RouteUrls.SettingFivedays} element={<SettingsFivedaysPage />}></Route>
					)}
				</Routes>
			</AuthenticatedTemplate>
		</>
	);
};

export default RoutesConfig;
