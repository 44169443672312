import React from 'react';
import styles from './project-details-header.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { IconArchive, IconDelete, IconEdit } from '../../../../shared/utils/icon';
import {
	deleteProject,
	deleteProjectState,
	setSelectedProject,
	updateProjectState,
	updateProjects,
} from '../../../../store/project/slice';
import DeleteConfirmBox from '../../../../shared/utils/delete-box';
import ProjectHeaderPeople from './project-header-people';
import ProjectUpdateModal from '../../modal/project-update.modal';
import { ProjectContract } from '../../../../store/project/type';
import { RouteUrls } from '../../../../routes/routes-config';
import { useNavigate } from 'react-router-dom';

const ProjectDetailsHeader = () => {
	const dispatch = useAppDispatch();
	const { selectProject } = useAppSelector((state) => state.project);
	const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};
	const handleDeleteProject = () => {
		if (selectProject) {
			dispatch(deleteProject(selectProject.id)).then(() => {
				dispatch(deleteProjectState(selectProject.id));
				navigate(RouteUrls.Project);
				dispatch(setSelectedProject(undefined));
				handleConfirmBox();
			});
		}
	};

	const [updateModal, setUpdateModal] = React.useState(false);
	const handleSetUpdateModal = () => {
		setUpdateModal(!updateModal);
	};

	const handleArchivedProject = () => {
		if (selectProject) {
			const body: ProjectContract = {
				id: selectProject.id,
				projectName: selectProject.projectName,
				accessRightType: selectProject.accessRightType,
				graphUserId: selectProject.graphUserId,
				text: selectProject.text,
				tags: selectProject.tags,
				emoticon: selectProject.emoticon,
				archived: selectProject.archived === false ? true : false,
				todoCol: selectProject.todoCol,
				doingCol: selectProject.doingCol,
				blockedCol: selectProject.blockedCol,
				doneCol: selectProject.doneCol,
				createdOn: selectProject.createdOn,
			};
			dispatch(updateProjects(body)).then((res) => {
				const newProject = res.payload as ProjectContract;

				const newBody: ProjectContract = {
					id: newProject.id,
					projectName: newProject.projectName,
					accessRightType: newProject.accessRightType,
					graphUserId: newProject.graphUserId,
					text: newProject.text,
					tags: newProject.tags,
					emoticon: newProject.emoticon,
					archived: newProject.archived,
					todoCol: selectProject.todoCol,
					doingCol: selectProject.doingCol,
					blockedCol: selectProject.blockedCol,
					doneCol: selectProject.doneCol,
					createdOn: newProject.createdOn,
				};

				dispatch(updateProjectState(newBody));
				navigate(RouteUrls.Project);
				dispatch(setSelectedProject(undefined));
			});
		}
	};

	return (
		<div className={styles.top}>
			<div className={styles.header}>
				<div className={styles.title}>
					<span dangerouslySetInnerHTML={{ __html: selectProject ? selectProject.emoticon : '' }}></span>
					<h1>{selectProject?.projectName}</h1>
				</div>
				{selectProject?.graphUserId === user?.graphUserId && (
					<div className={styles.buttons}>
						<div className={styles.icon} onClick={() => handleSetUpdateModal()}>
							<IconEdit />
						</div>
						<div className={styles.icon} onClick={() => handleArchivedProject()}>
							<IconArchive />
						</div>
						<div className={styles.icon} onClick={() => handleConfirmBox()}>
							<IconDelete />
						</div>
					</div>
				)}

				{openConfirmBox && (
					<DeleteConfirmBox
						handleDelete={handleDeleteProject}
						handleConfirmBox={handleConfirmBox}
						message='Are you sure you want to delete this project?'
					/>
				)}
				{updateModal && <ProjectUpdateModal close={handleSetUpdateModal} />}
			</div>
			<ProjectHeaderPeople />
		</div>
	);
};

export default ProjectDetailsHeader;
