/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { NotesContract } from '../../../store/notes/type';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './note-form-access-update.module.scss';
import { Avatar } from '../../../shared/utils/avatar';
import { IconClose, IconSimplePlus } from '../../../shared/utils/icon';
import { Attendees } from '../../../shared/types/meetings-graph-interface';
import {
	addPeopleShare,
	deleteNotesPeople,
	deletePeopleShareNote,
	getPeopleShareNote,
	postPeopleShareNote,
} from '../../../store/notes/slice';
import { getCurrentUserContacts } from '../../../store/user/slice';
import 'react-tooltip/dist/react-tooltip.css';
import { ContactsGraphContract } from '../../../store/user/type';
import { getPeopleShareProject } from '../../../store/project/slice';

interface Props {
	note: NotesContract;
}

const NotesFormUpdateAccessSharedComponent: React.FC<Props> = ({ note }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { user, contacts } = useAppSelector((state) => state.user);
	const { sharedPeopleNote } = useAppSelector((state) => state.notes);
	const { projectsSharePeople, selectProject } = useAppSelector((state) => state.project);

	React.useEffect(() => {
		if (note) {
			dispatch(getPeopleShareNote(note.id));

			if (note.projectId) {
				dispatch(getPeopleShareProject(note.projectId));
			}
		}
	}, [note]);

	const [openMenu, setOpenMenu] = React.useState(false);
	const handleMenu = () => {
		setOpenMenu(!openMenu);
	};

	const [selectedAddresses, setSelectedAddresses] = React.useState<Attendees[]>([]);

	const handleCheckboxChange = (attendee: Attendees) => {
		setSelectedAddresses([...selectedAddresses, attendee]);

		const body = {
			id: '1',
			username: attendee.emailAddress.name,
			mail: attendee.emailAddress.address,
		};

		dispatch(postPeopleShareNote({ body, noteId: note.id })).then(() => {
			dispatch(addPeopleShare(body));
		});
	};

	const handleDeletePeopleShare = (mail: string) => {
		dispatch(deletePeopleShareNote({ noteId: note.id, mail })).then(() => {
			dispatch(deleteNotesPeople(mail));
		});
	};

	let timeoutId: any;

	const [noMatch, setNotMatch] = React.useState(false);
	const [contactNM, setContactNM] = React.useState<Attendees>();
	const [isShearching, setIsShearching] = React.useState('');

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const onChangeHandler = (filter: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setIsShearching(filter);

			dispatch(getCurrentUserContacts(filter)).then((res) => {
				const response = res.payload as ContactsGraphContract[];
				const newContactPR = {
					type: '',
					emailAddress: {
						name: filter,
						address: filter,
					},
				};
				setNotMatch(false);

				if (response?.length < 1) {
					setNotMatch(true);
					setContactNM(newContactPR);
				}
			});
		}, 200);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	return (
		<>
			{sharedPeopleNote && sharedPeopleNote.length > 0 && (
				<div className={styles.invited}>
					{sharedPeopleNote.length !== 0 &&
						sharedPeopleNote
							.filter((p) => p.mail !== user?.email)
							.map((contact, index) => (
								<div key={index} className={styles.avatar}>
									<div className={styles.avatarWrapper}>
										<Avatar name={contact.username} mail={contact.mail} index={index} />
										<p>{contact.username}</p>
									</div>
									<div className={styles.icon} onClick={() => handleDeletePeopleShare(contact.mail)}>
										<IconClose />
									</div>
								</div>
							))}
				</div>
			)}
			<div className={styles.attendees}>
				<div className={styles.addAttendee} onClick={() => handleMenu()}>
					{/* {t('Invite people')} */}
					<input
						placeholder={t('Invite people') as string}
						type='text'
						onChange={(e) => onChangeHandler(e.target.value)}
					/>
					<span className={styles.iconColor}>
						<IconSimplePlus />
					</span>
				</div>

				{isShearching && (
					<div className={styles.modalPeople} ref={modalRef}>
						<div className={styles.peopleList}>
							{/* {user && (
								<div className={styles.searchInput}>
									<span>
										<IconSearch />
									</span>
									<input type='text' onChange={(e) => onChangeHandler(e.target.value)} />
								</div>
							)} */}
							{user?.userCompany
								.filter((userC) => userC.userName !== user.email)
								.filter((userC) => userC.userName.toLowerCase().includes(isShearching.toLowerCase()))
								.filter(
									(userC) =>
										!selectedAddresses.some((u) => u.emailAddress.address === userC.userName),
								)
								.map((userC, index) => (
									<div
										className={styles.row}
										key={userC.userName}
										onClick={() =>
											handleCheckboxChange({
												type: '',
												emailAddress: {
													name: userC.name,
													address: userC.userName,
												},
											})
										}>
										<div className={styles.avatar}>
											<Avatar name={userC.name} mail={userC.userName} index={index} />
											<span>{userC.name}</span>
										</div>
										<div>
											<IconSimplePlus />
										</div>
									</div>
								))}
							{note &&
								note.meetingAttendees
									.filter((att) => att !== user?.email)
									.filter((userC) => userC.toLowerCase().includes(isShearching.toLowerCase()))
									.filter((userC) => !selectedAddresses.some((u) => u.emailAddress.address === userC))
									.filter((userC) => !user?.userCompany.some((u) => u.userName === userC))
									.map((userC, index) => (
										<div
											className={styles.row}
											key={userC}
											onClick={() =>
												handleCheckboxChange({
													type: '',
													emailAddress: {
														name: userC,
														address: userC,
													},
												})
											}>
											<div className={styles.avatar}>
												<Avatar name={userC} mail={userC} index={index} />
												<span>{userC}</span>
											</div>
											<div>
												<IconSimplePlus />
											</div>
										</div>
									))}
							{note &&
								note.projectId &&
								projectsSharePeople &&
								projectsSharePeople
									.filter((att) => att.mail !== user?.email)
									.filter((userC) =>
										userC.username.toLowerCase().includes(isShearching.toLowerCase()),
									)
									.filter(
										(userC) =>
											!selectedAddresses.some((u) => u.emailAddress.address === userC.mail),
									)
									.filter((userC) => !user?.userCompany.some((u) => u.userName === userC.mail))
									.map((userC, index) => (
										<div
											className={styles.row}
											key={userC.id}
											onClick={() =>
												handleCheckboxChange({
													type: '',
													emailAddress: {
														name: userC.username,
														address: userC.mail,
													},
												})
											}>
											<div className={styles.avatar}>
												<Avatar name={userC.username} mail={userC.mail} index={index} />
												<span>{userC.username}</span>
											</div>
											<div>
												<IconSimplePlus />
											</div>
										</div>
									))}
							{contacts &&
								contacts
									.filter(
										(contact) =>
											contact.emailAddresses &&
											contact.emailAddresses.length > 0 &&
											contact.emailAddresses[0].address !== '' &&
											contact.displayName !== '',
									)
									.filter(
										(userC) =>
											!selectedAddresses.some(
												(u) => u.emailAddress.address === userC.emailAddresses[0].address,
											),
									)
									.map((contact, index) => (
										<div
											className={styles.row}
											key={contact.emailAddresses[0].address}
											onClick={() =>
												handleCheckboxChange({
													type: '',
													emailAddress: {
														name: contact.displayName,
														address: contact.emailAddresses[0].address,
													},
												})
											}>
											<div className={styles.avatar}>
												<Avatar
													name={contact.displayName}
													mail={contact.emailAddresses[0].address}
													index={index}
												/>
												<span>{contact.displayName}</span>
											</div>
											<div>
												<IconSimplePlus />
											</div>
										</div>
									))}
							{noMatch && contactNM && isEmail(contactNM.emailAddress.address) === true && (
								<>
									<div
										className={styles.row}
										key={contactNM.emailAddress.address}
										onClick={() => handleCheckboxChange(contactNM)}>
										<div className={styles.avatar}>
											<Avatar
												name={contactNM.emailAddress.name}
												mail={contactNM.emailAddress.address}
												index={2}
											/>
											<span>{contactNM.emailAddress.name}</span>
										</div>
										<div>
											<IconSimplePlus />
										</div>
									</div>
								</>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default NotesFormUpdateAccessSharedComponent;
