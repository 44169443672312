/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import styles from './project-notes.module.scss';
import { useTranslation } from 'react-i18next';
import { NotesContract } from '../../../../../store/notes/type';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { deleteOneNote, updateNotes } from '../../../../../store/notes/slice';
import { deleteNotesProject, getNotesProject, updateNoteProjectArchive } from '../../../../../store/project/slice';
import useWebSocketConnections from '../../../../notes/utils/websocket';
import { getDateFormatWithYear } from '../../../../../shared/utils/date.util';
import { AccessRightType } from '../../../../../store/meetings/types';
import {
	IconArchive,
	IconContact,
	IconDelete,
	IconEdit,
	IconGlobe,
	IconLock,
	IconMeetingsPage,
} from '../../../../../shared/utils/icon';
import DeleteConfirmBox from '../../../../../shared/utils/delete-box';
import NotesFormUpdateAccessComponent from '../../../../notes/components/notes.form-update-access.component';
import MeetingNotesSimpleEditor from '../../../../meetings/components/meetingDetails/meetingDetailsBody/notes/meeting-simple-editor';
import MeetingsDetailsNotesEditing from '../../../../meetings/components/meetingDetails/meetingDetailsBody/notes/meetings-details-notes-editing';
import DetailsNotesComments from './project-notes-comments';

interface Props {
	notes?: NotesContract[];
}

const DetailsNotesText: React.FC<Props> = ({ notes }) => {
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);

	const [editingIndex, setEditingIndex] = React.useState('');

	const handleIsEditing = (index: string) => {
		setEditingIndex(index);
	};

	// DEELETE //

	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [noteToDelete, setNoteToDelete] = React.useState<NotesContract | undefined>(undefined);
	const dispatch = useAppDispatch();

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleNoteClick = (NoteTD: NotesContract) => {
		setNoteToDelete(NoteTD);
		handleConfirmBox();
	};

	const handleDeleteNote = () => {
		if (noteToDelete && user) {
			dispatch(deleteOneNote(noteToDelete.id)).then(() => {
				setOpenConfirmBox(!openConfirmBox);
				dispatch(deleteNotesProject(noteToDelete.id));
			});
		}
	};

	// --- arch

	const handleArchiveNote = (noteA: NotesContract) => {
		if (user && noteA) {
			const body = {
				id: noteA.id,
				title: noteA.title,
				text: noteA.text,
				graphUserId: noteA.graphUserId,
				graphUserName: noteA.graphUserName,
				meetingAttendees: noteA.meetingAttendees,
				graphiCalUId: noteA.graphiCalUId,
				meetingTitle: noteA.meetingTitle,
				meetingStartDate: noteA.meetingStartDate,
				tags: noteA.tags,
				updateNote: new Date(),
				createdOn: noteA.createdOn,
				updateOn: noteA.updateOn,
				accessRightType: noteA.accessRightType,
				accessRightCompanies: noteA.accessRightCompanies,
				archived: noteA.archived === false ? true : false,
				projectId: noteA.projectId,
			};

			if (user) {
				dispatch(updateNotes({ noteId: noteA.id, body })).then(() => {
					dispatch(
						updateNoteProjectArchive({
							id: noteA.id,
							archive: noteA.archived === false ? true : false,
						}),
					);
				});
			}
		}
	};

	// --- ACCESS

	const [openAccessMenu, setOpenAccessMenu] = React.useState(false);
	const [noteToUpdate, setNoteToUpdate] = React.useState<NotesContract | undefined>(undefined);

	const handleAccessMenu = (note: NotesContract) => {
		setOpenAccessMenu(!openAccessMenu);
		setNoteToUpdate(note);
	};

	const handleAccessMenuUpdate = (noteAccess: NotesContract) => {
		setOpenAccessMenu(!openAccessMenu);
		if (noteToUpdate) {
			dispatch(getNotesProject(noteToUpdate.projectId));
		}
	};

	// --- modal

	const { getConnection } = useWebSocketConnections();

	return (
		<div>
			{notes &&
				notes.length > 0 &&
				notes.map((note, index) => {
					const connection: any = getConnection(note.id);
					const { doc, provider } = connection;

					return (
						<>
							{' '}
							<div key={note.id} className={styles.fullNote} id={`noteIdProject${note.id}`}>
								<div className={styles.button}>
									<p className={styles.date}>
										{getDateFormatWithYear(note.updateOn.toString())}
										<div className={styles.accessFLex}>
											{note?.accessRightType === AccessRightType.EVERYONE ? (
												<div className={styles.accessButton}>
													<IconGlobe />
													<span>{t('Public note')}</span>
												</div>
											) : note.accessRightType === AccessRightType.ONLYME ? (
												<div className={styles.accessButton}>
													<IconLock />
													<span>{t('Private note')}</span>
												</div>
											) : note.accessRightType === AccessRightType.SHARED ? (
												<div className={styles.accessButton}>
													<IconMeetingsPage />
													<span>{t('Shared note')}</span>
												</div>
											) : (
												<div className={styles.accessButton}>
													<IconContact />
													<span>{t('Internal note')}</span>
												</div>
											)}
											{user?.graphUserId === note?.graphUserId && (
												<div className={styles.icon} onClick={() => handleAccessMenu(note)}>
													<IconEdit />
												</div>
											)}
										</div>
									</p>

									{user && user.graphUserId === note.graphUserId && (
										<div className={styles.flexButton}>
											<div className={styles.icon} onClick={() => handleArchiveNote(note)}>
												<IconArchive />
											</div>
											<div className={styles.icon} onClick={() => handleNoteClick(note)}>
												<IconDelete />
											</div>
										</div>
									)}
								</div>

								<div
									className={editingIndex === note.id ? styles.selectNote : styles.note}
									onClick={() => handleIsEditing(note.id)}
									key={index}>
									{editingIndex === note.id ? (
										<div key={index}>
											<MeetingsDetailsNotesEditing
												storedAccessMail={''}
												note={note}
												doc={doc}
												provider={provider}
												handleIsEditing={handleIsEditing}
											/>
										</div>
									) : (
										<MeetingNotesSimpleEditor content={note.text} title={note.title} note={note} />
									)}
								</div>
								<DetailsNotesComments note={note} />
								{openAccessMenu && noteToUpdate && noteToUpdate.id === note.id && (
									<NotesFormUpdateAccessComponent
										note={noteToUpdate}
										close={handleAccessMenuUpdate}
									/>
								)}
							</div>
						</>
					);
				})}
			{openConfirmBox && noteToDelete && (
				<DeleteConfirmBox
					handleDelete={handleDeleteNote}
					handleConfirmBox={handleConfirmBox}
					message='Are you sure you want to delete this note?'
				/>
			)}
		</div>
	);
};

export default DetailsNotesText;
