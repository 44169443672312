/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { RequestStatusType } from '../../../../../../store/shared/types';
import Loader from '../../../../../../shared/components/loader/loader.component';
import MeetingsDetailsTodosFormComponents from './meetings-details-todos-form.components';
import styles from './meetings-details-todos.module.scss';
import {
	IconClock,
	IconDateTime,
	IconFilter,
	IconList,
	IconMeetings,
	IconSimplePlus,
	IconTodoCalendarView,
	IconTodoListView,
} from '../../../../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import TodosViewList from '../../../../../todos/components/todos-view-list';
import TodosViewMeetings from '../../../../../todos/components/todos-view-meetings';
import TodosViewSimple from '../../../../../todos/components/todos-view-simple';
import TodosFilterModal from '../../../../../todos/modals/todos.filter';
import { TodosStates } from '../../../../../../store/todos/type';

interface Props {
	storedAccessMail?: string;
}

interface Meeting {
	meetingName: string;
	meetingStartDate: string;
	meetingGraphId: string;
	graphiCalUId: string;
}

const MeetingsDetailsTodos: React.FC<Props> = ({ storedAccessMail }) => {
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);
	const { selectMeetingTodos, selectMeetingTodosRequestStatus, selectMeetingPrevTodos } = useAppSelector(
		(state) => state.todos,
	);
	const { shareTodos, shareTodosRequestStatus, shareTodosPrev } = useAppSelector((state) => state.sharing);
	const { prevMeeting, selectedMeeting } = useAppSelector((state) => state.meetings);

	const storedView = localStorage.getItem('viewTodoMeetings');
	const [view, setView] = React.useState(storedView ? storedView : 'Simple');
	const [meetings, setMeetings] = React.useState<Meeting[]>([
		{
			meetingName: selectedMeeting ? selectedMeeting.subject : '',
			meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
			meetingGraphId: selectedMeeting ? selectedMeeting.graphEventId : '',
			graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
		},
		{
			meetingName: prevMeeting ? prevMeeting.subject : '',
			meetingStartDate: prevMeeting ? prevMeeting.start : '',
			meetingGraphId: prevMeeting ? prevMeeting.graphEventId : '',
			graphiCalUId: prevMeeting ? prevMeeting.iCalUId : 'none',
		},
	]);

	const handleGroupChange = (val: string) => {
		localStorage.setItem('viewTodoMeetings', val);
		setView(val);
	};

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		setMeetings([
			{
				meetingName: selectedMeeting ? selectedMeeting.subject : '',
				meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
				meetingGraphId: selectedMeeting ? selectedMeeting.graphEventId : '',
				graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
			},
			{
				meetingName: prevMeeting ? prevMeeting.subject : '',
				meetingStartDate: prevMeeting ? prevMeeting.start : '',
				meetingGraphId: prevMeeting ? prevMeeting.graphEventId : '',
				graphiCalUId: prevMeeting ? prevMeeting.iCalUId : 'none',
			},
		]);
	}, [selectedMeeting]);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	const [showTodo, setShowTodo] = React.useState<boolean>(true);
	const [showDone, setShowDone] = React.useState<boolean>(true);
	const [showArchived, setArchived] = React.useState<boolean>(false);
	const [showDoing, setShowDoing] = React.useState<boolean>(true);
	const [showBlock, setShowBlock] = React.useState<boolean>(true);

	const handleShowTodo = (show: boolean) => {
		setShowTodo(show);
	};

	const handleShowDone = (show: boolean) => {
		setShowDone(show);
	};

	const handleShowArchived = (show: boolean) => {
		setArchived(show);
	};

	const handleShowDoing = (show: boolean) => {
		setShowDoing(show);
	};

	const handleShowBlock = (show: boolean) => {
		setShowBlock(show);
	};

	const [showFilterModal, setShowFilterModal] = React.useState(false);
	const handleShowFilterModal = () => {
		setShowFilterModal(!showFilterModal);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowFilterModal]);

	const trueCount = [showTodo, showDone, showArchived, showDoing, showBlock].filter(Boolean).length;

	return (
		<>
			{user ? (
				<div>
					{selectMeetingTodosRequestStatus.type === RequestStatusType.InProgress && (
						<div>
							<Loader></Loader>
						</div>
					)}

					{selectMeetingTodosRequestStatus.type === RequestStatusType.Success && (
						<div className={styles.over}>
							<div className={styles.top}>
								<div className={styles.flexButton}>
									<button className={styles.cancel} onClick={() => handleMenu()}>
										<IconSimplePlus />
										{t('Add new todo')}
									</button>
									<div className={styles.filters}>
										<div className={styles.place}>
											<div
												className={showFilterModal ? styles.activeButton : styles.buttonFilter}
												onClick={() => handleShowFilterModal()}>
												<IconFilter />
												{t('Filters')}
												<div>{trueCount !== 0 ? trueCount : ''}</div>
											</div>
											{showFilterModal && (
												<div className={styles.filterModalWrapper} ref={modalRefFilter}>
													<TodosFilterModal
														showTodo={showTodo}
														showDone={showDone}
														showArchived={showArchived}
														showDoing={showDoing}
														showBlock={showBlock}
														handleShowTodo={handleShowTodo}
														handleShowDone={handleShowDone}
														handleShowArchived={handleShowArchived}
														handleShowDoing={handleShowDoing}
														handleShowBlock={handleShowBlock}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
								<div className={styles.flex}>
									<div className={styles.icons}>
										<div
											className={view === 'Simple' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('Simple');
											}}>
											<IconClock />
										</div>
										<div
											className={view === 'List' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('List');
											}}>
											<div className={styles.childIcon}>
												<IconTodoCalendarView />
											</div>
										</div>
										<div
											className={view === 'Meetings' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('Meetings');
											}}>
											<div className={styles.childIcon}>
												<IconTodoListView />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className={styles.overview}>
								{view === 'List' && selectMeetingTodos && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewList
												todosAssignee={
													prevMeeting && selectMeetingPrevTodos
														? [
																...selectMeetingTodos.filter((todo) => {
																	if (todo.archived && !showArchived) {
																		return false;
																	}

																	if (showTodo && todo.state === TodosStates.TODO) {
																		return true;
																	}

																	if (showDoing && todo.state === TodosStates.DOING) {
																		return true;
																	}

																	if (showDone && todo.state === TodosStates.DONE) {
																		return true;
																	}

																	if (
																		showBlock &&
																		todo.state === TodosStates.BLOCKED
																	) {
																		return true;
																	}

																	return false;
																}),
																...selectMeetingPrevTodos.filter((todo) => {
																	if (todo.archived && !showArchived) {
																		return false;
																	}

																	if (showTodo && todo.state === TodosStates.TODO) {
																		return true;
																	}

																	if (showDoing && todo.state === TodosStates.DOING) {
																		return true;
																	}

																	if (showDone && todo.state === TodosStates.DONE) {
																		return true;
																	}

																	if (
																		showBlock &&
																		todo.state === TodosStates.BLOCKED
																	) {
																		return true;
																	}

																	return false;
																}),
														  ]
														: selectMeetingTodos.filter((todo) => {
																if (todo.archived && !showArchived) {
																	return false;
																}

																if (showTodo && todo.state === TodosStates.TODO) {
																	return true;
																}

																if (showDoing && todo.state === TodosStates.DOING) {
																	return true;
																}

																if (showDone && todo.state === TodosStates.DONE) {
																	return true;
																}

																if (showBlock && todo.state === TodosStates.BLOCKED) {
																	return true;
																}

																return false;
														  })
												}
											/>
										</div>
									</>
								)}

								{view === 'Meetings' && selectMeetingTodos && meetings && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewMeetings
												todosAssignee={
													prevMeeting && selectMeetingPrevTodos
														? [
																...selectMeetingTodos.filter((todo) => {
																	if (todo.archived && !showArchived) {
																		return false;
																	}

																	if (showTodo && todo.state === TodosStates.TODO) {
																		return true;
																	}

																	if (showDoing && todo.state === TodosStates.DOING) {
																		return true;
																	}

																	if (showDone && todo.state === TodosStates.DONE) {
																		return true;
																	}

																	if (
																		showBlock &&
																		todo.state === TodosStates.BLOCKED
																	) {
																		return true;
																	}

																	return false;
																}),
																...selectMeetingPrevTodos.filter((todo) => {
																	if (todo.archived && !showArchived) {
																		return false;
																	}

																	if (showTodo && todo.state === TodosStates.TODO) {
																		return true;
																	}

																	if (showDoing && todo.state === TodosStates.DOING) {
																		return true;
																	}

																	if (showDone && todo.state === TodosStates.DONE) {
																		return true;
																	}

																	if (
																		showBlock &&
																		todo.state === TodosStates.BLOCKED
																	) {
																		return true;
																	}

																	return false;
																}),
														  ]
														: selectMeetingTodos.filter((todo) => {
																if (todo.archived && !showArchived) {
																	return false;
																}

																if (showTodo && todo.state === TodosStates.TODO) {
																	return true;
																}

																if (showDoing && todo.state === TodosStates.DOING) {
																	return true;
																}

																if (showDone && todo.state === TodosStates.DONE) {
																	return true;
																}

																if (showBlock && todo.state === TodosStates.BLOCKED) {
																	return true;
																}

																return false;
														  })
												}
												meetings={meetings.filter((meeting) => meeting.graphiCalUId !== 'none')}
											/>
										</div>
									</>
								)}

								{view === 'Simple' && selectMeetingTodos && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewSimple
												todosAssignee={
													prevMeeting && selectMeetingPrevTodos
														? [
																...selectMeetingTodos.filter((todo) => {
																	if (todo.archived && !showArchived) {
																		return false;
																	}

																	if (showTodo && todo.state === TodosStates.TODO) {
																		return true;
																	}

																	if (showDoing && todo.state === TodosStates.DOING) {
																		return true;
																	}

																	if (showDone && todo.state === TodosStates.DONE) {
																		return true;
																	}

																	if (
																		showBlock &&
																		todo.state === TodosStates.BLOCKED
																	) {
																		return true;
																	}

																	return false;
																}),
																...selectMeetingPrevTodos.filter((todo) => {
																	if (todo.archived && !showArchived) {
																		return false;
																	}

																	if (showTodo && todo.state === TodosStates.TODO) {
																		return true;
																	}

																	if (showDoing && todo.state === TodosStates.DOING) {
																		return true;
																	}

																	if (showDone && todo.state === TodosStates.DONE) {
																		return true;
																	}

																	if (
																		showBlock &&
																		todo.state === TodosStates.BLOCKED
																	) {
																		return true;
																	}

																	return false;
																}),
														  ]
														: selectMeetingTodos.filter((todo) => {
																if (todo.archived && !showArchived) {
																	return false;
																}

																if (showTodo && todo.state === TodosStates.TODO) {
																	return true;
																}

																if (showDoing && todo.state === TodosStates.DOING) {
																	return true;
																}

																if (showDone && todo.state === TodosStates.DONE) {
																	return true;
																}

																if (showBlock && todo.state === TodosStates.BLOCKED) {
																	return true;
																}

																return false;
														  })
												}
											/>
										</div>
									</>
								)}
							</div>
							{menu && (
								<div ref={modalRef}>
									<MeetingsDetailsTodosFormComponents close={handleMenu} />
								</div>
							)}
						</div>
					)}

					{selectMeetingTodosRequestStatus.type === RequestStatusType.Failed && (
						<div>{selectMeetingTodosRequestStatus.errorMessage}</div>
					)}
				</div>
			) : (
				<div className={styles.over}>
					{shareTodosRequestStatus.type === RequestStatusType.InProgress && (
						<div>
							<Loader></Loader>
						</div>
					)}

					{shareTodosRequestStatus.type === RequestStatusType.Success && (
						<div className={styles.over}>
							<div className={styles.top}>
								<button className={styles.cancel} onClick={() => handleMenu()}>
									<IconSimplePlus />
									{t('Add new todo')}
								</button>
								<div className={styles.flex}>
									<div className={styles.icons}>
										<div
											className={view === 'Simple' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('Simple');
											}}>
											<IconList />
										</div>
										<div
											className={view === 'List' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('List');
											}}>
											<div className={styles.childIcon}>
												<IconDateTime />
											</div>
										</div>
										<div
											className={view === 'Meetings' ? styles.iconSelect : styles.icon}
											onClick={() => {
												handleGroupChange('Meetings');
											}}>
											<div className={styles.childIcon}>
												<IconMeetings />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className={styles.overview}>
								{view === 'List' && shareTodos && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewList
												todosAssignee={
													prevMeeting && shareTodosPrev
														? [...shareTodos, ...shareTodosPrev]
														: shareTodos
												}
											/>
										</div>
									</>
								)}

								{view === 'Meetings' && shareTodos && meetings && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewMeetings
												todosAssignee={
													prevMeeting && shareTodosPrev
														? [...shareTodos, ...shareTodosPrev]
														: shareTodos
												}
												meetings={meetings}
											/>
										</div>
									</>
								)}

								{view === 'Simple' && shareTodos && (
									<>
										<div className={styles.mainTodoPage}>
											<TodosViewSimple
												todosAssignee={
													prevMeeting && shareTodosPrev
														? [...shareTodos, ...shareTodosPrev]
														: shareTodos
												}
											/>
										</div>
									</>
								)}
							</div>
							{menu && (
								<div ref={modalRef}>
									<MeetingsDetailsTodosFormComponents
										close={handleMenu}
										storedAccessMail={storedAccessMail}
									/>
								</div>
							)}
						</div>
					)}

					{shareTodosRequestStatus.type === RequestStatusType.Failed && (
						<div>{shareTodosRequestStatus.errorMessage}</div>
					)}
				</div>
			)}
		</>
	);
};

export default MeetingsDetailsTodos;
