import React from 'react';
import MeetingsDetailsNotesBridge from './meetings-details-notes-bridge.component';
import styles from './meetings-details-notes.module.scss';

interface Props {
	storedAccessMail?: string;
}

const MeetingsDetailsNotes: React.FC<Props> = ({ storedAccessMail }) => {
	return (
		<div className={styles.over}>
			<MeetingsDetailsNotesBridge storedAccessMail={storedAccessMail} />
		</div>
	);
};

export default MeetingsDetailsNotes;
