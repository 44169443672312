import { Configuration, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { RuntimeConfig } from './runtime-config';

export const msalConfig: Configuration = {
	auth: {
		clientId: RuntimeConfig.getClientID(),
		authority: RuntimeConfig.getClientAuthorityURI(),
		redirectUri: RuntimeConfig.getRedirectURI(),
		postLogoutRedirectUri: RuntimeConfig.getLogoutRedirectURI(),
		navigateToLoginRequestUrl: false,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			logLevel: LogLevel.Error,
			loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
				}
			},
		},
	},
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
	scopes: RuntimeConfig.getClientScopes(),
};
