import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './project-files.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { SendNotificationsProject, addFilesProject, addProjectFile } from '../../../../../store/project/slice';
import { toast } from '../../../../../shared/components/modals/toast/toast-manager';
import { IconSimplePlus } from '../../../../../shared/utils/icon';
import { FileContract } from '../../../../../store/meetings/types';
import { NotificationsProjectType } from '../../../../../store/project/type';

interface props {
	userId: string;
	close: () => void;
}

const ProjectFormFiles: React.FC<React.PropsWithChildren<props>> = ({ userId, close }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	type FormData = {
		url: string;
		file: FileList;
	};

	const dataSchema = yup.object().shape({
		url: yup.string().url(t('Invalid URL') as string),
		file: yup.mixed(),
	});

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const handleInvalidUrl = errors.url && errors.url.message;
	const { selectProject } = useAppSelector((state) => state.project);
	const { user } = useAppSelector((state) => state.user);

	if (selectProject !== undefined) {
		const handleSubmitFiles = async (data: FormData) => {
			if (data.url) {
				const formData = new FormData();

				formData.append('name', data.url);
				formData.append('location', data.url);
				formData.append('type', 'URL');
				formData.append('graphUserId', userId);

				if (user) {
					dispatch(
						addProjectFile({
							body: formData,
							projectId: selectProject.id,
						}),
					).then((response) => {
						dispatch(addFilesProject(response.payload as FileContract));

						const bodyNotification = {
							id: '',
							userFromName: user.name,
							userFromMail: user.email,
							type: NotificationsProjectType.NEW_FILE,
							noteId: '',
							todoId: '',
							todoDueDate: '',
							itemTitle: (response.payload as FileContract).name,
							graphiCalUId: '',
							meetingStartDate: '',
							meetingEndDate: '',
							meetingName: '',
							meetingAttendees: [],
							projectId: selectProject.id,
							userSeenNotif: [],
							todoAssignee: '',
						};

						dispatch(SendNotificationsProject(bodyNotification)).then(() => {
							reset();
							close();
						});

						toast.show({
							id: userId,
							title: t('File upload success') as string,
							duration: 10000,
							type: 'success',
						});
					});
				}
			} else if (data.file) {
				const formData = new FormData();

				formData.append('name', data.file[0].name);
				formData.append('type', 'FILE');
				formData.append('file', data.file[0]);
				formData.append('graphUserId', userId);

				if (user) {
					dispatch(
						addProjectFile({
							body: formData,
							projectId: selectProject.id,
						}),
					).then((response) => {
						dispatch(addFilesProject(response.payload as FileContract));

						toast.show({
							id: userId,
							title: t('File upload success') as string,
							duration: 10000,
							type: 'success',
						});

						const bodyNotification = {
							id: '',
							userFromName: user.name,
							userFromMail: user.email,
							type: NotificationsProjectType.NEW_FILE,
							noteId: '',
							todoId: '',
							todoDueDate: '',
							itemTitle: (response.payload as FileContract).name,
							graphiCalUId: '',
							meetingStartDate: '',
							meetingEndDate: '',
							meetingName: '',
							meetingAttendees: [],
							projectId: selectProject.id,
							userSeenNotif: [],
							todoAssignee: '',
						};

						dispatch(SendNotificationsProject(bodyNotification)).then(() => {
							close();
						});
					});
				}
			}
		};

		return (
			<div className={styles.formFile}>
				<form onSubmit={handleSubmit(handleSubmitFiles)}>
					<div>
						<input {...register('url')} placeholder={t('Add a link') as string} />
					</div>
					{handleInvalidUrl && <p className={styles.error}>{handleInvalidUrl}</p>}
					<div className={styles.orForm}>
						<div className={styles.line} />
						<div>{t('OR')}</div>
						<div className={styles.line} />
					</div>
					<div>
						<input className={styles.inputFile} type={'file'} {...register('file')} />
					</div>
					<button type='submit'>
						<IconSimplePlus />
						{t('Create')}
					</button>
				</form>
			</div>
		);
	} else {
		return <></>;
	}
};

export default ProjectFormFiles;
