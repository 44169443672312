import React from 'react';
import {
	useCommands,
	useActive,
	HeadingLevelButtonGroup,
	ToggleUnderlineButton,
	DropdownButton,
	CommandMenuItem,
	ToggleStrikeButton,
} from '@remirror/react';
import 'remirror/styles/all.css';
import styles from './utils.module.scss';
import { IconEdit } from './icon';
import NotesEditorColorsModal from './color-editor';

export const SimpleEditorMenu = () => {
	const FONT_SIZES = ['8', '10', '12', '14', '16', '18', '24', '30'];

	const { toggleBold, focus, toggleItalic, setFontSize, ...commandsT } = useCommands();

	const active = useActive();
	const { fontSize } = useActive();

	const [colorCode, handleSetColorText] = React.useState('#000000');
	const [openColorMenu, setOpenColorMenu] = React.useState(false);

	const handleColorMenu = () => {
		setOpenColorMenu(!openColorMenu);
	};

	const handleSetColor = (color: string) => {
		handleSetColorText(color);
	};

	const handleSetTextColor = (color: string) => {
		commandsT.removeTextHighlight();
		commandsT.setTextColor(color);
	};

	// ------

	const modalRefColor = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefColor.current && !modalRefColor.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleColorMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleColorMenu]);

	// -----

	const [colorHLCode, setColorHLCode] = React.useState('#000000');
	const [openColorHLMenu, setOpenColorHLMenu] = React.useState(false);

	const handleColorHLMenu = () => {
		setOpenColorHLMenu(!openColorHLMenu);
	};

	const handleSetColorHL = (color: string) => {
		setColorHLCode(color);
	};

	const handleSetTextColorHL = (color: string) => {
		commandsT.removeTextColor();
		commandsT.setTextHighlight(color);
	};

	// ------

	const modalRefColorHL = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefColorHL.current && !modalRefColorHL.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleColorHLMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleColorHLMenu]);

	// -----

	return (
		<div className={styles.menu}>
			<div className={styles.group}>
				<button
					type='button'
					onClick={() => {
						toggleBold();
						focus();
					}}
					style={{
						fontWeight: active.bold() ? 'bold' : undefined,
						backgroundColor: active.bold() ? '#f2fcf7' : 'transparent',
					}}>
					B
				</button>
				<button
				type='button'
					onClick={() => {
						toggleItalic();
						focus();
					}}
					style={{
						fontWeight: active.italic() ? 'bold' : undefined,
						backgroundColor: active.italic() ? '#f2fcf7' : 'transparent',
					}}>
					I
				</button>
				<ToggleUnderlineButton />
				<ToggleStrikeButton />
			</div>

			<div className={styles.group}>
				<DropdownButton aria-label='Set font size' icon='fontSize'>
					{FONT_SIZES.map((size) => (
						<CommandMenuItem
							key={size}
							commandName='setFontSize'
							onSelect={() => setFontSize(size)}
							enabled={setFontSize.enabled(size)}
							active={fontSize({ size })}
							label={size}
							icon={undefined}
							displayDescription={false}
						/>
					))}
				</DropdownButton>

				<HeadingLevelButtonGroup showAll />
			</div>

			<div className={styles.group}>
				<button type='button'className={styles.color} onClick={() => handleColorMenu()}>
					<div>A</div>
					<div style={{ backgroundColor: colorCode }} className={styles.col}></div>
					{openColorMenu && (
						<div ref={modalRefColor} className={styles.colors}>
							<NotesEditorColorsModal
								setColor={handleSetTextColor}
								handleSetColor={handleSetColor}
								handleColorMenu={handleColorMenu}
							/>
						</div>
					)}
				</button>

				<button type='button' className={styles.color} onClick={() => handleColorHLMenu()}>
					<span>
						<IconEdit />
					</span>
					<div style={{ backgroundColor: colorHLCode }} className={styles.col}></div>
					{openColorHLMenu && (
						<div ref={modalRefColorHL} className={styles.colors}>
							<NotesEditorColorsModal
								setColor={handleSetTextColorHL}
								handleSetColor={handleSetColorHL}
								handleColorMenu={handleColorHLMenu}
								isHl={true}
							/>
						</div>
					)}
				</button>
			</div>
		</div>
	);
};
