/* eslint-disable unicorn/prefer-string-slice */
/* eslint-disable unicorn/no-null */
import React, { useState } from 'react';
import styles from './copilot-summary.module.scss';
import { IconLeft } from '../../../../../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { getDateFormatL, getDateFormatWithYear, getTimeFormat } from '../../../../../../../shared/utils/date.util';
import { AccessRightType } from '../../../../../../../store/meetings/types';
import { extractDomainWithExt } from '../../../../../../../shared/utils/domainext';
import { createNotes, getAllNoteForAMeeting } from '../../../../../../../store/notes/slice';
import { RequestStatusType } from '../../../../../../../store/shared/types';
import Loader from '../../../../../../../shared/components/loader/loader.component';

export interface Props {
	handleClose: () => void;
}

function parseText(text: string) {
	const paragraphs = text.split(/\n\s*\n/);

	return paragraphs.map((paragraph) => {
		// Replace bold text with appropriate JSON format
		const content = [];
		let lastIndex = 0;
		const boldPattern = /\*\*(.*?)\*\*/g;
		let match;

		while ((match = boldPattern.exec(paragraph)) !== null) {
			if (match.index > lastIndex) {
				content.push({ type: 'text', text: paragraph.substring(lastIndex, match.index) });
			}
			content.push({ type: 'text', text: match[1], marks: [{ type: 'bold' }] });
			lastIndex = boldPattern.lastIndex;
		}

		if (lastIndex < paragraph.length) {
			content.push({ type: 'text', text: paragraph.substring(lastIndex) });
		}

		return { type: 'paragraph', content };
	});
}

function buildRemirrorDoc(parsedContent: any) {
	return {
		type: 'doc',
		content: parsedContent,
	};
}

function convertToRemirrorContent(text: string) {
	// Parse the text
	const parsedContent = parseText(text);

	// Build the Remirror document
	return buildRemirrorDoc(parsedContent);
}

const CopilotSummarytModal = ({ handleClose }: Props) => {
	const { t } = useTranslation();
	const [animation, setAnimation] = useState<boolean>(false);
	const { mistralSummary, mistralSummaryRequestStatus } = useAppSelector((state) => state.recall);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const domain = extractDomainWithExt(user ? user.email : '');
	const [text, setText] = React.useState(mistralSummary);

	React.useEffect(() => {
		if (mistralSummary) {
			setText(mistralSummary);
		}
	}, [mistralSummary]);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	const createNote = () => {
		if (user && text) {
			const attendeeEmails = selectedMeeting
				? selectedMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
				: [];

			const textType = convertToRemirrorContent(text);
			const body = {
				id: '',
				title: `${t('Bot Note')} ${selectedMeeting?.subject} (${getDateFormatL(new Date())})`,
				text: JSON.stringify(textType),
				graphUserId: user ? user.graphUserId : '',
				graphUserName: user ? user.displayName : '',
				meetingAttendees: attendeeEmails.length > 0 ? attendeeEmails : [user.email],
				graphiCalUId: selectedMeeting ? selectedMeeting.iCalUId : '',
				meetingTitle: selectedMeeting ? selectedMeeting.subject : '',
				meetingStartDate: selectedMeeting ? selectedMeeting.start : '',
				tags: [],
				updateNote: new Date(),
				createdOn: new Date(),
				updateOn: new Date(),
				accessRightType: AccessRightType.EVERYONE,
				accessRightCompanies: domain ? [domain] : [],
				archived: false,
				projectId: '',
			};

			dispatch(createNotes(body)).then(() => {
				selectedMeeting &&
					dispatch(getAllNoteForAMeeting(selectedMeeting?.iCalUId)).then(() => {
						closeModal();
					});
			});
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.close} onClick={() => closeModal()}>
					<IconLeft />
					{t('Back')}
				</div>
				{selectedMeeting && (
					<div className={styles.info}>
						<span>{t(getDateFormatWithYear(selectedMeeting.start))}</span>
						<br />
						<span>
							<span>{getTimeFormat(selectedMeeting.start, selectedMeeting.end)}</span>
						</span>
						<h3>{selectedMeeting.subject}</h3>
					</div>
				)}
				<div className={styles.note}>
					{mistralSummaryRequestStatus.type === RequestStatusType.InProgress && (
						<div>
							<Loader />
						</div>
					)}
					{mistralSummaryRequestStatus.type === RequestStatusType.Failed && (
						<div>{mistralSummaryRequestStatus.errorMessage}</div>
					)}
					{mistralSummaryRequestStatus.type === RequestStatusType.Success && (
						<div>
							<textarea value={text} onChange={(e) => setText(e.target.value)}></textarea>
							<button onClick={() => createNote()}>{t('Save')}</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CopilotSummarytModal;
