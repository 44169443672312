/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './meeting-details-form.module.scss';
import { getDateFormatL } from '../../../../../../../shared/utils/date.util';
import { MeetingContract } from '../../../../../../../store/meetings/types';
import {
	addLastMeetings,
	getLastMeetings,
	getMeetingsForSelectOption,
	updateSelectedMeeting,
} from '../../../../../../../store/meetings/slice';
import { IconClose, IconDownArrow, IconPrev } from '../../../../../../../shared/utils/icon';
import { MeetingForSeriesContract } from '../../../../../../../store/series/type';
import { meetingsForSeries, postMeetingsForSeries } from '../../../../../../../store/series/slice';
import NotesFormMeetingsModalComponent from '../../../../../../notes/components/notes.form-meetings.modal.component';
import { toast } from '../../../../../../../shared/components/modals/toast/toast-manager';

export interface MeetingDetailsHeaderProps {
	handleClose: () => void;
}

const MeetingDetailsSeriesConnect = ({ handleClose }: MeetingDetailsHeaderProps) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { selectedMeeting } = useAppSelector((state) => state.meetings);
	const { user } = useAppSelector((state) => state.user);
	const [animation, setAnimation] = useState<boolean>(false);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	type FormData = {
		meeting: MeetingContract;
	};

	const dataSchema = yup.object().shape({
		meeting: yup.object(),
	});

	const { handleSubmit, setValue } = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	// ----

	React.useEffect(() => {
		dispatch(getLastMeetings()).then((res) => {
			const m = res.payload as MeetingContract[];

			dispatch(addLastMeetings(m));
		});
	}, [dispatch]);

	// ___ connected meeting

	let timeoutId: any;

	const [isSearching, setIsSearching] = React.useState(false);

	const [openMeetingMenu, setOpenMeetingMenu] = React.useState(false);

	const handleMeetingMenu = () => {
		setIsSearching(false);
		setOpenMeetingMenu(!openMeetingMenu);
	};

	const onChangeHandlerMeeting = (filter: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			dispatch(getMeetingsForSelectOption(filter)).then(() => {
				setIsSearching(true);
			});
		}, 200);
	};

	const [meetingConnect, setMeetingConnect] = React.useState<MeetingContract>();

	const [series, setSeries] = React.useState<MeetingForSeriesContract[]>();

	React.useEffect(() => {
		if (selectedMeeting) {
			dispatch(meetingsForSeries(selectedMeeting.iCalUId)).then((res) => {
				const data = res.payload as MeetingForSeriesContract[];
				const sortedData =
					data !== undefined
						? [...data].sort(
								(a, b) =>
									new Date(a.meetingStartDate).getTime() - new Date(b.meetingStartDate).getTime(),
						  )
						: [];
				setSeries(sortedData);
			});
		}
	}, [selectedMeeting]);

	const handleChangeMeeting = (selectedValue: MeetingContract) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setValue('meeting', selectedValue);
			setMeetingConnect(selectedValue);
			handleMeetingMenu();
		}, 1000);
	};

	// -----

	const onSubmit = (data: FormData) => {
		if (data.meeting && selectedMeeting) {
			const bodySerie = {
				prevGraphiCalUId: data.meeting.iCalUId,
				prevMeetingStartDate: data.meeting.start,
				prevMeetingName: data.meeting.subject,
				nextGraphiCalUId: selectedMeeting.iCalUId,
				nextMeetingStartDate: selectedMeeting.start,
				nextMeetingName: selectedMeeting.subject,
			};
			dispatch(postMeetingsForSeries(bodySerie)).then((res) => {
				const val = {
					subject: selectedMeeting.subject,
					start: selectedMeeting.start,
					end: selectedMeeting.end,
					location: selectedMeeting.location,
					attendees: selectedMeeting.attendees,
					group: selectedMeeting.group,
					isOnlineMeeting: selectedMeeting.isOnlineMeeting,
					onlineMeetingProvider: 'teamsForBusiness',
					bodyContent: selectedMeeting.bodyContent,
					isAllDay: selectedMeeting.isAllDay,
					projectId: selectedMeeting.projectId ? selectedMeeting.projectId : '',
					IcalUid: selectedMeeting.iCalUId,
				};

				if (res.meta.requestStatus === 'fulfilled') {
					dispatch(updateSelectedMeeting(val));
					toast.show({
						id: user?.graphUserId,
						title: t('Meeting connect success') as string,
						duration: 10000,
						type: 'success',
					});
				}

				closeModal();
			});
		}
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.titleWrapper}>
					<h3>
						<IconPrev />
						{t('Previous meeting')}
					</h3>
					<div className={styles.closeButton}>
						<div
							className={styles.icon}
							onClick={() => {
								closeModal();
							}}>
							<IconClose />
						</div>
					</div>
				</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div>
						<div className={styles.linkMeeting} onClick={() => handleMeetingMenu()}>
							{!meetingConnect && !series ? (
								<>{t('Connected meeting')}</>
							) : !meetingConnect && series ? (
								<>
									{t('Connected meeting')}
									{selectedMeeting &&
										series &&
										series
											.filter(
												(meetingS) =>
													new Date(meetingS.meetingStartDate) <
														new Date(selectedMeeting?.start) &&
													meetingS.iCalUId !== selectedMeeting.iCalUId,
											)
											.slice(-1)
											.map((meetingS, index) => (
												<div key={index}>
													{meetingS.meetingName} ({getDateFormatL(meetingS.meetingStartDate)})
												</div>
											))}
								</>
							) : meetingConnect ? (
								<>
									{' '}
									<span>{t('Connected meeting')}</span>
									{meetingConnect.subject} ({getDateFormatL(meetingConnect.start)})
								</>
							) : (
								<></>
							)}

							<span className={styles.iconColor}>
								<IconDownArrow />
							</span>
						</div>
						{openMeetingMenu && (
							<NotesFormMeetingsModalComponent
								isSearching={isSearching}
								onChangeHandler={onChangeHandlerMeeting}
								handleChangeMeeting={handleChangeMeeting}
								close={handleMeetingMenu}
							/>
						)}
					</div>
					<div className={styles.btnContainer}>
						<button className={styles.addBtn}>{t('Save')}</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default MeetingDetailsSeriesConnect;
