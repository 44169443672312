import React from 'react';
import styles from './notes.module.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useTranslation } from 'react-i18next';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import { NotesContract } from '../../store/notes/type';
import { AccessRightType } from '../../store/meetings/types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getDateFormatL } from '../../shared/utils/date.util';
import { addNotes, createNotes, getAllNote, setSelectedNote } from '../../store/notes/slice';
import { RouteSearchParams, RouteUrls } from '../../routes/routes-config';
import {
	IconContact,
	IconFilter,
	IconGlobe,
	IconLock,
	IconMeetingsPage,
	IconSimplePlus,
} from '../../shared/utils/icon';
import NotesFilterModal from './modal/note-filter.modal';
import NotesBridgeForm from './components/notes-bridge-form';

const NotesPage = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);
	const { notes } = useAppSelector((state) => state.notes);
	const [searchParams, setSearchParams] = useSearchParams();
	const [noteId, setNoteId] = React.useState('');
	const [key, setKey] = React.useState('');
	const [unmount, setUnmount] = React.useState<boolean>(false);

	React.useEffect(() => {
		const Id = searchParams.get(RouteSearchParams.NoteId);
		setNoteId(Id ? Id : notes && notes.length > 0 ? notes[0].id : '');
		setKey(Id ? Id : notes && notes.length > 0 ? notes[0].id : '');
	}, [searchParams]);

	React.useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));

		dispatch(getAllNote()).then(() => {
			if (noteId !== '') {
				searchParams.set(RouteSearchParams.NoteId, notes && notes.length > 0 ? notes[0].id : '');
				setSearchParams(searchParams, {});
			}
		});
	}, [dispatch]);

	const createNote = () => {
		if (user) {
			const body = {
				id: '',
				title: `${t('New draft note')} ${getDateFormatL(new Date())}`,
				text: '',
				graphUserId: user ? user.graphUserId : '',
				graphUserName: user ? user.displayName : '',
				meetingAttendees: [user?.email.toLowerCase()],
				graphiCalUId: '',
				meetingTitle: '',
				meetingStartDate: '',
				tags: [],
				updateNote: new Date(),
				createdOn: new Date(),
				updateOn: new Date(),
				accessRightType: AccessRightType.ONLYME,
				accessRightCompanies: [],
				archived: false,
				projectId: '',
			};

			dispatch(createNotes(body)).then((result) => {
				const ID = (result.payload as NotesContract).id;
				dispatch(addNotes(result.payload as NotesContract));
				navigate(RouteUrls.Notes);
				searchParams.set(RouteSearchParams.NoteId, ID);
				setSearchParams(searchParams, {});
				dispatch(setSelectedNote(result.payload as NotesContract));
			});
		}
	};

	// filters //

	const [showFilters, setShowFilters] = React.useState(false);

	const handleShowFilterModal = () => {
		setShowFilters(!showFilters);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	// React.useEffect(() => {
	// 	const handleClickOutside = (event: MouseEvent) => {
	// 		if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
	// 			setTimeout(() => {
	// 				handleShowFilterModal();
	// 			}, 150);
	// 		}
	// 	};

	// 	document.addEventListener('mousedown', handleClickOutside);

	// 	return () => {
	// 		document.removeEventListener('mousedown', handleClickOutside);
	// 	};
	// }, [handleShowFilterModal]);

	// -----

	const [showPersonalNote, setShowPersonalNote] = React.useState<boolean>(true);

	const handleShowPersoNote = (show: boolean) => {
		setShowPersonalNote(show);
	};

	const [showSharedNote, setShowSharedNote] = React.useState<boolean>(true);

	const handleShowSharedNote = (show: boolean) => {
		setShowSharedNote(show);
	};

	const [showArchived, setShowArchived] = React.useState<boolean>(false);

	const handleShowArchived = (show: boolean) => {
		setShowArchived(show);
	};

	// ___

	const [openList, setOpenList] = React.useState(false);

	const handleOpenList = () => {
		setOpenList(!openList);
	};

	const [isMobile, setIsMobile] = React.useState(false);
	const mediaQuery = window.matchMedia('(max-width: 500px)');

	function updateIsMobile(event: MediaQueryListEvent): void {
		if (isMobile !== event.matches) {
			setIsMobile(event.matches);
		}
	}

	mediaQuery.addEventListener('change', updateIsMobile);

	const trueCount = [showPersonalNote, showSharedNote, showArchived].filter(Boolean).length;

	return (
		<div className={styles.mainContainerOv}>
			{!isMobile && (
				<div className={styles.filterContainer}>
					<div className={styles.filter}>
						<div className={styles.titlePlace}>
							<h1 className={styles.title}>{t('Notes')}</h1>
							<div className={styles.button} onClick={() => createNote()}>
								<IconSimplePlus />
							</div>
						</div>

						<div className={styles.filters}>
							<div
								className={showFilters ? styles.activeButton : styles.buttonFilter}
								onClick={() => handleShowFilterModal()}>
								<IconFilter />
								{t('Filters')}
								<div>{trueCount !== 0 ? trueCount : ''}</div>
							</div>
							<div
								style={{ maxHeight: showFilters ? '350px' : '0px' }}
								className={styles.modalFilterWrapper}
								ref={modalRefFilter}>
								<NotesFilterModal
									showPersonalNote={showPersonalNote}
									handleShowPersoNote={handleShowPersoNote}
									showSharedNote={showSharedNote}
									handleShowSharedNote={handleShowSharedNote}
									showArchived={showArchived}
									handleShowArchived={handleShowArchived}
								/>
							</div>
						</div>
						<div className={styles.list}>
							{notes &&
								user &&
								notes
									.filter((note) => {
										if (!showArchived && note.archived) {
											return false;
										}

										if (showPersonalNote && note.graphUserId === user.graphUserId) {
											return true;
										}
										if (showSharedNote && note.graphUserId !== user.graphUserId) {
											return true;
										}
										return false;
									})
									.map((note) => {
										return (
											<div
												onClick={() => {
													navigate(RouteUrls.Notes);
													searchParams.set(RouteSearchParams.NoteId, note.id);
													setSearchParams(searchParams, {});
													dispatch(setSelectedNote(note));
													setUnmount(false);
												}}
												className={noteId === note.id ? styles.selectNote : styles.note}
												style={{ opacity: note.archived ? 0.5 : 1 }}
												key={note.id}>
												<div className={styles.flexTitle}>
													{note?.accessRightType === AccessRightType.EVERYONE ? (
														<div className={styles.accessButton}>
															<IconGlobe />
														</div>
													) : note.accessRightType === AccessRightType.ONLYME ? (
														<div className={styles.accessButton}>
															<IconLock />
														</div>
													) : note.accessRightType === AccessRightType.INTERN ? (
														<div className={styles.accessButton}>
															<IconContact />
														</div>
													) : (
														<div className={styles.accessButton}>
															<IconMeetingsPage />
														</div>
													)}
													<p>{note.title}</p>
												</div>
											</div>
										);
									})}
						</div>
					</div>
				</div>
			)}

			{!isMobile && (
				<div className={styles.overviewList} key={key} id='overviewNote'>
					<NotesBridgeForm toggle={handleOpenList} noteId={noteId} unmount={unmount} />
				</div>
			)}

			{openList && isMobile && (
				<div className={styles.filterContainerMobile}>
					<div className={styles.filter}>
						<p className={styles.title}>{t('Notes')}</p>
						<div className={styles.filters}>
							<div
								className={showFilters ? styles.activeButton : styles.buttonFilter}
								onClick={() => handleShowFilterModal()}>
								<IconFilter />
								{t('Filters')}
								<div>{trueCount !== 0 ? trueCount : ''}</div>
							</div>
							{showFilters && (
								<div ref={modalRefFilter}>
									<NotesFilterModal
										showPersonalNote={showPersonalNote}
										handleShowPersoNote={handleShowPersoNote}
										showSharedNote={showSharedNote}
										handleShowSharedNote={handleShowSharedNote}
										showArchived={showArchived}
										handleShowArchived={handleShowArchived}
									/>
								</div>
							)}
						</div>
						<div className={styles.list}>
							{notes &&
								user &&
								notes
									.filter((note) => {
										if (showPersonalNote && note.graphUserId === user.graphUserId) {
											return true;
										}
										if (showSharedNote && note.graphUserId !== user.graphUserId) {
											return true;
										}
										return false;
									})
									.map((note) => {
										return (
											<div
												onClick={() => {
													navigate(RouteUrls.Notes);
													searchParams.set(RouteSearchParams.NoteId, note.id);
													setSearchParams(searchParams, {});
													dispatch(setSelectedNote(note));
													handleOpenList();
												}}
												className={noteId === note.id ? styles.selectNote : styles.note}
												style={{ opacity: note.archived ? 0.5 : 1 }}
												key={note.id}>
												<p>{note.title}</p>
											</div>
										);
									})}
						</div>
					</div>
					<button className={styles.button} onClick={() => createNote()}>
						<IconSimplePlus />
						{t('Create a new notes')}
					</button>
				</div>
			)}

			{!openList && isMobile && (
				<div className={styles.overviewListMobile}>
					{notes && notes.length > 0 && (
						<NotesBridgeForm toggle={handleOpenList} noteId={noteId} unmount={unmount} />
					)}
				</div>
			)}
		</div>
	);
};

export default NotesPage;
