/* eslint-disable unicorn/no-null */
import React from 'react';
import styles from './todos.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import { getCurrentUserTodos, sortTodosAssignee } from '../../store/todos/slice';
import { RequestStatusType } from '../../store/shared/types';
import Loader from '../../shared/components/loader/loader.component';
import {
	IconBoard,
	IconClock,
	IconDateTime,
	IconFilter,
	IconList,
	IconMeetings,
	IconSimplePlus,
	IconTodoCalendarView,
	IconTodoKaban,
	IconTodoListView,
} from '../../shared/utils/icon';
import TodosViewList from './components/todos-view-list';
import TodosViewMeetings from './components/todos-view-meetings';
import { TodosStates } from '../../store/todos/type';
import { useNavigate } from 'react-router-dom';
import TodosFilterModal from './modals/todos.filter';
import TodosViewSimple from './components/todos-view-simple';
import TodosViewBoard from './components/todo-view-board';
import { getCustomColumnForAUser } from '../../store/column/slice';

interface Meeting {
	meetingName: string;
	meetingStartDate: string;
	meetingGraphId: string;
	graphiCalUId: string;
}

const TodosPage = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { todos, todosRequestStatus } = useAppSelector((state) => state.todos);
	const { user } = useAppSelector((state) => state.user);
	const [meetings, setMeetings] = React.useState<Meeting[]>([]);
	const navigate = useNavigate();
	const storedView = localStorage.getItem('viewTodo');
	const [view, setView] = React.useState(storedView ? storedView : 'Simple');

	const [showTodo, setShowTodo] = React.useState<boolean>(true);
	const [showDone, setShowDone] = React.useState<boolean>(view === 'Board' ? true : false);
	const [showArchived, setArchived] = React.useState<boolean>(false);
	const [showDoing, setShowDoing] = React.useState<boolean>(true);
	const [showBlock, setShowBlock] = React.useState<boolean>(true);

	const handleGroupChange = (val: string) => {
		localStorage.setItem('viewTodo', val);
		if (val === 'Board') {
			setShowDone(true);
		} else if (val !== 'Board') {
			setShowDone(false);
		}
		setView(val);
	};

	React.useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
		dispatch(getCurrentUserTodos());
		if (user) dispatch(getCustomColumnForAUser(user.graphUserId));
	}, []);

	React.useEffect(() => {
		if (todos) {
			dispatch(sortTodosAssignee());
			const uniqueMeetings = new Map<string, Meeting>();
			todos.forEach((todo) => {
				const meetingKey = `${todo.meetingName}-${todo.meetingStartDate}`;
				uniqueMeetings.set(meetingKey, {
					meetingName: todo.meetingName,
					meetingStartDate: todo.meetingStartDate,
					meetingGraphId: todo.meetingGraphId,
					graphiCalUId: todo.graphiCalUId,
				});
			});
			const meetingsArray = [...uniqueMeetings.values()];
			setMeetings(meetingsArray);
		}
	}, [todos]);

	const createTodo = () => {
		navigate('/todos/?create');
	};

	// ----

	const handleShowTodo = (show: boolean) => {
		setShowTodo(show);
	};

	const handleShowDone = (show: boolean) => {
		setShowDone(show);
	};

	const handleShowArchived = (show: boolean) => {
		setArchived(show);
	};

	const handleShowDoing = (show: boolean) => {
		setShowDoing(show);
	};

	const handleShowBlock = (show: boolean) => {
		setShowBlock(show);
	};

	const [showFilterModal, setShowFilterModal] = React.useState(false);
	const handleShowFilterModal = () => {
		setShowFilterModal(!showFilterModal);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowFilterModal]);

	const trueCount = [showTodo, showDone, showArchived, showDoing, showBlock].filter(Boolean).length;

	return (
		<>
			<div className={styles.overviewList}>
				<div className={styles.top}>
					<h1>{t('My tasks')}</h1>
					<div className={styles.flex}>
						<div className={styles.icons}>
							<div
								className={view === 'Simple' ? styles.iconSelect : styles.icon}
								onClick={() => {
									handleGroupChange('Simple');
								}}>
								<IconClock />
							</div>
							<div
								className={view === 'Board' ? styles.iconSelect : styles.icon}
								onClick={() => {
									handleGroupChange('Board');
								}}>
								<IconTodoKaban />
							</div>
							<div
								className={view === 'List' ? styles.iconSelect : styles.icon}
								onClick={() => {
									handleGroupChange('List');
								}}>
								<div className={styles.childIcon}>
									<IconTodoCalendarView />
								</div>
							</div>
							<div
								className={view === 'Meetings' ? styles.iconSelect : styles.icon}
								onClick={() => {
									handleGroupChange('Meetings');
								}}>
								<div className={styles.childIcon}>
									<IconTodoListView />
								</div>
							</div>
						</div>
						<div className={styles.button} onClick={() => createTodo()}>
							<button>
								<IconSimplePlus />
								{t('Create')}
							</button>
						</div>
					</div>
				</div>

				<div className={styles.main}>
					<div>
						{todosRequestStatus.type === RequestStatusType.Success && view === 'List' && todos && (
							<>
								<div className={styles.mainTodoPage}>
									<div className={styles.filters}>
										<div className={styles.place}>
											<div
												className={showFilterModal ? styles.activeButton : styles.buttonFilter}
												onClick={() => handleShowFilterModal()}>
												<IconFilter />
												{t('Filters')}
												<div>{trueCount !== 0 ? trueCount : ''}</div>
											</div>
											{showFilterModal && (
												<div className={styles.filterModalWrapper} ref={modalRefFilter}>
													<TodosFilterModal
														showTodo={showTodo}
														showDone={showDone}
														showArchived={showArchived}
														showDoing={showDoing}
														showBlock={showBlock}
														handleShowTodo={handleShowTodo}
														handleShowDone={handleShowDone}
														handleShowArchived={handleShowArchived}
														handleShowDoing={handleShowDoing}
														handleShowBlock={handleShowBlock}
													/>
												</div>
											)}
										</div>
									</div>
									<div className={styles.overview}>
										<TodosViewList
											height='calc(100vh - 276px)'
											todosAssignee={todos.filter((todo) => {
												if (todo.archived && !showArchived) {
													return false;
												}

												if (showTodo && todo.state === TodosStates.TODO) {
													return true;
												}

												if (showDoing && todo.state === TodosStates.DOING) {
													return true;
												}

												if (showDone && todo.state === TodosStates.DONE) {
													return true;
												}

												if (showBlock && todo.state === TodosStates.BLOCKED) {
													return true;
												}

												return false;
											})}
										/>
									</div>
								</div>
							</>
						)}

						{todosRequestStatus.type === RequestStatusType.Success &&
							view === 'Meetings' &&
							todos &&
							meetings && (
								<>
									<div className={styles.mainTodoPage}>
										<div className={styles.filters}>
											<div className={styles.place}>
												<div
													className={
														showFilterModal ? styles.activeButton : styles.buttonFilter
													}
													onClick={() => handleShowFilterModal()}>
													<IconFilter />
													{t('Filters')}
													<div>{trueCount !== 0 ? trueCount : ''}</div>
												</div>
												{showFilterModal && (
													<div className={styles.filterModalWrapper} ref={modalRefFilter}>
														<TodosFilterModal
															showTodo={showTodo}
															showDone={showDone}
															showArchived={showArchived}
															showDoing={showDoing}
															showBlock={showBlock}
															handleShowTodo={handleShowTodo}
															handleShowDone={handleShowDone}
															handleShowArchived={handleShowArchived}
															handleShowDoing={handleShowDoing}
															handleShowBlock={handleShowBlock}
														/>
													</div>
												)}
											</div>
										</div>
										<div className={styles.overview}>
											<TodosViewMeetings
												height='calc(100vh - 276px)'
												todosAssignee={todos.filter((todo) => {
													if (todo.archived && !showArchived) {
														return false;
													}

													if (showTodo && todo.state === TodosStates.TODO) {
														return true;
													}

													if (showDoing && todo.state === TodosStates.DOING) {
														return true;
													}

													if (showDone && todo.state === TodosStates.DONE) {
														return true;
													}

													if (showBlock && todo.state === TodosStates.BLOCKED) {
														return true;
													}

													return false;
												})}
												meetings={meetings}
											/>
										</div>
									</div>
								</>
							)}

						{todosRequestStatus.type === RequestStatusType.Success && view === 'Simple' && todos && (
							<>
								<div className={styles.mainTodoPage}>
									<div className={styles.filters}>
										<div className={styles.place}>
											<div
												className={showFilterModal ? styles.activeButton : styles.buttonFilter}
												onClick={() => handleShowFilterModal()}>
												<IconFilter />
												{t('Filters')}
												<div>{trueCount !== 0 ? trueCount : ''}</div>
											</div>
											{showFilterModal && (
												<div className={styles.filterModalWrapper} ref={modalRefFilter}>
													<TodosFilterModal
														showTodo={showTodo}
														showDone={showDone}
														showArchived={showArchived}
														showDoing={showDoing}
														showBlock={showBlock}
														handleShowTodo={handleShowTodo}
														handleShowDone={handleShowDone}
														handleShowArchived={handleShowArchived}
														handleShowDoing={handleShowDoing}
														handleShowBlock={handleShowBlock}
													/>
												</div>
											)}
										</div>
									</div>
									<div className={styles.overview}>
										<TodosViewSimple
											height='calc(100vh - 276px)'
											todosAssignee={todos.filter((todo) => {
												if (todo.archived && !showArchived) {
													return false;
												}

												if (showTodo && todo.state === TodosStates.TODO) {
													return true;
												}

												if (showDoing && todo.state === TodosStates.DOING) {
													return true;
												}

												if (showDone && todo.state === TodosStates.DONE) {
													return true;
												}

												if (showBlock && todo.state === TodosStates.BLOCKED) {
													return true;
												}

												return false;
											})}
										/>
									</div>
								</div>
							</>
						)}

						{todosRequestStatus.type === RequestStatusType.Success && view === 'Board' && todos && (
							<>
								<div className={styles.mainTodoPage}>
									<div className={styles.filters}>
										<div className={styles.place}>
											<div
												className={showFilterModal ? styles.activeButton : styles.buttonFilter}
												onClick={() => handleShowFilterModal()}>
												<IconFilter />
												{t('Filters')}
												<div>{trueCount !== 0 ? trueCount : ''}</div>
											</div>
											{showFilterModal && (
												<div className={styles.filterModalWrapper} ref={modalRefFilter}>
													<TodosFilterModal
														showTodo={showTodo}
														showDone={showDone}
														showArchived={showArchived}
														showDoing={showDoing}
														showBlock={showBlock}
														handleShowTodo={handleShowTodo}
														handleShowDone={handleShowDone}
														handleShowArchived={handleShowArchived}
														handleShowDoing={handleShowDoing}
														handleShowBlock={handleShowBlock}
													/>
												</div>
											)}
										</div>
									</div>

									<TodosViewBoard
										h='calc(100vh - 218px)'
										w='100%'
										todosAssignee={todos.filter((todo) => {
											if (todo.archived && !showArchived) {
												return false;
											}

											if (showTodo && todo.state === TodosStates.TODO) {
												return true;
											}

											if (showDoing && todo.state === TodosStates.DOING) {
												return true;
											}

											if (showDone && todo.state === TodosStates.DONE) {
												return true;
											}

											if (showBlock && todo.state === TodosStates.BLOCKED) {
												return true;
											}

											return false;
										})}
									/>
								</div>
							</>
						)}

						{todosRequestStatus.type === RequestStatusType.Failed && (
							<tr>{todosRequestStatus.errorMessage}</tr>
						)}
					</div>

					{/* {todosRequestStatus.type === RequestStatusType.InProgress && (
						<div className={styles.noMeetings}>
							<Loader></Loader>
						</div>
					)} */}
				</div>
			</div>
		</>
	);
};

export default TodosPage;
