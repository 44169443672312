export class RuntimeConfig {
	public static getApiURL(): string {
		return this.getRuntimeConfig('apiUrl');
	}

	public static getWebSocketURL(): string {
		return this.getRuntimeConfig('webSocketUrl');
	}

	public static getEnvironment(): string {
		return this.getRuntimeConfig('environment');
	}

	public static getClientID(): string {
		return this.getRuntimeConfig('clientId');
	}

	public static getClientAuthorityURI(): string {
		return this.getRuntimeConfig('authority');
	}

	public static getRedirectURI(): string {
		return this.getRuntimeConfig('redirectUri');
	}

	public static getLogoutRedirectURI(): string {
		return this.getRuntimeConfig('logoutRedirectUri');
	}

	public static getClientScopes(): string[] {
		return this.getRuntimeConfig('scopes');
	}

	private static getRuntimeConfig<T>(configProp: string): T {
		// @ts-ignore
		return window.runConfig[configProp];
	}
}
