/* eslint-disable @typescript-eslint/no-explicit-any */
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest, msalInstance } from '../config/msal-auth-config.';
import { RuntimeConfig } from '../config/runtime-config';

export class AuthenticationService {
	public static async getToken(): Promise<string> {
		const accounts = msalInstance.getAllAccounts();
		const account = accounts[0];
		if (account) {
			try {
				const response = await msalInstance.acquireTokenSilent({
					...loginRequest,
					account,
				});
				return response?.accessToken;
			} catch (error) {
				if (error instanceof InteractionRequiredAuthError) {
					return await msalInstance
						.acquireTokenPopup({
							scopes: RuntimeConfig.getClientScopes(),
						})
						.catch((error_: any) => {
							msalInstance.logoutRedirect({
								account,
								postLogoutRedirectUri: RuntimeConfig.getLogoutRedirectURI(),
							});
							return error_;
						});
				} else {
					await msalInstance.logoutRedirect({
						account,
						postLogoutRedirectUri: RuntimeConfig.getLogoutRedirectURI(),
					});
					return '';
				}
			}
		} else {
			return '';
		}
	}
}
