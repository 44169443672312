import React from 'react';
import MeetingsDetailsDescription from './description/meetings-details-description';
import MeetingsDetailsAgenda from './agenda/meetings-details-agenda';
import MeetingsDetailsTodos from './todos/meetings-details-todos';
import MeetingsDetailsNotes from './notes/meetings-details-notes';
import styles from './meetings-details-body.module.scss';
import { useTranslation } from 'react-i18next';
import { Tab } from '../../../../../shared/types/component-interface';
import { useAppSelector } from '../../../../../store/hooks';
import { useParams } from 'react-router-dom';
import { TodosStates } from '../../../../../store/todos/type';
import MeetingsFilesComponent from './files/meetings-files.component';
import { TagContract } from '../../../../../store/meetings/types';
import { IconDownChev, IconUpChev } from '../../../../../shared/utils/icon';
import CopilotComponent from './coPilot/copilot.component';

export interface MeetingDetailsHeaderProps {
	handleTagsChanged: (tags: TagContract[]) => void;
}

const MeetingDetailsBody = ({ handleTagsChanged }: MeetingDetailsHeaderProps) => {
	const [activeTabIndex, setActiveTabIndex] = React.useState(0);
	const { user } = useAppSelector((state) => state.user);
	const { selectMeetingTodos, selectMeetingPrevTodos } = useAppSelector((state) => state.todos);
	const { agendaItems } = useAppSelector((state) => state.agenda);
	const { selectedMeeting, prevMeeting } = useAppSelector((state) => state.meetings);
	const { shareTodos, shareAgenda, shareNotes, shareFiles } = useAppSelector((state) => state.sharing);
	const { selectedMeetingNotes, prevMeetingsNote } = useAppSelector((state) => state.notes);
	const { t } = useTranslation();

	const todos =
		selectMeetingTodos && selectMeetingPrevTodos
			? selectMeetingTodos?.filter((todo) => todo.state === TodosStates.TODO && !todo?.archived).length +
			  selectMeetingPrevTodos?.filter(
					(todo) =>
						todo.state === TodosStates.TODO &&
						!todo?.archived &&
						todo.graphiCalUId === prevMeeting?.iCalUId,
			  ).length
			: selectMeetingTodos && !selectMeetingPrevTodos
			? selectMeetingTodos?.filter((todo) => todo.state === TodosStates.TODO && !todo?.archived).length
			: shareTodos
			? shareTodos?.filter((todo) => todo.state === TodosStates.TODO && !todo?.archived).length
			: '';
	const agenda = agendaItems ? agendaItems.length : shareAgenda ? shareAgenda.length : '';
	const notes =
		selectedMeetingNotes && prevMeetingsNote
			? selectedMeetingNotes.filter((note) => !note?.archived).length +
			  prevMeetingsNote.filter(
					(note) =>
						!note?.archived && !selectedMeetingNotes.some((selectedNote) => selectedNote.id === note.id),
			  ).length
			: shareNotes
			? shareNotes.filter((note) => !note?.archived).length
			: '';
	const files = user ? selectedMeeting?.files.length : shareFiles ? shareFiles.length : '';

	const params = useParams();
	const shareId = params.shareID;
	const storedAccessMail = localStorage.getItem(`accessMail/${shareId}`);

	const [tabs] = user
		? React.useState<Tab[]>([
				{
					label: t('Description'),
					component: <MeetingsDetailsDescription handleTagsChanged={handleTagsChanged} />,
				},
				{ label: t('Agenda'), component: <MeetingsDetailsAgenda /> },
				{
					label: t('Todos'),
					component: <MeetingsDetailsTodos storedAccessMail={storedAccessMail ? storedAccessMail : ''} />,
				},
				{
					label: t('Notes'),
					component: <MeetingsDetailsNotes storedAccessMail={storedAccessMail ? storedAccessMail : ''} />,
				},
				{
					label: t('Files'),
					component: <MeetingsFilesComponent />,
				},
				{
					label: t('Copilot (beta)'),
					component: <CopilotComponent />,
				},
		  ])
		: React.useState<Tab[]>([
				{
					label: t('Description'),
					component: <MeetingsDetailsDescription handleTagsChanged={handleTagsChanged} />,
				},
				{ label: t('Agenda'), component: <MeetingsDetailsAgenda /> },
				{
					label: t('Todos'),
					component: <MeetingsDetailsTodos storedAccessMail={storedAccessMail ? storedAccessMail : ''} />,
				},
				{
					label: t('Notes'),
					component: <MeetingsDetailsNotes storedAccessMail={storedAccessMail ? storedAccessMail : ''} />,
				},
				{
					label: t('Files'),
					component: <MeetingsFilesComponent />,
				},
		  ]);

	// MOBILE //

	const [dropdownOptions, setDropdownOptions] = React.useState(false);

	const handleDropDownOptions = () => {
		setDropdownOptions(!dropdownOptions);
	};

	const [Options, setOptions] = React.useState('Description');

	const handleNewOption = (data: string) => {
		setOptions(data);
		handleDropDownOptions();
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					if (dropdownOptions) handleDropDownOptions();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleDropDownOptions]);

	return (
		<>
			<div className={styles.bodyMeeting}>
				<div className={styles.tabs}>
					{tabs.map((tab, index) => (
						<div
							className={styles.tabsWrapper}
							key={index}
							onClick={() => setActiveTabIndex(index)}
							style={{ cursor: 'pointer' }}>
							<a className={index === activeTabIndex ? styles.activeTab : ''}>{tab.label}</a>
							{index === 1 && (
								<span className={index === activeTabIndex ? styles.activeNumber : styles.number}>
									{agenda}
								</span>
							)}
							{index === 2 && (
								<span className={index === activeTabIndex ? styles.activeNumber : styles.number}>
									{todos}
								</span>
							)}
							{index === 3 && (
								<span className={index === activeTabIndex ? styles.activeNumber : styles.number}>
									{notes}
								</span>
							)}
							{index === 4 && (
								<span className={index === activeTabIndex ? styles.activeNumber : styles.number}>
									{files}
								</span>
							)}
						</div>
					))}
				</div>
				<div>{tabs[activeTabIndex].component}</div>
			</div>

			<div className={styles.mobileBody}>
				<div className={styles.menuMobile}>
					<div className={styles.selectCustom}>
						<button type='button' onClick={() => handleDropDownOptions()}>
							{t(Options)}
							{!dropdownOptions ? <IconDownChev /> : <IconUpChev />}
						</button>
						{dropdownOptions && (
							<div className={styles.dropdown} ref={modalRef}>
								<p onClick={() => handleNewOption('Description')}>{t('Description')}</p>

								<p onClick={() => handleNewOption('Agenda')}>{t('Agenda')}</p>

								<p onClick={() => handleNewOption('Todos')}>{t('Todos')}</p>

								<p onClick={() => handleNewOption('Notes')}>{t('Notes')}</p>

								<p onClick={() => handleNewOption('Files')}>{t('Files')}</p>

								{user && <p onClick={() => handleNewOption('Copilot')}>{t('Copilot (beta)')}</p>}
							</div>
						)}
					</div>
				</div>
				<div>
					{Options === 'Description' ? (
						<MeetingsDetailsDescription handleTagsChanged={handleTagsChanged} />
					) : Options === 'Agenda' ? (
						<MeetingsDetailsAgenda />
					) : Options === 'Todos' ? (
						<MeetingsDetailsTodos storedAccessMail={storedAccessMail ? storedAccessMail : ''} />
					) : Options === 'Notes' ? (
						<MeetingsDetailsNotes storedAccessMail={storedAccessMail ? storedAccessMail : ''} />
					) : Options === 'Files' ? (
						<MeetingsFilesComponent />
					) : Options === 'Copilot' ? (
						<CopilotComponent />
					) : (
						<></>
					)}
				</div>
			</div>
		</>
	);
};

export default MeetingDetailsBody;
