import React from 'react';
import styles from './todo-board.module.scss';
import { useTranslation } from 'react-i18next';
import DateSelectorComponent from '../../meetings/modals/date-selector.component';

interface Props {
	date: Date;
	handleDatePicker: (date: Date) => void;
	close: () => void;
}

const TodoDateSelect: React.FC<Props> = ({ date, handleDatePicker, close }) => {
	const { t } = useTranslation();
	const modalRef = React.useRef<HTMLDivElement>(null);
	const [newDate, setnewDate] = React.useState(date);

	const handleDateSelect = (dateSelect: Date) => {
		setnewDate(dateSelect);
	};

	const confirmNewDate = () => {
		handleDatePicker(newDate);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					close();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [close]);

	return (
		<>
			<div className={styles.datePicker} ref={modalRef}>
				<DateSelectorComponent selectedDate={newDate} onDateSelect={handleDateSelect} type='agenda' />
				<button onClick={() => confirmNewDate()} className={styles.confirm}>
					{t('Confirm')}
				</button>
			</div>
		</>
	);
};

export default TodoDateSelect;
