import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NotificationsContract, NotificationsSendContract } from './type';
import { RequestStatus, RequestStatusType, createRequestStatus } from '../shared/types';
import ErrorUtil from '../../shared/utils/error.util';
import NotificationsAPI from '../../api/notifications-api';

interface NotificationsState {
	notifications?: NotificationsContract[];
	notificationsRequestStatus: RequestStatus;
}

const initialState: NotificationsState = {
	notifications: undefined,
	notificationsRequestStatus: createRequestStatus(RequestStatusType.New),
};

export const getNotifications = createAsyncThunk(
	'notifications/currentUser/get',
	async (): Promise<NotificationsContract[]> => {
		return NotificationsAPI.getCurrentUserNotifications();
	},
);

export const hideNotifications = createAsyncThunk(
	'notifications/hide',
	async (notificationId: string): Promise<NotificationsContract> => {
		return NotificationsAPI.hideNotifications(notificationId);
	},
);

export const SendNotifications = createAsyncThunk(
	'notifications/create',
	async (bodyNotification: NotificationsSendContract): Promise<NotificationsSendContract> => {
		return NotificationsAPI.sendNotifications(bodyNotification);
	},
);

const slice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		hideNotificationReducer: (state, action: PayloadAction<string>) => {
			const notificationId = action.payload;
			if (state.notifications) {
				state.notifications = state.notifications.filter((notification) => notification.id !== notificationId);
			}
		},
	},
	extraReducers(builder): void {
		builder.addCase(getNotifications.pending, (state) => {
			state.notificationsRequestStatus = createRequestStatus(RequestStatusType.InProgress);
		});
		builder.addCase(getNotifications.fulfilled, (state, action) => {
			state.notificationsRequestStatus = createRequestStatus(RequestStatusType.Success);
			state.notifications = action.payload;
		});
		builder.addCase(getNotifications.rejected, (state, action) => {
			state.notificationsRequestStatus = createRequestStatus(
				RequestStatusType.Failed,
				ErrorUtil.getErrorMessage(action.error),
			);
		});
	},
});

const { actions, reducer } = slice;
export const { hideNotificationReducer } = actions;
export default reducer;
