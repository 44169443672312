import axios, {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosRequestHeaders,
	AxiosResponse,
	AxiosResponseTransformer,
} from 'axios';
import { RuntimeConfig } from '../../config/runtime-config';
import { AuthenticationService } from '../../services/authentication.service';

export default class HttpClient {
	private static readonly baseURL: string = RuntimeConfig.getApiURL() || 'http://localhost:3001';

	public static async buildHeaders(headers?: { [key: string]: string | number }): Promise<AxiosRequestHeaders> {
		const token = await AuthenticationService.getToken();
		const baseHeaders = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`,
		};

		if (headers) {
			return { ...baseHeaders, ...headers };
		}
		return baseHeaders;
	}

	public static buildUrl(path: string, parameters?: { [key: string]: string | number }): string {
		const fullUrl = path;
		if (parameters) {
			return Object.keys(parameters).reduce(
				(url, parameterKey) => url.replace(`{${parameterKey}}`, parameters[parameterKey].toString()),
				fullUrl,
			);
		}
		return fullUrl;
	}

	private static transformResponse(input: string): AxiosResponseTransformer | AxiosResponseTransformer[] {
		return JSON.parse(input);
	}

	private static async client(header = {}): Promise<AxiosInstance> {
		const controller = new AbortController();

		const config: AxiosRequestConfig = {
			baseURL: this.baseURL,
			signal: controller.signal,
			headers: await this.buildHeaders(header),
		};

		config.transformResponse = [
			(data) => {
				return data && typeof data === 'string' ? this.transformResponse(data) : data;
			},
		];

		return axios.create(config);
	}

	public static async get<TResp>(
		path: string,
		parameters?: { [key: string]: string | number },
		searchParameters?: URLSearchParams,
	): Promise<AxiosResponse<TResp>> {
		const url = this.buildUrl(path, parameters);
		const client = await this.client();
		return client.get<TResp>(url, {
			params: searchParameters,
		});
	}

	public static async getImage<TResp>(
		path: string,
		parameters?: { [key: string]: string | number },
		searchParameters?: URLSearchParams,
		responsetype?: string,
	): Promise<AxiosResponse<TResp>> {
		const url = this.buildUrl(path, parameters);
		const client = await this.client();
		return client.get<TResp>(url, {
			params: searchParameters,
			responseType: 'arraybuffer',
		});
	}

	public static async getFile<TResp>(
		path: string,
		parameters?: { [key: string]: string | number },
		searchParameters?: URLSearchParams,
		responsetype?: string,
	): Promise<AxiosResponse<TResp>> {
		const url = this.buildUrl(path, parameters);
		const client = await this.client();
		return client.get<TResp>(url, {
			params: searchParameters,
			responseType: 'blob',
		});
	}

	public static async post<TPayload, TResp>(
		path: string,
		payload: TPayload,
		parameters?: { [key: string]: string | number },
		searchParameters?: URLSearchParams,
	): Promise<AxiosResponse<TResp>> {
		const url = this.buildUrl(path, parameters);
		const client = await this.client();
		return client.post<TResp>(url, payload);
	}

	public static async postFile<TPayload, TResp>(
		path: string,
		payload: TPayload,
		parameters?: { [key: string]: string | number },
		searchParameters?: URLSearchParams,
	): Promise<AxiosResponse<TResp>> {
		const url = this.buildUrl(path, parameters);
		const client = await this.client();
		return client.post<TResp>(url, payload, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		});
	}

	public static async patch<TPayload, TResp>(
		path: string,
		payload: TPayload,
		parameters?: { [key: string]: string | number },
		searchParameters?: URLSearchParams,
	): Promise<AxiosResponse<TResp>> {
		const url = this.buildUrl(path, parameters);
		const client = await this.client();
		return client.patch<TResp>(url, payload);
	}

	public static async patchFile<TPayload, TResp>(
		path: string,
		payload: TPayload,
		parameters?: { [key: string]: string | number },
		searchParameters?: URLSearchParams,
	): Promise<AxiosResponse<TResp>> {
		const url = this.buildUrl(path, parameters);
		const client = await this.client();
		return client.patch<TResp>(url, payload, {
			headers: {
				'content-type': 'multipart/form-data',
			},
		});
	}

	public static async put<TPayload, TResp>(
		path: string,
		payload: TPayload,
		parameters?: { [key: string]: string | number },
		searchParameters?: URLSearchParams,
	): Promise<AxiosResponse<TResp>> {
		const url = this.buildUrl(path, parameters);
		const client = await this.client();
		return client.put<TResp>(url, payload);
	}

	public static async delete<TResp>(
		path: string,
		parameters?: { [key: string]: string | number },
		searchParameters?: URLSearchParams,
	): Promise<AxiosResponse<TResp>> {
		const url = this.buildUrl(path, parameters);
		const client = await this.client();
		return client.delete(url);
	}
}
