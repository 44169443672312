/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { AccessRightType, FileContract } from '../../../../../store/meetings/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
	addPeopleShareFile,
	deletePeopleFile,
	deletePeopleShareFile,
	postPeopleShareFile,
	updateProjectFile,
	updateProjectFiles,
} from '../../../../../store/project/slice';
import { People } from '../../../../../store/notes/type';
import {
	IconClose,
	IconContact,
	IconEdit,
	IconGlobe,
	IconLock,
	IconMeetingsPage,
	IconSearch,
	IconSimplePlus,
} from '../../../../../shared/utils/icon';
import { Avatar } from '../../../../../shared/utils/avatar';
import styles from './files-share.module.scss';
import { extractDomainWithExt } from '../../../../../shared/utils/domainext';
import { getCurrentUserContacts } from '../../../../../store/user/slice';
import { ContactsGraphContract } from '../../../../../store/user/type';

interface props {
	file: FileContract;
	close: (index: number) => void;
}

const ProjectShareFormModal: React.FC<React.PropsWithChildren<props>> = ({ file, close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const modalRef = React.useRef<HTMLDivElement>(null);

	const [animation, setAnimation] = useState<boolean>(false);

	const closeModal = (index: number) => {
		setAnimation(true);
		setTimeout(() => {
			close(index);
			setAnimation(false);
		}, 300);
	};

	const { selectProject, filesPeople } = useAppSelector((state) => state.project);
	const { user, contacts } = useAppSelector((state) => state.user);

	const domain = extractDomainWithExt(user ? user.email : '');

	const [selectedAccessType, setSelectedAccessType] = React.useState<AccessRightType>(file.accessRightType);
	const [companiesAccess, setcompaniesAccess] = React.useState<string[]>([domain ? domain : '']);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		if (AccessRightType) {
			event.preventDefault();
			const body = { accessRightType: selectedAccessType, accessRightCompanies: companiesAccess };
			if (file && user && selectProject) {
				dispatch(
					updateProjectFile({
						body,
						projectId: selectProject.id,
						fileId: file?.id,
					}),
				).then((res) => {
					dispatch(updateProjectFiles(res.payload as FileContract));
					close(-1);
				});
			}
		}
	};

	const handleCheckboxChange = (accessData: AccessRightType) => {
		const value = accessData;
		if (
			value === AccessRightType.ONLYME ||
			value === AccessRightType.EVERYONE ||
			value === AccessRightType.SHARED ||
			value === AccessRightType.INTERN
		) {
			setSelectedAccessType(value);
		}
	};

	// --------

	const [openMenu, setOpenMenu] = React.useState(false);
	const handleOpenMenu = () => {
		setOpenMenu(!openMenu);
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleOpenMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleOpenMenu]);

	const [selectedAddresses, setSelectedAddresses] = React.useState<People[]>([]);

	const handleCheckboxChangePeople = (attendee: People) => {
		setSelectedAddresses([...selectedAddresses, attendee]);
		const body = {
			id: '1',
			username: attendee.username,
			mail: attendee.mail,
		};

		if (file)
			dispatch(postPeopleShareFile({ body, fileId: file.id })).then((res) => {
				dispatch(addPeopleShareFile(res.payload as People));
			});
	};

	const handleDeletePeopleShare = (mail: string) => {
		if (file)
			dispatch(deletePeopleShareFile({ fileId: file.id, mail })).then(() => {
				dispatch(deletePeopleFile(mail));
			});
	};

	let timeoutId: any;

	const [noMatch, setNotMatch] = React.useState(false);
	const [contactNM, setContactNM] = React.useState<People>();
	const [isShearching, setIsShearching] = React.useState('');

	const isEmail = (email: string) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const onChangeHandler = (filter: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setIsShearching(filter);

			dispatch(getCurrentUserContacts(filter)).then((res) => {
				const response = res.payload as ContactsGraphContract[];
				const newContactPR = {
					id: '',
					username: filter,
					mail: filter,
				};
				setNotMatch(false);

				if (response?.length < 1) {
					setNotMatch(true);
					setContactNM(newContactPR);
				}
			});
		}, 200);
	};

	return (
		<>
			<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
				<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
					<div className={styles.titleWrapper}>
						<h3>
							<IconEdit />
							{t('Update access')}
						</h3>
						<div className={styles.closeButton}>
							<div
								className={styles.icon}
								onClick={() => {
									closeModal(-1);
								}}>
								<IconClose />
							</div>
						</div>
					</div>
					<form onSubmit={handleSubmit}>
						<div className={styles.flexAccess}>
							<div
								className={
									selectedAccessType === AccessRightType.EVERYONE
										? styles.selectAccess
										: styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.EVERYONE)}>
								<IconGlobe />
								{t('Public')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.INTERN ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.INTERN)}>
								<IconContact />
								{t('Intern')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.SHARED ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.SHARED)}>
								<IconMeetingsPage />
								{t('Shared')}
							</div>
							<div
								className={
									selectedAccessType === AccessRightType.ONLYME ? styles.selectAccess : styles.access
								}
								onClick={() => handleCheckboxChange(AccessRightType.ONLYME)}>
								<IconLock />
								{t('Private')}
							</div>
						</div>
						<br />
						{selectedAccessType === AccessRightType.SHARED && (
							<>
								<div className={styles.invited}>
									{filesPeople?.map((people, index) => (
										<div className={styles.avatar} key={index}>
											<div className={styles.avatarWrapper}>
												<Avatar name={people.username} mail={people.mail} index={index} />
												<p>{people.username}</p>
											</div>
											<div
												className={styles.icon}
												onClick={() => handleDeletePeopleShare(people.mail)}>
												<IconClose />
											</div>
										</div>
									))}
								</div>

								<div className={styles.addAttendee}>
									<div className={styles.addAttendeeContainer} onClick={() => handleOpenMenu()}>
										{t('Invite people')}

										<div className={styles.iconPlus}>
											<IconSimplePlus />
										</div>
									</div>
									{openMenu && (
										<div className={styles.modalPeople} ref={modalRef}>
											<div className={styles.peopleList}>
												{user && (
													<div className={styles.searchInput}>
														<span>
															<IconSearch />
														</span>
														<input
															type='text'
															onChange={(e) => onChangeHandler(e.target.value)}
														/>
													</div>
												)}
												{user?.userCompany
													.filter((userC) => userC.userName !== user.email)
													.filter((userC) =>
														userC.userName
															.toLowerCase()
															.includes(isShearching.toLowerCase()),
													)
													.map((userC, index) => (
														<div
															className={styles.row}
															key={userC.userName}
															onClick={() =>
																handleCheckboxChangePeople({
																	id: '',
																	username: userC.name,
																	mail: userC.userName,
																})
															}>
															<div className={styles.avatar}>
																<Avatar
																	name={userC.name}
																	mail={userC.userName}
																	index={index}
																/>
																<span>{userC.userName}</span>
															</div>
															<div>
																<IconSimplePlus />
															</div>
														</div>
													))}
												{contacts &&
													contacts
														.filter(
															(contact) =>
																contact.emailAddresses &&
																contact.emailAddresses.length > 0 &&
																contact.emailAddresses[0].address !== '' &&
																contact.displayName !== '',
														)
														.map((contact, index) => (
															<div
																className={styles.row}
																key={contact.emailAddresses[0].address}
																onClick={() =>
																	handleCheckboxChangePeople({
																		id: '',
																		username: contact.displayName,
																		mail: contact.emailAddresses[0].address,
																	})
																}>
																<div className={styles.avatar}>
																	<Avatar
																		name={contact.displayName}
																		mail={contact.emailAddresses[0].address}
																		index={index}
																	/>
																	<span>{contact.displayName}</span>
																</div>
																<div>
																	<IconSimplePlus />
																</div>
															</div>
														))}
												{noMatch && contactNM && isEmail(contactNM.mail) === true && (
													<>
														<div
															className={styles.row}
															key={contactNM.mail}
															onClick={() => handleCheckboxChangePeople(contactNM)}>
															<div className={styles.avatar}>
																<Avatar
																	name={contactNM.username}
																	mail={contactNM.mail}
																	index={2}
																/>
																<span>{contactNM.username}</span>
															</div>
															<div>
																<IconSimplePlus />
															</div>
														</div>
													</>
												)}
											</div>
										</div>
									)}
								</div>
							</>
						)}
						<br />

						<button type='submit'>{t('Confirm')}</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default ProjectShareFormModal;
