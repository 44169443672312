/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { NotesContract } from '../../../../../../store/notes/type';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import NotesEditorComponent from '../../../../../notes/components/notes-editor.component';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { updateNoteSharedState, updateNotesShared } from '../../../../../../store/sharing/slice';
import {
	updateNoteMeeting,
	updateNoteMeetingPrev,
	updateNoteMeetingTitle,
	updateNoteMeetingTitlePrev,
	updateNotes,
} from '../../../../../../store/notes/slice';
import { updateNoteProject, updateNoteTitleProject } from '../../../../../../store/project/slice';
import { MeetingContract } from '../../../../../../store/meetings/types';

interface Props {
	storedAccessMail: string;
	note: NotesContract;
	doc: Y.Doc;
	provider: WebsocketProvider;
	handleIsEditing: (index: string) => void;
	meeting?: MeetingContract | undefined
	projectId?: string;
}

const MeetingsDetailsNotesEditing: React.FC<Props> = ({ meeting, storedAccessMail, note, doc, provider, handleIsEditing }) => {
	const modalRef = React.useRef<HTMLDivElement>(null);

	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);

	let timeoutId: any;

	const [isUpdate, setIsUpdate] = React.useState<boolean>(false);
	const [isEditing, setIsEditing] = React.useState<boolean>(false);
	const [text, setText] = React.useState(note?.text);
	const [title, setTitle] = React.useState(note?.title);

	const handleEditingState = () => {
		setIsEditing(!isEditing);
		if (!isEditing) {
			setText(note?.text);
			setTitle(note?.title);
			setIsUpdate(false);
		}
	};

	const onSave = () => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			setIsUpdate(false);
			setIsEditing(false);
		}, 2000);
	};

	const handleSave = async (body: NotesContract) => {
		

		await (user
			? dispatch(updateNotes({ noteId: note.id, body })).then(() => {
					setIsEditing(false);
					setIsUpdate(true);
					dispatch(updateNoteMeetingTitle({ noteId: note.id, newText: title }));
					dispatch(updateNoteMeetingTitlePrev({ noteId: note.id, newText: title }));
					dispatch(updateNoteMeeting({ noteId: note.id, newText: text }));
					dispatch(updateNoteMeetingPrev({ noteId: note.id, newText: text }));
					dispatch(updateNoteProject({ id: note.id, newText: text }));
					dispatch(updateNoteTitleProject({ id: note.id, newText: title }));
					onSave();
			  })
			: dispatch(updateNotesShared({ noteId: note.id, body })).then(() => {
					setIsUpdate(true);
					dispatch(updateNoteSharedState(body));
					dispatch(updateNoteMeetingTitlePrev({ noteId: note.id, newText: title }));
					dispatch(updateNoteMeetingPrev({ noteId: note.id, newText: text }));
					onSave();
			  }));
	};

	const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

	const onChangeHandlerText = (data: string) => {
		const body = {
			id: note.id,
			title,
			text: data,
			graphUserId: note.graphUserId,
			graphUserName: note.graphUserName,
			meetingAttendees: note.meetingAttendees,
			graphiCalUId: note.graphiCalUId,
			meetingTitle: note.meetingTitle,
			meetingStartDate: note.meetingStartDate,
			tags: note.tags,
			updateNote: new Date(),
			createdOn: note.createdOn,
			updateOn: note.updateOn,
			accessRightType: note.accessRightType,
			accessRightCompanies: note.accessRightCompanies,
			archived: note.archived,
			projectId: note.projectId,
		};

		setText(data);
		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}
		debounceTimeout.current = setTimeout(() => {
			handleSave(body);
		}, 1500);
	};

	const onChangeHandlerTitle = (data: string) => {
		setTitle(data);
	};

	const updateNote = async () => {
		const body = {
			id: note.id,
			title,
			text,
			graphUserId: note.graphUserId,
			graphUserName: note.graphUserName,
			meetingAttendees: note.meetingAttendees,
			graphiCalUId: note.graphiCalUId,
			meetingTitle: note.meetingTitle,
			meetingStartDate: note.meetingStartDate,
			tags: note.tags,
			updateNote: new Date(),
			createdOn: note.createdOn,
			updateOn: note.updateOn,
			accessRightType: note.accessRightType,
			accessRightCompanies: note.accessRightCompanies,
			archived: note.archived,
			projectId: note.projectId,
		};
		await (user
			? dispatch(updateNotes({ noteId: note.id, body })).then(() => {
					dispatch(updateNoteMeetingTitle({ noteId: note.id, newText: title }));
					dispatch(updateNoteMeetingTitlePrev({ noteId: note.id, newText: title }));
					dispatch(updateNoteMeeting({ noteId: note.id, newText: text }));
					dispatch(updateNoteMeetingPrev({ noteId: note.id, newText: text }));
					dispatch(updateNoteProject({ id: note.id, newText: text }));
					dispatch(updateNoteTitleProject({ id: note.id, newText: title }));
					handleIsEditing('');
			  })
			: dispatch(updateNotesShared({ noteId: note.id, body })).then(() => {
					dispatch(updateNoteSharedState(body));
					dispatch(updateNoteMeetingTitlePrev({ noteId: note.id, newText: note.title }));
					dispatch(updateNoteMeetingPrev({ noteId: note.id, newText: note.text }));
					handleIsEditing('');
			  }));
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const remirrorMenuBarSelectors =
				'.MuiPaper-root, .MuiStack-root, .MuiPopover-root, .MuiMenu-root, .MuiModal-root';
			const isMenuClick = [...document.querySelectorAll(remirrorMenuBarSelectors)].some((element) =>
				element.contains(event.target as Node),
			);

			if (!isMenuClick && modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					updateNote();
					clearTimeout(timeoutId);
					setIsEditing(false);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setIsEditing, text, title, note]);

	return (
		<div>
			<NotesEditorComponent
				content={text}
				onChangeHandlerText={onChangeHandlerText}
				handleEditingChange={handleEditingState}
				provider={provider}
				doc={doc}
				inviteUser={storedAccessMail}
				noteId={note?.id}
				note={note}
				userName={user ? user.userName : ''}
				meetingPage
				onChangeHandlerTitle={onChangeHandlerTitle}
				title={title}
			/>
		</div>
	);
};

export default MeetingsDetailsNotesEditing;
