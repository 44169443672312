/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import styles from './project-todos.module.scss';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { Attendees } from '../../../../../shared/types/meetings-graph-interface';
import { TodosContract, TodosStates } from '../../../../../store/todos/type';
import { createTodos } from '../../../../../store/todos/slice';
import { SendNotificationsProject, addTodoProject, getTodosProject } from '../../../../../store/project/slice';
import { IconDownChev, IconSimplePlus, IconTodosSidebar, IconUpChev } from '../../../../../shared/utils/icon';
import { Avatar } from '../../../../../shared/utils/avatar';
import { NotificationsProjectType } from '../../../../../store/project/type';
import { NotificationsType } from '../../../../../store/notifications/type';
import { SendNotifications } from '../../../../../store/notifications/slice';
import { AccessRightType } from '../../../../../store/meetings/types';

interface Props {
	close: () => void;
}

const DetailsTodosFormComponents: React.FC<Props> = ({ close }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { selectProject, projectsSharePeople } = useAppSelector((state) => state.project);

	const rightPeople =
		selectProject?.accessRightType !== AccessRightType.SHARED
			? projectsSharePeople?.filter((people) => people.id === selectProject?.graphUserId)
			: projectsSharePeople;

	type FormData = {
		title: string;
		assignee: string;
		mail: string;
	};

	const dataSchema = yup.object().shape({
		title: yup.string().required(),
		assignee: yup.string(),
		mail: yup.string(),
	});

	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<FormData>({
		resolver: yupResolver(dataSchema),
	});

	const [dropdown, setDropdown] = React.useState(false);

	const handleDropDown = () => {
		setDropdown(!dropdown);
	};

	const [assignee, setAssignee] = React.useState(user ? user.name : '');

	React.useEffect(() => {
		if (user) {
			setValue('assignee', user.name);
			setValue('mail', user.email);
		}
	}, []);

	const handleNewAssignee = (data: Attendees) => {
		setAssignee(data.emailAddress.name);
		setValue('assignee', data.emailAddress.name);
		setValue('mail', data.emailAddress.address);
		handleDropDown();
	};

	const createTodo = (data: FormData) => {
		const body = {
			id: undefined,
			title: data.title,
			text: undefined,
			state: TodosStates.TODO,
			dueDate: undefined,
			duration: undefined,
			graphUserId: user?.graphUserId,
			noteId: undefined,
			assigneeDisplayName: data.mail,
			graphiCalUId: undefined,
			meetingGraphId: undefined,
			meetingName: undefined,
			meetingStartDate: undefined,
			createdOn: new Date(),
			tags: undefined,
			projectId: selectProject ? selectProject.id : undefined,
			assigneeName: data.assignee,
		};

		if (user) {
			dispatch(createTodos(body)).then((res) => {
				if (selectProject) {
					reset();

					setAssignee(user ? user.name : '');
					setValue('assignee', user.name);
					setValue('mail', user.email);

					const todo = res.payload as TodosContract;
					if (user) {
						const newTodo: any = {
							id: todo.id,
							title: todo.title,
							text: '',
							state: TodosStates.TODO,
							dueDate: todo.dueDate,
							duration: 0,
							graphUserId: user.graphUserId,
							noteId: null,
							assigneeDisplayName: todo.assigneeDisplayName,
							graphiCalUId: null,
							meetingGraphId: null,
							meetingName: null,
							meetingStartDate: null,
							createdOn: new Date().toISOString(),
							tags: [],
							projectId: selectProject ? selectProject.id : undefined,
							assigneeName: todo.assigneeName,
						};

						dispatch(addTodoProject(newTodo));
						dispatch(getTodosProject(selectProject.id));
						close();

						const bodyNotification = {
							id: '',
							userFromName: user.name,
							userFromMail: user.email,
							type: NotificationsProjectType.NEW_TODO,
							noteId: '',
							todoId: todo.id,
							todoDueDate: todo.dueDate,
							itemTitle: todo.title,
							graphiCalUId: '',
							meetingStartDate: '',
							meetingEndDate: '',
							meetingName: '',
							meetingAttendees: [],
							projectId: selectProject.id,
							userSeenNotif: [],
							todoAssignee: todo.assigneeDisplayName,
						};

						dispatch(SendNotificationsProject(bodyNotification));

						if (todo.assigneeDisplayName !== user.userName) {
							const bodyNotification: any = {
								id: undefined,
								userFromName: user.name,
								userFromMail: user.userName,
								userToName: todo.assigneeName,
								userToMail: todo.assigneeDisplayName,
								type: NotificationsType.NEW_TODO,
								noteId: undefined,
								todoId: todo.id,
								graphiCalUId: undefined,
								meetingStartDate: undefined,
								itemTitle: todo.title,
								visible: true,
								meetingName: undefined,
							};

							dispatch(SendNotifications(bodyNotification));
						}
					}
				}
			});
		}
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					if (dropdown) handleDropDown();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleDropDown]);

	return (
		<div className={styles.form}>
			<p>
				<div className={styles.icon}>
					<IconTodosSidebar width='23' height='23' />
				</div>
				{t('Add new todo')}
			</p>
			<form onSubmit={handleSubmit(createTodo)}>
				<input
					type='text'
					{...register('title')}
					id='description'
					placeholder={t('Write your todo here') as string}
				/>
				{errors.title && <p className={styles.error}>{t('Title is required')}</p>}
				<div className={styles.selectCustom} ref={modalRef}>
					<button type='button' onClick={() => handleDropDown()}>
						<p>{assignee}</p>
						{!dropdown ? <IconDownChev /> : <IconUpChev />}
					</button>
					{dropdown && (
						<div className={styles.dropdown}>
							<p
								onClick={() =>
									handleNewAssignee({
										type: '',
										emailAddress: {
											name: 'Unasigned',
											address: '',
										},
									})
								}>
								<Avatar name={'Unasigned'} mail={''} index={2} />
								{t('Unasigned')}
							</p>
							{rightPeople &&
								selectProject?.accessRightType !== AccessRightType.INTERN &&
								rightPeople.map((attendee, index) => (
									<p
										key={index}
										onClick={() =>
											handleNewAssignee({
												type: '',
												emailAddress: {
													name: attendee.username,
													address: attendee.mail,
												},
											})
										}>
										<Avatar name={attendee.username} mail={attendee.mail} index={index} />
										{attendee.username}
									</p>
								))}
							{user &&
								selectProject?.accessRightType === AccessRightType.INTERN &&
								user.userCompany.map((attendee, index) => (
									<p
										key={index}
										onClick={() =>
											handleNewAssignee({
												type: '',
												emailAddress: {
													name: attendee.name,
													address: attendee.userName,
												},
											})
										}>
										<Avatar name={attendee.name} mail={attendee.userName} index={index} />
										{attendee.name}
									</p>
								))}
						</div>
					)}
				</div>
				<button className={styles.formButton} type='submit'>
					<IconSimplePlus /> {t('Create')}
				</button>
			</form>
		</div>
	);
};

export default DetailsTodosFormComponents;
