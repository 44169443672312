/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable unicorn/no-null */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getMeetings, createMeeting, updateMeetingTags } from '../../../store/meetings/slice';
import { PropsModalsCreate } from '../../../shared/types/component-interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from '../../../shared/components/modals/toast/toast-manager';
import { RouteSearchParams, RouteUrls } from '../../../routes/routes-config';
import { MeetingContract, TagContract } from '../../../store/meetings/types';
import {
	getDayLessOneDayStart,
	getDayPastOneDayStart,
	getStartOfDay,
	setToMidnight,
	setToMidnightAddOneDay,
} from '../../../shared/utils/date.util';
import { postMeetingsForSeries } from '../../../store/series/slice';
import 'react-tooltip/dist/react-tooltip.css';
import MeetingFormComponent from './meeting-form.component';
import { checkUserFromFivedays } from '../../../store/user/slice';
import MeetingFormModalInvite from './meeting-form-modal-invite';
import { MeetingsProjectContract, NotificationsProjectType, ProjectContract } from '../../../store/project/type';
import { SendNotificationsProject, linkMeetingProject } from '../../../store/project/slice';
import { NotificationsType } from '../../../store/notifications/type';
import { SendNotifications } from '../../../store/notifications/slice';

const MeetingCreateModal: React.FC<PropsModalsCreate> = ({ close, date, onDateSelect, isSeries, projectId }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useAppSelector((state) => state.user);
	const [timeType, setTimeType] = React.useState('minutes');
	const [isAllDay, setIsAllDay] = React.useState(false);

	const [invite, setInvite] = React.useState(false);
	const [meeting, setMeeting] = React.useState<MeetingContract>();
	const [attendeesNonUser, setAttendeeNonUser] =
		React.useState<{ type: string; emailAddress: { name: string; address: string } }[]>();

	const handleAllDay = (allDay: boolean) => {
		setIsAllDay(allDay);
	};

	const handleTimeType = (time: string) => {
		setTimeType(time);
	};

	const { t } = useTranslation();

	const dispatch = useAppDispatch();

	type FormData = {
		eventSubject: string;
		eventLocation: string;
		eventStart: Date;
		eventEnd: Date;
		isOnline: boolean;
		bodyContent: string;
		attendees: Array<{
			type: string;
			emailAddress: {
				address: string;
			};
		}>;
		serial: boolean;
		meeting: MeetingContract;
		project: ProjectContract;
		isBot: boolean;
	};

	// ------ ATTENDEES

	const [attendees, setAttendees] = React.useState<
		Array<{
			type: string;
			emailAddress: {
				name: string;
				address: string;
			};
		}>
	>([
		{
			type: 'required',
			emailAddress: {
				name: user?.displayName ? user.displayName : '',
				address: user?.email ? user.email : '',
			},
		},
	]);

	const handleNewAttendees = (
		newAt: Array<{
			type: string;
			emailAddress: {
				name: string;
				address: string;
			};
		}>,
	) => {
		setAttendees(newAt);
	};

	// -------------

	const [newTags, setNewTags] = React.useState<Array<{ tag: string; color: string }>>([]);

	const handleNewTags = (tags: Array<{ tag: string; color: string }>) => {
		setNewTags(tags);
	};

	const onSubmit = (data: FormData) => {
		if (data.bodyContent !== undefined) {
			const startDateUP = isAllDay === false ? new Date(data.eventStart) : setToMidnight(data.eventStart);
			const startDateUTC = startDateUP.toISOString();

			const endDateUP = isAllDay === false ? new Date(data.eventEnd) : setToMidnightAddOneDay(data.eventEnd);
			const endDateUTC = endDateUP.toISOString();

			const html = data.bodyContent;

			const body = {
				eventBody: {
					subject: data.eventSubject,
					start: {
						dateTime: startDateUTC,
						timeZone: 'UTC',
					},
					end: {
						dateTime: endDateUTC,
						timeZone: 'UTC',
					},
					location: data.eventLocation,
					attendees,
					isOnlineMeeting: data.isOnline,
					onlineMeetingProvider: 'teamsForBusiness',
					content: html,
					isAllDay,
				},
			};

			const startDate = getDayLessOneDayStart(new Date(startDateUTC));
			const endDate = getDayPastOneDayStart(new Date(startDateUTC));

			const dateRange = { startDate, endDate };

			const nonUserAttendees: { type: string; emailAddress: { name: string; address: string } }[] = [];

			attendees.forEach((attendee) => {
				const address = attendee.emailAddress.address;

				dispatch(checkUserFromFivedays(address)).then((res) => {
					if (res.payload === false) {
						nonUserAttendees.push(attendee);
					}
				});
			});

			dispatch(createMeeting(body)).then((result) => {
				const newMeeting = result.payload as MeetingContract;

				if (user) {
					const createBodyNotification = (attendeeName: string, attendeeEmail: string) => {
						return {
							id: undefined,
							userFromName: user.displayName,
							userFromMail: user.email,
							userToName: attendeeName,
							userToMail: attendeeEmail,
							type: NotificationsType.NEW_MEETING,
							noteId: undefined,
							todoId: undefined,
							graphiCalUId: newMeeting.iCalUId,
							projectId: undefined,
							meetingStartDate: newMeeting.start,
							itemTitle: newMeeting.subject,
							visible: true,
							meetingName: newMeeting.subject,
						};
					};

					newMeeting.attendees.forEach((attendee) => {
						const bodyMeetingNotification = createBodyNotification(
							attendee.emailAddress.name,
							attendee.emailAddress.address,
						);

						if (user.email !== attendee.emailAddress.address) {
							dispatch(SendNotifications(bodyMeetingNotification));
						}
					});
				}

				if (data.project && user) {
					const attendeeEmails = newMeeting
						? newMeeting.attendees.map((attendee) => attendee.emailAddress.address.toLowerCase())
						: [];

					const bodyMeetingProject: MeetingsProjectContract = {
						id: '',
						projectId: data.project.id,
						graphiCalUId: newMeeting.iCalUId,
						meetingName: newMeeting.subject,
						meetingStartDate: newMeeting.start,
						meetingEndDate: newMeeting.end,
						meetingAttendees: attendeeEmails,
						tags: [],
					};
					dispatch(linkMeetingProject({ body: bodyMeetingProject, projectId: data.project.id }));

					const bodyNotification = {
						id: '',
						userFromName: user.name,
						userFromMail: user.email,
						type: NotificationsProjectType.NEW_MEETING,
						noteId: '',
						todoId: '',
						todoDueDate: '',
						itemTitle: '',
						graphiCalUId: newMeeting.iCalUId,
						meetingStartDate: newMeeting.start,
						meetingEndDate: newMeeting.end,
						meetingName: newMeeting.subject,
						meetingAttendees: attendeeEmails,
						projectId: data.project.id,
						userSeenNotif: [],
						todoAssignee: '',
					};

					dispatch(SendNotificationsProject(bodyNotification));
				}
				if (newTags) {
					newTags.forEach((tag) => {
						const tags = [
							...(result.payload as MeetingContract).tags,
							new TagContract(
								undefined,
								tag.tag,
								tag.color,
								user!.graphUserId,
								[(result.payload as MeetingContract).iCalUId],
								[],
								[],
								[],
							),
						];

						dispatch(
							updateMeetingTags({
								tags,
								selectedMeetingID: (result.payload as MeetingContract).iCalUId,
							}),
						);
					});
				}

				if (data.meeting && data.serial === true) {
					const bodySerie = {
						prevGraphiCalUId: data.meeting.iCalUId,
						prevMeetingStartDate: data.meeting.start,
						prevMeetingName: data.meeting.subject,
						nextGraphiCalUId: (result.payload as MeetingContract).iCalUId,
						nextMeetingStartDate: (result.payload as MeetingContract).start,
						nextMeetingName: (result.payload as MeetingContract).subject,
					};
					dispatch(postMeetingsForSeries(bodySerie)).then(() => {
						const ID = (result.payload as MeetingContract).iCalUId;
						const dateEvent = (result.payload as MeetingContract).start;
						dispatch(getMeetings(dateRange));
						onDateSelect(new Date(startDateUTC));
						close();
						const params: any = {};
						params[RouteSearchParams.Date] = getStartOfDay(dateEvent);
						params[RouteSearchParams.MeetingId] = ID;

						navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);

						toast.show({
							id: user?.graphUserId,
							title: t('Meeting create success') as string,
							duration: 10000,
							type: 'success',
						});
					});
				} else {
					const ID = (result.payload as MeetingContract).iCalUId;
					const dateEvent = (result.payload as MeetingContract).start;
					dispatch(getMeetings(dateRange));
					onDateSelect(new Date(startDateUTC));
					setMeeting(result.payload as MeetingContract);
					setAttendeeNonUser(nonUserAttendees);

					if (nonUserAttendees && nonUserAttendees.length > 0) {
						setInvite(true);
					} else {
						close();
						const params: any = {};
						params[RouteSearchParams.Date] = getStartOfDay(dateEvent);
						params[RouteSearchParams.MeetingId] = ID;

						navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
					}

					toast.show({
						id: user?.graphUserId,
						title: t('Meeting create success') as string,
						duration: 10000,
						type: 'success',
					});
				}
			});
		}
	};

	const handleClose = () => {
		close();
		if (location.pathname.includes('/meetings')) {
			navigate(`${RouteUrls.Meetings}`);
		} else if (location.pathname.includes('/calendar')) {
			navigate(`${RouteUrls.Calendars}`);
		}
	};

	const handleCloseInvite = (start: string, id: string) => {
		close();
		const params: any = {};
		params[RouteSearchParams.Date] = getStartOfDay(start);
		params[RouteSearchParams.MeetingId] = id;
		navigate(`${RouteUrls.Meetings}?${new URLSearchParams(params).toString()}`);
	};

	return (
		<>
			{!invite ? (
				<MeetingFormComponent
					onSubmit={onSubmit}
					handleClose={handleClose}
					isAllDay={isAllDay}
					handleAllDay={handleAllDay}
					date={date}
					handleTimeTime={handleTimeType}
					handleNewAttendees={handleNewAttendees}
					type='create'
					handleNewTags={handleNewTags}
					projectId={projectId}
					isBot={false}
				/>
			) : (
				meeting &&
				attendeesNonUser && (
					<MeetingFormModalInvite
						meeting={meeting}
						nonUserAttendees={attendeesNonUser}
						close={handleCloseInvite}
					/>
				)
			)}
		</>
	);
};

export default MeetingCreateModal;
