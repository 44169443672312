import React, { useState } from 'react';
import styles from './copilot-transcript.module.scss';
import { IconLeft } from '../../../../../../../shared/utils/icon';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../../../store/hooks';
import { getDateFormatWithYear, getTimeFormat } from '../../../../../../../shared/utils/date.util';
import { Persona, PersonaSize } from '@fluentui/react/lib/Persona';
import { RequestStatusType } from '../../../../../../../store/shared/types';
import Loader from '../../../../../../../shared/components/loader/loader.component';

export interface Props {
	handleClose: () => void;
}

const CopilotTranscriptModal = ({ handleClose }: Props) => {
	const { t } = useTranslation();
	const [animation, setAnimation] = useState<boolean>(false);
	const { dataMeetingBotTranscript, dataMeetingBotRequestStatusTranscript } = useAppSelector((state) => state.recall);
	const { selectedMeeting } = useAppSelector((state) => state.meetings);

	const closeModal = () => {
		setAnimation(true);
		setTimeout(() => {
			handleClose();
			setAnimation(false);
		}, 300);
	};

	return (
		<div className={`${styles.container} ${animation ? styles.fadeOut : ''}`}>
			<div className={`${styles.modal} ${animation ? styles.slideOut : ''}`}>
				<div className={styles.close} onClick={() => closeModal()}>
					<IconLeft />
					{t('Back')}
				</div>
				{selectedMeeting && (
					<div className={styles.info}>
						<span>{t(getDateFormatWithYear(selectedMeeting.start))}</span>
						<br />
						<span>
							<span>{getTimeFormat(selectedMeeting.start, selectedMeeting.end)}</span>
						</span>
						<h3>{selectedMeeting.subject}</h3>
					</div>
				)}
				<div className={styles.transcript}>
					{dataMeetingBotRequestStatusTranscript &&
						dataMeetingBotRequestStatusTranscript.type === RequestStatusType.InProgress && (
							<div>
								<p className={styles.message}>{t('Generating the transcript')}</p>
								<Loader />
							</div>
						)}
					{dataMeetingBotRequestStatusTranscript &&
						dataMeetingBotRequestStatusTranscript.type === RequestStatusType.Failed && (
							<div>
								<p className={styles.message}>{dataMeetingBotRequestStatusTranscript.errorMessage}</p>
							</div>
						)}
					{dataMeetingBotRequestStatusTranscript &&
						dataMeetingBotRequestStatusTranscript.type === RequestStatusType.Success &&
						dataMeetingBotTranscript?.objectTranscript &&
						dataMeetingBotTranscript?.objectTranscript?.map((entry, index) => (
							<div key={index} className={styles.entry}>
								<div className={styles.left}>
									<div className={styles.name}>
										<Persona
											text={entry?.name ? entry.name : entry?.position}
											secondaryText={entry?.name ? entry.name : entry?.position}
											size={PersonaSize.size32}
											hidePersonaDetails={true}
										/>
										<span>{entry?.name} :</span>
									</div>
									<p className={styles.text}>{entry?.text}</p>
								</div>
								<div className={styles.right}>
									<span>{entry?.startTimesStamp}</span>
								</div>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default CopilotTranscriptModal;
