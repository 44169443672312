import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { togglePanel } from '../../store/layout/slice';
import { PanelState } from '../../store/layout/types';
import ProjetcList from './components/project-list';
import styles from './project.module.scss';
import {
	getFilesProject,
	getMeetingsProject,
	getNotesCommentsProject,
	getNotesProject,
	getNotificationsProject,
	getPeopleShareProject,
	getProjects,
	getTodosProject,
	setSelectedProject,
} from '../../store/project/slice';
import ProjectDetails from './components/project-details';
import { getCustomColumnForAProject } from '../../store/column/slice';
import { useSearchParams } from 'react-router-dom';
import { RouteSearchParams } from '../../routes/routes-config';
import { ProjectContract } from '../../store/project/type';

const ProjectPage = () => {
	const dispatch = useAppDispatch();
	const { selectProject, projects } = useAppSelector((state) => state.project);
	const [searchParams, setSearchParams] = useSearchParams();

	const [projectId, setProjectId] = React.useState('');

	React.useEffect(() => {
		const Id = searchParams.get(RouteSearchParams.ProjectId);
		setProjectId(
			Id ? Id : selectProject ? selectProject.id : projects && projects.length > 0 ? projects[0].id : '',
		);
	}, [searchParams, projects]);

	useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
		dispatch(getProjects()).then((res) => {
			const Id = searchParams.get(RouteSearchParams.ProjectId);
			const proj = res.payload as ProjectContract[];
			if (!Id) {
				const projId = selectProject ? selectProject.id : proj && proj.length > 0 ? proj[0].id : '';
				searchParams.set(RouteSearchParams.ProjectId, projId);
				setSearchParams(searchParams, {});
				const projectToBeShow = proj?.find((projectSelect) => projectSelect.id === projId);
				dispatch(setSelectedProject(projectToBeShow));
			} else {
				searchParams.set(RouteSearchParams.ProjectId, Id);
				setSearchParams(searchParams, {});
				const projectToBeShow = proj?.find((projectSelect) => projectSelect.id === Id);
				dispatch(setSelectedProject(projectToBeShow));
			}
		});
	}, [dispatch]);

	useEffect(() => {
		if (projectId) {
			const projectToBeShow = projects?.find((projectSelect) => projectSelect.id === projectId);
			if (projectToBeShow) {
				dispatch(setSelectedProject(projectToBeShow));
			} else {
				dispatch(setSelectedProject(undefined));
			}
		}
	}, [projectId]);

	useEffect(() => {
		if (selectProject) {
			dispatch(getPeopleShareProject(selectProject.id));
			dispatch(getNotesProject(selectProject.id));
			dispatch(getTodosProject(selectProject.id));
			dispatch(getMeetingsProject(selectProject.id));
			dispatch(getNotesCommentsProject(selectProject.id));
			dispatch(getNotificationsProject(selectProject.id));
			dispatch(getFilesProject(selectProject.id));
			dispatch(getCustomColumnForAProject(selectProject.id));
		}
	}, [selectProject]);

	const [openList, setOpenList] = React.useState(false);
	const [isMobile, setIsMobile] = React.useState(false);
	const mediaQuery = window.matchMedia('(max-width: 500px)');

	function updateIsMobile(event: MediaQueryListEvent): void {
		if (isMobile !== event.matches) {
			setIsMobile(event.matches);
		}
	}

	mediaQuery.addEventListener('change', updateIsMobile);

	const handleOpenList = () => {
		setOpenList(!openList);
	};

	return (
		<div className={styles.mainProjects}>
			{!isMobile && (
				<>
					<ProjetcList showList={handleOpenList} />
					<ProjectDetails showList={handleOpenList} />
				</>
			)}
			{isMobile && (
				<>
					{openList ? (
						<ProjetcList showList={handleOpenList} />
					) : (
						<ProjectDetails showList={handleOpenList} />
					)}
				</>
			)}
		</div>
	);
};

export default ProjectPage;
