import React from 'react';
import styles from './project-notes.module.scss';
import { AccessRightType } from '../../../../../store/meetings/types';
import { createNotes, postPeopleShareNote } from '../../../../../store/notes/slice';
import { NotesContract } from '../../../../../store/notes/type';
import { getDateFormatL } from '../../../../../shared/utils/date.util';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { SendNotificationsProject, getNotesProject } from '../../../../../store/project/slice';
import {
	IconContact,
	IconFilter,
	IconGlobe,
	IconLock,
	IconMeetingsPage,
	IconNotes,
	IconSimplePlus,
} from '../../../../../shared/utils/icon';
import NotesFilterModal from './project-notes-filter';
import DetailsNotesText from './project-notes-detail';
import { NotificationsProjectType } from '../../../../../store/project/type';
import { extractDomainWithExt } from '../../../../../shared/utils/domainext';
import { NotificationsType } from '../../../../../store/notifications/type';
import { SendNotifications } from '../../../../../store/notifications/slice';

const ProjectNotesContent = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { user } = useAppSelector((state) => state.user);
	const { selectProject, projectsNotes, projectsSharePeople } = useAppSelector((state) => state.project);

	// filters //

	const [showFilters, setShowFilters] = React.useState(false);

	const handleShowFilterModal = () => {
		setShowFilters(!showFilters);
	};

	const modalRefFilter = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRefFilter.current && !modalRefFilter.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleShowFilterModal();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleShowFilterModal]);

	// -----

	const [showPersonalNote, setShowPersonalNote] = React.useState<boolean>(true);

	const handleShowPersoNote = (show: boolean) => {
		setShowPersonalNote(show);
	};

	const [showSharedNote, setShowSharedNote] = React.useState<boolean>(true);

	const handleShowSharedNote = (show: boolean) => {
		setShowSharedNote(show);
	};

	const [showArchived, setShowArchived] = React.useState<boolean>(false);

	const handleShowArchived = (show: boolean) => {
		setShowArchived(show);
	};

	// ----------------

	const [menu, setMenu] = React.useState(false);
	const handleMenu = () => {
		setMenu(!menu);
	};

	const modalRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					handleMenu();
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [handleMenu]);

	// ____________

	const [title, setTitle] = React.useState('');
	const [access, setAccess] = React.useState(AccessRightType.EVERYONE);

	// -------

	const domain = extractDomainWithExt(user ? user.email : '');

	const createNote = async () => {
		if (user && selectProject) {
			// const people =
			// 	selectProject.accessRightType === AccessRightType.SHARED && projectsSharePeople
			// 		? projectsSharePeople.map((peopleS) => peopleS.mail.toLowerCase())
			// 		: [user.email];
			const body = {
				id: '',
				title: title
					? title
					: `${t('New draft note')} ${selectProject?.projectName} (${getDateFormatL(new Date())})`,
				text: '',
				graphUserId: user ? user.graphUserId : '',
				graphUserName: user ? user.displayName : '',
				meetingAttendees: [user.email],
				graphiCalUId: '',
				meetingTitle: '',
				meetingStartDate: '',
				tags: [],
				updateNote: new Date(),
				createdOn: new Date(),
				updateOn: new Date(),
				accessRightType: access,
				accessRightCompanies: domain ? [domain] : [],
				archived: false,
				projectId: selectProject.id,
			};

			dispatch(createNotes(body)).then((res) => {
				const newNote = res.payload as NotesContract;

				const createNotificationBody = (attendee: string) => {
					return {
						id: undefined,
						userFromName: user.displayName,
						userFromMail: user.userName,
						userToName: attendee,
						userToMail: attendee,
						type: NotificationsType.NEW_PROJECT_NOTE,
						noteId: newNote.id,
						todoId: undefined,
						graphiCalUId: undefined,
						projectId: selectProject.id,
						meetingStartDate: undefined,
						itemTitle: selectProject.projectName,
						visible: true,
						meetingName: newNote.meetingTitle,
					};
				};

				if (access === AccessRightType.EVERYONE && projectsSharePeople) {
					projectsSharePeople.forEach((attendee) => {
						if (attendee.mail !== user.userName) {
							const bodyNotification: any = createNotificationBody(attendee.mail);
							dispatch(SendNotifications(bodyNotification));
						}
					});
				} else if (access === AccessRightType.SHARED) {
					if (selectProject.accessRightType === AccessRightType.SHARED && projectsSharePeople) {
						projectsSharePeople.forEach((attendee) => {
							if (attendee.mail !== user.userName) {
								const bodyNotification: any = createNotificationBody(attendee.mail);
								dispatch(SendNotifications(bodyNotification));
							}
						});

						projectsSharePeople.forEach((att) => {
							if (att.mail !== user.userName) {
								const bodyAtt = {
									id: '1',
									username: att.username,
									mail: att.mail,
								};

								dispatch(postPeopleShareNote({ body: bodyAtt, noteId: newNote.id }));
							}
						});
					} else {
						user.userCompany.forEach((attendee) => {
							if (attendee.userName !== user.userName) {
								const bodyNotification: any = createNotificationBody(attendee.userName);
								dispatch(SendNotifications(bodyNotification));
							}
						});

						user.userCompany.forEach((att) => {
							if (att.userName !== user.userName) {
								const bodyAtt = {
									id: '1',
									username: att.name,
									mail: att.userName,
								};

								dispatch(postPeopleShareNote({ body: bodyAtt, noteId: newNote.id }));
							}
						});
					}
				} else if (access === AccessRightType.INTERN) {
					user.userCompany.forEach((attendee) => {
						if (attendee.userName !== user.userName) {
							const bodyNotification: any = createNotificationBody(attendee.userName);
							dispatch(SendNotifications(bodyNotification));
						}
					});
				}

				dispatch(getNotesProject(selectProject.id));
				setTitle('');
				setAccess(AccessRightType.EVERYONE);
				handleMenu();

				const bodyNotification = {
					id: '',
					userFromName: user.name,
					userFromMail: user.email,
					type: NotificationsProjectType.NEW_NOTE,
					noteId: newNote.id,
					todoId: '',
					todoDueDate: '',
					itemTitle: newNote.title,
					graphiCalUId: '',
					meetingStartDate: '',
					meetingEndDate: '',
					meetingName: '',
					meetingAttendees: [],
					projectId: selectProject.id,
					userSeenNotif: [],
					todoAssignee: '',
				};

				dispatch(SendNotificationsProject(bodyNotification));
			});
		}
	};

	const trueCount = [showPersonalNote, showSharedNote, showArchived].filter(Boolean).length;

	return (
		<>
			<div className={styles.filters}>
				<div>
					<button
						className={styles.cancel}
						style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
						onClick={() => {
							handleMenu();
						}}>
						{' '}
						<IconSimplePlus /> {t('Create a new note')}
					</button>
				</div>
				<div
					className={showFilters ? styles.activeButton : styles.buttonFilter}
					onClick={() => handleShowFilterModal()}>
					<IconFilter />
					{t('Filters')}
					<div>{trueCount !== 0 ? trueCount : ''}</div>
				</div>
				{showFilters && (
					<div className={styles.filterModalWrapper} ref={modalRefFilter}>
						<NotesFilterModal
							showPersonalNote={showPersonalNote}
							handleShowPersoNote={handleShowPersoNote}
							showSharedNote={showSharedNote}
							handleShowSharedNote={handleShowSharedNote}
							showArchived={showArchived}
							handleShowArchived={handleShowArchived}
						/>
					</div>
				)}

				{menu && (
					<div ref={modalRef}>
						<div className={styles.form}>
							<p>
								<div className={styles.icon}>
									<IconNotes width='23' height='23' />
								</div>
								{t('Create a new note')}
							</p>

							<input
								type='text'
								placeholder={t('Write your title here') as string}
								defaultValue={title}
								onChange={(e) => setTitle(e.target.value)}
							/>
							<div className={styles.flexAccess}>
								<div
									className={
										access === AccessRightType.EVERYONE ? styles.selectAccess : styles.access
									}
									onClick={() => setAccess(AccessRightType.EVERYONE)}>
									<IconGlobe />
									{t('Public')}
								</div>
								<div
									className={access === AccessRightType.SHARED ? styles.selectAccess : styles.access}
									onClick={() => setAccess(AccessRightType.SHARED)}>
									<IconMeetingsPage />
									{t('Shared')}
								</div>
								<div
									className={access === AccessRightType.INTERN ? styles.selectAccess : styles.access}
									onClick={() => setAccess(AccessRightType.INTERN)}>
									<IconContact />
									{t('Intern')}
								</div>
								<div
									className={access === AccessRightType.ONLYME ? styles.selectAccess : styles.access}
									onClick={() => setAccess(AccessRightType.ONLYME)}>
									<IconLock />
									{t('Private')}
								</div>
							</div>
							<button className={styles.formButton} onClick={() => createNote()}>
								<IconSimplePlus />
								{t('Create')}
							</button>
						</div>
					</div>
				)}
			</div>
			{projectsNotes && user && (
				<DetailsNotesText
					notes={projectsNotes.filter((note) => {
						if (!showArchived && note.archived) {
							return false;
						}

						if (showPersonalNote && note.graphUserId === user.graphUserId) {
							return true;
						}
						if (showSharedNote && note.graphUserId !== user.graphUserId) {
							return true;
						}
						return false;
					})}
				/>
			)}
		</>
	);
};

export default ProjectNotesContent;
