/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './notes.form.component.module.scss';
import { useNavigate } from 'react-router-dom';
import { NotesContract } from '../../../store/notes/type';
import {
	deleteNotes,
	deleteOneNote,
	getOneNote,
	getPeopleShareNote,
	setSelectedNote,
	updateNoteArchiveState,
	updateNoteTitle,
	updateNotes,
} from '../../../store/notes/slice';
import { IconArchive, IconDelete, IconDownChev, IconLeftChev } from '../../../shared/utils/icon';
import { togglePanel } from '../../../store/layout/slice';
import { PanelState } from '../../../store/layout/types';
import NotesEditorComponent from '../components/notes-editor.component';
import DeleteConfirmBox from '../../../shared/utils/delete-box';
import { RouteUrls } from '../../../routes/routes-config';
import { getCommentsForANote } from '../../../store/comments/slice';
import NoteFormComments from '../components/note-form-comment';
import Loader from '../../../shared/components/loader/loader.component';
import NotesInformations from '../components/note-form-informations';
import MeetingNotesSimpleEditor from '../../meetings/components/meetingDetails/meetingDetailsBody/notes/meeting-simple-editor';
import HeaderPeople from '../components/note-people';
import { getNotesTodos } from '../../../store/todos/slice';

interface Props {
	toggle: () => void;
	provider: any;
	doc: any;
	noteId: string;
	unmount: boolean;
}

const NotesForm: React.FC<Props> = ({ toggle, provider, doc, noteId, unmount }) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);
	const { notes } = useAppSelector((state) => state.notes);

	const modalRef = React.useRef<HTMLDivElement>(null);
	const modalRefEditor = React.useRef<HTMLDivElement>(null);

	// React.useEffect(() => {
	// 	if (user && noteId) {
	// 		dispatch(getNotesTodos(noteId));
	// 	}
	// }, [noteId]);

	const [noteData, setNoteData] = React.useState<NotesContract>();
	const [updateDate, setUpdateDate] = React.useState<Date>();
	const [isUpdate, setIsUpdate] = React.useState<boolean>(false);
	const [isEditing, setIsEditing] = React.useState<boolean>(false);
	const [openOptions, setOpenOptions] = React.useState<boolean>(false);
	const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
	const [openConfirmBoxArchived, setOpenConfirmBoxArchived] = React.useState(false);

	React.useEffect(() => {
		if (noteData && user) {
			dispatch(getNotesTodos(noteId));
		}
	}, [noteData]);

	const handleNoteData = (note: NotesContract) => {
		setNoteData(note);
	};

	const handleEditingState = () => {
		setIsEditing(!isEditing);
	};

	const handleConfirmBox = () => {
		setOpenConfirmBox(!openConfirmBox);
	};

	const handleDeleteNote = () => {
		if (noteData && user?.graphUserId === noteData.graphUserId) {
			dispatch(deleteOneNote(noteData.id)).then((res) => {
				if (res.meta.requestStatus === 'fulfilled') {
					dispatch(deleteNotes(noteData.id));
					setOpenConfirmBox(!openConfirmBox);
					dispatch(setSelectedNote(undefined));
					navigate(RouteUrls.Notes);
				}
			});
		}
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (openOptions !== undefined && modalRef.current && !modalRef.current.contains(event.target as Node)) {
				setTimeout(() => {
					setOpenOptions(!openOptions);
				}, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [openOptions]);

	React.useEffect(() => {
		dispatch(togglePanel(PanelState.Visible));
	}, [dispatch]);

	React.useEffect(() => {
		if (noteId) {
			dispatch(getOneNote(noteId)).then((result) => {
				setNoteData(result.payload as NotesContract);
				setUpdateDate((result.payload as NotesContract).updateNote);

				dispatch(getCommentsForANote(noteId));
			});

			dispatch(getPeopleShareNote(noteId));
		}
	}, [noteId]);

	let timeoutId: any;

	const onChangeHandlerTitle = (data: string) => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(function () {
			if (noteData && user?.graphUserId === noteData.graphUserId) {
				const body = {
					id: noteData.id,
					title: data,
					text: noteData.text,
					graphUserId: noteData.graphUserId,
					graphUserName: noteData.graphUserName,
					meetingAttendees: noteData.meetingAttendees,
					graphiCalUId: noteData.graphiCalUId,
					meetingTitle: noteData.meetingTitle,
					meetingStartDate: noteData.meetingStartDate,
					tags: noteData.tags,
					updateNote: new Date(),
					createdOn: noteData.createdOn,
					updateOn: noteData.updateOn,
					accessRightType: noteData.accessRightType,
					accessRightCompanies: noteData.accessRightCompanies,
					archived: noteData.archived,
					projectId: noteData.projectId,
					userMail: noteData.userMail,
					creatorCompany: noteData.creatorCompany,
				};
				dispatch(updateNotes({ noteId: noteData.id, body })).then((res) => {
					if (res.payload && typeof res.payload === 'object') {
						const updatedNoteData: NotesContract = {
							...noteData,
							title: data,
						};
						setNoteData(updatedNoteData);
						dispatch(updateNoteTitle({ noteId: noteData.id, newText: data }));
						setUpdateDate(new Date());
						setIsUpdate(true);
					}
				});
			}
		}, 1500);
	};

	const [open, setOpen] = React.useState(false);
	const handleOpen = async () => {
		await dispatch(getOneNote(noteId)).then((result) => {
			setNoteData(result.payload as NotesContract);
			setUpdateDate((result.payload as NotesContract).updateNote);

			dispatch(getCommentsForANote(noteId));
		});

		await dispatch(getPeopleShareNote(noteId));
		setOpen(!open);
	};

	const debounceTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

	const onChangeHandlerText = (data: string) => {
		if (noteData && noteData.text !== data) {
			const body = {
				id: noteData.id,
				title: noteData.title,
				text: data,
				graphUserId: noteData.graphUserId,
				graphUserName: noteData.graphUserName,
				meetingAttendees: noteData.meetingAttendees,
				graphiCalUId: noteData.graphiCalUId,
				meetingTitle: noteData.meetingTitle,
				meetingStartDate: noteData.meetingStartDate,
				tags: noteData.tags,
				updateNote: new Date(),
				createdOn: noteData.createdOn,
				updateOn: noteData.updateOn,
				accessRightType: noteData.accessRightType,
				accessRightCompanies: noteData.accessRightCompanies,
				archived: noteData.archived,
				projectId: noteData.projectId,
				userMail: noteData.userMail,
				creatorCompany: noteData.creatorCompany,
			};
			setUpdateDate(new Date());
			handleNoteData(body);
			setIsUpdate(true);

			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}

			debounceTimeout.current = setTimeout(() => {
				dispatch(updateNotes({ noteId: noteData.id, body })).then((res) => {
					if (res.payload && typeof res.payload === 'object') {
						setUpdateDate(new Date());
						handleNoteData(body);
						setIsUpdate(true);
					}
				});
			}, 1500);
		}
	};

	const [showInformation, setShowInformation] = React.useState(false);

	const handleShowInformation = () => {
		setShowInformation(!showInformation);
	};

	const handleArchived = (noteA: NotesContract) => {
		if (noteA) {
			const body = {
				id: noteA.id,
				title: noteA.title,
				text: noteA.text,
				graphUserId: noteA.graphUserId,
				graphUserName: noteA.graphUserName,
				meetingAttendees: noteA.meetingAttendees,
				graphiCalUId: noteA.graphiCalUId,
				meetingTitle: noteA.meetingTitle,
				meetingStartDate: noteA.meetingStartDate,
				tags: noteA.tags,
				updateNote: new Date(),
				createdOn: noteA.createdOn,
				updateOn: noteA.updateOn,
				accessRightType: noteA.accessRightType,
				accessRightCompanies: noteA.accessRightCompanies,
				archived: noteA.archived === false ? true : false,
				projectId: noteA.projectId,
				userMail: noteA.userMail,
				creatorCompany: noteA.creatorCompany,
			};
			dispatch(updateNotes({ noteId: noteA.id, body })).then((res) => {
				const notedUp = res.payload as NotesContract;
				if (res.payload && typeof res.payload === 'object') {
					dispatch(updateNoteArchiveState({ id: noteA.id, archive: notedUp.archived }));
					setIsUpdate(true);
					setOpenConfirmBoxArchived(!openConfirmBoxArchived);
					dispatch(setSelectedNote(undefined));
					navigate(RouteUrls.Notes);
				}
			});
		}
	};

	const handleToggle = () => {
		if (toggle) toggle();
	};

	React.useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const remirrorMenuBarSelectors =
				'.MuiPaper-root, .MuiStack-root, .MuiPopover-root, .MuiMenu-root, .MuiModal-root';
			const isMenuClick = [...document.querySelectorAll(remirrorMenuBarSelectors)].some((element) =>
				element.contains(event.target as Node),
			);

			if (!isMenuClick && modalRefEditor.current && !modalRefEditor.current.contains(event.target as Node)) {
				setTimeout(handleOpen, 150);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [handleOpen]);

	return (
		<>
			{' '}
			<>
				{!noteData && notes && notes.length < 0 && (
					<div className={styles.noNotes}>
						<h2>{t('You dont have any notes yet')}</h2>
					</div>
				)}
				{!noteData && notes && notes.length > 0 && <Loader />}
				{noteData && (
					<div className={styles.main}>
						<div className={styles.editor} id='editorId'>
							<div className={styles.noteHeader}>
								<div className={styles.iconMob} onClick={() => handleToggle()}>
									<IconLeftChev />
								</div>

								<input
									className={styles.inputTitle}
									readOnly={user?.graphUserId === noteData?.graphUserId ? false : true}
									defaultValue={noteData?.title}
									onChange={(e) => {
										onChangeHandlerTitle(e.target.value);
									}}
								/>
								<span className={styles.more}>
									{noteData && user?.graphUserId === noteData.graphUserId && (
										<div className={styles.icon} onClick={() => handleArchived(noteData)}>
											<IconArchive />
										</div>
									)}
								</span>
								<span className={styles.more}>
									{noteData && user?.graphUserId === noteData.graphUserId && (
										<div className={styles.icon} onClick={() => setOpenConfirmBox(!openConfirmBox)}>
											<IconDelete />
										</div>
									)}
								</span>
							</div>

							<div className={styles.attendees}>
								<HeaderPeople note={noteData} />
							</div>

							<div className={styles.notesWrapper}>
								<div className={styles.show} onClick={() => handleShowInformation()}>
									{t('Show note details')}
									<div
										className={styles.noteDetailsArrow}
										style={{ transform: showInformation ? 'rotate(180deg)' : '' }}>
										<IconDownChev />
									</div>
								</div>

								<div
									style={{ maxHeight: showInformation ? '350px' : '0px' }}
									className={styles.noteDetails}>
									<NotesInformations noteId={noteData.id} handleNoteData={handleNoteData} />
								</div>

								<div className={styles.textEditor}>
									{noteData && user ? (
										<>
											{open ? (
												<div>
													<div>
														<NotesEditorComponent
															content={noteData.text}
															onChangeHandlerText={onChangeHandlerText}
															handleEditingChange={handleEditingState}
															provider={provider}
															doc={doc}
															inviteUser=''
															noteId={noteData.id}
															note={noteData}
															userName={user?.displayName}
															title={noteData.title}
														/>
													</div>
													<NoteFormComments note={noteData} />
												</div>
											) : (
												<>
													<div style={{ cursor: 'pointer' }} onClick={() => handleOpen()}>
														<MeetingNotesSimpleEditor
															content={noteData.text}
															title=''
															note={noteData}
														/>
													</div>
													<NoteFormComments note={noteData} />
												</>
											)}
										</>
									) : (
										<></>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</>
			{openConfirmBox && noteData && (
				<DeleteConfirmBox
					handleDelete={handleDeleteNote}
					handleConfirmBox={handleConfirmBox}
					message='Are you sure you want to delete this note?'
				/>
			)}
		</>
	);
};

export default NotesForm;
