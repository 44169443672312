import { UserLanguage } from '../../store/shared/types';

export const getTextTemplate = (language: UserLanguage) => {
	switch (language) {
		case UserLanguage.EN:
			return `
            Human: You will always provide at least two outputs :
            Human: Summarize the context of the meeting
            Human: Summarize the meeting as a text, if any
            Human: You will not use point but text
            Human: You will use classical paragraph and not numbers
            Human: You will avoid any topic about:
            Human: Personal life of participants
            Human: Pointing out late participant or early leaver in the meeting
        `;

		case UserLanguage.FR:
			return `
            Humain : Vous fournirez toujours au moins deux sorties :
            Humain : Résumez le contexte de la réunion
            Humain : Résumez la réunion sous forme de texte, le cas échéant
            Humain : Vous n'utiliserez pas de points mais du texte
            Humain : Vous utiliserez des paragraphes classiques et non des numéros
            Humain : Vous éviterez tout sujet concernant :
            Humain : La vie personnelle des participants
            Humain : Le fait de signaler les participants arrivant en retard ou partant tôt lors de la réunion
        `;

		case UserLanguage.DE:
			return `
            Mensch: Sie werden immer mindestens zwei Ausgaben bereitstellen:
            Mensch: Fassen Sie den Kontext der Besprechung zusammen
            Mensch: Fassen Sie die Besprechung als Text zusammen, falls vorhanden
            Mensch: Sie verwenden keine Aufzählungspunkte, sondern Text
            Mensch: Sie verwenden klassische Absätze und keine Zahlen
            Mensch: Sie werden Themen vermeiden, die sich mit folgendem befassen:
            Mensch: Dem Privatleben der Teilnehmer
            Mensch: Der Nennung von spät eingetroffenen Teilnehmern oder frühzeitig Gehenden in der Besprechung
        `;

		case UserLanguage.NL:
			return `
            Mens: U zult altijd ten minste twee uitvoer geven:
            Mens: Vat de context van de vergadering samen
            Mens: Vat de vergadering samen in tekstvorm, indien van toepassing
            Mens: U zult geen opsommingstekens gebruiken, maar tekst
            Mens: U zult klassieke alinea's gebruiken en geen nummers
            Mens: U zult elk onderwerp vermijden over:
            Mens: Het persoonlijke leven van de deelnemers
            Mens: Het wijzen op te laat gekomen deelnemers of vroege vertrekkers tijdens de vergadering
        `;

		default:
			return `
            Human: You will always provide at least two outputs :
            Human: Summarize the context of the meeting
            Human: Summarize the meeting as a text, if any
            Human: You will not use point but text
            Human: You will use classical paragraph and not numbers
            Human: You will avoid any topic about:
            Human: Personal life of participants
            Human: Pointing out late participant or early leaver in the meeting
        `;
	}
};

// ------------

export const getListTemplate = (language: UserLanguage) => {
	switch (language) {
		case UserLanguage.EN:
			return `
            Human: You will always provide three outputs :
            Human: Summarize the context of the meeting
            Human: Highlight key outcome of the meeting, if any
            Human: Define next steps in bullet points, if any
            Human: You will avoid any topic about:
            Human: Personal life of participants
            Human: Pointing out late participant or early leaver in the meeting
        `;

		case UserLanguage.FR:
			return `
            Humain: Vous fournirez toujours trois résultats :
            Humain: Résumer le contexte de la réunion
            Humain: Mettre en évidence les résultats clés de la réunion, s'il y en a
            Humain: Définir les prochaines étapes sous forme de puces, s'il y en a
            Humain: Vous éviterez tout sujet concernant :
            Humain: La vie personnelle des participants
            Humain: Mentionner les participants en retard ou ceux qui quittent la réunion plus tôt
        `;

		case UserLanguage.DE:
			return `
            Mensch: Sie werden immer drei Ergebnisse liefern:
            Mensch: Fassen Sie den Kontext des Meetings zusammen
            Mensch: Heben Sie die wichtigsten Ergebnisse des Meetings hervor, falls vorhanden
            Mensch: Definieren Sie die nächsten Schritte in Stichpunkten, falls vorhanden
            Mensch: Sie werden alle Themen vermeiden, die Folgendes betreffen:
            Mensch: Das Privatleben der Teilnehmer
            Mensch: Das Hervorheben von zu spät gekommenen Teilnehmern oder von Personen, die das Meeting früh verlassen haben
        `;

		case UserLanguage.NL:
			return `
            Mens: U zult altijd drie uitkomsten geven:
            Mens: Vat de context van de vergadering samen
            Mens: Benadruk de belangrijkste uitkomsten van de vergadering, indien van toepassing
            Mens: Definieer de volgende stappen in bullet points, indien van toepassing
            Mens: U zult elk onderwerp vermijden dat betrekking heeft op:
            Mens: Het persoonlijke leven van de deelnemers
            Mens: Het wijzen op te late deelnemers of vroegtijdige vertrekkers in de vergadering
        `;

		default:
			return `
            Human: You will always provide three outputs :
            Human: Summarize the context of the meeting
            Human: Highlight key outcome of the meeting, if any
            Human: Define next steps in bullet points, if any
            Human: You will avoid any topic about:
            Human: Personal life of participants
            Human: Pointing out late participant or early leaver in the meeting
        `;
	}
};

// -------------

export const getQuestionTemplate = (language: UserLanguage, question: string) => {
	switch (language) {
		case UserLanguage.EN:
			return `
          Human: You will always provide at least one output:
          Human: Summarize the context of the meeting
          Human: ${question}
          Human: You will avoid any topic about:
          Human: Personal life of participants
          Human: Pointing out late participants or early leavers in the meeting
        `;

		case UserLanguage.FR:
			return `
          Humain: Vous fournirez toujours au moins une réponse :
          Humain: Résumez le contexte de la réunion
          Humain: ${question}
          Humain: Vous éviterez tout sujet concernant :
          Humain: La vie personnelle des participants
          Humain: Le fait de signaler les participants en retard ou les départs anticipés
        `;

		case UserLanguage.DE:
			return `
          Mensch: Sie werden immer mindestens eine Antwort geben:
          Mensch: Fassen Sie den Kontext des Treffens zusammen
          Mensch: ${question}
          Mensch: Sie werden jedes Thema vermeiden über:
          Mensch: Das Privatleben der Teilnehmer
          Mensch: Das Ansprechen von verspäteten Teilnehmern oder frühzeitigen Verlassen des Treffens
        `;

		case UserLanguage.NL:
			return `
          Mens: U zult altijd minstens één antwoord geven:
          Mens: Vat de context van de vergadering samen
          Mens: ${question}
          Mens: U zult elk onderwerp vermijden over:
          Mens: Het persoonlijke leven van de deelnemers
          Mens: Het wijzen op laatkomers of vroege vertrekkers tijdens de vergadering
        `;

		default:
			return `
          Human: You will always provide at least one output:
          Human: Summarize the context of the meeting
          Human: ${question}
          Human: You will avoid any topic about:
          Human: Personal life of participants
          Human: Pointing out late participants or early leavers in the meeting
        `;
	}
};
