import { AccessRightType, FileContract, TagContract } from '../meetings/types';
import { UserRole } from '../user/type';

export enum RequestStatusType {
	New = 'New',
	InProgress = 'InProgress',
	Success = 'Success',
	Failed = 'Failed',
}

export interface RequestStatus {
	type: RequestStatusType;
	errorMessage?: string;
}

export const createRequestStatus = (type: RequestStatusType, errorMessage?: string): RequestStatus => {
	return {
		type,
		errorMessage,
	};
};

export type UpdateMeetingTagsContract = {
	tags: TagContract[];
};

export type UpdateMeetingFilesContract = {
	files: FileContract[];
};

export type UpdateMeetingFilesAccess = {
	accessRightType: AccessRightType | undefined;
	accessRightCompanies: string[] | undefined;
};

export type UpdateUserRole = {
	role: UserRole;
	userToUpdate: string;
	jobTitle?: string;
};

export type UpdateCompany = {
	companyId: string | undefined;
	name: string | undefined;
	country: string | undefined;
	city: string | undefined;
	street: string | undefined;
	postCode: string | undefined;
	industry: string | undefined;
	companyScale: string | undefined;
	companyLogo: string | undefined;
	vatNumber: string | undefined;
};

export class SendInvitationContract {
	constructor(public mail: string | undefined, public role: UserRole) {}
}

export class SendInvitationsContract {
	constructor(public users: SendInvitationContract[]) {}
}

export enum UserLanguage {
	EN = 'en',
	FR = 'fr',
	NL = 'nl',
	DE = 'de',
}
