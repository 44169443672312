import React from 'react';
import styles from './meetings-overview.component.module.scss';
import { MeetingContract } from '../../../../store/meetings/types';
import Loader from '../../../../shared/components/loader/loader.component';
import { RequestStatus, RequestStatusType } from '../../../../store/shared/types';
import { useTranslation } from 'react-i18next';
import MeetingCreateModal from '../../modals/meeting-create.modal';
import { useLocation } from 'react-router-dom';
import { getHour, getRightTimeSlot } from '../../../../shared/utils/date.util';
import { IconLeftChev, IconSimplePlus } from '../../../../shared/utils/icon';

// ----------------

interface MeetingsOverviewProps {
	date: Date;
	meetings: MeetingContract[] | undefined;
	meetingsRequestStatus: RequestStatus;
	toggle: () => void;
}

const MeetingsOverviewComponent = ({ date, meetings, meetingsRequestStatus, toggle }: MeetingsOverviewProps) => {
	const { t } = useTranslation();
	const location = useLocation();

	// FILTER MEETINGS //

	const filteredMeetings = meetings?.filter((meeting) => {
		const start = new Date(meeting.start);
		return (
			start.getFullYear() === date.getFullYear() &&
			start.getMonth() === date.getMonth() &&
			start.getDate() === date.getDate()
		);
	});

	// MODAL CREATE //

	const [toggleCreateMeeting, setToggleCreateMeeting] = React.useState(false);

	const handleToggleCreateMeeting = () => {
		return setToggleCreateMeeting(!toggleCreateMeeting);
	};

	React.useEffect(() => {
		if (location.search.includes('?create')) {
			setToggleCreateMeeting(true);
		}
	}, [location]);

	// ------------------

	return (
		<div className={styles.main}>
			<div className={styles.overviewList}>
				{meetingsRequestStatus.type === RequestStatusType.InProgress && (
					<div className={styles.noMeetings}>
						<Loader></Loader>
					</div>
				)}

				<div className={styles.flex}>
					<div className={styles.icon} onClick={() => toggle()}>
						<IconLeftChev />
					</div>
				</div>

				{meetingsRequestStatus.type === RequestStatusType.Success &&
					filteredMeetings &&
					filteredMeetings.length === 0 && (
						<div className={styles.noMeetings}>
							<div className='meetingsList'>
								<h2>{t('You dont have any meetings scheduled')}</h2>
								<button className={styles.buttonCreate} onClick={() => handleToggleCreateMeeting()}>
									<span>
										<span>
											<IconSimplePlus />
										</span>
										{t('Create event')}
									</span>
								</button>
							</div>
						</div>
					)}

				{toggleCreateMeeting && (
					<MeetingCreateModal
						close={handleToggleCreateMeeting}
						date={getRightTimeSlot(date, getHour(new Date()), 0).toString()}
						onDateSelect={() => undefined}
					/>
				)}
			</div>
		</div>
	);
};

export default MeetingsOverviewComponent;
