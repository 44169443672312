import React from 'react';
import {
	BoldExtension,
	ItalicExtension,
	HeadingExtension,
	ImageExtension,
	OrderedListExtension,
	BulletListExtension,
	UnderlineExtension,
	FontSizeExtension,
	StrikeExtension,
	TextColorExtension,
	HorizontalRuleExtension,
	TextHighlightExtension,
} from 'remirror/extensions';
import { useRemirror, Remirror, EditorComponent, OnChangeJSON } from '@remirror/react';
import { RemirrorJSON, htmlToProsemirrorNode } from 'remirror';

import 'remirror/styles/all.css';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import { SimpleEditorMenu } from './simple-editor-menu';

export const usercolors = ['#30bced', '#6eeb83', '#ffbc42', '#ecd444', '#ee6352', '#9ac2c9', '#8acb88', '#1be7ff'];

interface Props {
	content: string;
	onChangeHandlerText?: (content: string) => void;
	notEditable?: boolean;
}

const SimpleEditor: React.FC<Props> = ({ content, onChangeHandlerText, notEditable }) => {
	const { t } = useTranslation();
	const { user } = useAppSelector((state) => state.user);

	console.log(content);

	const { manager, state } = useRemirror({
		extensions: () => [
			new BoldExtension({}),
			new ItalicExtension(),
			new HeadingExtension({}),
			new ImageExtension({ enableResizing: true }),
			new BulletListExtension({ enableSpine: true }),
			new OrderedListExtension(),
			new UnderlineExtension(),
			new FontSizeExtension({ defaultSize: '16', unit: 'px' }),
			new StrikeExtension(),
			new TextColorExtension({}),
			new HorizontalRuleExtension({}),
			new TextHighlightExtension({}),
		],
		content:
			content === ''
				? undefined
				: content === null
				? undefined
				: content.startsWith('{')
				? JSON.parse(content)
				: content.startsWith('<')
				? content
				: undefined,
		selection: 'start',
		stringHandler: htmlToProsemirrorNode,
	});

	const isEmptyJSON = (Json: RemirrorJSON) => {
		if (!Json || !Json.content) {
			return true;
		}
		const isEmptyContent = Json.content.every((node) => {
			if (node.type === 'paragraph') {
				return !node.content || node.content.length === 0;
			}
			return false;
		});

		return isEmptyContent;
	};

	const handleEditorChange = React.useCallback((Json: RemirrorJSON) => {
		if (onChangeHandlerText && content !== JSON.stringify(Json)) {
			if (isEmptyJSON(Json)) {
				onChangeHandlerText('');
			} else {
				onChangeHandlerText(JSON.stringify(Json));
			}
		}
	}, []);

	// ____

	return (
		<div>
			<div className='remirror-theme'>
				<Remirror
					manager={manager}
					editable={!notEditable ? true : false}
					initialContent={state}
					placeholder={t('Write something here') as string}>
					{!notEditable && <SimpleEditorMenu />}

					<EditorComponent />
					<OnChangeJSON onChange={handleEditorChange} />
				</Remirror>
			</div>
		</div>
	);
};

export default SimpleEditor;
